import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import {  Modal, ModalBody } from 'reactstrap';
import closeIcon from 'resources/common/close_icon.png';
import Terms from 'views/Main/Terms';
import PrivacyPolicy from 'views/Main/PrivacyPolicy';
import ThirdParty from "views/Main/ThirdParty";
import Refund from "views/Main/Refund";

const PolicyModal = ( {modal, handleModal, type, refundInfo} ) => {
  const [modalType, setModalType] = useState("");

  useEffect( () => {
    setModalType(type);
  }, [type]);

  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  
  return ( 
    <Modal 
      // isOpen={ mode === 'pc'? false : modal}
      isOpen={modal}
      toggle={handleModal}
      backdrop="static"
      // aria-labelledby="contained-modal-title-vcenter"
      style={{
        width: '100%',
        height: '100vh',
        // position: "absolute",
        marginTop: mode === 'mobile' ? '48px': '83px',
        border: 'none',
        marginLeft: mode === 'mobile' ? 'auto' : '' ,
        // display: mode === 'pc'? 'none' : '' ,
        // fade: mode==='pc' ? 'false' :'',
        // isOpen: mode=== 'pc' ? 'false' : '',
        // marginLeft: '100px',
        // top: "56%",
        // left: "48%",
        // transform: `translate(-50%, -50%)`,
        // padding: '0px',
        // paddingLeft: '16px',
        // paddingRight: '16px'
      }}
    >
      <ModalBody 
        style={{
          position:"relative", 
          height: '100vh',
          height: modalType ==="banCancel"?"348px": modalType==="cancelBtn"? '100vh' : modalType==='virtualAcc'? "1060px": "" ,
          width: '100%',
          position:'relative',
          border:'0px solid #2C2C2C',
          background: '#FFFFFF',
          padding: '0',
          // display: mode === 'pc'? 'none' : '' ,
          isOpen: mode=== 'pc' ? {handleModal} : '',
          // backdrop: false
        }}>
        <Container>
          <CloseIconContainer>
            <Closeicon type="button" className="closeIcon" onClick={handleModal}></Closeicon> 
          </CloseIconContainer>
          { modalType === "terms/1" ? <Terms />
            : modalType === "privacy" ? <PrivacyPolicy /> 
            : modalType === "terms/3" ? <ThirdParty /> 
            : modalType === "refund" ? <Refund refundInfo={refundInfo}/> 
            : ""
          }
        </Container>
      </ModalBody>
    </Modal> 
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // width: 396px;
  width: 600px;
  background: white;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
  }

`
const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0px 0px;
`

const Closeicon = styled.button`
  // position: absolute;
  // top: 10px;
  // right: -10px;
  height: 15px;
  width: 15px;
  display: block;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${closeIcon});
  cursor: pointer;
  z-index: 11;
  border:white;
  background-color: transparent;

  &:hover{
    transform: rotate(90deg);
  }

  
  @media ${({ theme }) => theme.device.mobile} {
    top: 13px;
  }
`

export default PolicyModal;