import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { isObjEmpty } from 'utils/util';
import { getPortfolioPhotoList, insertHitItem } from './store/action';

import PhotoViewerModal from './PhotoViewerModal';

import NoImage from 'resources/common/no_image_photo.png';

const Photo = ({ portfolioInfo, searchPortfolioNo, callCount, setConnectCallCount }) => {
  const dispatch = useDispatch();

  const [portfolioList, setPortfolioList] = useState();
  const [searchPtfoSeqno, setSearchPtfoSeqno] = useState();

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  const [currentPhotoInfo, setCurrentPhotoInfo] = useState();

  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  // 포트폴리오 클릭
  const clickItem = (item) => {
    // 로그
    dispatch(insertHitItem(item.ptfoSeqno));

    // 포트폴리오 사진 목록 호출
    dispatch(getPortfolioPhotoList(item.ptfoSeqno));

    // 모달 호출 시 하단 주석 해제
    setCurrentPhotoInfo(item);
    handleViewerModal();
  }

  /**
   * 포트폴리오 목록을 부모로 부터 가져와 내부 변수에 저장
   * 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
   */
  useEffect(() => {
    setPortfolioList(portfolioInfo);
    if (searchPortfolioNo !== null && searchPortfolioNo !== '') {
      setSearchPtfoSeqno(searchPortfolioNo)
    }
  }, [portfolioInfo, searchPortfolioNo]);

  useEffect(() => {
    if (!isObjEmpty(portfolioList) && searchPtfoSeqno !== null && searchPtfoSeqno !== '') {
      // 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
      if (!isObjEmpty(portfolioList)) {
        const findItem = portfolioList.filter(item => item.ptfoSeqno === searchPtfoSeqno)
        if (!isObjEmpty(findItem)) {
          if (callCount < 1) {
            setTimeout(() => {
              clickItem(findItem[0])
              setConnectCallCount(1)
            }, 200)
          }
        }
      }
    }
  }, [portfolioList, searchPtfoSeqno]);

  return (
    <ContentContainer>
      {!isObjEmpty(portfolioList) && portfolioList.map((item, index) => {
        return (
          <Item key={index} onClick={() => clickItem(item)}>
            <ThumbNailWrapper>
              <ThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
              <ThumbnailMobile image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
            </ThumbNailWrapper>
            <PhotoInfoWrapper>
              <TagList>
                {!isObjEmpty(item.tagList) && item.tagList.map((tag, i) => {
                  return (
                    <Tag key={i}>{tag.tagNm}</Tag>
                  )
                })}
              </TagList>
              <DescWrapper>
                <div className='title'>{item.ptfoNm}</div>
                <div className='clnt'>{item.clntNm}</div>
                <div className='prc'>{item.prdnCost}</div>
              </DescWrapper>
            </PhotoInfoWrapper>
          </Item>
        )
      })}
      <PhotoViewerModal 
        open={viewerModal}
        handleModal={handleViewerModal}
        photoInfo={currentPhotoInfo}
      />
    </ContentContainer>
  )
}

export default Photo;

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 40px 22px;
  
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    gap: 30px;
  }
`

const Item = styled.div`
  width: 255px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const ThumbNailWrapper = styled.div`
  position: relative;
  text-align: center;
  text-align: -webkit-center;
`

const ThumbNail = styled.div`
  width: 100%;
  aspect-ratio: 3 / 2;
  background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
  background-size: cover, contain;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const ThumbnailMobile = styled.div`
  display: none;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
    width: 100%;
    aspect-ratio: 3 / 2;
    background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
    background-size: cover, contain;
  };
`

const PhotoInfoWrapper = styled.div``

const TagList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0 12px;
  gap: 5px;
`

const Tag = styled.div`
  padding: 6px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ABABAC;
  background: #F5F5F5;
  border-radius: 3px;
`

const DescWrapper = styled.div`
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .clnt {
    color: #6D6E71;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .prc {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
`