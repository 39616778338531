import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getPortfolioPhotoList, insertHitItem } from 'views/Portfolio/store/action';
import PhotoViewerModal from 'views/Portfolio/PhotoViewerModal';

import NoImage from 'resources/common/no_image_photo.png';

const PortfolioPhoto = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.portfolio);
  const navigate = useNavigate();

  const [photoList , setPhotoList] = useState([]);

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  const [currentPhotoInfo, setCurrentPhotoInfo] = useState();

  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  };

  // 더보기 버튼
  const clickMoreButton = () => {
    const elem = {
      ctgrId: `${process.env.REACT_APP_PHOTO_CATEGORY_ID}`
    }

    navigate('/portfolio', {state: elem});
  }

  // 클릭 시 모달 호출
  const clickPhoto = (item) => {
    // 로그
    dispatch(insertHitItem(item.ptfoSeqno));

    // 포트폴리오 사진 목록 호출
    dispatch(getPortfolioPhotoList(item.ptfoSeqno));

    // 모달 호출 시 하단 주석 해제
    setCurrentPhotoInfo(item);
    handleViewerModal();
  }

  // 이미지 없을 경우 대체 이미지 설정
  const handleImageError = (e) => {
    e.target.src = NoImage;
  }
  useEffect(() => {
    setPhotoList(store.photoList);
  }, [store.photoList]);

  return (
    <Container>
      <PortfolioContainer>
        <Title>사진 포트폴리오</Title>
        <MoreButton onClick={() => clickMoreButton()}>더보기 ▶</MoreButton>
        <ItemContainer>
        { photoList && photoList.map((item, index) => {
          return (
            <ItemWrapper key={item.ptfoSeqno} onClick={() => clickPhoto(item)}>
              <ThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
            </ItemWrapper>
          )
        })}
        { photoList && photoList.map((item, index) => {
          if (index < 6) {
            return (
              <MobileItemWrapper key={item.ptfoSeqno} onClick={() => clickPhoto(item)}>
                <ThumbnailMobile src={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} onError={handleImageError} />
              </MobileItemWrapper>
            )
          }
        })}
        </ItemContainer>
      </PortfolioContainer>
      <PhotoViewerModal 
        open={viewerModal}
        handleModal={handleViewerModal}
        photoInfo={currentPhotoInfo}
      />
    </Container>
  )
}

export default PortfolioPhoto;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.device.mobile} {
    padding: 0 16px;
  };
`

const PortfolioContainer = styled.div`
  width: 1088px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto 220px;

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto 90px;
  };
` 

const Title = styled.div`
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  @media ${({theme}) => theme.device.mobile} {
    font-size: 20px;
    line-height: 24px;
  };
`

const MoreButton = styled.div`
  width: 80px;
  height: 30px;
  padding: 7px; 
  margin-left: auto;
  margin-top: 6px;
  text-align: center;
  background: #F5F5F5;
  color: #4B4B4B;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 15px;
  cursor: pointer;

  @media ${({theme}) => theme.device.mobile} {
    height: 16px;
    text-align: right;
    padding: 0;
    background: #FFFFFF;
    color: #ABABAC;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
  };
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 20px 22px;
  margin: 20px 0;

  @media ${({theme}) => theme.device.mobile} {
    margin: 10px 0 0;
    gap: 10px;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  cursor: pointer;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const MobileItemWrapper = styled.div`
  display: none;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
    position: relative;
    width: 48%;
    cursor: pointer;
  }
`

const ThumbNail = styled.div`
  width: 255px;
  height: 170px;
  aspect-ratio: 3 / 2;
  background: url(${props  => props.image}), url(${NoImage}) no-repeat 50% 50%;
  background-size: cover, contain;
  border-radius: 6px;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const ThumbnailMobile = styled.img`
  display: none;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    border-radius: 6px;
  };
`