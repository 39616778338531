import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const globalStyles = createGlobalStyle`
    
  ${reset};
  
  /* 스크롤 부드럽게 이동 */
  html {scroll-behavior: smooth;}
  
  a {
    text-decoration: none !important;
    color: inherit !important;
  }

  * {
    box-sizing:border-box;
  }

  body, button, input, select {
    font-family : "Apple SD Gothic", sans-serif;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;    
  }

  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  
  button {
    cursor: pointer;
    border: none;
  }

  html {
    // height: 100%;
  }

  body {
    // min-width: 1088px;
    /* max-width:1920px; */
    // min-height: 100%;
    margin: 0 auto;
    color: ${props => props.theme.txtBlack};
    background-color: ${props => props.theme.bgColorBlack};
  } 

  ::-webkit-scrollbar {
    position:absolute;
    top: 0;
    right: 0;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: lightgrey;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  .recatch_modal .modal-content {
    background-color: #FFFFFF;
    color: #212121;
  }

  .infinite-scroll-component {
    overflow: unset;
    scrollbar-width: none;
  }

  .swiper-slide-next {
    width: 200px;
  }

  .accordion-button:focus {
    box-shadow: none;
    background-color: #FFFFFF;
    color: #000000;
  }

`;

export default globalStyles;