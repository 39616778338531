import React from 'react';
import { Navigate } from 'react-router-dom';
import { isGuestLoggedIn, isUserLoggedIn } from 'utils/jwtUtil';

/**
 * 로그인 정보가 없을 경우 접근 시 로그인 페이지로 이동시킨다.
 */
const PrivateRoute = ({ element: children }) => {
  return (
    isUserLoggedIn() || isGuestLoggedIn() ? children : <Navigate to="/login" replace />
  );
}

export default PrivateRoute;