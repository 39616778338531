const initialState = {
  categoryList: [],
  codeList: [],
  questionList: [],
  questionItemList: [],
  responseList: [],
  amountList: [],
  quotationInfo: {},
}

const quotation = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CATEGORY_LIST' :
      return {
        ...state,
        categoryList: action.data.categoryList,
        qualityCodeList: action.data.qualityCodeList,
      }
    case 'GET_QUESTION_LIST' :
      return {
        ...state,
        questionList: action.data.questionList,
        questionItemList: action.data.questionItemList,
      }
    case 'GET_QUOTATION_INFO' :
      return {
        ...state,
        responseList: action.data.responseList,
        quotationInfo: action.data.quotationInfo,
        amountList: action.data.amountList,
      }
    case 'INSERT_QUOTATION_INFO' :
      return {
        ...state,
        quotationInfo: action.data
      }
    case 'UPDATE_QUOTATION_INFO' :
      return {
        ...state,
        quotationInfo: action.data
      }

    default:
      return {...state}
  }
}

export default quotation;