import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from 'styles/GlobalStyles';
import theme from 'styles/theme';
import { useState, useEffect } from 'react';
import Router from 'router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'styles/css/apple_sd.css';
import 'styles/fonts/pretendard/pretendard.css';
import 'styles/fonts/prompt/prompt.css';
import GoogleTagManager from 'components/GoogleTagManager';

import ArrowLeft from 'resources/studio/workScope/SOW_arrow_left.png';

function App() {
  const runMode = `${process.env.REACT_APP_RUN_MODE}`;
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    if (!window.scrollY) return

    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleShowButton = () => {
      let checkScrollLocation = window.innerHeight * 1.3;
      if (window.innerWidth > 750) {
        checkScrollLocation = window.innerHeight * 3;
      }
      if (window.scrollY > checkScrollLocation) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }  
    }

    window.addEventListener('scroll', handleShowButton)
    return () => {
      window.removeEventListener('scroll', handleShowButton)
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <title>브리피</title>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="브리피"/>
            <meta property="og:description" content="Bridge to Photo/Videography"/>
            <meta property="og:url" content="https://www.briphy.com"/> 
            <meta property="og:site_name" content="Bridge to Photo/Videography"/>
            <meta property="og:locale" content="ko_KR"/>
          </Helmet>
        </HelmetProvider>
        <GlobalStyles />
        <Router />
        {showButton && (
        // <ScrollToTopContainer>
          // <ScrollToTopButton><img src={ArrowLeft} size={window.innerWidth > 750 ? '48' : '32'} onClick={() => handleScroll()} /></ScrollToTopButton>
          <ScrollToTopButton src={ArrowLeft} size={window.innerWidth > 750 ? '48' : '20'} onClick={() => handleScroll()} />
        // </ScrollToTopContainer>
        )}
      </ThemeProvider>
      {runMode === 'prod' ? (
        <GoogleTagManager gtmId={`${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`} />
      ) : ''}
    </>
  );
}

export default App;

const ScrollToTopContainer = styled.div`
  height: 0px;
`

const ScrollToTopButton = styled.img`
  position: sticky;
  bottom: 70px;
  float: right;
  z-index: 10000;
  right: 20px;
  background-color: #FFFFFF;
  height: 48px;
  border-radius 24px;
  rotate: 90deg;
  cursor: pointer;
  // height: 0px;

  @media ${({theme}) => theme.device.mobile} {
    width: 40px;
    height: 40px;
    border-radius 20px;
    bottom: 40px;
  }
`