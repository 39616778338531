import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSOWContentItems } from 'utils/util';

import SOWImage01 from 'resources/studio/workScope/SOW_image_01.png'
import SOWImage02 from 'resources/studio/workScope/SOW_image_02.jpg'
import SOWImage03 from 'resources/studio/workScope/SOW_image_03.png'
import SOWImage04 from 'resources/studio/workScope/SOW_image_04.jpg'

const WorkScope = () => {

  const [selectedWorkScopeNo, setSelectedWorkScopeNo] = useState('01');
  const [workScopeInfo, setWorkScopeInfo] = useState();
  const scopeContentItems = getSOWContentItems();

  useEffect(() => {
    if (selectedWorkScopeNo) {
      const findData = scopeContentItems.find(item => item.num === selectedWorkScopeNo);
      setWorkScopeInfo(findData);
    }
  }, [selectedWorkScopeNo])

  useEffect(() => {
    document.getElementById('workScopeMenu_01').style.color = '#000000';
  }, [])

  const onChangeMenu = (num) => {
    if (selectedWorkScopeNo !== num) {
      setSelectedWorkScopeNo(num);
      for(let i=1; i<7; i++) {
        const findElem = document.getElementById('workScopeMenu_0'.concat(i));
        if (findElem.id === 'workScopeMenu_'.concat(num)) {
          findElem.style.color = '#000000'; 
        } else {
          findElem.style.color = '#21212166'; 
        }
      }
    }
  }

  return (
    <Container>
      <LeftContainer>
        <LeftTopWrapper>
          <LeftTitle>SCOPE OF WORK</LeftTitle>
        </LeftTopWrapper>
        <LeftBottomWrapper>
          {scopeContentItems.map((item, index) => {
            return (
            <LeftMenuList key={index} id={'workScopeMenu_'.concat(item.num)} onClick={() => onChangeMenu(item.num)}>
              <LeftMenuItemNo>{item.num}</LeftMenuItemNo>
              <LeftMenuItemName>{item.name}</LeftMenuItemName>
            </LeftMenuList>
            )
          })}
        </LeftBottomWrapper>
      </LeftContainer>
      <RightContainer>
        <ScopeDescriptionWrapper>
          <ScopeTitleWrapper>
            <ScopeNo>{workScopeInfo?.num}</ScopeNo>
            <ScopeName>{workScopeInfo?.name}</ScopeName>
          </ScopeTitleWrapper>
          <ScopeDescription><pre>{workScopeInfo?.desc}</pre></ScopeDescription>
          {workScopeInfo?.verified? (
            <ScopeVerified>
              <ScopeVerifiedBold>Verified by BRIPHY.</ScopeVerifiedBold>
              {workScopeInfo?.verified}
            </ScopeVerified>
          ) : ''}
        </ScopeDescriptionWrapper>
        <ScopeContentWrapper>
          <ContentImage>
          { selectedWorkScopeNo === '01' ? (
            <img src={SOWImage01} width={828} />
          ) : selectedWorkScopeNo === '02' ? (
            <img src={SOWImage02} width={828}/>
          ) : selectedWorkScopeNo === '03' ? (
            <img src={SOWImage03} width={828} />
          ) : selectedWorkScopeNo === '04' ? (
            <img src={SOWImage04} width={828} />
          ) : selectedWorkScopeNo === '05' ? (
            <img src={SOWImage01} width={828} />
          ) : <img src={SOWImage01} width={828} />
          }
          </ContentImage>
          <ContentInfo>
            <ContentInfoRow>
            <ContentInfoTitle>Client</ContentInfoTitle>
            <ContentInfoValue>{workScopeInfo?.portfolioClient}</ContentInfoValue>
            </ContentInfoRow>
            <ContentInfoRow>
            <ContentInfoTitle>유형</ContentInfoTitle>
            <ContentInfoValue>{workScopeInfo?.portfolioType}</ContentInfoValue>
            </ContentInfoRow>
            <ContentInfoRow>
            <ContentInfoTitle>제공내역</ContentInfoTitle>
            <ContentInfoValue>{workScopeInfo?.portfolioScope}</ContentInfoValue>
            </ContentInfoRow>
          </ContentInfo>
        </ScopeContentWrapper>
        <ScopeContentDescription>
          <ContentTitle>{workScopeInfo?.portfolioName}</ContentTitle>
          <ContentDescription><pre>{workScopeInfo?.portfolioDesc}</pre></ContentDescription>
        </ScopeContentDescription>
      </RightContainer>
    </Container>
  )
}

export default WorkScope;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 1080px;
  min-height: calc(100vh - 62px);
  background-color: #FFFFFF;
`

const LeftContainer = styled.div`
  width: 546px;
  height: 1080px;
`

const LeftTopWrapper = styled.div`
  width: 546px;
  height: 430px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  margin-left: 123px;
`

const LeftTitle = styled.div`
  font-family: Prompt;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  width: 235px;
  // margin-left : 123px;
  margin-top : 120px;
  color: #212121;
`

const LeftBottomWrapper = styled.div`
  width: 546px;
`

const LeftMenuList = styled.div`
  width: 200px;
  display: flex;
  margin-left: 40px;
  margin-bottom: 20px;
  color: #21212166;
  cursor: pointer;

`

const LeftMenuItemNo = styled.div`
font-family: Pretendard;
font-size: 12px;
font-weight: 600;
line-height: 12px;
text-align: left;
margin-right: 8px;
`

const LeftMenuItemName = styled.div`
font-family: Pretendard;
font-size: 20px;
font-weight: 700;
line-height: 20px;
text-align: left;
`

const RightContainer = styled.div`
  margin-top: 124px;
`

const ScopeDescriptionWrapper = styled.div`
  // height: 340px;
`

const ScopeTitleWrapper = styled.div`
  display:flex;
  height: 30px;
  margin-bottom: 24px;
`

const ScopeNo = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  margin-right: 10px;
`

const ScopeName = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  // color: #21212166;  
`

const ScopeDescription = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 16px;
`
const ScopeVerified = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  
  margin-top: 6px;
  display: flex;
  color: #21212166;
`

const ScopeVerifiedBold = styled.div`
  font-weight: 900;
  color: #000000;
`

const ScopeContentWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  height: 466px;
  gap: 24px;
`

const ContentImage = styled.div`
  height: 466px;
  width: 826px;
`

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
`

const ContentInfoRow = styled.div`
  display: flex;
  flex-direction: row;
`

const ContentInfoTitle = styled.div`
  width: 118px;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 800;
  line-height: 21.6px;
  text-align: left;
`
const ContentInfoValue = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.48px;
  text-align: left;
`

const ScopeContentDescription = styled.div`
  margin-top: 40px;
`

const ContentTitle = styled.div`
  font-family: Prompt;
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin-bottom: 20px;
`

const ContentDescription = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
`