import styled, { keyframes } from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { mainApi } from 'utils/api';
import { checkPortfolioVisitorSession, setPortfolioVisitorSession } from 'utils/jwtUtil';
import { isObjEmpty } from 'utils/util';
import { getCategoryList, getPortfolioList, getTagList } from './store/action';

import Video from './Video';
import Photo from './Photo';
import ContactPopup from './ContactPopup';
import MobileContactPopup from './MobileContactPopup';

import CheckboxUnChecked from 'resources/portfolio/checkbox.svg';
import CheckboxChecked from 'resources/portfolio/checkbox_checked.svg';
import X from 'resources/portfolio/close_icon.svg';
import NoDataImage from 'resources/common/no_image.svg';

import NaverADLogAnalyticsConvert from 'components/NaverADLogAnalyticsConvert';

const Portfolio = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.portfolio);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [isVideoCategory, setIsVideoCategory] = useState(true)

  // 1번 이상 중복 수행 방지용 카운트
  const [connectCallCount, setConnectCallCount] = useState(0);

  // URL로 영상 seqno가 전달되면 바로 팝업을 띄우기 위한 상태관리
  const [searchPortfolioNo, setSearchPortfolioNo] = useState();
  
  // 상위 카테고리 ID
  const [videoCategoryId, setVideoCategoryId] = useState(`${process.env.REACT_APP_VIDEO_CATEGORY_ID}`); // 영상
  const [photoCategoryId, setPhotoCategoryId] = useState(`${process.env.REACT_APP_PHOTO_CATEGORY_ID}`); // 사진

  const [isFirst, setIsFirst] = useState(true); // 최초 화면 여부
  const [isPortfolioFirst, setIsPortfolioFirst] = useState(true); // 최초 화면 여부

  const [currentType, setCurrentType] = useState(); // 선택한 상위 카테고리 ID
  const [currentCategoryId, setCurrentCategoryId] = useState(); // 선택한 카테고리 ID

  const [categoryList, setCategoryList] = useState([]); // 카테고리 목록
  const [tagList, setTagList] = useState([]); // 태그 목록

  const [selectedTagList, setSelectedTagList] = useState([]); // 선택한 태그 목록
  const [portfolioList, setPortfolioList] = useState([]); // 포트폴리오 목록

  const [toggleTagWrapper, setToggleTagWrapper] = useState(false); // 검색 필터창 토글
 
  // 검색 필터 초기화
  const initialFilter = () => {
    // 두 번 호출 중
    store.portfolioList = [];
    setCurrentCategoryId('');
    setSelectedTagList([]); 
    setPortfolioList([]);
    setToggleTagWrapper(false);
    
    // 체크된 태그 목록 초기화
    const checkedList = document.querySelectorAll("input[type=checkbox][id^=tag]:checked");
    const checkedAllList = document.querySelectorAll("input[type=checkbox][id^=allTag]:checked");
    
    checkedList?.forEach(item => {
      item.checked = false;
    });

    checkedAllList?.forEach(item => {
      item.checked = false;
    });
    
    const checkedMobileList = document.querySelectorAll("input[type=checkbox][id^=mtag]:checked");
    const checkedMobileAllList = document.querySelectorAll("input[type=checkbox][id^=mAllTag]:checked");
    
    checkedMobileList?.forEach(item => {
      item.checked = false;
    });

    checkedMobileAllList?.forEach(item => {
      item.checked = false;
    });
  }
  
  // 상위 카테고리 변경 시 클릭한 변수 초기화 및 데이터 세팅
  const setSelectedType = (e, type) => {
    setCurrentType(type);
    setCurrentCategoryId('');
    setToggleTagWrapper(false);

    // 버튼 애니메이션
    const target = e.target;
    const highlight = document.querySelector('.highlight');
    const targetRect = target.getBoundingClientRect();
    highlight.style.left = `${targetRect.left}px`;
  }

  // 카테고리 타입 슬라이드 CSS 적용
  const setHighlightCSS = () => {
    let type = currentType;

    if (!isObjEmpty(location.state) && location.state.ctgrId) {
      type = location.state.ctgrId;
    }

    const videoTarget = document.querySelectorAll('.selector-item')[0];
    const photoTarget = document.querySelectorAll('.selector-item')[1];

    const vrect = videoTarget.getBoundingClientRect();
    const prect = photoTarget.getBoundingClientRect();

    const highlight = document.querySelector('.highlight');

    if (type === videoCategoryId) {
      highlight.style.left = `${vrect.left}px`;
    } else if (type === photoCategoryId) {
      highlight.style.left = `${prect.left}px`;
    } else {
      highlight.style.left = `${vrect.left}px`;
    }
  }

  // 화면 너비 변화에 따른 카테고리 타입 슬라이드 CSS 적용
  useEffect (()=> {
    window.addEventListener('resize', setHighlightCSS);
    return () => {
      window.removeEventListener('resize', setHighlightCSS);
    };
  }, [window.innerWidth]);

  /**
   * 카테고리 버튼 클릭
   * @param {Object} item 클릭한 카테고리 정보
   * @param {boolean} isActive 현재 카테고리와 클릭한 카테고리의 일치 여부
   */
  const clickCategoryButton = (item, isActive) => {
    setCurrentCategoryId(item.prodCtgrId);

    if (isFirst) {
      checkAll(item.prodCtgrId, isFirst);
      checkAllMobile(item.prodCtgrId, isFirst);
    } else {
      // 기존 선택된 체크박스 전체 해제 후 선택한 태그로 다시 체크박스 선택
      const checkedAllList = document.querySelectorAll(`input[type=checkbox][name^=tag]:checked`);
      const mobileCheckedAllList = document.querySelectorAll(`input[type=checkbox][name^=mtag]:checked`);
      
      checkedAllList?.forEach(item => {
        item.checked = false;
      });
      
      mobileCheckedAllList?.forEach(item => {
        item.checked = false;
      });
      
      selectedTagList?.forEach(item => {
        let check = document.querySelector(`input[type=checkbox][id=tag_${item}]`);
        check.checked = true;
      });

      selectedTagList?.forEach(item => {
        let check = document.querySelector(`input[type=checkbox][id=mtag_${item}]`);
        check.checked = true;
      });

      clickCheck(item.prodCtgrId);
      clickCheckMobile(item.prodCtgrId);
    }


    if (isActive) {
      setToggleTagWrapper(!toggleTagWrapper);
    } else {
      setToggleTagWrapper(true);
    }
  }

  /**
   * 전체 선택 체크박스 클릭
   * @param {string} ctgrId 선택한 카테고리 id
   * @param {boolean} isFirst 처음 화면 여부
   */ 
  const checkAll = (ctgrId, first) => {
    const allcheck = document.querySelector(`input[type=checkbox][id=allTag_${ctgrId}]`);
    const checkList = document.querySelectorAll(`input[type=checkbox][name=tag_${ctgrId}]`);
    
    if (first) {
      allcheck.checked = true;
    }

    checkList.forEach(item => {
      item.checked = allcheck.checked;
    });
  }

  /**
   * 전체 선택 체크박스 클릭
   * @param {string} ctgrId 선택한 카테고리 id
   * @param {boolean} isFirst 처음 화면 여부
   */ 
  const checkAllMobile = (ctgrId, first) => {
    const mobileAllcheck = document.querySelector(`input[type=checkbox][id=mAllTag_${ctgrId}]`);
    const mobileCheckList = document.querySelectorAll(`input[type=checkbox][name=mtag_${ctgrId}]`);
    
    if (first) {
      mobileAllcheck.checked = true;
    }

    mobileCheckList.forEach(item => {
      item.checked = mobileAllcheck.checked;
    });
  }

  /**
   * 체크박스(태그) 클릭
   * @param {string} ctgrId 선택한 카테고리 id
   */
  const clickCheck = (ctgrId) => {
    // 현재 카테고리의 태그 목록 길이
    const checkboxListLength = document.querySelectorAll(`input[type=checkbox][name=tag_${ctgrId}]`).length;
    
    // 체크한 태그 목록
    const checkedListLength = document.querySelectorAll(`input[type=checkbox][name=tag_${ctgrId}]:checked`).length;

    if (checkboxListLength === checkedListLength) {
      // 전체 체크박스 체크
      document.querySelector(`input[type=checkbox][id=allTag_${ctgrId}]`).checked = true;
    } else {
      document.querySelector(`input[type=checkbox][id=allTag_${ctgrId}]`).checked = false;
    }
  }

  /**
   * 체크박스(태그) 클릭 - 모바일
   * @param {string} ctgrId 선택한 카테고리 id
   */
  const clickCheckMobile = (ctgrId) => {
    // 현재 카테고리의 태그 목록 길이
    const checkboxListLength = document.querySelectorAll(`input[type=checkbox][name=mtag_${ctgrId}]`).length;
    
    // 체크한 태그 목록
    const checkedListLength = document.querySelectorAll(`input[type=checkbox][name=mtag_${ctgrId}]:checked`).length;

    if (checkboxListLength === checkedListLength) {
      // 전체 체크박스 체크
      document.querySelector(`input[type=checkbox][id=mAllTag_${ctgrId}]`).checked = true;
    } else {
      document.querySelector(`input[type=checkbox][id=mAllTag_${ctgrId}]`).checked = false;
    }
  }

  // 찾기 버튼
  const clickSearchButton = (view) => {
    setToggleTagWrapper(false);
    setIsFirst(false); // 화면 로드 후 첫 검색 시 isFirst false 필요

    let checkedList;

    if (view === 'PC') {
      checkedList = document.querySelectorAll("input[type=checkbox][id^=tag]:checked");
    } else if (view === 'Mobile') {
      checkedList = document.querySelectorAll("input[type=checkbox][id^=mtag]:checked");
    }

    let tagArray = [];

    checkedList?.forEach(item => {
      tagArray.push(item.value);
    });

    setSelectedTagList(tagArray);
  }

  // 검색 호출
  const searchPortfolioList = () => {
    const elem = {
      ctgrId: currentType ? currentType : videoCategoryId,
      arrTagSeqno: !isObjEmpty(selectedTagList) ? selectedTagList.toString() : null,
      // page: ,
      // rows: 
    }

    dispatch(getPortfolioList(elem));
  }

  // 태그 삭제
  const deleteTag = (item) => {
    let tagArray = selectedTagList;
    tagArray = tagArray.filter(tag => tag !== item.tagSeqno);
    setSelectedTagList(tagArray);
  }

  useEffect(() => {
    if (!isObjEmpty(selectedTagList)) {
      searchPortfolioList();
    }
  }, [selectedTagList]);

  // 포트폴리오 목록 세팅
  useEffect(() => {
    setPortfolioList(store.portfolioList);
  }, [store.portfolioList]);

  // 카테고리 목록 세팅
  useEffect(() => {
    if (!isObjEmpty(store.categoryList)) {
      store.categoryList.filter(prev => isObjEmpty(prev.hgrkProdCtgrId)).map(item => {
        if (item.prodCtgrNm === '영상' && item.ptfoOnlyYn === 'Y') {
          setVideoCategoryId(item.prodCtgrId);
          if (currentType === null || currentType === undefined || currentType === '') {
            setCurrentType(item.prodCtgrId);
          }
        } else if (item.prodCtgrNm === '사진' && item.ptfoOnlyYn === 'Y') {
          setPhotoCategoryId(item.prodCtgrId);
        }
      });

      setCategoryList(store.categoryList);
    }

    if (!isObjEmpty(store.tagList)) {
      setTagList(store.tagList);
    }
  }, [store.categoryList, store.tagList]);
  
  // 카테고리 조회
  useEffect(() => {
    // 접속 이력 생성
    if (connectCallCount < 1) {
      mainApi.insertConnectInfo({cnntParam: searchParams.get('cnntParam'), pageDvnm: '포트폴리오'});
    }
    if (searchParams.get('cnntParam')) {
      if (!checkPortfolioVisitorSession(searchParams.get('cnntParam'))) {
        setPortfolioVisitorSession(searchParams.get('cnntParam'));
      }
    }

    // URL로 전달된 카테고리 정보가 있다면 카테고리 정보를 먼저 반영
    if (searchParams.get('ctgrId')) {
      // console.log("ctgrId : ", searchParams.get('ctgrId'))
      setCurrentType(searchParams.get('ctgrId'))
    } else {
      if (location.state) {
        setCurrentType(location.state.ctgrId);
      }
    }
    
    // 접속 URL에 영상 지정 queryParameter가 있다면 추출
    if (searchParams.get('ptfoNo')) {
      setSearchPortfolioNo(searchParams.get('ptfoNo'))
    }

    dispatch(getCategoryList());
    dispatch(getTagList());
    setHighlightCSS();
  }, []);

  useEffect(() => {
    if (currentType !== null && currentType !== undefined && currentType !== '') {
      setIsVideoCategory(currentType === videoCategoryId)
      initialFilter();
      setHighlightCSS();
      searchPortfolioList();
    }
  }, [currentType]);

  // useEffect(() => {
  //   // navigate(".", { replace: true });
  // }, [navigate]);

  return (
    <>
    {isPortfolioFirst ? (
      <NaverADLogAnalyticsConvert type='5' value='0' setIsPortfolioFirst={setIsPortfolioFirst} isExcludeConvert={true}/>
    ) : ''}

    <Container>
      {/* <script defer
        dangerouslySetInnerHTML={{
          __html: `
          var _nasa={};
          if(window.wcs) _nasa["cnv"] = wcs.cnv("5","0"); // 전환유형 : 기타로 설정

          if (!wcs_add) var wcs_add={};
          wcs_add["wa"] = "s_5030e6e28cbd";
          if (!_nasa) var _nasa={};
          if(window.wcs){
            wcs.inflow("briphy.com");
            wcs_do(_nasa);
          }
          `
        }}
      /> */}
      <Menu>포트폴리오</Menu>
      {/* 문의하기 PC 팝업 */}
      <ContactPopup />
      <PortfolioContainer>
        <TopContainer>
          <TypeWrapper className='selector'>
            <HighlightButton className='highlight' />
            <SlideTypeButton className='selector-item' active={isVideoCategory} onClick={(e) => setSelectedType(e, videoCategoryId)}>영상</SlideTypeButton>
            <SlideTypeButton className='selector-item' active={!isVideoCategory} onClick={(e) => setSelectedType(e, photoCategoryId)}>사진</SlideTypeButton>
          </TypeWrapper>
          
          {/* PC 카테고리 */}
          <FilterContainer>
            <CategoryWrapper>
              {!isObjEmpty(categoryList) && categoryList.map((item => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <CategoryButton
                      key={item.prodCtgrId}
                      onClick={() => clickCategoryButton(item, currentCategoryId === item.prodCtgrId)}
                      active={currentCategoryId === item.prodCtgrId}
                    >
                      {item.prodCtgrNm}
                    </CategoryButton>
                  )  
                }
              }))}
            </CategoryWrapper>

            <TagWrapper show={toggleTagWrapper} className={toggleTagWrapper ? 'appear' : 'disappear'}>
              {!isObjEmpty(categoryList) && categoryList.map(item => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <TagItem key={item.prodCtgrId} showYn={item.prodCtgrId === currentCategoryId}>
                      <Check 
                        name={`allTag_${item.prodCtgrId}`}
                        id={`allTag_${item.prodCtgrId}`}
                        onChange={() => checkAll(item.prodCtgrId, false)}
                      />
                      <label htmlFor={`allTag_${item.prodCtgrId}`}>전체</label>
                    </TagItem>
                  )
                }
              })}

              {!isObjEmpty(tagList) && tagList.map(((item) => {
                return (
                  <TagItem key={item.tagSeqno} showYn={item.ctgrId === currentCategoryId}>
                    <Check 
                      name={`tag_${item.ctgrId}`}
                      id={`tag_${item.tagSeqno}`}
                      value={item.tagSeqno}
                      onChange={() => clickCheck(item.ctgrId)}
                    />
                    <label htmlFor={`tag_${item.tagSeqno}`}>{item.tagNm}</label>
                  </TagItem>
                )
              }))}
              <SearchButton onClick={() => clickSearchButton('PC')}>찾기</SearchButton>
            </TagWrapper>
          </FilterContainer>

          <SelectedTagWrapper>
            {tagList.filter(prev => selectedTagList.includes(prev.tagSeqno)).map(item => {
              return (
                <SelectTagItem key={item.tagSeqno}>{item.tagNm}
                  <XIcon onClick={() => deleteTag(item)}><img src={X} /></XIcon>
                </SelectTagItem>
              )
            })}
          </SelectedTagWrapper>

          {/* 모바일 카테고리 */}
          <MobileFilterContainer>
            <MobileCategoryWrapper>
              {!isObjEmpty(categoryList) && categoryList.map((item => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <MobileCategoryButton
                      key={item.prodCtgrId}
                      onClick={() => clickCategoryButton(item, currentCategoryId === item.prodCtgrId)}
                      active={currentCategoryId === item.prodCtgrId}
                    >
                      {item.prodCtgrNm}
                      {/* ({tagList.filter(prev => item.prodCtgrId === prev.ctgrId && selectedTagList.includes(prev.tagSeqno)).length}) */}
                    </MobileCategoryButton>
                  )  
                }
              }))}
            </MobileCategoryWrapper>
          </MobileFilterContainer>
        </TopContainer>
 
        { isObjEmpty(portfolioList) ?
          <NoData>
            <NoDataIcon><img src={NoDataImage} /></NoDataIcon>
            <NoDesc>조건에 맞는 데이터가 없습니다</NoDesc>
          </NoData> : ''
        }

        {/* 포트폴리오 목록 */}
        { currentType === videoCategoryId ? <Video portfolioInfo={portfolioList} searchPortfolioNo={searchPortfolioNo} callCount={connectCallCount} setConnectCallCount={setConnectCallCount} /> : <Photo portfolioInfo={portfolioList} searchPortfolioNo={searchPortfolioNo} callCount={connectCallCount} setConnectCallCount={setConnectCallCount} /> }

      </PortfolioContainer>

      {/* 모바일 카테고리 태그 필터 */}
      { toggleTagWrapper ? <ShadowBox onClick={() => setToggleTagWrapper(false)} /> : '' }
        <MobileFilterPopup showYn={toggleTagWrapper}>
          <MobileTagContainer>
            <LineHeader />
            <MobileCategoryTabWrapper>
              {!isObjEmpty(categoryList) && categoryList.map((item => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <MobileCategoryTab
                      key={item.prodCtgrId}
                      onClick={() => clickCategoryButton(item, currentCategoryId === item.prodCtgrId)}
                      active={currentCategoryId === item.prodCtgrId}
                    >
                      {item.prodCtgrNm}
                      {/* ({tagList.filter(prev => item.prodCtgrId === prev.ctgrId && selectedTagList.includes(prev.tagSeqno)).length}) */}
                    </MobileCategoryTab>
                  )  
                }
              }))}
            </MobileCategoryTabWrapper>
            <MobileTagList>
              {!isObjEmpty(categoryList) && categoryList.map(item => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <MobileTagItem key={item.prodCtgrId} showYn={item.prodCtgrId === currentCategoryId}>
                      <Check 
                        name={`mAllTag_${item.prodCtgrId}`}
                        id={`mAllTag_${item.prodCtgrId}`}
                        onChange={() => checkAllMobile(item.prodCtgrId, false)}
                      />
                      <label htmlFor={`mAllTag_${item.prodCtgrId}`}>전체</label>
                    </MobileTagItem>
                  )
                }
              })}

              {!isObjEmpty(tagList) && tagList.map(((item) => {
                return (
                  <MobileTagItem key={item.tagSeqno} showYn={item.ctgrId === currentCategoryId}>
                    <Check 
                      name={`mtag_${item.ctgrId}`}
                      id={`mtag_${item.tagSeqno}`}
                      value={item.tagSeqno}
                      onChange={() => clickCheckMobile(item.ctgrId)}
                    />
                    <label htmlFor={`mtag_${item.tagSeqno}`}>{item.tagNm}</label>
                  </MobileTagItem>
                )
              }))}
            </MobileTagList>
          </MobileTagContainer>
          <MobileSearchButtonWrapper>
            <MobileSearchButton onClick={() => clickSearchButton('Mobile')}>찾기</MobileSearchButton>
          </MobileSearchButtonWrapper>
        </MobileFilterPopup>

      {/* 문의하기 Mobile 팝업 */}
      <MobileContactPopup />
    </Container>
    </>
  )
}

export default Portfolio;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: calc(100vh - 62px);
`
  
const Menu = styled.div`
  width: 100%;
  margin: 120px auto 40px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 77px 0 27px;
    font-size: 20px;
    line-height: 24px;
  }
`

const PortfolioContainer = styled.div`
  width: 1088px;
  margin: 0 auto 180px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0 auto 250px;
    padding: 0 16px;
  }
`

const TopContainer = styled.div`
  margin-bottom: 10px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-bottom: 65px;
  }
`

const TypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  width: 300px;
  height: 42px;
  background: #FAFAFA;
  box-shadow: inset 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 207px;
    height: 32px;
    padding: 2px;
    margin-bottom: 15px;
  }
`

const HighlightButton = styled.div`
  position: absolute;
  z-index: 1;
  width: 144px;
  height: 36px;
  left: 58px;
  background-color: #FE5A1D;
  font-weight: 700;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
  padding: 9px;
  border-radius: 5px;
  transition: left 0.2s ease-out;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100px;
    height: 28px;
    padding: 5px;
    left: 18px;
  }
`

const SlideTypeButton = styled.div`
  width: 144px;
  height: 36px;
  text-align: center;
  padding: 9px;
  border-radius: 5px;
  cursor: pointer;
  color: ${props => props.active ? '#FFFFFF' : '#ABABAC'};
  font-weight: ${props => props.active ? 700 : 200};
  font-size: 16px;
  line-height: 19px;
  z-index: 2;
  transition: color 0.2s ease;
  
  & + & {
    margin-left: 5px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100px;
    height: 28px;
    padding: 5px;
  }
`

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const disappear = keyframes`
  from {
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
`
const FilterContainer = styled.div`
  position: relative;
  margin-bottom: 55px;

  .appear {
    animation: ${appear} 0.2s;
    animation-fill-mode: forwards;
    // animation: ${appear};
    // animation-duration: 0.3s;
  }
  
  .disappear {
    animation: ${disappear} 0.2s;
    animation-fill-mode: forwards;
    // animation: ${disappear};
    // animation-duration: 0.3s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const CategoryWrapper = styled.div`
  position: absolute;
  z-index: 20;
  display: inline-flex;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const CategoryButton = styled.div`
  min-width: 128px;
  height: 32px;
  padding: 7px;
  text-align: center;
  border-radius: 5px;
  border: ${props => props.active ? '1px solid #FE5A1D' : ''};
  cursor: pointer;
  color: ${props => props.active ? '#FE5A1D' : '#ABABAC'};
  transition: all .1s linear;
  font-size: 14px;
  font-weight: ${props => props.active ? 700 : 200};
  line-height: 17px;
`


const TagWrapper = styled.ul`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 16px;
  width: 639px;
  padding: 36px 30px 31px;
  flex-flow: row wrap;
  gap: 20px 0;
  
  // animation: ${props => props.show ? appear : disappear};
  // animation-duration: 0.3s;

  background: #FFFFFF;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;
const TagItem = styled.li`
  display: ${props => props.showYn ? 'flex' : 'none'};
  width: 20%;
  min-width: 144px;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 16px;
    height: 16px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const SearchButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: -16px;

  width: 120px;
  height: 32px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: #ABABAC;
  border: 1px solid #ABABAC;
  border-radius: 5px;
  margin-left: auto;

  &:hover {
    font-weight: 600;
    border: none;
    background: #4B4B4B;
  }
`

const SelectedTagWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 32px;
  flex-flow: row wrap;
  gap: 5px 10px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const SelectTagItem = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  padding: 6px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ABABAC;
  background: #F5F5F5;
  border-radius: 3px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
` 

const XIcon = styled.div`
  cursor: pointer;
  margin-left: 3px;
`

const MobileFilterContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`

const MobileCategoryWrapper = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    width: 100%;
    gap: 0 5px;
    overflow-x: auto;
    white-space: nowrap;
    left: 0;
    position: absolute;
    z-index: 3;
    padding-left: 16px;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const MobileCategoryButton = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    height: 30px;
    padding: 5px 15px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
    background: #FFFFFF;
    border: 1px solid #ABABAC;
    border-radius: 35px;
  }
`

const itemKeyframes = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const MobileFilterPopup = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: ${props => props.showYn ? 'block' : 'none'};
    bottom: 0;
    z-index: 30;
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;

    animation: ${itemKeyframes} 0.1s ease-in-out;
  }
`

const MobileTagContainer = styled.div`
  width: 100%;
  height: 70vh;
  padding: 10px 16px 20px;
`

const ShadowBox = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
    animation: ${itemKeyframes} 0.2s ease-in-out;
  }
`

const LineHeader = styled.div`
  width: 48px;
  height: 5px;
  margin: 0 auto 7px;
  background: #DDDDDD;
  border-radius: 2px;
`

const MobileCategoryTabWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  margin: 11px 0;
  border-bottom: 1px solid #ABABAC;

  ::-webkit-scrollbar {
    display: none;
  }
`

const MobileCategoryTab = styled.div`
  width: 100%;
  padding: 11px 7px;
  color: ${props => props.active ? '#FE5A1D' : '#4B4B4B'};
  font-weight: ${props => props.active ? 700 : 300};
  font-size: 15px;
  line-height: 18px;
  border-bottom: ${props => props.active ? '3px solid #FE5A1D' : 'none'};
`

const MobileSearchButtonWrapper = styled.div`
  width: 100%;
  padding: 6px 16px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background: #FFFFFF;
`

const MobileTagList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 414px;
  margin: 25px 0 20px;
  overflow-y: auto;
  gap: 30px 0;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #d3d3d347;
    border-radius: 8px;
  }
`

const MobileTagItem = styled.li`
  display: ${props => props.showYn ? 'flex' : 'none'};
  flex-direction: ${props => props.showYn ? 'flex' : 'none'};
  align-items: center;

  label {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 24px;
    height: 24px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const MobileSearchButton = styled.div`
  width: 100%;
  height: 54px;
  padding: 18px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #FE5A1D;
  border-radius: 5px;
`

const NoData = styled.div`
  margin-top: 130px;
  height: 500px;
  text-align: center;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 80px;
    height: 460px;
  }
`

const NoDataIcon = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 15px;

    img {
      width: 30%;
      height: 30%;
    }
  }
`

const NoDesc = styled.div`
  color: #ABABAB;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media ${({ theme }) => theme.device.mobile} {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`