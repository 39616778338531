import styled from 'styled-components';
import RoundChecked from 'resources/member/round_checkbox_checked.svg';
import RoundUnChecked from 'resources/member/round_checkbox.svg';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustUserInfo, modifyUserInfo } from './store/action';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/common/FormError';
import { pwReg, emailReg } from 'components/regulation';
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { convertPhoneNumberFormat, isObjEmpty } from 'utils/util';
import YouTubePlayer from 'react-player/youtube';
import { handleAlertText } from 'components/common/SweetAlert';
import NumberFormat from 'react-number-format';

const UserInfo = ({changePageNav}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector(state => state.mypages);
  const [custUserNo, setCustUserNo] = useState();

  // 수신설정
  const [rcvCheck, setRcvCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [snsEmailCheck, setSNSEmailCheck] = useState(false);
  
  const schema = yup.object().shape({
    custUserNm: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    email: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches(emailReg,'⚠ 올바른 이메일 형식을 입력하셔야 합니다'),
    hpNo: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches(
        /^\d{3}-\d{4}-\d{4}$/,
        '전화번호 형식에 맞게 입력해주세요'),
    currentPW: yup
      .string()
      .when('newPW', {
        is: (newPW) => !isObjEmpty(newPW),
        then: yup.string().required('⚠ 필수로 입력하셔야 합니다'),
        otherwise: yup.string().notRequired(),
      }),
    newPW: yup
      .string()
      .when('newPW', {
        is: (newPW) => isObjEmpty(newPW),
        then: yup.string().notRequired(),
        otherwise: yup.string().matches( pwReg, '⚠ 비밀번호는 숫자/영문자/특수문자 조합으로 8~15자리 입력해주세요.'),
      }),
    confirmCurPW: yup
      .string()
      // .required('⚠ 필수로 입력하셔야 합니다')
      .when('confirmCurPW', {
        is: (confirmCurPW) => isObjEmpty(confirmCurPW),
        then: yup.string().notRequired(),
        otherwise: yup.string().oneOf([yup.ref('newPW'), null], '⚠ 비밀번호가 일치하지 않습니다'),
      })
  }, [['newPW', 'newPW'],['confirmCurPW', 'confirmCurPW'],['currentPW' , 'newPW']]);

  const { 
    handleSubmit,
    register, 
    formState:{errors}, 
    control,
    setValue,
    } = useForm({
      resolver: yupResolver(schema)
  });

  //수신설정 모두 체크 할 시 
  const rcvCheckAll = (checked) => {
    setRcvCheck(checked);
    setSNSEmailCheck(checked);
    setSmsCheck(checked);
  }

  useEffect(() => {
    if (smsCheck && snsEmailCheck) {
      setRcvCheck(true);
    } else {
      setRcvCheck(false);
    }
  }, [smsCheck, snsEmailCheck]);
  
  // 새로고침 할 때 마다 로컬스토리지에 저장된 회원번호를 통해 회원정보를 디비에서 가져옴
  useEffect (() => {
    const custUserInfo = JSON.parse(localStorage.getItem('briphyMarketplaceUser')); // 로그인 할 때 로컬스토리지에 저장된 회원정보
    const custUserNo = custUserInfo.custUserNo;
    if (custUserNo) {
      setCustUserNo(custUserNo);
      dispatch(getCustUserInfo(custUserNo)); // action을 dispatch 함수로 실행시켜줌
    }
  }, []);
  
  // store에 저장된 사용자 정보들중 화면에 필요한 정보들만 가져와서 뿌려주기
  useEffect (() => {
    const data = store.custUserInfo; // store에 저장되어 있는 사용자 정보들 
    if(data) {
      setSNSEmailCheck(data.emailRcvAgrYn === 'Y' ? true : false);
      setSmsCheck(data.smsRcvAgrYn === 'Y' ? true : false);
      
      // 사용자 정보들 중에 화면에서 필요한 정보들만 가져오기
      const formList = ['loginId', 'custUserNm', 'email', 'hpNo']; 
      formList.map( elem => {
        setValue(elem, data[elem]) // elem에 data[elem] 값을 setting
        const dom = document.querySelector(`input[name=${elem}]`)
        if (dom) {
          dom.value = data[elem];
          if (dom.name === 'hpNo') {
            dom.value=convertPhoneNumberFormat(data[elem]);
          }
        }
      })
    }
  }, [store.custUserInfo]);

  // sweetAlert 커스터마이징
  const CustomedSwal = withReactContent(Swal);

  // 확인 버튼 클릭시 회원 정보 수정하기
  const onSubmit = async(newData) => {
    CustomedSwal.fire({
      title: "정말 수정하시겠습니까?",
      text: "정말 회원 정보를 수정하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText:"취소",
      confirmButtonText: '확인',
      reverseButtons: true,
    }).then(async(result) => {
      if (result.isConfirmed) {
        if(newData) {
          const param = {
            custUserNo : custUserNo,
            custUserNm : newData.custUserNm,
            preLoginPwd : newData.currentPW ? newData.currentPW : null ,
            loginPwd : newData.newPW ? newData.newPW : null,
            hpNo : newData.hpNo.replaceAll('-',''),
            email : newData.email,
            emailRcvAgrYn : snsEmailCheck ? 'Y' : 'N',
            smsRcvAgrYn : smsCheck ? 'Y' : 'N',
          }
          dispatch(modifyUserInfo(custUserNo, param)); // action을 dispatch 함수로 실행시켜줌

          // 비밀번호들 inputbox 초기화
          document.getElementById('currentPW').value = '';
          document.getElementById('newPW').value = '';
          document.getElementById('confirmCurPW').value = '';
        } 
      } 
    })
  }

   // '취소' 버튼 클릭 시
   const cancelHandler = () => {
    navigate('/');
   }  	
  
  // 회원탈퇴 버튼을 눌렀을 시
  const delAccHandler = () => {
    navigate('/deleteAccount');
  }

  // 모바일 사이즈 세팅
  const [mode, setMode] = useState("pc");

  useEffect (()=> {
    const handleResize = () => {
      setMode(window.innerWidth);
      if(window.innerWidth < 768) {
        setMode("mobile");
      } else {
        setMode("pc");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <Title>회원정보</Title>
        <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InnerContent>
            <InputWrapper>
              {mode === 'pc' ? 
                <div>
                  <div id='leftCol'>
                    <label htmlFor='label'> 
                      아이디
                    </label>
                  </div>
                  <div id='rightCol'>
                    <InputBox 
                      disabled={true} 
                      style={{ border:'none', color:'black'}} 
                      {...register('loginId')}
                    />
                  </div>
                </div> : mode === 'mobile' ? 
                  <div>
                    <div id='leftCol'>
                      <label htmlFor='label'> 
                        아이디
                      </label>
                    </div>
                    <div id='rightCol'>
                      <InputBox 
                        readOnly
                        {...register('loginId')}
                      />
                    </div>
                  </div> : ''
              }
              <div>
                <div id='leftCol'>
                  <label htmlFor='label'>
                    비밀번호
                  </label>
                </div>
                <div id='rightCol'>
                  <PWInputBox 
                    type='password' 
                    placeholder='비밀번호 변경 시 현재 비밀번호를 입력해주세요'
                    id='currentPW'
                    {...register('currentPW')}
                  />
                  {errors.currentPW?
                    <FormErrorWrapper id='newPWFormWrapper'>
                      <FormError message={errors.currentPW?.message}/> 
                    </FormErrorWrapper> :'' }
                    
                  <PWInputBox 
                    type='password' 
                    placeholder='새 비밀번호를 입력해주세요'
                    id='newPW'
                    {...register('newPW')}
                  />
                  {errors.newPW?
                    <FormErrorWrapper id='newPWFormWrapper'>
                      <FormError message={errors.newPW?.message}/> 
                    </FormErrorWrapper> :'' }
  
                  <InputBox 
                    type='password' 
                    placeholder='새 비밀번호를 한번 더 입력해주세요'
                    id='confirmCurPW'
                    {...register('confirmCurPW')}
                  />
                  {errors.confirmCurPW?
                  <FormErrorWrapper>
                    <FormError message={errors.confirmCurPW?.message}/> 
                  </FormErrorWrapper> : '' }
                </div>
              </div>
               
              <div>
                <div id='leftCol'>
                  <label htmlFor='label'>
                    이름
                  </label>
                </div>
                <div id='rightCol'>
                  <InputBox 
                    placeholder='이름을 입력해주세요'
                    {...register('custUserNm')}
                  />
                  <FormError message={errors.custUserNm?.message}/>
                </div>
              </div>

              <div>
                <div id='leftCol'>
                  <label htmlFor='label'>
                    이메일
                  </label>
                </div>
                <div id='rightCol'>
                  <InputBox 
                    placeholder='이메일을 입력해주세요'
                    {...register('email')}
                  />
                    <FormError message={errors.email?.message}/>
                </div>
              </div>

              <div>
                <div id='leftCol'>
                  <label htmlFor='hpNo'>
                    휴대폰
                  </label>
                </div>
                <div id='rightCol'>
                  <Controller 
                    id='hpNo'
                    name='hpNo'
                    control={control}
                    render={({ field: {ref, ...field}}) => 
                    <NumberFormat {...field} 
                      type='text'
                      htmlFor='hpNo'
                      placeholder='휴대폰 번호를 입력해주세요'
                      format='###-####-####'
                      mask='_'
                      innerRef={ref}
                      customInput={InputBox}
                    />}
                  />
                  {/* <InputBox 
                    placeholder='휴대폰 번호를 입력해주세요' 
                    type='number'
                    {...register('hpNo')}
                  /> */}
                  <FormErrorWrapper id="hpFormWrapper">
                    <FormError message={errors.hpNo?.message}/>
                  </FormErrorWrapper>
                </div>
              </div>
            <div id='setting'>
              <div style={{width:'100px'}}>
                수신설정
              </div> 
                { mode === "pc" ? 
                  <CheckboxWrapper>
                    <CheckField size={'16px'}>  
                      <div style={{width:'300px', marginRight:'-20px'}}>
                        <label htmlFor='rcvCheckAll'>
                          <Check 
                            id='rcvCheckAll'
                            name='rcvCheckAll'
                            checked={rcvCheck}
                            onChange={() => rcvCheckAll(!rcvCheck)}
                            fontSize={14}
                          /><i className='check-icon'></i>[선택] 광고성 정보 수신 및 마케팅 활용 동의
                        </label>
                      </div>              
                    </CheckField>
                    <CheckField size={'16px'}>   
                      <div style={{width:'70px', marginRight:'150px'}}>
                        <label htmlFor='sms'>
                          <div style={{display:'inline'}}>(</div>
                          <Check 
                            id='sms'
                            name='sms'
                            checked={smsCheck}
                            onChange={() => setSmsCheck(!smsCheck)}
                            fontSize={14}
                          /> <i className='check-icon'></i>SMS
                        </label>
                      </div>             
                    </CheckField>
                    <CheckField size={'16px'}>   
                      <div style={{width:'100px', marginLeft:'-140px'}}>
                        <label htmlFor='snsEmail'>
                          <Check 
                            id='snsEmail'
                            name='snsEmail'
                            checked={snsEmailCheck}
                            onChange={() => setSNSEmailCheck(!snsEmailCheck)}
                            fontSize={14}
                          /><i className='check-icon'></i>이메일 )
                        </label>
                      </div>             
                    </CheckField>
                  </CheckboxWrapper> : mode === "mobile" ? <CheckboxWrapper>
                  <CheckField size={'20px'}>  
                    <div>
                      <label htmlFor='rcvCheckAll'>
                        <Check 
                          id='rcvCheckAll'
                          name='rcvCheckAll'
                          checked={rcvCheck}
                          onChange={() => rcvCheckAll(!rcvCheck)}
                          fontSize={14}
                        /><i className='check-icon'></i>[선택] 광고성 정보 수신 및 마케팅 활용 동의
                      </label>
                    </div>              
                  </CheckField>
                  <div style={{display:"flex", marginLeft:"30px" }}>
                  <CheckField size={'20px'}>   
                    <div>
                      <label htmlFor='sms'>
                        <Check 
                          id='sms'
                          name='sms'
                          checked={smsCheck}
                          onChange={() => setSmsCheck(!smsCheck)}
                          fontSize={14}
                        /> <i className='check-icon'></i>SMS
                      </label>
                    </div>             
                  </CheckField>
                  <CheckField size={'20px'}>   
                    <div style={{marginLeft: "-30px"}}>
                      <label htmlFor='snsEmail'>
                        <Check 
                          id='snsEmail'
                          name='snsEmail'
                          checked={snsEmailCheck}
                          onChange={() => setSNSEmailCheck(!snsEmailCheck)}
                          fontSize={14}
                        /><i className='check-icon'></i>이메일
                      </label>
                    </div>             
                  </CheckField>
                  </div>
                </CheckboxWrapper> : ""
              }
            </div>
            </InputWrapper>
          </InnerContent>
          <Buttons>
            <CancelBtn type="button" onClick={cancelHandler}> 취소 </CancelBtn>
            <ConfirmBtn type="submit"> 확인 </ConfirmBtn>
          </Buttons>
          </form>
        </Content>
      <DeleteAccount 
        onClick={delAccHandler}
      > 회원탈퇴
      </DeleteAccount>
    </Container>
  )
}

export default UserInfo;

const Container = styled.div`

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    padding: 92px 16px 0px;
    margin-bottom: 160px;
    // background: pink;
  }
`

const Title = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  position: relative;
  margin: 40px auto auto;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0; 
    // margin-bottom: 38px;
    font-size: 20px;
    line-height: 24px; 
  }
`

const Content = styled.div`
  width: 790px;
  height: 480px;
  position: relative;
  margin: 42px auto auto 80px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 42px;
  }
`

const InnerContent = styled.div`
  width: 790px;
  height: 380px;

  #setting {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    margin-top: 29px;
    // position: relative;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    // margin-top: -16px;

    #setting {
      display: block;
      margin-top: 16px;

      label {
        margin-top: 0;
      }
    }
  }
`
const CheckboxWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    display: block;
    height: 75px;
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 0.25px solid #D9D9D9;
    padding: 15px 0px 13px 8px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})`
`

const CheckField = styled.div`
  width: 100%;
  text-align: left;

  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon { 
    background-image: url(${RoundChecked}); 
  }

  .check-icon { 
    display: inline-block; 
    width: ${props => props.size};
    height: ${props => props.size};
    margin: 0 10px 0 0;
    background: url(${RoundUnChecked}) no-repeat 95%; 
    background-size: cover;
    vertical-align: middle; 
    transition-duration: .2s; 
  }
`

const InputWrapper = styled.div`
  width: 790px;
  height: 349px;
  position: relative;
  top: -10px;
  
  #leftCol {
    display: inline;
    position:relative;
    top: 25px;
  }

  #rightCol {
    padding-left: 90px;

    #newPWFormWrapper {
      margin-top: -10px;
    }

    #hpFormWrapper {
      margin-bottom: -10px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 500;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    // height: 495px;
    height: 100%;

    label {
      margin-bottom: 5px;
      margin-top: 16px;
    }

    #leftCol {
      display: contents;
      position: relative;
      top: 25px;
      background-color: grey;
      margin-bottom: 50px;
    }
    
    #rightCol {
      padding: 0;
    }
  }
`

const InputBox = styled.input`
  width: 700px;
  height: 34px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 0.25px solid #D9D9D9;
  font-size: 16px;
  font-weight: 500;
  padding-left: 18px;
  padding-top: 4px;

  &::placeholder {
    color: #ABABAC;
    font-weight: 200;
    font-size: 16px;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-weight: 400;
    padding: 15px 0px 14px 8px;
    
    &::placeholder {
      color: #ABABAC;
      font-weight: 200;
      line-height: 18px;
      font-size: 15px;
    }
  }
`

const PWInputBox = styled.input`
  margin-bottom: 10px;
  width: 700px;
  height: 34px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 0.25px solid #D9D9D9;
  font-size: 16px;
  font-weight: 500;
  padding-left: 18px;
  padding-top: 4px;

  &::placeholder {
    color: #ABABAC;
    font-weight: 200;
    font-size: 16px;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-weight: 400;
    padding: 15px 0px 14px 8px;
    margin-bottom: 10px;
    
    &::placeholder {
      color: #ABABAC;
      font-size: 15px;
    }
  }
`

const FormErrorWrapper = styled.div`
  #newPWFormWrapper {
    background: pink;
    margin-top: -10px;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  height: 174px;
  justify-content:center;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    position: initial;
    margin: 0;
    margin-top: 60px;
    // margin: 100px auto auto -300px;
    display: flex;
  }
`

const CancelBtn = styled.button`
  width: 90px;
  height: 44px;
  background: #FFFFFF;
  border: 0.5px solid #2C2C2C;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-top: 4px;
  color: #2C2C2C;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 54px;
    font-size: 18px;
    border: 0.25px solid #2C2C2C;
  }
`

const ConfirmBtn = styled.button`
  width: 90px;
  height: 44px;
  background: #FE5A1D;
  border-radius: 5px;
  border: 0.5px solid #FE5A1D;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  padding-top: 4px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 54px;
    font-size: 18px;
  }
`

const DeleteAccount = styled.button`
  width: 100px;
  height: 30px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  color: #ABABAC;
  background-color: transparent;
  position: relative;
  left: 90%;
  margin-top: 50px;
  text-decoration: underline;
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    position: initial;
    margin-top: 20px;
  }
`
