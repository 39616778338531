import { portfolioApi } from 'utils/api';

export const getCategoryList = () => {
  return async dispatch => {
    await portfolioApi.getCategoryList().then(response => {
      dispatch({
        type: 'GET_CATEGORY_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const getTagList = () => {
  return async dispatch => {
    await portfolioApi.getTagList().then(response => {
      dispatch({
        type: 'GET_TAG_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const getStudioPortfolioList = (obj) => {
  return async dispatch => {
    await portfolioApi.getStudioPortfolioList(obj).then(response => {
      dispatch({
        type: 'GET_PORTFOLIO_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const insertContact = (obj) => {
  return async dispatch => {
    await portfolioApi.insertContact(obj).then(response => {
    }).catch(err => console.log(err))
  }
}

export const insertHitItem = (ptfoSeqno) => {
  return async dispatch => {
    await portfolioApi.insertHitItem(ptfoSeqno).then(response => {
    }).catch(err => console.log(err))
  }
}

export const getMainPortfolioList = (elem) => {
  return async dispatch => {
    await portfolioApi.getMainPortfolioList(elem).then(response => {
      dispatch({
        type: 'GET_MAIN_PORTFOLIO_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const getPortfolioPhotoList = (ptfoSeqno) => {
  return async dispatch => {
    await portfolioApi.getPortfolioPhotoList(ptfoSeqno).then(response => {
      dispatch({
        type: 'GET_PORTFOLIO_PHOTO_FILE_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}


export const getVideoMainPortfolioList = (elem) => {
  return async dispatch => {
    await portfolioApi.getVideoMainPortfolioList(elem).then(response => {
      dispatch({
        type: 'GET_VIDEO_MAIN_PORTFOLIO_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}