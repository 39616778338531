import React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import FormError from 'components/common/FormError';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isObjEmpty } from 'utils/util';
import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import { strictPhoneReg } from 'components/regulation';

const FindPassword = ({userId, sendPW}) => {
  const schema = yup.object().shape({
    loginId: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    hpNo: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( strictPhoneReg, '⚠ 전화번호 형식에 맞게 입력해주세요'),
  });

  const {
    register,
    formState:{errors},
    handleSubmit,
    control, 
  } = useForm({
    resolver: yupResolver(schema)
  });

  // '비밀번호 재설정' 버튼 클릭 시
  const clickHandler = async(val) => {
    if (isObjEmpty(errors)) { // error가 없을 때 - Validation Check를 통과 했을 시
    	const elem = { 
        loginId : val.loginId,
        hpNo: val.hpNo.replaceAll('-','')
    }
    const res = await memberApi.findPW(elem).then( response => {
      // 위에서 보낸 elem이 데베에 있는지 확인
			if (response.data === '451') {
				handleAlertText('비밀번호찾기 실패!', '입력하신 정보와 일치하는 고객님이 존재하지 않습니다', 'error');
				return false;
			} else if (response.data.startsWith( 'SNS_JOIN:' )) {
        //  “SNS_JOIN:카카오” 로 return 되면 replaceAll(”SNS_JOIN:”, “”)로 불필요 문자 삭제 후 “카카오로 간편가입되어 있습니다. 카카오로 간편로그인을 하십시오”로 메시지 출력
        const snsType = response.data.replaceAll( 'SNS_JOIN:', '');
				handleAlertText( '비밀번호찾기 오류!', snsType.concat('(으)로 간편가입되어 있습니다. ').concat(snsType.concat('(으)로 간편로그인을 하십시오')), 'error');
				return false;
			} else { // 정상적으로 비밀번호를 찾았을 경우에는 고객사용자번호(custUserNo)를  return, 비밀번호 변경 화면으로 이동하여 비밀번호 변경 시 고객사용자번호를 함께 전달
				const custUserNo = response.data;
        sendPW(custUserNo);
			}
		}, error => { 
				handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
			})
    }
  };
    
  return (
    <form onSubmit={handleSubmit(clickHandler)}>
      <Wrapper>
        <Contentbox>
          <InputWrapper>
            <Inputbox
              name='loginId'
              placeholder='아이디를 입력해주세요' 
              id='loginId'
              {...register('loginId')}
            >
            </Inputbox>
            {errors.loginId? 
              <FormErrorWrapper>
                {<FormError message={errors.loginId?.message}/>}
              </FormErrorWrapper> : ''}
            
            <Controller 
              id='hpNo'
              name='hpNo'
              control={control}
              render={({ field: {ref, ...field}}) => 
              <NumberFormat {...field} 
                type='text'
                htmlFor='hpNo'
                placeholder='휴대폰 번호를 입력해주세요' 
                format='###-####-####'
                mask='_'
                innerRef={ref}
                customInput={Inputbox}
              />}
            />
            {/* <Inputbox 
              name='hpNo'
              placeholder='휴대폰 번호를 입력해주세요' 
              id='hpNo'
              {...register('hpNo')}
            >
            </Inputbox> */}
            {errors.hpNo?
            <FormErrorWrapper>
              {<FormError message={errors.hpNo?.message}/>}
            </FormErrorWrapper> : ''}

            <Noti>
              가입 시 등록하신 휴대폰 번호를 입력해주세요 
            </Noti>
        	</InputWrapper>

          <Button type='submit'>비밀번호 재설정</Button>
          <br />
      	</Contentbox>
    	</Wrapper>
    </form>
  );
}

export default FindPassword;

const Wrapper = styled.div`
  width: 477px;
  height: 257px;
  margin-top: 40px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0 auto;
    height: 218px;
  }
`

const Contentbox = styled.div`
  width: 100%;
  height: 100%;
  

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    padding: 0px;
  }
`

const InputWrapper = styled.div`
  width: 100%;

  input + input {
    margin-top: 15px;     
  }

  // chrome에서 자동입력/자동채우기 시 자동으로 input의 background-color 바꾸는 것 막기
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;

    input + input {
      margin-top: 10px;     
    }
    // background-color: purple;
  }
`

const Inputbox = styled.input`
  width: 100%;
  height: 54px;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #F5F5F5;
  padding: 3px 30px;
  display: inline;
  padding-bottom: 0px;    

  ::placeholder { 
    color: #ABABAC;
    font-size: 16px;              
    font-weight: 300;
    position: relative;
    top: 1px;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;
    padding: 0px;
    padding-left: 10px;
    // background-color: red;

    ::placeholder {
      color: #ABABAC; 
      font-size: 15px;
      font-weight: 200;
      line-height: 18px;
      // padding-left: 10px;
    }
  }
`

const FormErrorWrapper = styled.div`
  text-align: left;
  margin-left: 10px;
  margin-bottom: 3px;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin: 0;
  }
`

const Noti = styled.div`
  margin-top: 10px;
  color: #ABABAC;
  font-size: 12px;
  font-weight: 200;
  // background-color: white;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 8px;
  }
`

const Button = styled.button`
  width: 100%;
  height: 54px;
  background: #2C2C2C;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 56px;
 

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 18px;
    left: 0px;
    top: 0px;
    margin-top: 38px;
  }
`
