import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import checkboxChecked from 'resources/common/checkbox_checked.png';
import checkbox from 'resources/common/checkbox.png';
import { useForm } from 'react-hook-form';
import FormError from 'components/common/FormError';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isObjEmpty } from 'utils/util.js';
import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import { useNavigate } from 'react-router-dom';


const DeleteAccount = () => {
  const [check, setCheck] = useState(false);
  const [agree, setAgree] = useState({});
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    whdwlRsn: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    agree: yup
    .boolean()
    .oneOf([true],"⚠ 필수로 동의하셔야 합니다"),
  });

  const { 
    register,
    formState:{errors},
    handleSubmit 
  } = useForm({
    resolver : yupResolver(schema)
  });

  // '취소' 버튼 클릭 시
  const cancelHandler = () => {
   navigate('/');
   //navigate(-1); >> 이전페이지로가기
  };  	

  // '확인' 버튼 클릭 시
  const clickHandler = async (val) => {
    const loginUserData= JSON.parse(localStorage.getItem("briphyMarketplaceUser"));
    if (isObjEmpty(errors)) { //error가 없을 때 - Form Validation Check를 통과 했을 시
      const elem = { 
        custUserNo : loginUserData.custUserNo, // 고객사용자번호
        whdwlYn: 'Y', // 탈퇴여부
        whdwlRsn: val.whdwlRsn, // 탈퇴사유
      }
    
    // 신규 비밀번호를 서버에 저장 (put)
		const res = await memberApi.deleteUserInfo(elem).then( response => {
      handleAlertText('탈퇴 완료!', '회원 탈퇴가 완료 되었습니다 ', 'success');
      //로그아웃 시켜주기
      localStorage.removeItem('briphyMarketplaceUser');
      localStorage.removeItem('accessToken');
      navigate('/');
		}, error => { 
			handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
		  })
    }
  }  
  


  return (
    <Container>
      <form onSubmit={handleSubmit(clickHandler)}>
      <Wrapper>
          <Title> 회원탈퇴 </Title>
          <Content>
            <InnerContent>
                <div id='subtitle'> [필수] 회원탈퇴 이유를 알려주세요 </div>
              <ReasonBoxContainer>
                <ReasonBox 
                  placeholder='회원탈퇴하시려는 이유를 적어주세요'
                  id="whdwlRsn"
                  {...register("whdwlRsn")}
                >
                </ReasonBox>
                {errors.whdwlRsn ? 
                  <FormError message={errors.whdwlRsn?.message}/> : ''
                }
              </ReasonBoxContainer>

                <div id='subtitle'> 회원탈퇴 전 안내사항을 꼭 확인해주세요</div>
              <Announcement>
                <Notice>
                  <div id="noti">1. <p>사용하고 계신 계정은 탈퇴할 경우 복구가 불가능합니다.</p></div>
                  <div id="noti">2. <p>기존 결제 및 촬영 정보 등은 복구가 불가능합니다.</p></div> 
                  <div id="noti">3. <p>탈퇴 후에도 보존 필요가 있는 경우 해당 정보를 일정기간 보존합니다. </p></div>
                  <div id="noti">4. <p>문의사항은 contact@briphy.com으로 전달해주세요.</p></div>
                </Notice>
              </Announcement>

              <Agreement>
                <label htmlFor="agree">
                  <Check id="agree"
                    onClick={ e => setAgree(e.target.value) }
                    onChange={ e => setCheck(check => !check)}
                    {...register("agree")}
                  />
                  <i className = "check-icon"></i>
                  <div id="pre-agree">안내사항을 모두 확인했으며, </div>
                  <div id="post-agree">이에 동의합니다.</div>
                </label>
              </Agreement>
              {errors.agree ? 
                <FormError message={errors.agree?.message}/> : ''
              }
            </InnerContent>
            <Buttons>
              <CancelBtn type="button" onClick={cancelHandler}>취소</CancelBtn>
              <ConfirmBtn type="submit">확인</ConfirmBtn>
            </Buttons>
          </Content>
        {/* </ContentBox> */}
      </Wrapper>
      </form>
    </Container>
  )
}

export default DeleteAccount;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  position: relative;

  @media ${({ theme }) => theme.device.mobile} {
  }
  
`

const Wrapper = styled.div`
  width: 950px;
  display: flex;
  flex-direction: column;
  margin: auto auto;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100vh;
    margin-top: 92px;
    padding: 0px 16px 0px 16px;
  }
`

const Title = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  position: relative;
  margin: 40px auto auto;
  // background-color: red;
  

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    margin-bottom: 42px;
    font-size: 20px;
    line-height: 24px;
    // background-color: lightpink;
  }
`

const Content = styled.div`
  width: 790px;
  height: 491px;
  position: relative;
  // margin: -490px auto auto 80px;
  margin: 34px auto auto 80px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    margin: 0;
  }
 
`
const Check = styled.input.attrs( { type: 'checkbox' } )``;

const InnerContent = styled.div`
  width: 790px;
  height: 375px;
  position: relative;
  margin-top: -65px;

  #subtitle {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 7px;
    margin-top: 65px;
  }

  ${Check} {
    display: none;
  }

  ${Check}:checked + .check-icon {
    background-image: url( ${checkboxChecked} );
    background-size: 105%;
    border: none;
  }

  .check-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px 1px 0;
    transform: translate3d(0, -7%, 0);
    background-image: url(${checkbox});
    background-size: 100%;
    vertical-align: middle;
    transition-duration: .2s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;

    margin: 0;

    .check-icon {
      width: 14px;
      height: 14px;
      margin: 1px 10px 1px 0;
    }

    #subtitle {
      margin: 0;
      margin-bottom: 5px;
    }
  }
`

const ReasonBoxContainer = styled.div`

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 50px;
  }
`

const ReasonBox = styled.textarea`
  width: 790px;
  height: 140px;
  background: #FFFFFF;
  border: 0.25px solid #D9D9D9;
  border-radius: 5px;
  padding-top: 11px;
  padding-left: 18px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  resize: none;
  overflow: auto;

  &:focus {
    outline: 1px solid #ABABAC;
  }

  &::placeholder {
    color: #ABABAC;
    font-weight: 200;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 120px;
    overflow-y: none;
    padding: 15px 0px 0px 8px;

    font-weight: 200;
    font-size: 15px;
    line-height: 18px;
  }
`

const Announcement = styled.div`
  width: 790px;
  height: 103px;
  background-color: #F5F5F5;
  border-radius: 5px;
  margin-bottom: 7px;
  padding: 10px 0px 10px 18px;

  div + div {
    margin-top: 5px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    padding: 0;
    margin: 0;

    div + div {
      margin-top: 10px;
    }
    padding: 15px 10px;
    margin-bottom: 8px;

    // color: orange;
  }
`

const Notice = styled.div`
  width: 492px;
  height: 83px;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  line-height: 17px;

  p {
    margin-left: 15px;
    margin-top: -17px;
  }


  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;

    // background-color: purple;
  }
`

const Agreement = styled.div`
  #pre-agree {
    font-size: 14px;
    display: inline;
    color: #ABABAC;
    font-weight: 300;
  }

  #post-agree {
      font-size: 14px;
      display: inline;
      font-weight: 600;
    }

  @media ${({ theme }) => theme.device.mobile} {
    #pre-agree, #post-agree {
      font-size: 12px;
      line-height: 14px;
    }
  }
`
const Buttons = styled.div`
  display: flex;
  width: 100%;
  height: 197px;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    margin: 0;
    display: flex;
    margin-top: 38px;
  }
`

const CancelBtn = styled.button`
  width: 90px;
  height: 44px;
  background: #FFFFFF;
  border: 0.5px solid #2C2C2C;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-top: 4px;
  color: #2C2C2C;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 54px;
    font-size: 18px;
  }
`

const ConfirmBtn = styled.button`
  width: 90px;
  height: 44px;
  background: #FE5A1D;
  border-radius: 5px;
  border: 0.5px solid #FE5A1D;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  padding-top: 4px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 54px;
    font-size: 18px;
  }
`