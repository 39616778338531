import { useCallback, useEffect, useRef } from 'react';

export const useScrollFadeIn = ({threshold,delay,opacity=1,direction='bottomUp',use='active'}) => {
  const dom = useRef();
  const directionArr = {
    bottomUp:'translate3d(0, 30%, 0)',
    TopDown:'translate3d(0, -30%, 0)',
    rightLeft:'translate3d(30%, 0, 0)',
    leftRight:'translate3d(-30%, 0, 0)',
    rightLeft100:'translate3d(100%, 0, 0)',
    notmove:'translate3d(0, 0, 0)',
  }
  
  const handleScroll = useCallback(([entry]) => {
    const { current } = dom;
    
    if(entry.isIntersecting) {
      // 원하는 이벤트를 추가 할 것
      current.style.transitionProperty = 'opacity transform';
      current.style.transitionDuration = '.7s';
      current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
      current.style.transitionDelay = delay+'s';
      current.style.opacity = opacity;
      current.style.transform = 'translate3d(0, 0, 0)';
    }
  }, []); 

  const handleScrollStatic = useCallback(([entry]) => {
    const { current } = dom;
    
    if(entry.isIntersecting) {
      // 원하는 이벤트를 추가 할 것
      current.style.transitionProperty = 'opacity transform';
      current.style.transitionDuration = '1.5s';
      current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1) ease-in-out';
      current.style.transitionDelay = delay+'s';
      current.style.opacity = opacity;
      current.style.transform = 'translate3d(0, 0, 0)';
      current.style.color = '#2C2C2C';
    }
  }, []); 
  
  useEffect(() => {
    let observer;
    const { current } = dom;
    if (current) {
      if (use === 'active') {
        observer = new IntersectionObserver(handleScroll, { threshold });
      } else {
        observer = new IntersectionObserver(handleScrollStatic, { threshold });
      }

      observer.observe(current);
      
      return () => observer && observer.disconnect();
    };
  }, [handleScroll]);

  return {
    ref: dom,
    style: {
      opacity: use === 'static' ? 1 : 0,
      // opacity: 0,
      transform: directionArr[direction],
    }
  };
};

