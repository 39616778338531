"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.phoneReg = exports.emailReg = void 0;
var emailReg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
exports.emailReg = emailReg;
var phoneReg = /^\d{3}\d{4}\d{4}$/;
exports.phoneReg = phoneReg;
var strictPhoneReg = /^\d{3}-\d{4}-\d{4}$/;
exports.strictPhoneReg = strictPhoneReg;