import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { mainApi } from 'utils/api';
import { checkVisitorSession, setVisitorSession } from 'utils/jwtUtil';

import HeroSection from './HeroSection';
import Category from './Category';
import BestProduct from './BestProduct';
import WhyBriphy from './WhyBriphy';
import PortfolioVideo from './PortfolioVideo';
import PortfolioPhoto from './PortfolioPhoto';
import Quotation from './Quotation';

const MainContainer = () => {
  const [buttonOpacity, setButtonOpacity] = useState(false);
  const [buttonColor, setButtonColor] = useState(true);

  // 최하단 무료견적버튼 영역
  const [bottomRef, bottomInView] = useInView({threshold: 0.2});

  const onScroll = (e) => {
    if (window.scrollY > 100) {
      setButtonOpacity(true);
    } else {
      setButtonOpacity(false);
    }
  }

  useEffect(() => {
    setButtonColor(bottomInView);
  }, [bottomInView]);

  // 초기 화면 접속 시 접속 이력 저장
  useEffect(() => {
    if (!checkVisitorSession('mainVisit')) {
      mainApi.insertConnectInfo({pageDvnm: '사진메인'});
      setVisitorSession('mainVisit');
    } 
  }, []);

  // 스크롤 이벤트 감지
  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      onScroll(e);
    });
    return () =>window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Container>
      <Wrapper>
        <HeroSection />
        <Category />
        <BestProduct />
        <PortfolioVideo />
        <PortfolioPhoto />
        <WhyBriphy />
        <Quotation />
        <ButtonWrapper>
          <QuotationButton className={`${buttonOpacity ? 'opacity' : ''} ${buttonColor ? ' change' : ''}`}>
            <Link to='/quotation'>무료 촬영견적 받기</Link>
          </QuotationButton>
        </ButtonWrapper>
        <BlankBox ref={bottomRef} />
      </Wrapper>
    </Container>
  )
}

export default MainContainer;

const Container = styled.main`
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonWrapper = styled.div`
  bottom: 35px;
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;

  .opacity {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
  }

  .change {
    background: #FE5A1D;
    transition: all 0.2s ease-in-out;
  }

  @media ${({theme}) => theme.device.mobile} {
    bottom: 6px;
  }
`

const QuotationButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  width: 264px;
  height: 64px;
  padding: 21px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: #FFFFFF;
  border-radius: 32px;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: all 0.2s ease-in-out;

  @media ${({theme}) => theme.device.mobile} {
    width: 90vw;
    height: 54px;
    padding: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
`

const BlankBox = styled.div`
  width: 100%;
  height: 160px;
  background: #2C2C2C;

  @media ${({theme}) => theme.device.mobile} {
    height: 50px;
  }
`