import React from 'react'
import { useScrollFadeIn } from "hooks/useScrollFadeIn";
import styled from 'styled-components'
import BriphyVideo from 'components/video/BriphyVideo'

const Video = () => {
  const videoAnim = useScrollFadeIn({threshold:0.2,delay:'.3',direction:"leftRight"});
  return(
    <Container>
      <PlayerBox {...videoAnim}>
          <BriphyVideo />
      </PlayerBox>
    </Container>
  )
}

export default Video;

const Container = styled.section`
  width:${({ theme }) => theme.style.desktopWidth};
  margin:${({ theme }) => theme.style.containerMargin};
  @media ${({ theme }) => theme.device.mobile} {
    width:100%;
    margin:100px auto 0;
  }
`;

const PlayerBox = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  

  @media ${({ theme }) => theme.device.mobile} {
    min-width:0;
    height:fit-content;
  }
`;