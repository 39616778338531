import React from 'react'
import styled, { keyframes } from "styled-components";
// import partnersImg from "resources/main/partners/partners_wide.png";
import partnersImg from "resources/main/partners/new_partners_wide.png";

const Partners = () => {
  return (
    <Container>
      <Title>수많은 고객사가 인정한 최고의 촬영파트너, <span className="highlight">브리피</span></Title>
      <SubTitle>당신이 원하는 사진과 영상으로 다리를 놓아드립니다.</SubTitle>
      <PartnersBox>
        <div className="shadowFix" />
        <div className="shadowFix" />
        <img src ={partnersImg} className="clientLogo"/>
      </PartnersBox>
    </Container>
  )
} 

const Container = styled.section`
  display:flex;
  flex-direction:column;
  width:100vw;
  margin: 180px 0 0;
  padding: 77px 0;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
  @media ${({ theme }) => theme.device.mobile} {
    margin: 80px 0 0;
    padding: 40px 0;
  }
`;

const clientMove = keyframes`
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
`;

const Title = styled.div`
  text-align:center;
  font-size:45px;
  font-weight:600;
  margin: 0 0 20px 0;
  .highlight {
    font-weight: 800;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size:4vw;
    margin: 0 0 10px 0;
  }
`;

const SubTitle = styled.div`
  text-align:center;
  font-size:28px;
  color: #4B4B4B;
  font-weight:300;
  margin: 0 0 40px 0;
  @media ${({ theme }) => theme.device.mobile} {
    font-size:3.5vw;
  }
`;

const PartnersBox = styled.div`
    width: 1300px;
    height: 583px;
    overflow: hidden;
    position: relative;
    .shadowFix {
      position: absolute;
      z-index: 9;
      top: 0;
      height:100%;
      width:80px;
      background: linear-gradient(to right,#f6f6f600,#f6f6f6);
      &:nth-child(1) {
        left: -1px;
        transform: rotate(180deg);
      }
      &:nth-child(2){
        right:0;
      }
    }
    
    .clientLogo {
      height:100%;
      animation: ${clientMove} 25s .5s linear infinite; 
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 90%;
      height:317px;
    }
`;

export default Partners;