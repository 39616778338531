import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { isObjEmpty } from 'utils/util';

const Category = () => {
  const navigate = useNavigate();
  const store = useSelector(state => state.main);

  const [categoryList, setCategoryList] = useState([]);

  // 선택한 카테고리 상품 목록으로 이동
  const redirectPage = (id) => {
    const elem = {
      ctgrId: id
    }

    navigate('/product', {state: elem});
  }

  useEffect(() => {
    if (!isObjEmpty(store.productCategoryList)) {
      const temp = [];
      store.productCategoryList.map(item => {
        if(item.depth === '2' && item.useYn === 'Y') {
          temp.push({
            id: item.ctgrId,
            name: item.ctgrNm,
            filePath: item.mrkAtchFilePath,
            fileNm: item.mrkAtchFileSaveNm
          });
        }
      });

      setCategoryList(temp);
    }
  }, [store.productCategoryList]);


  return (
    <Container>
      <CategoryContainer>
        <Title>브리피 상품 카테고리</Title>
        <CategoryWrapper>
          {categoryList.map(item => {
            return (
              <CategoryItem key={item.id} onClick={() => redirectPage(item.id)}>
                <div><img src={`${process.env.REACT_APP_AWS_S3_URL}/${item.filePath}/${item.fileNm}`} /></div>
                <div>{item.name}</div>
              </CategoryItem>
            )
          })}
        </CategoryWrapper>
      </CategoryContainer>
    </Container>
  )
}

export default Category;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  
  @media ${({theme}) => theme.device.mobile} {
  };
`

const CategoryContainer = styled.div`
  width: 1088px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 119px auto 200px;

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin: 50px 16px 80px;
  };
`

const Title = styled.div`
  width: 100%;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  
  @media ${({theme}) => theme.device.mobile} {
    font-size: 20px;
    line-height: 24px;
  };
`

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 56px;

  @media ${({theme}) => theme.device.mobile} {
    margin-top: 26px;
  };
`

const CategoryItem = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  cursor: pointer;

  img {
    margin-bottom: 5px;
  }

  @media ${({theme}) => theme.device.mobile} {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    img {
      width: 81px;
      height: 81px;
    }
  };
`