import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FindID from './FindID';
import FindPassword from './FindPassword';
import { Modal, ModalBody } from 'reactstrap';
import closeIcon from 'resources/common/close_icon.png';
import ViewID from './ViewID';
import NewPassword from './NewPassword';

const FindModal = ({modal, handleModal, type}) => {
  const [clickVal, setClickVal] = useState('');
  const [userId, setUserId] = useState(''); // userId = 찾은 아이디
  const [custUserNo, setCustUserNo] = useState(''); // custUserNo = 고객사용자 번호

  /** 
   * FindId.js에서 userId를 찾아서 받아올 것 
   * 자식인 ViewId.js에 userId를 보내기 위한 함수
   */ 
  const sendId = (userId) => {
    setUserId(userId);
  }

  /** 
   * FindPassword.js에서 custUserNo를 찾아서 받아올 것
   * 자식인 NewPassword.js 에 custUserNo를 보내기 위한 함수 
   */
  const sendPW = (custUserNo) => {
    setCustUserNo(custUserNo);
  }

  /** 
   * ViewId.js 에서 쓰일 함수
   * 일단 아이디를 찾은 상태에서 '로그인 하러 가기' , '비밀번호찾기' 버튼관련 함수
   */
  const moveAction = (action) => {
    if (action === 'login') { // action이 '로그인 하러가기' 일 때
      handleModal(); // 모달 닫아주기 
    } else if (action === 'findPW') { // action이 '비밀번호 찾기'일 때
      setClickVal('PW');
    }
  }

  
  useEffect(() => {
    setUserId(''); // modal을 여닫을 때 마다 Id 값 초기화
    setCustUserNo('');
    setClickVal(type); // 마지막에 navbar의 클릭한 값과 무관하게 클릭한 버튼에 따라 초기화설정
  }, [handleModal]);  // handleModal은 함수라 manipulate이 어려우니 modal 변수로 하는 것이 더 확실하게 할 수 있음.-> 하단 참조
  
  // useEffect(() => {
    // if(modal===false) { //modal이 닫힐때
      //setUserId(''); 
      //   setCustUserNo('');
      //   setClickVal(type); 
    //}
  // }, [modal]);  


  /**  
   * 받는 id에 따라 toggle 및 클릭 할 때 마다 색이 바뀌고 bold 처리 해주는 함수 
   */
  const styleController = (id, color, fontWeight) => {
    if (id && color && fontWeight) {
      document.getElementById(id).style.color = color;
      document.getElementById(id).style.fontWeight = fontWeight;
    } 
  }

  /**  
   * '아이디찾기'인지 '비밀번호찾기'인지에 따라 clickVal 세팅 
   */
  useEffect(() => {
    setClickVal(type);
  }, [type])

 /**  
   * clickVal이 변경될 때마다 '아이디찾기'인지 '비밀번호찾기'인지에 따라 스타일다르게주기
   */  
 useEffect(() => {
    if (clickVal === 'ID') {
      styleController('findIDtext', '#2C2C2C', 700);
      styleController('findPWtext', '#ABABAC', 300);
    } else if (clickVal === 'PW') {
      styleController('findIDtext', '#ABABAC', 300);
      styleController('findPWtext', '#2C2C2C', 700);
    }
  }, [clickVal])

  // 아이디찾기/비밀번호 찾기 nav 변경함수
  const clickHandler = (event) => {
    setUserId(''); // ID찾기 결과 초기화

    if (event.currentTarget.id === 'idBtn') {
      setClickVal('ID');
      styleController('findIDtext', '#2C2C2C', 700);
      styleController('findPWtext', '#ABABAC', 300);
    }
    if (event.currentTarget.id === 'pwBtn') { 
      setClickVal('PW');
      styleController('findIDtext', '#ABABAC', 300);
      styleController('findPWtext', '#2C2C2C', 700);
    }
  };

  return (
  <div>
    <Modal 
      isOpen={modal}
      toggle={handleModal}
      style={{maxWidth:'700px', maxHeight:'450px', top: '176px'}}
      backdrop='static'
    > 
      <ModalBody 
        style={{width:'700px', height:'450px', padding:'0', backgroundColor:'white'}}
      >
      <CloseContainer>
        <Closeicon type='button' className='closeIcon' onClick={handleModal}></Closeicon>
      </CloseContainer>
        <ContentBox>

          {(clickVal === 'ID' && userId) ? ('') : (clickVal === 'PW' && custUserNo) ? ('') : (
          <ButtonsContainer>
            <FindNavBtn 
              type="button"
              onClick={clickHandler} 
              id='idBtn'
              style={clickVal === 'ID' ? {
                fontWeight: 700} : { 
                fontWeight: 300,
                color: '#ABABAC'}
              }
            > 
              <FindIdText id='findIDtext'>아이디 찾기</FindIdText> 
            </FindNavBtn>

            <Bar></Bar>

            <FindNavBtn
              type="button"
              onClick={clickHandler}
              id='pwBtn'
              style={clickVal === 'PW' ? {
                fontWeight: 700} : { 
                fontWeight: 300,
                color: '#ABABAC'} 
              }
            >
              <FindIdText id='findPWtext'>비밀번호 찾기</FindIdText> 
            </FindNavBtn>   
          </ButtonsContainer> )}

          { clickVal === 'ID' ? userId ? ( <ViewID userId={userId} moveFunction={moveAction}/> ) : ( 
          <FindID sendId={sendId}/> ) : ( 
            // custUserNo가 있다면 비밀번호 재설정, 없다면 비밀번호 찾기를 통해 custUserno 전송
            // 정상인 경우에는 고객사용자번호(custUserNo)를  return,
            // 비밀번호 변경 화면으로 이동하여 비밀번호 변경 시 고객사용자번호를 함께 전달
            // 비밀번호 찾기 후 변경 시 호출- NewPassword
          clickVal === 'PW' ? custUserNo ? ( <NewPassword custUserNo={custUserNo} moveFunction={moveAction}/> ) :
          <FindPassword userId={userId} sendPW={sendPW}/> : '' ) } 
        </ContentBox>
      </ModalBody>
    </Modal>
  </div>
  );
}

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px 0px 0px;
`

const Closeicon = styled.button`
  width: 15px;
  height: 15px;
  display: block;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${closeIcon});
  cursor: pointer;
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover{
    transform: rotate(90deg);
  }
`

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonsContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  margin-top: 37px;
`

const FindNavBtn = styled.div`
  background-color: white;
  padding-top:2px;
  width: 40%;
  text-align: center;
  border: white;
  font-size: 16px;
`

const FindIdText = styled.div`
`

const FindPWText = styled.div`
`

const Bar = styled.div`
  width: 1.3px;
  height: 16px;
  background: black;
`

export default FindModal;