import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import HeroMainImage from 'resources/studio/hero_studio_main.png';
import HeroMainImage2 from 'resources/main/herosection/heroImage.png';
// import './App.css';

function HeroSection() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeroContainer2, setShowHeroContainer2] = useState(false);

  const maxImageScale = 3.5; // 이미지의 최대 확대 비율
  // const minImageWidth = window.innerWidth/3.5; // 이미지 최소 너비
  // const minImageHeight = window.innerHeight/3.5; // 이미지 최소 높이
  const minImageWidth = 544; // 이미지 최소 너비
  const minImageHeight = 306; // 이미지 최소 높이

  const maxImageHeight = window.innerHeight;
 
  const imageScale = 1 + (scrollPosition / minImageHeight) * (maxImageScale - 2.2);

  // Hero Section 하단 메시지
  const HeroMessageLine1 = [
    { id:'heroMessage1', text:'광고주는'},
    { id:'heroMessage2', text:'광고가'},
    { id:'heroMessage3', text:'시작부터'}, 
    { id:'heroMessage4', text:'끝까지'}, 
    { id:'heroMessage5', text:'읽히기를'}, 
    { id:'heroMessage6', text:'바랍니다.'}];
  const HeroMessageLine2 = [
    { id:'heroMessage7', text:'대중은'},
    { id:'heroMessage8', text:'모든 광고를'},
    { id:'heroMessage9', text:'함부로'}, 
    { id:'heroMessage10', text:'성의 없이'}, 
    { id:'heroMessage11', text:'소비합니다.'}];
  const HeroMessageLine3 = [
    { id:'heroMessage12', text:'이'},
    { id:'heroMessage13', text:'간극을'},
    { id:'heroMessage14', text:'해결하는'}, 
    { id:'heroMessage15', text:'방법이'}, 
    { id:'heroMessage16', text:'있습니다.'}];
  const HeroMessageLine4 = [
    { id:'heroMessage17', text:'설명하지'},
    { id:'heroMessage18', text:'않고'}, 
    { id:'heroMessage19', text:'보여주면'}, 
    { id:'heroMessage20', text:'됩니다.'}];

  // const opacityOffset = 460;
  const opacityOffset = 0;
  const defaultOpacity = 0.1;
  const distanceOffset = 10;

  /**
   * scroll 감지 useEffect
   * 스크롤 위치값 저장
   */
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * 스크롤 변화에 따른 이미지 확대 또는 이미지 스크롤링 처리
   */
  useEffect(() => {
    const scrollY = ((scrollPosition - maxImageHeight*3) * -1) + 'px';
    // Hero Section Image 처리
    if (scrollPosition > maxImageHeight*3) {
      document.getElementById('HeroImage').style.transform = `translate(0px, ${scrollY})`;
    } else {
      document.getElementById('HeroImage').style.transform = `translate(0px, 0px)`;
    }
  
    // Hero Section bottom text message 처리
    if(scrollPosition < maxImageHeight*3 + opacityOffset) {
      const opacityInterval = maxImageHeight/10;
      for (let i = 1; i < 21; i++) {
        if (scrollPosition > opacityInterval * i + opacityOffset + i * distanceOffset) {
          document.getElementById('heroMessage'.concat(i)).style.opacity = 1;
        } else {
          document.getElementById('heroMessage'.concat(i)).style.opacity = defaultOpacity;
        }
      }
      // HeroMessageLine1.forEach(item => {
      //   const itemNumber = Number(item.id.replaceAll('heroMessage', ''))
      //   if (scrollPosition > opacityInterval * itemNumber + opacityOffset + itemNumber * distanceOffset) {
      //     document.getElementById(item.id).style.opacity = 1;
      //   } else {
      //     document.getElementById(item.id).style.opacity = defaultOpacity;
      //   }
      // })
      // HeroMessageLine2.forEach(item => {
      //   const itemNumber = Number(item.id.replaceAll('heroMessage', ''))
      //   if (scrollPosition > opacityInterval * itemNumber + opacityOffset + itemNumber * distanceOffset) {
      //     document.getElementById(item.id).style.opacity = 1;
      //   } else {
      //     document.getElementById(item.id).style.opacity = defaultOpacity;
      //   }
      // })
      // HeroMessageLine3.forEach(item => {
      //   const itemNumber = Number(item.id.replaceAll('heroMessage', ''))
      //   if (scrollPosition > opacityInterval * itemNumber + opacityOffset + itemNumber * distanceOffset) {
      //     document.getElementById(item.id).style.opacity = 1;
      //   } else {
      //     document.getElementById(item.id).style.opacity = defaultOpacity;
      //   }
      // })
      // HeroMessageLine4.forEach(item => {
      //   const itemNumber = Number(item.id.replaceAll('heroMessage', ''))
      //   if (scrollPosition > opacityInterval * itemNumber + opacityOffset + itemNumber * distanceOffset) {
      //     document.getElementById(item.id).style.opacity = 1;
      //   } else {
      //     document.getElementById(item.id).style.opacity = defaultOpacity;
      //   }
      // })
    }
  }, [scrollPosition])

  useEffect(() => {
    if (imageScale >= maxImageScale) {
      setShowHeroContainer2(true);
    } else {
      setShowHeroContainer2(false);
    }
  }, [imageScale, maxImageScale]);

  const imageStyle = {
    width: `${minImageWidth}px`,
    height: `${minImageHeight}px`,
    marginTop: '70px',
    transform: showHeroContainer2 ? 'scale(0px, 0px)' : `scale(${imageScale > maxImageScale ? maxImageScale : imageScale})`,
    transition: 'transform 0.3s ease' // 부드러운 전환 효과
  };

  return (
    <Container>
      {/* <HeroContainer style={{ display: showHeroContainer2 ? 'none' : 'flex' }}> */}

      <HeroContainerWrapper id='HeroImage'>
        <HeroContainer>
          <HeroImageContent>
          {/* <div className="hero-section" style={containerStyle}> */}
            <HeroTextWrapper id='HeroTextHead'>
            <HeroTextHead>
              STUDIO
            </HeroTextHead>
            <HeroTextMiddle>
              BRIPHY
            </HeroTextMiddle>
            </HeroTextWrapper>
            {/* <HeroImageWrapper>
              <img src={HeroMainImage} style={imageStyle} alt="hero-image" />
            </HeroImageWrapper> */}
            <HeroTextBottomWrapper id='HeroTextBottom'>
              <HeroTextBottom>
                설명하지 않고 보여주면 됩니다.
              </HeroTextBottom>
            </HeroTextBottomWrapper>
            <MobileHeroTextBottomWrapper>
              <HeroTextBottom key={'01'}>
                <pre key={'01'}>설명하지 않고<br/>보여주면 됩니다.</pre>
              </HeroTextBottom>
            </MobileHeroTextBottomWrapper>
          {/* </div> */}
          </HeroImageContent>
        </HeroContainer>
        <KeyMessageContainer>
          {/* <HeroContainer style={{ display: showHeroContainer2 ? 'none' : 'flex' }}> */}
          <HeroBottomContainer id='HeroBottomMessage'>
            <HeroMessageLineWrapper>
              {HeroMessageLine1.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine2.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineMargin></HeroMessageLineMargin>
            <HeroMessageLineWrapper>
              {HeroMessageLine3.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine4.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
          </HeroBottomContainer>
        </KeyMessageContainer>
      </HeroContainerWrapper>
    </Container>
  );
}

export default HeroSection;

const Container = styled.div`
  // display: flex;
  // width: 100%;  
  // height: 250vh;
  // margin: 0 auto;
  // // background-color: #F5F5F5;
  position: relative;
  display: contents;
  padding-bottom: 100px;
  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`

const HeroContainerWrapper = styled.div`
  translate: none;
  rotate: none;
  scale: none;
  // left: 66vh;
  // top: 31vh;
  margin: 0px;
  max-width: 1747px;
  width: 1747px;
  max-height: 0px;
  height: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: fixed;
  display: grid;
  justify-items: center;
  // transform: translate(0px, 0px);
`

const HeroContainer = styled.div`
  // width: 50%;
  // max-width: 100vh;
  // position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 80px auto 0px;
  // padding-right: 27px;
  // top: 0;
  // left: 0;
  translate: none;
  scale: none;
  // position: absolute;
  // top: 40vh;
  // left: 20vh;
  // transform: translate(50%, 50%);

  @media ${({theme}) => theme.device.mobile} {
    justify-content: flex-end;
    flex-direction: column-reverse;
    width: 100%;
    // height: 100vh;
    margin: 0;
    padding: 0;
  };
`

const HeroImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeroTextWrapper = styled.div`
  z-index:10;
  opacity: 1;
  width: 545px;
  height: 229px;
  margin-top: 100px;
  gap: 8px;
  opacity: 0px;

  @media ${({theme}) => theme.device.mobile} {
    width: 279px;
    margin-top: 198px;
  }
`

const HeroTextHead = styled.div`
  width: 545px;

  font-family: Prompt;
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 2em;
  text-align: center;

  margint: 0px;
  color: ${props => props.theme.txtWhite };

  @media ${({theme}) => theme.device.mobile} {
    width: 279px;
    font-family: Prompt;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.6em;
    text-align: center;

  }
`

const HeroTextMiddle = styled.div`
  width: 544px;
  height: 151px;

  font-family: Prompt;
  font-size: 100px;
  font-weight: 700;
  line-height: 151.2px;
  letter-spacing: 0.35em;
  text-align: center;

  color: ${props => props.theme.txtWhite };

  @media ${({theme}) => theme.device.mobile} {
    width: 279px;
    font-family: Prompt;
    font-size: 54px;
    font-weight: 700;
    line-height: 81.65px;
    letter-spacing: 0.3em;
    text-align: center;

  }
`

const HeroTextBottomWrapper = styled.div`
  margin-top: 60px;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const MobileHeroTextBottomWrapper = styled.div`
  display: none;
  margin-top: 30px;
  @media ${({theme}) => theme.device.mobile} {
    display: block;
  }
`

const HeroTextBottom = styled.div`
  // width: 489px;
  height: 40px;
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;

  color: ${props => props.theme.txtWhite };

  @media ${({theme}) => theme.device.mobile} {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
  }
`

const MobileHeroTextBottom = styled.div`
  display: none;

  @media ${({theme}) => theme.device.mobile} {


  }

`

const KeyMessageContainer = styled.div`

  // display: contents;
  margin-top: 200px;
  margin-bottom: 155px;

  @media ${({theme}) => theme.device.mobile} {
    margin-top: 250px;
    height: 528px;
  };
`

const HeroBottomContainer = styled.div`
  width: 100%;
  // height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${({theme}) => theme.device.mobile} {
    justify-content: flex-end;
    flex-direction: column-reverse;
    width: 100%;
    height: 100vh;
    margin-top : 220vh;
    padding: 0;
  };
`

const HeroMessageLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeroMessageLineItem = styled.div`
  margin-right: 12px;
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;

  color: ${props => props.theme.txtWhite };
`

const HeroMessageLineMargin = styled.div`
  margin-top: 48px;
`