const initialState = {
  productCategoryList: [],
  mainProductList: [],
}

const main = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MAIN_PRODUCT_LIST':
      return {
        ...state,
        productCategoryList: action.data.productCategoryList,
        mainProductList: action.data.mainProductList,
      }
    default: 
      return {...state}
  }
}

export default main;