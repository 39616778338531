import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { mainApi } from 'utils/api';
import { checkVisitorSession, setVisitorSession } from 'utils/jwtUtil';

import HeroSection from './HeroSection';
import KeyMessage from './KeyMessage';
import VideoPortFolio from './VideoPortFolio';
import WorkScopeSwiper from './WorkScopeSwiper';
import WithClients from './WithClients';
import HeroSectionMobile from './HeroSectionMobile';
import WorkScopeSwiperMobile from './WorkScopeSwiperMobile';
import ScopeOfWorks from './ScopeOfWorks';
import Contact from '../Common/Contact';
import ScopeOfWorksAccordion from './ScopeOfWorksAccordion';

const MainContainer = () => {
  const [buttonOpacity, setButtonOpacity] = useState(false);
  const [buttonColor, setButtonColor] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // 최하단 무료견적버튼 영역
  const [bottomRef, bottomInView] = useInView({threshold: 0.2});

  const isMobileCheck = () => {
    let check = /android|webos|iphone|ipad|ipod|blackberry|iemobile|mobile|opera mini/i.test(navigator.userAgent.toLowerCase());
    if (!check) {
      check = window.innerWidth < 740;
    }
    return check;
  }

  const onScroll = (e) => {
    if (window.scrollY > 100) {
      setButtonOpacity(true);
    } else {
      setButtonOpacity(false);
    }
  }

  useEffect(() => {
    setButtonColor(bottomInView);
  }, [bottomInView]);

  // 초기 화면 접속 시 접속 이력 저장
  useEffect(() => {
    if (!checkVisitorSession('mainVisit')) {
      mainApi.insertConnectInfo({pageDvnm: '메인'});
      setVisitorSession('mainVisit');
    } 
  }, []);

  // 스크롤 이벤트 감지
  useEffect(() => {
    setIsMobile(isMobileCheck())
    window.addEventListener('scroll', (e) => {
      onScroll(e);
    });
    return () =>window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsMobile(window.innerWidth < 740);
    }

  }, [window.innerWidth])

  return (
    <>
     <Container>
       <Wrapper>
         <HeroSectionMobile />
         <HeroSection />
  
        {/* {isMobile ? (
          <HeroSectionMobile />
        ) : (
          <>
          <HeroSection />
          </>
        )} */}
        <KeyMessage />
        <ScopeOfWorks />
        <ScopeOfWorksAccordion />
        {/* <WorkScopePrev /> */}
        {/* {isMobile ? (
          // <WorkScopeSwiperMobile />
          <ScopeOfWorksAccordion />
        ) : (
          // <WorkScopeSwiper />
          <ScopeOfWorks />
        )} */}
        {/* <VideoPortFolio/> */}
        <WithClients />
        <Contact />
       </Wrapper>
     </Container>
    </>
  )
}

export default MainContainer;

const Container = styled.main`
  position: flex;
// display: block;
  // background-color: #000000;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
