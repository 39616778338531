import React, { useLayoutEffect } from 'react'
import styled from 'styled-components';
import quality from "resources/main/whys/quality.png"
import price from "resources/main/whys/price.png"
import leadtime from "resources/main/whys/leadtime.png"
import timesaving from "resources/main/whys/timesaving.png"
import security from "resources/main/whys/security.png"
import { useScrollFadeIn } from 'hooks/useScrollFadeIn';

const Whys = () => {
  const qualityAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"leftRight"});
  const qualityContentAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"bottomUp"});
  const costAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"rightLeft"});
  const costContentAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"bottomUp"});
  const leadtimeAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"leftRight"});
  const leadtimeContentAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"bottomUp"});
  const timesavingAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"rightLeft"});
  const timesavingContentAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"bottomUp"});
  const securityAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"leftRight"});
  const securityContentAnim = useScrollFadeIn({threshold:0.5,delay:'.3',direction:"bottomUp"});

  return (
    <Container>
      <FlexBox direction={'left'}>
          <ThumbnailWrap {...qualityAnim} thumbImg={quality}></ThumbnailWrap>
        <ContentsWrap {...qualityContentAnim}>
          <div className="contents-title">Quality</div>
          <div className="contents-desc">검증된 공급자를 연결하여 확실한 촬영 퀄리티를 보장합니다.</div>
        </ContentsWrap>
      </FlexBox>
      <FlexBox direction={'right'}>
        <ThumbnailWrap {...costAnim} thumbImg={price}></ThumbnailWrap>
        <ContentsWrap {...costContentAnim} direction={'right'}>
          <div className="contents-title">Price</div>
          <div className="contents-desc">출장 거리 최소화와 대량 물량 확보를 통해 촬영 비용이 <br/>최대 50% 절감됩니다.</div>
        </ContentsWrap>
      </FlexBox>
      <FlexBox direction={'left'}>
        <ThumbnailWrap {...leadtimeAnim} thumbImg={leadtime}></ThumbnailWrap>
        <ContentsWrap {...leadtimeContentAnim}>
          <div className="contents-title">Lead Time</div>
          <div className="contents-desc">전국 네트워크를 바탕으로 국내 어디든 48시간 이내에 <br/>결과물 전달이 가능합니다.</div>
          <ul className="sub-contents">
            {/* <li><span className="star">*</span>인하우스 촬영팀 대비 33~66% 비용 절감</li>
            <li><span className="star">*</span>프리랜서 대비 최대 22% 비용 절감</li> */}
          </ul>
        </ContentsWrap>
      </FlexBox>
      <FlexBox direction={'right'}>
        <ThumbnailWrap {...timesavingAnim} thumbImg={timesaving}></ThumbnailWrap>
        <ContentsWrap {...timesavingContentAnim} direction={'right'}>
          <div className="contents-title">Time-Saving</div>
          <div className="contents-desc">촬영 스케줄링부터 결과물 전달까지 모든 촬영 과정을 대행합니다.</div>
        </ContentsWrap>
      </FlexBox>
      <FlexBox direction={'left'}>
        <ThumbnailWrap {...securityAnim} thumbImg={security}></ThumbnailWrap>
        <ContentsWrap {...securityContentAnim}>
          <div className="contents-title">Security</div>
          <div className="contents-desc">정식 위탁 계약이 체결된 프리랜서를 통해 확실한 개인정보보호와 <br/>결과물 유출을 방지합니다.</div>
          <ul className="sub-contents">
            {/* <li><span className="star">*</span>인하우스 촬영팀 대비 33~66% 비용 절감</li>
            <li><span className="star">*</span>프리랜서 대비 최대 22% 비용 절감</li> */}
          </ul>
        </ContentsWrap>
      </FlexBox>
    </Container>
  )
}

const Container = styled.section`
  width:${({ theme }) => theme.style.desktopWidth};
  // margin: 100px auto 0;
  margin: 100px auto 0;
  padding: 0px 88px;
  @media ${({ theme }) => theme.device.mobile} {
    width:100%;
    max-width:100%;
    margin:60px 0 0;
    padding: 0;
  }
`;

const FlexBox = styled.div`
  display:flex;
  width: 100%;
  height: 222px;
  flex-direction: ${props=>props.direction==='right'?'row-reverse':''};
  /* box-shadow: 7px 5px 8px #00000029; */
  & + & {
    margin-top: 60px;
  }
  @media ${({ theme }) => theme.device.mobile} {
    height:25vw;
    padding:0 16px;
  }
`;

const ThumbnailWrap = styled.div`
  // flex:.3;
  width: 290px;
  // height: 162px;
  background: url(${props=>props.thumbImg});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 15px;
  box-shadow: inset 3px 3px 10px 4px #cdcdcd75;
  @media ${({ theme }) => theme.device.mobile} {
    flex:.45;
    box-shadow: 2px 2px 8px 2px #cdcdcd75;
  }
`;

const ContentsWrap = styled.div`
  // flex:.7;
  display:flex;
  justify-content: center;
  flex-direction: column;
  text-align:${props=>props.direction==='right'?'right':'left'};
  margin:${props=>props.direction==='right'?'0 40px 0 0':'0 0 0 40px'};
  .contents-title {
    color:#FE5A1D;
    font-size: 34px;
    font-weight: 800;
    margin: 0 0 20px 0;
  }

  .contents-desc {
    font-size: 18px;
    color: #4B4B4B;
    font-weight: 400;
    line-height: 1.4;
  }
  .sub-contents {
    list-style: none;
    margin-top:10px;
    .star {
      color: #FE5A1D;
    }
    li{
      color:#707070;
      font-size:12px;
      &:last-child {
        margin-top: 5px;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    flex:.55;
    margin:${props=>props.direction==='right'?'0 10px 0 0':'0 0 0 10px'};
    .contents-title {
      font-size:4.3vw;
      margin: 0 0 10px 0;
    }
    .contents-desc {
      width:100%;
      font-size:3vw;
      line-height: 1.5;
    }

    .sub-contents {
      margin-top:1.4vw;
      li{
        font-size:2.6vw;
      }
    }
    br{
      display:none;
    }
  }
`;
export default Whys;