import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

import { isObjEmpty } from 'utils/util';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// import { Navigation, Pagination } from 'swiper';

import CloseIcon from 'resources/studio/portfolio_popup_close.png';
import NoImage from 'resources/common/no_image_photo.png';
import LinkIcon from "resources/common/link.svg";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { handleAlertText } from 'components/common/SweetAlert';

const PhotoFullViewerModal = ({open, handleModal, photoInfo}) => {
  const store = useSelector(state => state.portfolio);
  
  // 포트폴리오 사진 목록
  const [photoFileList, setPhotoFileList] = useState([]);

  useEffect(() => {
    setPhotoFileList(store.photoFileList);
  }, [store.photoFileList]);

  /**
   * 선택된 포트폴리오 팝업창을 바로 띄울 수 있는 link 정보를 클립보드에 copy
   */  
  const linkCopy = () => {
    const url = `${process.env.REACT_APP_BASE_FRONT_URL}/portfolio?ctgrId=`.concat(photoInfo?.ctgrId, '&ptfoNo=', photoInfo?.ptfoSeqno)
    
    window.navigator.clipboard.writeText(url).then(() => {
      handleAlertText('복사 완료', '접속 URL 복사가 완료되었습니다.', 'info')
    })
  }

  const isMobile =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 740;
  const viewWidth = isMobile ? '100vw' : '1024px'

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      fullscreen={true}
      className='modal-dialog-centered'
      backdrop='static'
      style={{margin: '0px', padding: '0px'}}
    >
      <ModalBody style={{margin: '0px', padding: '0px'}} >
        <ModalContainer 
          image={`${process.env.REACT_APP_AWS_S3_URL}/${photoInfo?.atchFilePath}/${photoInfo?.atchFileSaveNm}`}
          backgroundColor={photoInfo?.bkgrColr ? photoInfo.bkgrColr : '162, 195, 206'}
        >
          <Header>
            <div></div>
            <CloseButton onClick={handleModal} />
          </Header>
          <Body>
            <PhotoWrapper>
              <Photo>
                { !isObjEmpty(photoInfo) && !isObjEmpty(photoFileList) ? 
                  <SwiperContainer>
                    <Swiper
                      // cssMode={true} true : 드래그 안됨
                      navigation={!isMobile}
                      pagination={{
                        clickable: true,
                      }}
                      loop={true}
                      // width={isMobile ? '421px':'1024px'}
                      observer={true}
                      observeParents={true}
                      autoHeight={true}
                      modules={[Pagination, Navigation]}
                      style={{
                        width: '100%'
                      }}
                    >
                      { photoFileList?.map((thumb, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <PhotoItem><img src={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`}/></PhotoItem>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </SwiperContainer>
                : <PhotoItem image={`${process.env.REACT_APP_AWS_S3_URL}/${photoInfo?.atchFilePath}/${photoInfo?.atchFileSaveNm}`} /> }
                {/* : <PhotoItem image={NoImage} /> */}
              </Photo>

              {/* <MobilePhoto>
                { !isObjEmpty(photoInfo) && !isObjEmpty(photoFileList) ? 
                  <SwiperContainer>
                    <Swiper
                      // cssMode={true} true : 드래그 안됨
                      // navigation={true}
                      pagination={{
                        clickable: true,
                      }}
                      loop={true}
                      width='100vw'
                      observer={true}
                      observeParents={true}
                      autoHeight={true}
                      modules={[Pagination, Navigation]}
                    >
                      { photoFileList?.map((thumb, index) => {
                        return (
                          <SwiperSlide key={index} style={{width:'400px'}}>
                            <MobilePhotoItem><img src={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`} /></MobilePhotoItem>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </SwiperContainer>
                : <PhotoItem image={NoImage} />  } 
              </MobilePhoto> */}
            </PhotoWrapper>
            <ContentInfoWrapper>
              <Title>{photoInfo?.ptfoNm}</Title>
              <ClientName>
                {photoInfo?.clntNm}
              </ClientName>
            </ContentInfoWrapper>
          </Body>
        </ModalContainer>
      </ModalBody>
    </Modal>
  )
}

export default PhotoFullViewerModal;

const ModalContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-position: center;
  background-image: linear-gradient(rgba(${props => props.backgroundColor}, 0.5), rgba(${props => props.backgroundColor}, 0.8)), url(${props => props.image});
  background-repeat : no-repeat;
  background-size : cover;
  // z-index: 10001;
  // .image {
  //   opacity: 0.5;
  // }
`

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 10px;
  // border-bottom: 1px solid #ACAAAC;
`

const CloseButton = styled.button`
  height: 80px;
  width: 80px;
  margin-top: 48px;
  margin-right: 48px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 24px;
    width: 24px;
    margin-right: 20px;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1024px;
  // width: 600px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100vw;
  }
`
  
const Photo = styled.div`
  display: bleck;
  @media ${({ theme }) => theme.device.mobile} {
    // display: none;
  }
`

const SwiperContainer = styled.div`
  // width: 600px;
  width: 1024px;

  // navigation color
  --swiper-theme-color: #F5F5F5;
  
  // swiper bullet color
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
    opacity: 0.8;
  }
  .swiper-pagination-bullet-active {
    background-color: #FE5A1D !important;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    width: 100vw;
    min-height: 590px;
  }
`
  
const MobilePhoto = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`

const ContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  color: #FFFFFF;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 18px;
  }
`

const Title = styled.div`
  font-family: Prompt;
  font-size: 30px;
  font-weight: 700;
  line-height: 45.36px;
  letter-spacing: 0.2em;
  text-align: left;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 20px;
  }
`

const ClientName = styled.div`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 700;
  line-height: 36.29px;
  letter-spacing: 0.2em;
  text-align: left;


  @media ${({theme}) => theme.device.mobile} {
    min-height: auto;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 14px;
  }
`

const PhotoItem = styled.div`
  // cursor: pointer;
  position: relative;
  // width: 600px;
  width: 1024px;
  height: 630px;
  text-align: center;
  background: url(${props => props.image}) no-repeat 95%;
  background-size: cover;
  // background-size: contain;
  overflow: hidden;
  
  &:not(:first-child) {
    margin-left: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100vw;
    // display: none;
    img {
      width: 100vw;
    }
    
  }
`

const MobilePhotoItem = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: relative;
    // min-width: 100%;
    width: 100vw;
    // height: 200px;
    background: url(${props => props.image}) no-repeat 95%;
    background-size: cover;

    img {
      width: 100vw;
    }
    
    .swiper-slide {
      width: 100vw;
    }
  }
`