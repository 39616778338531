import React from 'react';
import styled from 'styled-components';
 
const ViewID = ({userId, moveFunction}) => {
  
  // '로그인 하러가기' 버튼 클릭 시
  const goLoginBtn = () => {
    moveFunction('login');
  }  
  // '비밀번호 찾기' 버튼 클릭 시
  const findPwdBtn = () => {
    moveFunction('findPW');
  }

  return (
    <Container>
      <Wrapper>
        <Content> 
          아이디<UserIdContent>{userId}</UserIdContent>
        </Content>

        <ButtonContainer>
          <Button 
            type='button' 
            style={{backgroundColor:'#FE5A1D'}}
            onClick={goLoginBtn} 
          > 로그인 하러가기 </Button>
      
          <Button 
            type='button' 
            style={{backgroundColor:'#2C2C2C'}}
            onClick={findPwdBtn}
          > 비밀번호 찾기 </Button>
        </ButtonContainer> 
      </Wrapper>
    </Container>
  );
}

export default ViewID;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; 
  height: 358px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 45vh;
    margin: 0;
    padding: 0;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 112px;
  padding-right: 111px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`

const Content = styled.div`
  width: 100%;
  height: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ABABAC;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;


  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 276px;
    margin: 0px;
    font-size: 15px;
    line-height: 18px;
  }
`

const UserIdContent = styled.div`
  color: #2C2C2C;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  margin-left: 15px;
  
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 20px;
    line-height: 24px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;

  button + button {
    margin-top: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: -90px;
  }
`

const Button = styled.button`
  width: 100%;
  height: 54px;
  background: #2C2C2C;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  border: none;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 18px;
    padding-top: 2px;
  }
`