import styled, { keyframes } from 'styled-components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Modal, ModalBody } from 'reactstrap';

// import { portfolioApi } from 'utils/api';
import FormError from 'components/common/FormError';
import { strictPhoneReg } from 'components/regulation';
import { handleAlertHtml, handleAlertText } from 'components/common/SweetAlert';
import NaverADLogAnalyticsConvert from 'components/NaverADLogAnalyticsConvert';
import RecatchBasicForm from 'views/RecatchForm/BasicForm';

import CheckboxUnChecked from 'resources/common/checkbox.png';
import CheckboxChecked from 'resources/common/checkbox_checked.png';
import EllipseIcon from 'resources/studio/ellipse_icon.png';

const Contact = ({open, handleModal}) => {
  // 개인정보 제공 동의
  // const [agree, setAgree] = useState(false);

  // // slack 채널
  // const contactWebHookURL =  `${process.env.REACT_APP_SLACK_CLIENT_WEBHOOK_URL}`;

  // const InputSchema = yup.object().shape({
  //   name: yup
  //     .string()
  //     .required('필수 입력 값입니다'),
  //   hpNo: yup
  //   .string()
  //   .required('필수 입력 값입니다')
  //   .matches(strictPhoneReg, '전화번호 형식에 맞게 입력해주세요'),
  // });

  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   formState: {errors},
  //   setValue,
  //   clearErrors
  // } = useForm({
  //   resolver: yupResolver(InputSchema)
  // });

  // // const clickContactIcon = () => {
  // //   setClickToggle(!clickToggle);
  // //   setValue('name', '');
  // //   setValue('hpNo', '');
  // //   clearErrors();
  // // }

  // const initialForm = () => {
  //   setValue('name', '');
  //   setValue('hpNo', '');
  // }

  // // 슬랙 발송
  // const sendSlackContact = async(contactInfo) => {
  //   const template = {
  //     "blocks": [
  //       { 
  //         "type": "section",
  //         "text": {
  //           "type": "mrkdwn",
  //           "text": "*────🔔 문의하기 🔔 ────*"
  //         }
  //       }, 
  //       {
  //         "type": "section",
  //         "text": {
  //           "type": "mrkdwn",
  //           "text": `*이름* : ${contactInfo.enqPerNm}\n*휴대폰번호* : ${contactInfo.cntcTlNo}`
  //         }
  //       },
  //       {
  //         "type": "divider"
  //       }, 
  //     ]
  //   }
  //   await axios.post(contactWebHookURL, JSON.stringify(template));
  // } 

  // // 문의 등록
  // const onSubmit = (data) => {
  //   if (!agree) {
  //     handleAlertText('', '개인정보 제공에 동의해주시기 바랍니다', 'warning');
  //     return;
  //   }
    
  //   const contactInfo = {
  //     enqPerNm: data.name,
  //     cntcTlNo: data.hpNo
  //   }

  //   sendSlackContact(contactInfo);

  //   handleAlertHtml('제출완료!', '브리피를 찾아주셔서 감사합니다.<br/>담당자가 확인 후 빠른 시간 내에 연락드리겠습니다.', 'info')
  //   initialForm();
  //   handleModal();
  //   // const formElem = {
  //   //   enqDvcd: 3, // 포폴문의
  //   //   enqPerNm: data.name,
  //   //   cntcTlNo: data.hpNo.replaceAll('-', '')
  //   // }

  //   // portfolioApi.insertContact(formElem).then((response) => {
  //   //   navigate('/portfolio/contact');
  //   //   sendSlackContact(contactInfo);
  //   // });
  // }

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }
  
  // re:catch Form modal call
  const openRecatchForm = () => {
    // 뷰어 호출
    handleViewerModal();

  }

  return (
    <Container id={'ContactMove'}>
      {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
      <Title>get in touch!</Title>
      <Message>연락처를 남겨주시면 담당자가 확인 후 연락드리겠습니다.<br/>지금 프로젝트의 고민 해결 방안을 제안 드리겠습니다.</Message>
      {/* <InputContainer>
        <InputBoxWrapper>
          <InputWrapper>
            <InputLabelSet>
              <InputLabel htmlFor='name'>이름</InputLabel>
              <LabelMandatoryBullet>
                <img src={EllipseIcon} />
              </LabelMandatoryBullet>
            </InputLabelSet>
            <InputBox className='wr'>
              <Input 
                  id='name' 
                  name='name' 
                  // placeholder='이름을 입력해주세요' 
                  {...register('name')} 
              />
              <FormError message={errors.name?.message} />
            </InputBox>
          </InputWrapper>
          <InputUnderline />
        </InputBoxWrapper>
        <InputBoxWrapper>
          <InputWrapper>
            <InputLabelSet>
              <InputLabel>휴대폰</InputLabel>
              <LabelMandatoryBullet>
                <img src={EllipseIcon} />
              </LabelMandatoryBullet>
            </InputLabelSet>
            <InputBox className='wr'>
              <Controller 
                id='hpNo'
                name='hpNo'
                control={control}
                render={({ field: {ref, ...field}}) => 
                <NumberFormat {...field} 
                  type='text'
                  htmlFor='hpNo'
                  // placeholder='휴대폰 번호를 입력해주세요'
                  format='###-####-####'
                  mask='_'
                  innerRef={ref}
                  customInput={Input}
                />}
              />
              <FormError message={errors.hpNo?.message} />
            </InputBox>
          </InputWrapper>
          <InputUnderline />
        </InputBoxWrapper>
      </InputContainer> */}
      <ContactWrapper>
        {/* <CheckWrapper>
          <Check 
            id='agree' 
            name='agree'
            onChange={() => setAgree(!agree)}
          /> 
          <label htmlFor='agree'>개인정보 제공에 동의합니다</label>
        </CheckWrapper> */}
        <SubmitButton onClick={() => openRecatchForm()}>문의하기</SubmitButton>
      </ContactWrapper>
      {/* </Form> */}
      <RecatchBasicForm
        open={viewerModal}
        handleModal={handleViewerModal}
      />
    </Container>
  )
}

export default Contact;

const Container = styled.div`
  width: 100%;
  // position: relative;
  display: flex;
  flex-direction: column;
  hegiht: 800px;
  background-color: #F2F2F2;
  align-items: center;
  overflow: hidden;

  @media ${({ theme }) => theme.device.mobile} {
    // display: none;
  }
`

const Title = styled.div`
  margin-top: 141px;
  font-family: Prompt;
  font-size: 114px;
  font-weight: 500;
  line-height: 172.37px;
  text-align: center;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 61px;
    margin-bottom: 40px;
    // height: 24px;
    font-family: Prompt;
    font-size: 34px;
    font-weight: 500;
    line-height: 62.37px;
  }
`

const Message = styled.div`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  margin-top: 27px;
  margin-bottom: 103px;


  @media ${({ theme }) => theme.device.mobile} {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 50px;
  }
`

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  gap: 100px;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    width: 90vw;
    gap: 30px;
  }
`

const InputBoxWrapper = styled.div`
  width: 700px;
  margin-top: 50px;
  margin-bottom: 100px;

  @media ${({ theme }) => theme.device.mobile} {
    // width: 350px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #FE5A1D;
  }

  .wr {
    width: 240px;
  }

  & + & {
    margin-top: 15px;
  }
`

const InputLabelSet = styled.div`
  display: flex;
  flex-direction: row;
`

const InputLabel = styled.label`
  // width: 57px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const LabelMandatoryBullet = styled.div`
  width: 4px;
  margin-left: 5px;
  display: grid;
`

const InputBox = styled.div`
  display: flex;
  width: 100%
`

const Input = styled.input`
  width: 480px;
  height: 38px;
  padding: 15px 8px;
  font-weight: 200;
  font-size: 16px;
  line-height: 38px;
  border: none;
  background: #F2F2F2;
  border-radius: 5px;
  outline: none;

  ::placeholder {
    color: #ABABAC;
  }
`

const InputUnderline = styled.div`
  border: 2px solid #A1A1A1;
`

const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 200px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;
const CheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 15px;
    height: 15px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const SubmitButton = styled.button`
  width: 544px;
  height: 72px;
  padding: 10px;
  // margin-top: 10px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  background: #FF5B34;
  // border-radius: 5px;

`

const Form = styled.form``