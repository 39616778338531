import React, { useState, useEffect } from "react";
import styled, { withTheme } from 'styled-components';
import { Modal, ModalBody, Row } from 'reactstrap';
import closeIcon from 'resources/common/close_icon.png';
import RadioButton from 'resources/common/radio.svg';
import CheckedRadioButton from 'resources/common/radio_checked.svg';
import CheckboxChecked from 'resources/common/checkbox_checked.png';
import CheckboxUnChecked from 'resources/common/checkbox.png';
import { isObjEmpty, compareRefundDate, digitFormatting, convertNumberFormat, convertMaskingString, convertDateTimeForSending, convertDateTime } from 'utils/util';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from 'components/common/FormError';
import { handleAlertText } from 'components/common/SweetAlert';
import { cancelCodeList } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import { mypageApi } from "utils/api";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import axios from 'axios';
import { getUserData } from 'utils/jwtUtil';
import emailjs from '@emailjs/browser';

const ModalController = ({modal, handleModal, type, selectedOrdId, productInfo}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.mypages);

  const commonSchema = yup.object().shape({
    radioOptions: yup
      .number()
      .required("⚠ 필수로 주문취소 사유를 선택하셔야 합니다")
      .nullable()
      .oneOf([10,20,30,40,90]),
    checkAgree: yup
      .boolean()
      .oneOf([true],"⚠ 필수로 동의하셔야 합니다"),
    extrabox: yup
      .string()
      .when("radioOptions", {
        is: 90,
        then: yup.string().required("⚠ 기타 취소 사유를 필수로 적어주셔야 합니다")
      })
    }
  );
  
  const virtualAccSchema = commonSchema.shape({
    refundBankSelectBox: yup 
      .string()
      .required("⚠ 필수로 환불은행을 선택하셔야 합니다"),
    refundAccountNumberInput: yup
      .string()
      .required("⚠ 필수로 환불계좌번호를 적어주셔야 합니다"),
    refundAccountNameInput: yup
      .string()
      .required("⚠ 필수로 환불계좌주명을 적어주셔야 합니다"),
    }
  );
 
  // 모달에 따라 yup schema 다르게 세팅하기
  const [yupSchema, setYupSchema] = useState();
  useEffect(()=>{
    if (type==="virtualAcc") {
      setYupSchema(virtualAccSchema);
    } else {
      setYupSchema(commonSchema);
    }
  },[type]);
 
  const {
    setValue, 
    handleSubmit, 
    register, 
    formState:{errors}, 
    reset ,
    watch,
  } = useForm( 
    {resolver:yupResolver(yupSchema)}
  );
  
  // 환불 정책 동의
  const [checkAgree, setCheckAgree] = useState(false);
  
  // 촬영 취소 사유
  const array = [];
  useEffect(() => {
    store.cancelReasonList?.map((item) => {
      array.push({id: item.instCd, name: item.instNm});
    });

    setCancelReasonList(array);
  }, [store.cancelReasonList]);

  // 주문취소사유 라디오버튼
  const [cancelReasonList, setCancelReasonList] = useState([]);

  const toggledRadioOption = (id, checked) => {
    return array.map((radioOption) =>
      radioOption.id === id ? { ...radioOption, checked } : radioOption
    );
  }

  const clickedRadio = (id, checked) => {
    const newCheckedRadioList = toggledRadioOption(id, checked);
    setCancelReasonList(newCheckedRadioList);
  }

  // 취소/취소불가/다운로드 모달의 '닫기(X)' 혹은 '취소' 버튼 누를 시
  const closeModal = () => {
    reset();
    setCheckAgree(false);
    setValue("extrabox","");
    handleModal();

    // if (modalType === "virtualAcc") {
    //   const getSelectBox = document.querySelector('#refundBank').value;
    //   setValue(getSelectBox.options[0].value, "" );
    // }
  }

  /** 
   * 환불 은행
  */ 
  const options= [];
  useEffect(() => {
      dispatch(cancelCodeList());
    }, []);
    
  store.bankCodeList.map((item) => {
    options.push({value: item.instCd, label: item.instNm});
  });

  // 환불 계좌번호
  const [refundAccountNumber, setRefundAccountNumber] = useState();
  // 환불 예금주명
  const [refundAccountName, setRefundAccountName] = useState();
  
  const [modalType, setModalType] = useState("");
  useEffect( () => {
    setModalType(type);
  }, [type])

  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // 주문 번호, 촬영날짜, 총 결제금액 받아오기 
  const [selectedItem, setSelectedItem] = useState({});
  const [orderedId, setOrderedId] = useState(); // 주문번호
  const [shootDate,setShootDate] = useState(); // 촬영날짜
  const [cnclFee, setCnclFee] = useState(0); // 취소수수료
  const [ordProcStcd, setOrdProcStcd] = useState(); // 주문진행상태코드
  const [totalPayment, setTotalPayment] = useState(); // 총 결제 금액
  const [ordCnclFee, setOrdCnclFee] = useState(0); // 주문취소수수료
  const [refundPrice, setRefundPrice] = useState(0); // 환불금액
  const [phgpyAddrNeedYn, setPhgpyAddrNeedYn] = useState(); // 촬영주소필요여부

  useEffect (() => {
    if (selectedOrdId !== undefined) {
      // TODO 마켓플레이스 반영을 위하여 임시로 작업하였습니다. 추후 코드 개선이 필요합니다.
      // 환불 금액 계산
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();
      const date = now.getDate();
      const hour = digitFormatting(now.getHours());
      const minute = digitFormatting(now.getMinutes());
      const today = `${year}${digitFormatting(parseInt(month) + 1)}${digitFormatting(date)}${hour}${minute}`;

      let sDate;
      let totPayAmt;
      let cFee;

      store.orderProductList && store.orderProductList?.forEach(item => {
        if (item.ordId === selectedOrdId) {
          setSelectedItem(item);
          setOrderedId(item.ordId);
          setShootDate(item.phgpyHopeDtm);
          setTotalPayment(item.prodOrdTamt);
          sDate = item.phgpyHopeDtm;
          totPayAmt = item.prodOrdTamt;
        }
      });

      if (!isObjEmpty(productInfo)) {
        setCnclFee(productInfo.cnclFee);
        setOrdProcStcd(productInfo.ordProcStcd);
        cFee = productInfo.cnclFee;
        setPhgpyAddrNeedYn(productInfo.phgpyAddrNeedYn);
        setOrdProcStcd(productInfo.ordProcStcd);
      }

      // let cancelFee;
      let refundPrice = 0;
      if (productInfo.phgpyAddrNeedYn === 'Y') { // phgpyAddrNeedYn: 촬영주소필요여부
        const refundable = compareRefundDate(sDate, today);
        if(refundable === 'refundWithPenalty') {
          setOrdCnclFee(Number(cFee));
          refundPrice = Number(totPayAmt) - Number(cFee);
          // cancelFee = 75000;
          // return refundPrice;
        } else if(refundable === 'fullRefund') {
          setOrdCnclFee(0);
          // cancelFee = 0;
          refundPrice = Number(totPayAmt);
          // return refundPrice;
        }
      } else {
        // 11: 입금대기중, 20: 접수대기중, 21: 제품수령대기중
        if (productInfo.ordProcStcd === '11' || productInfo.ordProcStcd === '20' || productInfo.ordProcStcd === '21') {
            setOrdCnclFee(0);
            refundPrice = Number(totPayAmt);
        } else {
          setOrdCnclFee(Number(cFee));
          refundPrice = Number(totPayAmt) - Number(cFee);
        }
      }
       
      setRefundPrice(refundPrice);
    }
  }, [selectedOrdId]);

  // 환불계좌번호 환불계좌주명 세팅
  const refundAccountInfo = () => {
    setValue('refundAccountNumberInput',document.querySelector(`input[name=refundAccountNumberInput]`).value);
    setValue('refundAccountNameInput',document.querySelector(`input[name=refundAccountNameInput]`).value);
  }

  // 화면 새로고침
  const refreshPage = () => {
    window.location.reload(false);
  }

  /**
   * 주문 취소 메일 발송
   * @param {object} orderInfo 주문정보
   */
  const sendEmailOrderInfo = (orderInfo) => {
    // 비회원일 경우 이름 마스킹 처리
    let userName = orderInfo.ordPerNm;
    if (isObjEmpty(getUserData())) {
      userName = convertMaskingString(userName);
    }

    const templateParams = {
      userNm: userName,
      ordId: orderInfo.ordId,
      ordDtm: convertDateTime(orderInfo.ordDtm, 'Y'),
      ordNm: orderInfo.prodNm,
      phgpyHopeDtm: convertDateTimeForSending(orderInfo.phgpyHopeDtm), // 미정 처리해야함
      phgpyAddr: `(${orderInfo.phgpyAddrZipCd}) ${orderInfo.phgpyAddr} ${orderInfo.phgpyAddrDtl}`,
      email: orderInfo.ordPerEmail,
      payMthStr: `${orderInfo.payCdCo}${orderInfo.payMthdNm}${!isObjEmpty(orderInfo.instMnthCnt) ? orderInfo.instMnthCnt === '0' ? ` 일시불` : `(${orderInfo.instMnthCnt}개월)` : ''}`,
      productAmount: convertNumberFormat(orderInfo.prodSumAmt, 'Y'),
      productOptionAmount: convertNumberFormat(orderInfo.optSumAmt, 'Y'),
      productTotalAmount: convertNumberFormat(orderInfo.prodOrdTamt, 'Y'),
      discountAmount: Number(orderInfo.copDscAplyAmt) ? `- ${convertNumberFormat(orderInfo.copDscAplyAmt, 'Y')}` : '0',
      productPayAmount: convertNumberFormat(orderInfo.prodPayAmt, 'Y'),
      ordCnclFee: Number(ordCnclFee) ? `- ${convertNumberFormat(ordCnclFee, 'Y')}` : '0',
      refundPrice: convertNumberFormat(refundPrice, 'Y'),      
    };

    emailjs.send('briphy', 'cancel', templateParams, `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY_HUGH}`);
  }

  /**
   * 주문 취소 Slack 발송
   * @param {*} orderInfo 주문정보
   */
  const sendSlackOrderCancel = async(orderInfo) => {    
    const template = {
      "blocks": [
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*───────❌ 주문취소 ❌───────*"
          }
        },
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*주문번호* : ${orderInfo.ordId}\n*상품명* : ${orderInfo.prodNm}\n*취소사유* : ${orderInfo.cancelReason}`
          }
        },
        {
          "type": "divider"
        }
      ]
    }

  let res = await axios.post(`${process.env.REACT_APP_SLACK_ORDER_WEBHOOK_URL}`, JSON.stringify(template));
}

  // sweetAlert 커스터마이징
  const CustomedSwal = withReactContent(Swal);

  // 확인 버튼 클릭 시
  const onSubmit = async(val) => {    
    CustomedSwal.fire({
      title: "정말 취소하시겠습니까?",
      text: "취소하신다면 다시 구매하셔야합니다!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText:"취소",
      confirmButtonText: '확인',
      reverseButtons: true,
    }).then(async(result) => {
      if (result.isConfirmed) {
        if (isObjEmpty(errors)) {
          // TODO 선택한 라디오 버튼의 label 값 개선필요
          let radioLabel = '';
          cancelReasonList.map((radio) => {
            if (Number(radio.id) === val.radioOptions) {
              radioLabel = radio.name;
            }
          });
          
          // 서버로 보내주는 항목들
          const elem = { 
            ordId : orderedId, // 주문번호
            cancelAmount: refundPrice, // 취소금액
            partCnclAmt: refundPrice, // 부분취소금액
            ordCnclFee: ordCnclFee, // 주문취소수수료
            ordCnclRsnCd: val.radioOptions, // 주문취소사유코드
            ordCnclEtcRsn: val?.extrabox, // 주문취소기타사유
            prodNm: productInfo.prodNm, // 상품명
            cancelReason: val.radioOptions === 90 ? val.extrabox : radioLabel // 취소사유(슬랙발송)
          }

          // 가상계좌를 선택 했을 시 추가로 보내줘야할 항목들
          if (modalType==='virtualAcc') {
            refundAccountInfo();
            elem.refundBank=(val?.refundBankSelectBox).substr(1,2); // 환불은행
            elem.refundAccountNumber=val?.refundAccountNumberInput; //환불계좌번호
            elem.refundHolderName=val?.refundAccountNameInput; // 환불계좌주명
          }

          await mypageApi.cancelOrder(elem).then(response => {
            if(response.data.failVO) {
              handleAlertText('결제 실패!', response.data.failVO.message, 'error');
            } else {
              sendEmailOrderInfo(selectedItem); // 주문 취소 이메일 발송
              handleAlertText('취소 완료!', '해당 주문내역이 성공적으로 취소되었습니다.', 'success').then( (result) => {
                sendSlackOrderCancel(elem); // 주문 취소 슬랙 전송
                if (result.isConfirmed) {
                  reset(); // 모달정보리셋
                  handleModal(); // 모달닫기
                  refreshPage(); // 화면새로고침
                }
              });
            }
          });
        }
      } else { 
        alert("해당 주문을 취소하는데 문제가 생겼습니다. 다시 시도해주세요");
      }
    })
  };

  return ( 
  <>
    { mode === "pc" ? 
    <>
    <Modal 
      isOpen={modal}
      toggle={handleModal}
      backdrop="static"
      style={{
        position: "absolute",
        top: modalType === "banCancel" ? "25.5%" : modalType === "cancelBtn" ? "43%" : modalType === 
        "virtualAcc" ? "55%" : "",
        left: "48%",
        transform: `translate(-50%, -50%)`,
        width: "600px",
      }}
    >
      {/* <div style={{
        backgroundColor:"white",
        width:"600px",
        height: "600px",
        border:"1px solid white"}}> */}
      <ModalBody 
        style={{
          position:"relative",
          width: "600px",
          height: modalType ==="banCancel"?"283px": modalType==="cancelBtn"? "600px" : modalType==='virtualAcc'? "950px": "" ,
          padding: "0px",
          background: "white",
        }}>
        <Closeicon type="button" className="closeIcon" onClick={closeModal}></Closeicon> 
        <Container>
          <Title>촬영취소{modalType === 'banCancel' ? ' 불가' : ''}</Title>
          <ContentBox>
            <SubTitle>취소 및 환불 정책</SubTitle>
            <Content>
              <TopContent>
                { phgpyAddrNeedYn === 'Y' ?
                  <LongDetailTopContent>
                    <div>촬영일 오전 00시 이전 취소:<p>100% 환불</p></div>
                    <div>촬영 당일 취소: 취소 수수료 <p>{convertNumberFormat(cnclFee, 'Y')}원 발생</p></div>
                    <div>촬영 시작 이후 취소:<p>환불 불가</p></div>
                  </LongDetailTopContent> :
                  <ShortDetailTopContent>
                    <div>촬영 상품 발송 전 주문 취소 시, 수수료가 발생하지 않습니다.</div>
                    <div style={{display: "inline"}}>촬영 상품 발송 후 주문 취소 시, <span>취소 수수료 {convertNumberFormat(cnclFee, 'Y')}원 발생합니다. (반송비 포함)</span></div>
                  </ShortDetailTopContent>
                }                
              </TopContent>
              <BottomContent>
                <div>
                  <div> 브리피가 연결해드리는 사진작가님은 현업에서 프리랜서로 활동하고 계신 분들입니다.</div>
                  <div>당일 취소 발생 시, 고객님과의 약속을 위해 비워 둔 촬영 시간에 대한 보상 비용을 적용하고 있습니다.</div>
                </div>
                  <p>하루 일당이 사라지는 프리랜서 포토그래퍼를 위한 부득이한 조치이니 양해 부탁드립니다. </p>
              </BottomContent>
            </Content>
          </ContentBox>
        </Container>
      <form onSubmit={handleSubmit(onSubmit)}> 
        {modalType === 'cancelBtn' || modalType==='virtualAcc'?
          <ContainerTwo>
            <SubTitleTwo>[필수] 주문 취소 사유를 알려주세요</SubTitleTwo>
              <RefundReasonBox>
                <CancelReasonWrapper>
                {cancelReasonList.map((item) => {
                  return (
                    <CancelReasonItem key={item.id}>
                      <CancelReasonLabel>
                      <CancelReasonRadioBtn 
                        name="radioOptions"
                        value={item.id}
                        checked={item.checked}
                        onChange={(e) => clickedRadio(item.id, e.target.checked)}
                        {...register('radioOptions')}
                      />
                        {item.name}
                      </CancelReasonLabel>
                    </CancelReasonItem>
                  )
                  })
                }
                    <CancelReasonWrapper>
                      <div style={{marginBottom: "-4px"}}></div>
                      <ExtraBox 
                        id="extrabox"
                        placeholder="기타 취소 사유를 적어주세요"
                        // defaultValue={cancelReason}
                        {...register('extrabox')}
                      />
                        <div style={{marginLeft:"30px"}}><FormError message={errors.extrabox?.message}/></div>
                      <FormError message={errors.radioOptions?.message}/>
                    </CancelReasonWrapper>
                </CancelReasonWrapper>
              </RefundReasonBox>
            {modalType === "virtualAcc"?
              <ContainerThree>
                <SubTitleThree>[필수] 가상계좌 환불
                  <div></div>
                  <div id="subcontent">가상계좌 건은 실시간으로 환불 처리가 불가하며, 영업일 기준 2~3일 소요 할 수 있습니다</div>
                </SubTitleThree> 
                <InputWrapper>
                  <label style={{fontWeight:300}} >
                    환불 은행
                  </label>
                  <RefundBankSelectBox 
                    type={"select"} 
                    id="refundBank"
                    onChange={(e) => setValue('refundBankSelectBox', e.target.value, { shouldValidate: true })} 
                    {...register("refundBankSelectBox")}
                  > 
                    <option value={""}>환불은행을 선택해주세요</option>
                    {options.map( item => {
                      if (item.value !== '') {
                        return (<option key={item.value} value={item.value}>{item.label}</option>)
                      }
                    })}
                  </RefundBankSelectBox>
                  <FormError message={errors.refundBankSelectBox?.message}/>
                  <InputItem>
                    <label>
                      환불 계좌번호 
                    </label>
                    <input 
                      type="number" 
                      id="refundAccountNumberInput" 
                      value={refundAccountNumber}
                      placeholder="숫자만 입력해주세요"
                      defaultValue={refundAccountNumber}
                      {...register('refundAccountNumberInput')}
                    />
                    <FormError message={errors.refundAccountNumberInput?.message}/>
                  </InputItem>
  
                  <InputItem>
                    <label>
                      환불 계좌주명 
                    </label>
                    <input 
                      id="refundAccountNameInput" 
                      placeholder="환불 계좌주명"
                      defaultValue={refundAccountName}
                      value={refundAccountName}
                      {...register('refundAccountNameInput')}
                    />
                      <FormError message={errors.refundAccountNameInput?.message}/>
                  </InputItem>
                  {/* </div> */}
                </InputWrapper>
              </ContainerThree> 
              : ""
            }
            <BottomSection>
              <RefundAgreeField size={16}>
                <label htmlFor='checkAgree'>
                  <Check 
                    id='checkAgree'
                    // name='checkAgree'
                    // onClick={() => setCheckAgree(!checkAgree)}
                    {...register("checkAgree")}
                    /><i className="check-icon"/>
                    취소할 상품의 정보와 환불정책을 확인하였으며 취소에 동의합니다.
                </label>
                <FormError message={errors.checkAgree?.message}/>
              </RefundAgreeField>   
              <Buttons>
                <CancelBtn type="button" onClick={closeModal}>취소</CancelBtn>
                <ConfirmBtn type="submit">확인</ConfirmBtn>
              </Buttons>      
                    
            </BottomSection>
          </ContainerTwo>
        : ""  }
      </form>
      </ModalBody>
      {/* </div> */}
    </Modal> 
    </>: mode === "mobile" ? <>
    <Modal 
      isOpen={modal}
      toggle={handleModal}
      // className='custom-modal-dialog'
      // contentClassName="custom-modal-content"
      style={{
        width: '100%',
        minWidth: '360px',
        height: '100vh',
        height: modalType ==="banCancel"?"348px": modalType==="cancelBtn"? '760px' : modalType==='virtualAcc'? "1060px": "" ,
        position: "absolute",
        marginTop: '30px',
        marginLeft: '0px',
        // top: modalType === "banCancel" ? "32.5%" : modalType === "cancelBtn" ? "60.5%" : modalType === 
        // "virtualAcc" ? "60%" : "",
        // left: "48%",
        // transform: `translate(-50%, -50%)`,
        padding: '0px',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
      backdrop="static"
    >
      <ModalBody 
        style={{
          position:"relative", 
          // height: '100vh',
          // height: modalType ==="banCancel"?"348px": modalType==="cancelBtn"? '760px' : modalType==='virtualAcc'? "1060px": "" ,
          width: '100%',
          border:'0px solid #2C2C2C',
          background: '#FFFFFF',
          borderRadius: '10px',
          padding: '0',
          paddingLeft: '10px',
          paddingRight: '10px'
        }}>
        <Closeicon type="button" className="closeIcon" onClick={closeModal}></Closeicon> 
        <Container>
          <Title>{ modalType==="cancelBtn" || modalType==="virtualAcc" ? '촬영을 정말 취소 하시겠습니까?' : modalType === 'banCancel' ? '촬영취소 불가' : ''}</Title>
          <ContentBox>
            <SubTitle>취소 및 환불 정책</SubTitle>
            <Content>
              <TopContent>
                { phgpyAddrNeedYn === 'Y' ?
                  <LongDetailTopContent>
                    <div>촬영일 오전 00시 이전 취소:<p>100% 환불</p></div>
                    <div>촬영 당일 취소:<p>취소 수수료 {convertNumberFormat(cnclFee, 'Y')}원 발생</p></div>
                    <div>촬영 시작 이후 취소:<p>환불 불가</p></div>
                  </LongDetailTopContent> :
                  <ShortDetailTopContent>
                    <div>촬영 상품 발송 전 주문 취소 시,<br/>  수수료가 발생하지 않습니다.</div>
                    <div style={{display: "inline"}}>촬영 상품 발송 후 주문 취소 시,<br/> <span>취소 수수료 {convertNumberFormat(cnclFee, 'Y')}원 발생합니다. (반송비 포함)</span></div>
                  </ShortDetailTopContent>
                }                
              </TopContent>
              <BottomContent>
                <div>
                  브리피가 연결해드리는 사진작가님은 현업에서 프리랜서로 활동하고 계신 분들입니다.
                  당일 취소 발생 시, 고객님과의 약속을 위해 비워 둔 촬영 시간에 대한 보상 비용을 적용하고 있습니다.
                </div>
                  <p>하루 일당이 사라지는 프리랜서 포토그래퍼를 위한 부득이한 조치이니 양해 부탁드립니다. </p>
              </BottomContent>
            </Content>
          </ContentBox>
        </Container>
      <form onSubmit={handleSubmit(onSubmit)}> 
      {modalType === 'cancelBtn' || modalType==='virtualAcc'?
          <ContainerTwo>
            <SubTitleTwo>[필수] 주문 취소 사유를 알려주세요</SubTitleTwo>
              <RefundReasonBox>
                <CancelReasonWrapper>
                  {cancelReasonList.map((item) => {
                    return (
                      <CancelReasonItem key={item.id}>
                        <CancelReasonLabel>
                        <CancelReasonRadioBtn 
                          // id= "cancelReasonRadioBtn"
                          name="radioOptions"
                          value={item.id}
                          checked={item.checked}
                          onChange={(e) => clickedRadio(item.id, e.target.checked)}
                          {...register('radioOptions')}
                        />
                          {item.name}
                        </CancelReasonLabel>
                      </CancelReasonItem>
                      )
                  })
                  }
                  <CancelReasonBox>
                    <div style={{marginBottom: "-4px"}}></div>
                    <ExtraBox  
                      id="extrabox" 
                      placeholder="기타 취소 사유를 적어주세요"
                      // defaultValue={cancelReason}
                      {...register('extrabox')}
                    />
                      <div style={{marginLeft:"25px"}}><FormError message={errors.extrabox?.message}/></div>
                    <div style={{marginTop:"-15px", marginLeft:"25px"}}><FormError message={errors.radioOptions?.message}/></div>
                  </CancelReasonBox>
                </CancelReasonWrapper>
              </RefundReasonBox>
            {modalType === "virtualAcc"?
              <ContainerThree>
                <SubTitleThree>[필수] 가상계좌 환불
                  <div></div>
                  <div id="subcontent">가상계좌 건은 실시간으로 환불 처리가 불가하며, 영업일 기준 2~3일 소요 할 수 있습니다</div>
                </SubTitleThree>
                <InputWrapper>
                    <label style={{fontWeight:300, fontSize:"15px"}} >
                      환불 은행
                    </label>
                  <RefundBankSelectBox 
                    type={"select"} 
                    id="refundBank"
                    onChange={(e) => setValue('refundBankSelectBox', e.target.value, { shouldValidate: true })} 
                    {...register("refundBankSelectBox")}
                  >
                    <option value={""}>환불은행을 선택해주세요</option>
                    {options.map( item => {
                      if (item.value !== '') {
                        return (<option key={item.value} value={item.value}>{item.label}</option>)
                      }
                    })}
                  </RefundBankSelectBox>
                  <FormError message={errors.refundBankSelectBox?.message}/>
                  <InputItem>
                    <label style={{fontWeight:300, fontSize:"15px"}}>
                      환불 계좌번호 
                    </label>
                    <input 
                      type="number" 
                      id="refundAccountNumberInput"
                      name="refundAccountNumberInput" 
                      placeholder="숫자만 입력해주세요"
                      defaultValue={refundAccountNumber}
                      value={refundAccountNumber}
                      {...register('refundAccountNumberInput')}
                    />
                    <FormError message={errors.refundAccountNumberInput?.message}/>
                  </InputItem>
  
                  <InputItem>
                    <label style={{fontWeight:300, fontSize:"15px"}}>
                      환불 계좌주명 
                    </label>
                    <input 
                      id="refundAccountNameInput" 
                      placeholder="환불 계좌주명" 
                      defaultValue={refundAccountName}
                      value={refundAccountName}
                      {...register('refundAccountNameInput')}
                    />
                    <FormError message={errors.refundAccountNameInput?.message}/>
                  </InputItem>
                </InputWrapper>
              </ContainerThree> 
              : ""
            }
            <BottomSection>
              <RefundAgreeField size={16}>
                <label htmlFor='checkAgree'>
                  <Check 
                    id='checkAgree'
                    {...register("checkAgree")}
                    /><i className="check-icon"/>
                    <div>취소할 상품의 정보와 환불정책을 확인하였으며 취소에 동의합니다.</div>
                </label>
                <FormError message={errors.checkAgree?.message}/>
              </RefundAgreeField>   
              <Buttons>
                <CancelBtn type="button" onClick={closeModal}>취소</CancelBtn>
                <ConfirmBtn type="submit">확인</ConfirmBtn>
              </Buttons>      
                    
            </BottomSection>
          </ContainerTwo>
        : "" 
      }
      </form>
      </ModalBody>
      {/* </div> */}
    </Modal>
    </> : " "
    } 
  </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  // background-color: black;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 50px 0px 30px;
  }

`
const ContainerTwo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 0px 8px;
  }
`
const ContainerThree = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  // height: 266px;
  // margin: 25px auto;
  // background-color: pink;
  // position: relative;
  // left: -64px;
  // bottom: 24px;
  margin-left: -64px;
  padding-left: 64px;
  margin-bottom: -30px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    padding: 0;

    margin-top: 70px;
    margin-bottom: -30px;
  }
`
const Title = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 20px;
    margin-bottom: 35px;
  }
`
  
const ContentBox = styled.div`
  width: 100%;
  background-color: #F5F5F5;
  padding: 15px 80px;
  // margin-bottom: 40px;
  // background-color: red;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    // height: 100%;
    position: initial;
    padding: 12px 10px 10px 10px;
  }
`

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
  }
  
`
const SubTitleTwo = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 13px;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
    margin: 0;
    font-size: 16px;
    margin-bottom: 12px;
  }

`
const SubTitleThree = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 13px;
  padding-top: 40px;
  margin-left: 16px;

  #subcontent { 
    font-weight: 200;
    font-size: 12px;
    color: #ABABAC;
    margin-top: 3px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;

    #subcontent { 
      margin-top: 2px;
      line-height: 14.4px;
      margin-bottom: 17px;
    }
  }
`

const Content = styled.div`
  // margin-left: 16px;

  // background-color: red;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0px;
  }
`

const TopContent = styled.div`
  width: 440px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 8px 8px 3px 8px;
  }
`

const LongDetailTopContent = styled.div`
  padding: 8px 10px 2px 11px;

  div {
    display: flex;
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
    margin-bottom: 5px;
  }

  p {
    font-weight: 800;
    margin-left: 5px;
  }

  span {
    font-weight: 800;
  }

  @media ${({ theme }) => theme.device.mobile} {
    div {
      margin-bottom: 4px;
      font-size: 13px;
      line-height: 15px;
      font-weight: 300;
    }
  
    p {
      font-size: 13px;
      font-weight: 800;
      margin-left: 5px;
    }

    span {
      font-size: 13px;
      font-weight: 800;
    }
  }
`

const ShortDetailTopContent = styled(LongDetailTopContent)`
  padding: 8px 10px 10px 11px;
  // padding-bottom: 3px;
`

const BottomContent = styled.div`
  font-size: 11px;
  line-height: 13px;

  div {
    font-weight: 200;
    margin-bottom: 5px;
  }

  p {
    margin-top: 7px;
    font-weight: 500;
    font-size: 12px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    // background-color: red;
    // height: 75px;
    margin-top: 2px;
    padding: 0 8px;

    div {
      font-weight: 200;
      font-size: 12px;
      line-height: 14.4px;
      // margin-bottom: 5px;
    }
    p {
      margin-top: 5px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14.4px;
    }
  }
`

const Closeicon = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 15px;
  width: 15px;
  display: block;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${closeIcon});
  cursor: pointer;
  z-index: 11;
  border:white;
  background-color: transparent;

  &:hover{
    transform: rotate(90deg);
  }

  
  @media ${({ theme }) => theme.device.mobile} {
    top: 15px;
  }
`
const RefundReasonBox = styled.div`
  // background-color: grey;

  @media ${({ theme }) => theme.device.mobile} {
    // margin: 0px;
    // background-color: yellow;
    // padding: 0;
    margin-left: 0px;
   
  }
 
`
const InputWrapper = styled.div`
  width: 440px;
  height: 250px;  
  margin-left: 16px;
  // background-color: red;

  .select {
    // margin-bottom: -10px;
  }
  // background-color: orange;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px;
    margin-bottom: -40px;

    
  }
`
const InputItem = styled.div`
  #refundAccountNameInput, #refundAccountNumberInput {
    // margin-top: -10px;
  }

  label {
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
  }

  input {
    width: 440px;
    height: 38px;
    background: #FFFFFF;
    border: 0.25px solid #ABABAC;
    border-radius: 5px;
    margin-top: 6px;
    padding-left: 20px;

    // ::-webkit-inner-spin-button {
    //   -webkit-appearance: none;
    //   margin: 0;
    // }



    &:focus{
      outline: 1px solid #ABABAC;
    }

    ::placeholder {
      font-color: #ABABAC;
      font-weight: 100;
      font-size: 15px;
    }
    
    @media ${({ theme }) => theme.device.mobile} {
      width: 100%;
      padding-left: 18px;

      label {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
`
const CancelReasonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`
const CancelReasonBox = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    // padding-left: 22px;
    padding: 0px 21px 0px 22px;
  }
`

const CancelReasonItem = styled.div`
  width: 220px;
  margin-left: 0px;
  height: 22px;
  margin-bottom: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    // background-color: aqua;
    width: 100%;
    margin: 0px;
    height: 22px;
    margin-bottom: 8px;

    span {
      background-color: red;
      font-size: 15px;
      line-height: 18px;
      margin-left: 10px;
      margin-top: -2px;
    }
  }
`

const CancelReasonLabel = styled.label`
  // display: flex;
  width: 124px;
  height: 16px;
  font-size: 14px;
  font-weight: 300;
  // padding-left: 10px;
  align-items: center;
  position: relative;
  top: -4px;
  // background-color: aqua;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
    position: initial;
  }
`

const CancelReasonRadioBtn = styled.input.attrs({type: 'radio'})`
  // display: none;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  position: relative;
  top: 4px;
  -webkit-appearance: button;

  &:checked {
    accent-color: #FE5A1D;
  }

  
  @media ${({ theme }) => theme.device.mobile} {
    top: 0px;
    position: relative;
  }
`
const ExtraBox = styled.textarea`
  width: 414px;
  height: 70px;
  background: #FFFFFF;
  border: 0.25px solid #ABABAC;
  border-radius: 5px;
  margin-left: 26px;
  padding-left: 8px;
  padding-top: 8px;
  // padding-bottom: 40px;
  resize: none;

  ::placeholder {
    font-color: #ABABAC;
    font-weight: 200;
    font-size: 14px;
  }

  &:focus{
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-left: 0px;
    padding: 7px 0px 0px 12px;

    ::placeholder {
      font-size: 15px;
    }
  }
`

const RefundBankSelectBox = styled.select`
  // label {
  //   font-size: 14px;
  //   font-weight: 300;
  //   margin-top: 15px;
  // }

  #plz {
    max-height: 100px;
    background-color: red;
  }

  width: 440px;
  height: 38px;
  // background: #FFFFFF;
  border: 0.25px solid #ABABAC;
  border-radius: 5px;
  margin-top: 6px;
  padding-left: 15px;
  color: #4B4B4B;
  font-weight: 100;
  font-size: 15px;

  // ::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }

  &:focus{
    outline: 1px solid #ABABAC;
  }

  // ::placeholder {
  //   // font-color: #ABABAC;
  //   color: aqua;
  //   font-weight: 100;
  //   font-size: 15px;
  // }
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding-left: 12px;
    padding-top: 2px;

    label {
      font-size: 15px;
      line-height: 18px;
    }
  }
`

const BottomSection = styled.div`
  margin-top: 22px;
  // background-color: orange;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 15px;
  }
`
const Check = styled.input.attrs({ type: 'checkbox'})``;
const RefundAgreeField = styled.div`
  font-size: 14px;
  font-weight: 200;  
  width: 100%;
  color: ${props => props.theme.txtGray};

  .agree {
    color: ${props => props.theme.txtBlack};
  }

  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon {
    background-image: url(${CheckboxChecked}); 
  }

  .check-icon {
    display: inline-block; 
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    margin-right: 10px;
    background: url(${CheckboxUnChecked}) no-repeat 95%; 
    background-size: cover;
    vertical-align: middle; 
    transition-duration: .2s; 
    // transform: translated(0, -7%, 0);

  }
  @media ${({ theme }) => theme.device.mobile} {
    margin: 0px;
    padding: 0;
    width: 100%;
    font-size: 13px;
    line-height: 16px;

    .check-icon {
      display: flex; 
    }

    div {
      width: 100%;
      margin: -15px 0px 0px 22px;
      padding-right: 25px;
    }
  }
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  // background-color: grey;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin:0;
    display: flex;
    justify-content: center;
    margin-top: 7px;
    margin-bottom: 30px;
  }
`

const CancelBtn = styled.button`
  width: 90px;
  height: 44px;
  background-color: #FFFFFF;
  border: 0.5px solid #2C2C2C;
  border-radius: 5px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-top: 4px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 18px;
  }
`

const ConfirmBtn = styled.button`
  width: 90px;
  height: 44px;
  background-color: #2C2C2C;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  padding-top: 4px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 18px;
  }
`
export default ModalController;