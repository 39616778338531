import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from "react-router";
import { productApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';

import { convertNumberFormat, isObjEmpty } from 'utils/util';

const ProductMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationStateInfo] = useState(location.state);
  const [ctgrId, setCtgrId] = useState('');
  const [responseData, setResponseData] = useState();
  const [mode, setMode] = useState('pc');

  const goProductDetail = (prodId) =>{
    navigate(`/product/${prodId}`);
  }

  useEffect(() => {
    if (locationStateInfo) {
      setCtgrId(locationStateInfo.ctgrId);
    } else {
      setCtgrId('10002'); // 메인 페이지에서 브리피 상품 카테고리를 클릭하지 않고 들어왔을 경우 '음식'으로 자동 세팅
    }
  }, [locationStateInfo]);
  
  useEffect (() => {
    window.scrollTo(0, 0);
    
    if(!isObjEmpty(ctgrId)){
      const res = productApi.getProductList(ctgrId).then( response => {
        setResponseData(response.data.productItemList);
      }, error => { 
        handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
      })
    }
  }, [ctgrId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Container>
      <Wrapper>
        <Title>촬영상품</Title>
        {
          responseData?.map((item, index) => {
            if (!isObjEmpty(item.listItemVOs)) {
              return (
                <ProductContainer key={index}>
                  <Category>
                    <CategoryIcon>
                      <img src={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
                    </CategoryIcon>
                    <CategoryName> {item.prodCtgrNm} </CategoryName>
                  </Category>
                  <ProductWrapper>
                    {item.listItemVOs.map((elem, index) => {
                      return(
                        <ProductItem key={index} onClick={()=> goProductDetail(elem.prodId)}> 
                          <ThumbnailContainer>
                            <img src={`${process.env.REACT_APP_AWS_S3_URL}/${elem.atchFilePath}/${elem.atchFileSaveNm}`} />
                          </ThumbnailContainer>
                          <ContentBox>
                            <ProductName>{elem.prodNm}</ProductName>
                            <Description>{elem.prodSmryCtnt}</Description>
                            <Price>
                              { Number(elem.sellPrc) ? `${convertNumberFormat(elem.sellPrc)}원` : `${convertNumberFormat(elem.minOptPrc)}원` }
                            </Price>
                          </ContentBox>
                        </ProductItem>
                      )
                      })
                    }
                  </ProductWrapper>
                </ProductContainer>
              )
            }
          })
        }
      </Wrapper>
    </Container>
  );
};

export default ProductMain;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 1300px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 auto;
    min-height: 0px;
  }
`

const Wrapper = styled.div`
  width: 1088px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
  align-items: center;
  margin: 118px auto 170px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 80px;
    margin-bottom: 160px;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 700;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px 0px 18px;
    font-size: 20px;
    line-height: 24px;
  }
`

const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0px 16px;
  }
`

const Category = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.device.mobile} {
    height: 32px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
`

const CategoryIcon = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 18px;

  img {
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
    border-radius: 10px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
`

const CategoryName = styled.div`
  margin-top: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 19.2px;
  
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
    margin-top: 8px;
  }
`

const ProductWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 31px;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-wrap: wrap;
  }
`
  
const ProductItem = styled.div`
  width: 100%;
  margin-bottom: 50px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    display: grid;
    grid-template-columns: repeat(1, 1fr 1.8fr);
    column-gap: 10px;
    margin-bottom: 15px;
  }
`

const ThumbnailContainer = styled.div`
  width: 100%;
  height: 228px;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    min-width: 120px;
    height: 100%;
    margin-bottom: 0px;
  }
`

const ContentBox = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`

const ProductName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
`

const Description = styled.div`
  font-weight: 200;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 14px;

  @media ${({ theme }) => theme.device.mobile} {
    min-height: 40px;
    margin-bottom: 0px;
  }
`

const Price = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 2px;
  }
`