import React, { useState } from 'react';
import styled, { keyframes }  from "styled-components";
import { useScrollFadeIn } from 'hooks/useScrollFadeIn';
import heroImage from "resources/main/herosection/heroImage.png"
import { useInterval } from "hooks/useInterval";

const HeroSection = () => {
  const [serviceImgNoMo,setServiceImgNoMo] = useState(0)

  const partnersAnim = useScrollFadeIn({threshold:0.3,delay:'.2'});
  
  useInterval(()=>setServiceImgNoMo((serviceImgNoMo+1)%4),1500)

  return (
    <Container>
      <LeftBox>

      </LeftBox>
      <KeyMessageBox >
        <div {...partnersAnim} className="KeyMessage">
        <div className="bannerText">플랫폼 촬영 대행 서비스</div>
          <div className="topText">브리피가</div>
          <div className="flip">
            <div><div>대금처리까지</div></div>
            <div><div>촬영하고</div></div>
            <div><div>스케줄 잡고</div></div>
            <div><div>작가 찾고</div></div>
          </div>
          <div className="downText">내 일은 끝났다</div>
        </div>
      </KeyMessageBox>
    </Container>
  )
}


const LeftBox = styled.div`
  flex:1;
  height:100vh;
  background: url(${heroImage});
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
  
  @media ${({ theme }) => theme.device.mobile} {
    flex:.55;
    width:100vw;
    height:55vh;
  }
`;

const Container = styled.article`
  position:relative;
  display:flex;
  overflow-x: hidden;
  width:100%;
  height:100%;
  
  align-items: center;
  justify-content: center;

  box-shadow: 0px -9px 37px #00000029;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column-reverse;
    width:100vw;
    height:100vh;
  }
`

const show = keyframes`
    0% {margin-top:-410px;}
    4% {margin-top:-300px;}
    7% {margin-top:-315px;}

    20% {margin-top:-315px;}
    24% {margin-top:-190px;}
    27% {margin-top:-200px;}

    40% {margin-top:-200px;}
    44% {margin-top:-80px;}
    47% {margin-top:-90px;}

    60% {margin-top:-90px;}
    64% {margin-top:20px;}
    67% {margin-top:10px;}

    100% {margin-top:10px;}
`;

const showMo = keyframes`
    0% {margin-top:-200px;}
    4% {margin-top:-112px;}
    7% {margin-top:-118px;}

    20% {margin-top:-118px;}
    24% {margin-top:-67px;}
    27% {margin-top:-76px;}

    40% {margin-top:-76px;}
    44% {margin-top:-36px;}
    47% {margin-top:-40px;}

    60% {margin-top:-40px;}
    64% {margin-top:2px;}
    67% {margin-top:-3px;}

    100% {margin-top:-3px;}
`;
const KeyMessageBox = styled.div`
  position:relative;
  display:flex;
  height:100vh;
  flex:1;
  flex-direction: column;
  text-align:left;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#F6F6F6,white);
  z-index:2;
  
  .bannerText {
    width:fit-content;
    color:white;
    letter-spacing: 1.4px;
    background-color: black;
    font-size: 23px;
    padding: 8px 10px;
    margin: 0 0 23px;
  }
  .topText {
    font-size:77px;
    font-weight:700;
  }
  .downText {
    font-size:77px;
    font-weight:700;
    margin-top:13px;
  }
  .KeyMessage {
    margin-left: -10%;
  }

  .flip {
    height:112px;
    font-size:77px;
    font-weight:600;
    overflow:hidden;
    div {
      &:first-child {
        animation: ${show} 5.5s ease-in-out infinite;
      }

      div{
        color:#FE5A1D;
        
        height:112px;
        display:inline-block;
      }
    }

  }

  p {
    position:fixed;
    width:100%;
    bottom:30px;
    font-size:12px;
    color:#999;
    margin-top:200px;
  }


  @media ${({ theme }) => theme.device.mobile} {
    flex:.45;
    width:100vw;
    height:45vh;
    justify-content: unset;
    align-items: unset;
    padding-top: 0;

    .bannerText {
      font-size:15px;
      padding: 5px 10px;
      margin: 0 0 12px;
    }

    .KeyMessage {
      // padding-top: 88px;
      // margin-left: 5%;
      margin: 0;
      padding: 88px 16px 0px;
    }

    .topText, .downText {
      font-size:42px;
    }
    .flip {
      height:62px;
      font-size:42px;
      margin-top: 9px;
      line-height:1.5;
      div{
        div{
          vertical-align: middle;
        }
      }
    }
    .flip div:first-child {
      /* margin-top:-32.12vw;
      animation:unset; */
      animation: ${showMo} 4.5s ease-in-out infinite;
    }

    .downText {
      margin-top:0;
    }
  }
`;

export default HeroSection;