import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import FormError from 'components/common/FormError';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isObjEmpty } from 'utils/util';
 import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';

const NewPassword = ({custUserNo, moveFunction}) => {
  const schema = yup.object().shape({
    loginPwd: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    loginPwdConfirm: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .oneOf([yup.ref('loginPwd'), null], '⚠ 비밀번호가 일치하지 않습니다'),
  });

  const { 
    register,
    formState:{errors},
    handleSubmit 
  } = useForm({
    resolver : yupResolver(schema)
  });
  	
  // '변경하기' 버튼 클릭 시
  const clickHandler = async (val) => {
    if (isObjEmpty(errors)) { //error가 없을 때 - Form Validation Check를 통과 했을 시
      const elem = { 
        custUserNo : custUserNo,
        loginPwd : val.loginPwd
      }
    
    // 신규 비밀번호를 서버에 저장 (put)
		const res = await memberApi.modifyPW(elem).then( response => {
      handleAlertText('비밀번호변경!', '신규 비밀번호 변경이 완료 되었습니다 ', 'success');
			moveFunction('login');
		}, error => { 
			handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
		  })
    }
  }  

  return (
    <form onSubmit={handleSubmit(clickHandler)}>
      <Wrapper>
        <Title>
          신규 비밀번호 재설정
        </Title>
          <Contentbox>
            <InputWrapper>
              <Inputbox
                name='loginPwd'
                placeholder='신규 비밀번호를 입력해주세요' 
                type='password'
                id='loginPwd'
                {...register('loginPwd')}
              >
              </Inputbox>
              {errors.loginPwd ? 
                <div style={{textAlign:'left', marginLeft:'15px', marginBottom:'3px'}}>
                  {<FormError message={errors.loginPwd?.message}/>}
                </div>: ''
              }
                
            	<Inputbox 
                name='loginPwdConfirm'
                placeholder='신규 비밀번호를 다시 입력해주세요' 
                id='loginPwdConfirm'
                type='password'
                {...register('loginPwdConfirm')}
              >
              </Inputbox>

              {errors.loginPwdConfirm ?
                <div style={{textAlign:'left', marginLeft:'15px', marginBottom:'5px'}}>
                  {<FormError message={errors.loginPwdConfirm?.message}/>}
                </div> : ''
              }
            </InputWrapper>

            <Button 
              type='submit'>
              변경하기
            </Button>
          </Contentbox>
      </Wrapper>
    </form>
  );
}

export default NewPassword;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    margin-top: -20px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  color: #2C2C2C;
  text-align: center;
  margin-top: 37px;

  @media ${({ theme }) => theme.device.mobile} {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
  }
`

const Contentbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${({ theme }) => theme.device.mobile} {
    height: 100%;
    padding: 0px;
    top: 0px;
  }
`
const InputWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  input + input {
    margin-top: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    input + input {
      margin-top: 10px;
    }
  }
`

const Inputbox = styled.input`
  width: 477px;
  height: 54px;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #F5F5F5;
  padding: 8px 30px;
  display: inline;
  padding-bottom: 2px;

  ::placeholder {
    color: #ABABAC; 
    font-size: 16px;
    font-weight: 400;
  }
  
  &:focus {
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 48px;
    padding: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding-left: 10px;

    ::placeholder {
      font-size: 15px;
      font-weight: 300;
      line-height: 18px;
    }

  }
`

const Button = styled.button`
  width: 477px;
  height: 54px;
  background: #2C2C2C;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  margin-top: 80px;

  @media ${({ theme }) => theme.device.mobile} {
    top: 0px;
    left: 0px;
    width: 100%;
    font-size: 18px;
    line-height: 21.6px;
    margin-top: 60px;
    margin-bottom: -10px;
  }
`
