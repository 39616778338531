import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Logo from 'resources/common/briphy_logo_4x1.png';
import SubLogo from 'resources/common/briphy_logo3.svg';
// import ContactPopup from 'views/Studio/Contact/ContactPopup';

import BasicForm from 'views/RecatchForm/BasicForm';

const Header = ({isTop}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formModal, setFormModal] = useState(false);
  const [hamburgerActive, setHamburgerActive] = useState(false);
  
  // modal여닫기 toogle함수
  const handleFindModal = () => {
    setFormModal(!formModal);
  }
  
  const hamburgerToggle = () => {
    setHamburgerActive(hamburgerActive ? false : true);
  }

  /**
   * 로고 버튼 클릭 시 상단으로 이동
   */
  const clickLogo = () => {
    window.scrollTo(0, 0);
    setHamburgerActive(false);
  }

  /**
   * 스크롤 이벤트 감지
   */  
  const [hide, setHide] = useState(false);
  const onScroll = (e) => {
    if (e.deltaY > 0) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  useEffect(() => {
    window.addEventListener('wheel', (e) => {
      onScroll(e);
    });
    return () =>window.removeEventListener('wheel', onScroll);
  }, []);

  const onMoveContactLocation = (mode) => {
    const location = document.querySelector('#ContactMove').offsetTop - 40;
    window.scrollTo({top: location, behavior: 'smooth'});

    if (mode) {
      hamburgerToggle();
    }
  }

  useEffect(() => {
    if (isTop) {
      setHide(false);
    }
  }, [isTop])

  return (
    <>    
      <FixedHeader className={hide && !hamburgerActive ? 'menuHide' : ''}>
        <MenuWrapper>
          <LeftHeaderMenu>
            <Item>
              <Link to={'/'}>
                <BriphyLogo src={Logo} onClick={() => clickLogo()} />
              </Link>
            </Item>
          </LeftHeaderMenu>
          <RightHeaderMenu>
            {/* <Item className={location.pathname.includes('/studio/workScope') ? 'focused' : ''}>
              <Link to={'/studio/workScope'}>Work Scope</Link>
            </Item> */}
            <Item className={location.pathname.includes('/studio/portfolio') ? 'focused' : ''}>
              <Link to={'/studio/portfolio'}>Portfolio</Link>
            </Item>
            {/* <Item className={location.pathname.includes('/mass') ? 'focused' : ''}>
              <Link to={'/mass'}>플랫폼 촬영 대행 솔루션</Link>
            </Item> */}
            <Item>
              <PartnerApplyContainer 
                // onClick={() => onMoveContactLocation(false)}>
                onClick={() => {setFormModal(!formModal)}}>
                <PartnerApplyButton>문의하기</PartnerApplyButton>
              </PartnerApplyContainer>
            </Item>
          </RightHeaderMenu>
          <HamburgerButton onClick={() => hamburgerToggle()}  aria-label='Menu' data-cy='burger' className='baseBurger'>
            <span className='baseBurger__icon'>
              <span className={`baseBurger__line ${hamburgerActive?'active':''}`} ></span>
              <span className={`baseBurger__line ${hamburgerActive?'active':''}`} ></span>
              <span className={`baseBurger__line ${hamburgerActive?'active':''}`} ></span>
            </span>
          </HamburgerButton>
          <SubMenuWrapper className={`${hamburgerActive ? 'active' : ''}`}>
            <SubMenuLogo src={SubLogo} onClick={() => clickLogo()} />
            <SubMenu>
              {/* <div><Link to={'/portfolio'} onClick={() => hamburgerToggle()}>포트폴리오</Link></div>
              - */}
              {/* <div><Link to={'/studio/workScope'} onClick={() => hamburgerToggle()}>Work Scope</Link></div>
              - */}
              <div><Link to={'/studio/portfolio'} onClick={() => hamburgerToggle()}>Portfolio</Link></div>
              -
              {/* <div onClick={() => onMoveContactLocation(true)}>문의하기</div> */}
              <div onClick={() => {setFormModal(!formModal)}}>문의하기</div>
              {/* -
              <div><Link to={'/mass'} onClick={() => hamburgerToggle()}>플랫폼 촬영 대행 솔루션</Link></div>      */}
            </SubMenu>
          </SubMenuWrapper>
        </MenuWrapper>
        <BasicForm
          open={formModal} // true/false 값을 통해 모달을 열건지 말건지 전달
          handleModal={handleFindModal} // 모달 여닫기 토글 함수 전달
        />
      </FixedHeader>
    </>
  )
}

export default Header;

const FixedHeader = styled.header`
  position:fixed;
  width: 100%;
  margin: 0 auto;
  // top:0;
  // left:0;
  // transition: background-color 0.2s ease-in-out;
  z-index: 99;
  background-color: ${props => props.theme.bgColorBlack};

  &.menuHide {
    transform: translateY(-80px);
    transition: all .2s ease-in-out;
  }

  transition: all .2s ease-in-out;
  
  @media ${({theme}) => theme.device.mobile} {
    width: 100vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  };
`;

const MenuWrapper = styled.ul`
  display: flex;
  position: relative;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  margin-left: 120px;
  margin-right: 120px;
  // margin-top:10px;

  .baseBurger {
    .baseBurger__icon {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      position: relative;
      height: 24px;
      width: 24px;
      z-index: 10;
      transform-origin: center center;
      will-change: transform;
      transform: translateZ(0px) scale(1);
      margin: 0px auto;
      transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
    }

    .baseBurger__line {
      background-color: #FFFFFF;
      position: absolute;
      left: 0px;
      height: 2px;
      width: 100%;
      z-index: 10;
      display: block;
      
      border-radius:10px;

      /* transition: background-color 0.4s ease 0s; */
      transition: all 0.3s ease 0s;

      &:first-child {
        top: 13%;
        background-color: #FFFFFF;
      }
      &:nth-child(2) {
        top: 50%;
        background-color: #FFFFFF;
        transform: translate3d(0%, -50%,0);
        opacity: 1;
      };
      &:nth-child(3) {
        bottom: 10%;
        background-color: #FFFFFF;
      }
      &.active {
        &:first-child {
          top: 50%;
          background-color: #FFFFFF;
          transform: translate(0%, -50%) matrix(0.7071, -0.7071, 0.7071, 0.7071, 0, 0);
        }
        &:nth-child(2) {
          background-color: #FFFFFF;
          opacity: 0;
        };
        &:nth-child(3) {
          bottom: 50%;
          background-color: #FFFFFF;
          transform: translate(0%, 50%) matrix(0.7071, 0.7071, -0.7071, 0.7071, 0, 0);
        }
      }
    }
  }
  
  @media ${({theme}) => theme.device.mobile} {
    // width: 100%;
    height: 60px;
    // padding: 10px 16px;
    margin-left: 20px;
    margin-right: 5px;
    // margin-top:22px;
  };
`;

const LeftHeaderMenu = styled.div`
  flex: 1;

  @media ${({theme}) => theme.device.mobile} {
    flex: .8;
  };
`;

const RightHeaderMenu = styled.div`
  display: flex;
  float: right;
  margin: 10px 0;
  font-size: 15px;  
  font-weight: 500;
  color: ${props => props.theme.txtWhite };

  .focused {
    font-weight: 800;
    transition-duration: 0.1s;
  }

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`;

const Item = styled.li`
  display: flex;
  float: left;  
  align-items: center;
  justify-items: center;

  font-family: Maven Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;

  & + & {
    margin-left: 50px;
  }

  .last {
    margin-left: -30px;
  }

  // list-style:none;
  // text-align:center;
  // margin-left:50px;
  // /* border-bottom : 5px solid ${props=> props.current?'#2A435B':'transparent'}; */
  // transition: all .2s ease-in-out;
  
  // &:nth-child(2) {
  //   transform: translate3d(0,1%,0);
  // }

`;

const PartnerApplyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 119px;
  height: 40px;
  cursor: pointer;
  padding: 12px 0px 12px 0px;
  border-radius: 20px 20px 20px 20px;
  background: #FFFFFF;
  text-align: center;
  // font-size: 15px;
  // font-weight: 500;
  // line-height: 18px;
  // background-color: ${props => props.theme.txtWhite};
  // padding: 7px 7px 5px 13px;  
  // border-radius: 35px;  
  // color: ${props => props.theme.bgColorOrange};
  // border: 1px solid ${props => props.theme.bgColorOrange};
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const PartnerApplyButton = styled.div`
  width: 65px;
  cursor: pointer;
  font-family: Maven Pro;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #212121;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const BriphyLogo = styled.img`
  @media ${({theme}) => theme.device.mobile} {
    height: 16px;
  };
`

const HamburgerButton = styled.button`
  display: none;
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;
  top: 50%;
  right: 10px;
  transform: translate3d(0,-50%,0);

  @media ${({theme}) => theme.device.mobile} {
    display: block;
  };
`;

const SubMenuLogo = styled.img`
  display: none;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
    margin: 38px auto;
  };
`

const SubMenuWrapper = styled.div`
  width: 100%;
  height: 0;
  color: white;
  background-color: ${props => props.theme.bgColorBlack};

  position:fixed;
  top: 48px;
  // top: 0;
  left:0;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(.74,.55,.24,1.05);

  &.active {
    height: 926px;
  }

  div {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    margin: 5px auto;
    cursor: pointer;
  }

  .gray {
    color: #4C4C4C;
    font-weight: 700;
  }

  .bold {
    font-weight: 700;
  }
`

const SubMenu = styled.div`
  padding: 40px;
`