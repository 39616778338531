import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
// import OrderDetails from './OrderList';
import OrderList from './OrderList';
import UserInfo from './UserInfo';
import DeleteAccount from './DeleteAccount';
import { isGuestLoggedIn, isUserLoggedIn } from 'utils/jwtUtil';
import { handleAlertText } from 'components/common/SweetAlert';
import { useNavigate } from 'react-router-dom';

const Mypage = () => {
  const navigate = useNavigate();
  const [myPageNav, setMyPageNav] = useState('OrderList');
  
  // 회원인지 비회원인지 구분 상태 관리
  const [isMember, setIsMember] = useState(false);

  // 버튼 스타일링 함수
  const btnStyleController = (id, backgroundColor, fontColor, fontWeight) => {
    if(id && backgroundColor && fontColor && fontColor && fontWeight) {
      document.getElementById(id).style.backgroundColor = backgroundColor;
      document.getElementById(id).style.color = fontColor;
      document.getElementById(id).style.fontWeight = fontWeight;
      // document.getElementById(id).style.boxShadow = boxShadow;
    }
  }

  useEffect(() => {
    if(!isUserLoggedIn() && !isGuestLoggedIn()) {
      handleAlertText('접근불가!', '비정상적인 접근입니다.', 'error').then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    }
    if (isUserLoggedIn()) {
      setIsMember(true);
      btnStyleController('orderListBtn', '#2C2C2C', '#FFFFFF', 700);
      btnStyleController('userInfoBtn', '#FFFFFF', ' #ABABAC', 200);
    }
  }, []);

  // '주문내역', '회원정보' 버튼 스타일링
  useEffect (() => {
    if(isUserLoggedIn()) {
      if (myPageNav === 'OrderList') {
        btnStyleController('orderListBtn', '#2C2C2C', '#FFFFFF', 700);
        btnStyleController('userInfoBtn', '#FFFFFF', ' #ABABAC', 200);
      } else if (myPageNav === 'UserInfo' || myPageNav === 'withdrawl') {
        btnStyleController('userInfoBtn', '#2C2C2C', '#FFFFFF', 700);
        btnStyleController('orderListBtn', '#FFFFFF', '#ABABAC', 200);
      }
    }
  }, [myPageNav]);

  // 주문내역 - 회원정보 nav 변경 함수
  const clickHandler = (event) => {
    if ( isMember && event.currentTarget.id === 'orderListBtn') {
      setMyPageNav('OrderList');
    }
    if ( isMember && event.currentTarget.id === 'userInfoBtn') {
      setMyPageNav('UserInfo');
    }
  };

  // 회원탈퇴 버튼 클릭시 -> 회원탈퇴 세팅 
  const changePageNav = () => {
    setMyPageNav('withdrawl');
  };


  // 회원탈퇴 버튼을 눌렀을 시
  const delAccHandler = () => {
    changePageNav();
    // navigate('/deleteAccount');
  }

  return (
    <Container>
      <InnerContainer>
        <Wrapper>
          <MenuButton> 
            { isUserLoggedIn() ? <>
              <OrderListStyl 
                type='button' 
                id='orderListBtn' 
                onClick={clickHandler} 
              > 주문내역
              </OrderListStyl>
              <br />
              <UserInfoStyl 
                type='button' 
                id='userInfoBtn' 
                onClick={clickHandler}
              > 회원정보
              </UserInfoStyl></> : isGuestLoggedIn() ? <GuestOrderListTab
                type='button' 
              > 비회원 주문내역
              </GuestOrderListTab>: ''
            }
          </MenuButton>
          <ContentBox> 
            { (isUserLoggedIn() && (myPageNav === 'OrderList')) ? <OrderList /> : 
              (isUserLoggedIn() && (myPageNav === 'UserInfo')) ? <UserInfo changePageNav={changePageNav} /> : 
              (isUserLoggedIn() && (myPageNav === 'withdrawl')) ? <DeleteAccount /> : <OrderList />}
          </ContentBox>

        </Wrapper>
      { (isUserLoggedIn() && (myPageNav === 'UserInfo'))? 
        <DeleteAccountBtn 
          onClick={delAccHandler}
        > 회원탈퇴
        </DeleteAccountBtn> : ''
      }
      </InnerContainer>
    </Container>
  )
}

export default Mypage;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  position: relative;

  // background-color: red;


  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 160px;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: auto auto;
  width: 1088px;
  height: 775px;

  // background-color: green;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    padding: 0px 16px 0px 16px;
    overflow-y: auto;
  }

`

const InnerContainer =styled.div`
  display: block;
  margin: auto auto;
  width: 1088px;
  height: 1110px;

  @media ${({ theme }) => theme.device.mobile} {
    // height: 2250px;
    width: 100%;
    height: unset;
  }
`

const MenuButton = styled.div`
  z-index: 2;
  width: 170px;
  height: 108px;
  margin-top: 20px;
  position: relative;
  top: 150px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
    margin: 0;
  }
`

const ContentBox = styled.div`
  z-index: 1;
  width: 100%;
  height: 675px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;  
  position: relative;
  margin: 150px auto 0px -32px;
  overflow-y: auto;
  overflow-x: hidden;

  //FIX ME _ 버튼만이 아니라 height바뀜에 따라 전체적인 내용에 대한 가운데 정렬을 원하실 경우
  // display: flex;
  // flex-direction: column;
  // justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    box-shadow: none;
    border-radius: 0px;
    margin: 0 auto;
    overflow: unset;

    // background-color: aqua;
  }
`

const OrderListStyl = styled.button`
  width: 170px;
  height: 54px;
  font-size: 16px;
  border: none;
  padding-top: 5px;
  padding-right: 80px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
`

const GuestOrderListTab = styled.div`
  width: 170px;
  height: 54px;
  font-size: 16px;
  border: none;
  background: #2C2C2C;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  font-weight: 700;
  line-height: 19px;
  color: white;
  padding: 20px 0px 0px 20px;
`
  
const UserInfoStyl = styled.button`
  width: 170px;
  height: 54px;
  font-size: 16px;
  border: none;
  padding-top: 5px;
  padding-right: 80px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
`

const DeleteAccountBtn = styled.button`
  // display: none;
  width: 100px;
  height: 30px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  color: #ABABAC;
  background-color: transparent;
  text-decoration: underline;
  margin-left: 1010px;
  margin-top: 60px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
