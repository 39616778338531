import { isObjEmpty } from 'utils/util'

/** 로그인 시 토큰 값 저장 */
export const isUserLoggedIn = () => {
  // 저장된 토큰이 없는지 체크
  if (isObjEmpty(localStorage.getItem('briphyMarketplaceUser'))) return false;

  const userData = JSON.parse(localStorage.getItem('briphyMarketplaceUser'));
  if (Date.now() >= userData.exp * 1000) { // 토큰 만료 시 처리
    localStorage.removeItem('briphyMarketplaceUser');
    return false;
  } else {
    return true;
  }
}

/** 비회원 로그인 체크 */
export const isGuestLoggedIn = () => {
  // 저장된 토큰이 없는지 체크
  if (isObjEmpty(localStorage.getItem('briphyMarketplaceGuest'))) return false;

  // 로그인 정보가 없을 경우 비회원 정보 체크
  const guestData = JSON.parse(localStorage.getItem('briphyMarketplaceGuest'));
  if (Date.now() >= guestData.exp * 1000) { // 토큰 만료 시 처리
    localStorage.removeItem('briphyMarketplaceGuest');
    return false;
  } else {
    return true;
  }
}

/** 유저 세션값 가져오기 */
export const getUserData = () => JSON.parse(localStorage.getItem('briphyMarketplaceUser'));

/** 비회원 세션값 가져오기 */
export const getGuestData = () => JSON.parse(localStorage.getItem('briphyMarketplaceGuest'));

/** 
 * 방문자 세션 체크 
 * key : 체크할 string key
 */
export const checkVisitorSession = (key) => {
  if (isObjEmpty(localStorage.getItem(key))) {
    return false;
  }

  const visitData = JSON.parse(localStorage.getItem(key));
  if (Date.now() >= visitData.exp * 1000) {
    localStorage.removeItem(key);
    return false;
  } else {
    return true;
  }
}

/** 
 * 포트폴리오 방문자 세션 체크 
 * key : 체크할 string key
 */
export const checkPortfolioVisitorSession = (key) => {
  if (isObjEmpty(localStorage.getItem(key))) {
    return false;
  }

  const visitData = JSON.parse(localStorage.getItem(key));
  if (Date.now() >= visitData.exp * 1000) {
    localStorage.removeItem(key);
    return false;
  } else {
    return true;
  }
}

/** 
 * 방문자 세션 생성
 * 5분 이내에 재접속 시 접속이력 생성 안함
 */
export const setVisitorSession = (key) => {
  if (isObjEmpty(localStorage.getItem(key))) {
    const data = {
      exp: Date.now() / 1000 + 60 * 5
    }
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    return false;
  }
}

/** 
 * 포트폴리오 방문자 세션 생성
 * 30분 이내에 재접속 시 접속이력 생성 안함
 */
export const setPortfolioVisitorSession = (key) => {
  if (isObjEmpty(localStorage.getItem(key))) {
    const data = {
      exp: Date.now() / 1000 + 60 * 30
    }
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    return false;
  }
}