// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => {
  if (obj === undefined || obj === null) return true
  else return Object.keys(obj).length === 0
}

// ** Checks if an object is empty (returns boolean)
export const isNumberEmpty = obj => {
  if (obj === undefined || obj === null) return true
  else return !(Number(obj) > 0)
}


/**
 * 숫자 표시 (콤마를 표시해줌)
 * num : 변환할 숫자
 * zeroYn : 값이 0일 경우 0 표시 여부 (Y/N)
 */
export const convertNumberFormat = (num, zeroYn) => {
  if (zeroYn === 'Y') {
    if (isNumberEmpty(num)) {
      return '0';
    } 
  } else {
    if (isObjEmpty(num)) {
      return '';
    } 
  }

  return num.toString().replace(/[^\d]+/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

/**
 * 휴대폰번호 포맷
 */
export const convertPhoneNumberFormat = (num) => {
  if (isObjEmpty(num)) {
    return ''
  } else {
    return num.replace(/[^0-9]/, '').replace(/^(\d{3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }
}

/**
 * 날짜와 시간 formatting 
 * MM월 DD일(W) HH:MM
 */
export const convertDateTime = (date, yearYn='N') => {
  if (date !== undefined && date !== null && !isObjEmpty(date)) {
    const dY = date.slice(0, 4);
    const dM = date.slice(4, 6);
    const dD = date.slice(6, 8);
    const tH = date.slice(8, 10);
    const tM = date.slice(10, 12);
    const stringYMD = `${dY}-${dM}-${dD}`;

    let week = new Array('일', '월', '화', '수', '목', '금', '토');
    let today = new Date(stringYMD).getDay();
    let todayLabel = week[today];

    let stringDate = '';
    if (yearYn === 'Y') {
      stringDate = `${dY}년 ${dM}월${dD}일(${todayLabel}) ${tH}:${tM}`;
    } else {
      stringDate = `${dM}월${dD}일(${todayLabel}) ${tH}:${tM}`;
    }

    return stringDate;
  } else {
    return ''
  }
}

/**
 * 촬영일자 날짜 및 시간 formatting
 * YYYY년 MM월 DD일(W) HH:MM or YYYY년 MM월 DD일 시간 미정
 */
export const convertDateTimeForSending = (date) => {
  if (date !== undefined && date !== null && !isObjEmpty(date)) {
    const dY = date.slice(0, 4);
    const dM = date.slice(4, 6);
    const dD = date.slice(6, 8);
    const tH = date.slice(8, 10);
    const tM = date.slice(10, 12);
    const stringYMD = `${dY}-${dM}-${dD}`;

    let week = new Array('일', '월', '화', '수', '목', '금', '토');
    let today = new Date(stringYMD).getDay();
    let todayLabel = week[today];
    let time = `${tH}:${tM}`;
    if (tH === '00' && tM === '00') {
      time = '시간 미정';
    }

    const stringDate = `${dY}년 ${dM}월${dD}일(${todayLabel}) ${time}`; 
    return stringDate;
  } else {
    return ''
  }
}

/**
 * 날짜 formatting
 */
export const convertDate = (date) => {
  if (date !== undefined && date !== null && !isObjEmpty(date)) {
    const dY = date.slice(0, 4);
    const dM = date.slice(4, 6);
    const dD = date.slice(6, 8);
    const stringYMD = `${dY}.${dM}.${dD}`;

    return stringYMD;
  } else {
    return ''
  }
} 

/** 
 * 날짜 형식 두 자리수 formatting
 * desc 날짜가 한 자리일 경우 두 자리로 표현
 */
export const digitFormatting = (number) => {
  if(parseInt(number) < 10){
    let result = '0' + number;
    return result;
  } else{
    return number;
  }
}

/**
 * 날짜 slice 
 * @param {string} date 날짜 YYYYMMDDHHMM
 * @returns {string} yyyyMMDD
 */
export const dateSlice = (date) => {
  const dY = date.slice(0, 4);
  const dM = date.slice(4, 6);
  const dD = date.slice(6, 8);
  const str = `${dY}${dM}${dD}`;

  return str;
}

/**
 * 환불을 위한 날짜 비교
 * @param {string} date YYYYMMDDHHMM
 * @param {string} today YYYYMMDDHHMM
 */
export const compareRefundDate = (date, today) => {
  let refundState = '';
  if (date !== undefined && date !== null && !isObjEmpty(date)) {
    const strDate = dateSlice(date);
    const strToday = dateSlice(today);

    if (parseInt(date) < parseInt(today)) {
      refundState = 'noRefund';
    } else if (parseInt(strDate) === parseInt(strToday)) { 
      refundState = 'refundWithPenalty';
    } else {
      refundState = 'fullRefund';
    }
  }

  return refundState;
}

/**
 * 날짜 포맷변경하기
 */
export const convertDateFormat = (date) => {
  if (date !== undefined && date !== null && !isObjEmpty(date)) {
    let year = date.slice(6,10);
    let month = date.slice(0,2);
    let day = date.slice(3,5);

    const stringYMD = year + month + day;

    return stringYMD;
  } else {
    return ''
  }
}

/**
 * 마스킹 처리
 * @param {string} string 변환할 string
 */
export const convertMaskingString = (string) => {
  let maskingStr = '';
  let mask = '';
  // maskingStr = string.replace(/(?<=.{1})./gi, "*"); // safari 호환 문제로 사용 불가

  if (!isObjEmpty(string)) {
    if (string.length > 2) {
      maskingStr = string.substring(0, 1);
      [...Array(string.length - 1)].map( i => {
        maskingStr += '*';
      });
    } else { // 외자일 경우
      maskingStr = string.substring(0, 1) + '*';      
    }
  }

  return maskingStr;
}

/**
 * @returns YYYYMMDDHHSS 형식의 오늘 날짜
 */
export const todayDateTime = () => {
  const date = new Date();
  const year = date.getFullYear().toString();

  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month.toString() : month.toString();
  
  let day = date.getDate();
  day = day < 10 ? '0' + day.toString() : day.toString();
  
  let hour = date.getHours();
  hour = hour < 10 ? '0' + hour.toString() : hour.toString();

  let minute = date.getHours();
  minute = minute < 10 ? '0' + minute.toString() : minute.toString();

  return year + month + day + hour + minute;
}

export const getSOWContentItems = () => {
return ([
  {
    num: '01',
    name: '광고제작',
    desc: 'Studio BRIPHY는 국내 최정상급의 제작사, 에이전시, 엔터사들과 협업하고 시너지를 창출하여\n최고의 광고 제작 솔루션을 제공합니다.',
    mobileDesc: 'Studio BRIPHY는 국내 최정상급의 제작사, 에이전시, 엔터사들과 협업하고 시너지를 창출하여 최고의 광고 제작 솔루션을 제공합니다.',
    verified: '동남아시아 에이전시와의 협업을 통한 다양한 글로벌 캠페인을 진행하고 있습니다.',
    portfolioName: '#ExperienceLimitlessWithSimInvest',
    // portfolioSrc: {SOWImage01},
    portfolioDesc: '아티스트 뷔와 글로벌 배우 성룡의 콜라보로 만들어진\nSinarmas Sekuritas의 증권앱 SimInvest 광고 영상입니다.',
    portfolioClient: 'Sinarmas Sekuritas',
    portfolioType: 'TVCF',
    portfolioScope: '기획, 제작, 섭외',
    link: 'https://www.youtube.com/watch?v=XUjSZfmSqh4'
  },
  {
    num: '02',
    name: '영상제작',
    desc: "철저하게 상업적으로 판단합니다.\n'우리의 콘텐츠를 통해 고객이 돈을 벌 수 있는가’\n우리는 이 한가지 기준으로 작업합니다.",
    mobileDesc: "철저하게 상업적으로 판단합니다. '우리의 콘텐츠를 통해 고객이 돈을 벌 수 있는가’ 우리는 이 한가지 기준으로 작업합니다.",
    verified: '',
    portfolioName: 'Y-Collection 우아한 휴식',
    // portfolioSrc: {SOWImage02},
    portfolioDesc: '지냄의 High-end Residence 브랜드 Y-Collection의 브랜드 필름입니다.\n초고층 하이엔드 레지던스에서의 시간을 우아한 휴식이라는 주제로 풀어냈습니다.',
    portfolioClient: 'Jienem',
    portfolioType: '브랜드 필름',
    portfolioScope: '기획, 제작, 섭외',
    link: ''
  },
  {
    num: '03',
    name: '사진촬영',
    desc: '뷰티, 패션, 제품, 건축, F&B, 매거진, 현장스케치 등\n모든 분야에서 고품질 상업 이미지를 제공합니다.',
    mobileDesc: '뷰티, 패션, 제품, 건축, F&B, 매거진, 현장스케치 등 모든 분야에서 고품질 상업 이미지를 제공합니다.',
    verified: '',
    portfolioName: '하우스오브신세계(HOS)',
    // portfolioSrc: {SOWImage03},
    portfolioDesc: '신세계백화점이 제공하는 새롭고 특별한 미식 문화 공간의 그랜드 오프닝 현장 촬영입니다.',
    portfolioClient: '신세계백화점 X JW메리어트호텔',
    portfolioType: '인테리어 및 행사 스케치 촬영',
    portfolioScope: '기획, 제작, 섭외'
  },
  {
    num: '04',
    name: '숏폼영상',
    desc: '간결한 것이 더 풍부하고, 더 많은 이야기를 전달합니다.\n짧은 영상에 강한 자극을 요하는 요즘 콘텐츠 소비 성향에 더욱 들어맞는 말입니다.\n거품이 걷히면 핵심이 보입니다. 진정한 실력은 간결함에서 나타납니다.',
    mobileDesc: '간결한 것이 더 풍부하고, 더 많은 이야기를 전달합니다. 짧은 영상에 강한 자극을 요하는 요즘 콘텐츠 소비 성향에 더욱 들어맞는 말입니다. 거품이 걷히면 핵심이 보입니다. 진정한 실력은 간결함에서 나타납니다.',
    verified: '',
    portfolioName: '페이히어 다해야',
    // portfolioSrc: {SOWImage04},
    portfolioDesc: '부평에 위치한 숙성회 전문점 다해야 숏폼 홍보영상입니다.\n단순한 정보 나열식의 양산형 숏폼이 아닌 소비자의 시선과 기억에 오래 머물 수 있는 제작 방식을 시도하였습니다.',
    portfolioClient: '페이히어',
    portfolioType: '숏폼 영상',
    portfolioScope: '기획, 제작, 섭외'
  },
  {
    num: '05',
    name: '대량촬영솔루션',
    desc: '전국적으로 다수의 촬영이 필요할 때.\n티오더, 여기어때, 헤이딜러 등 잘 나가는 플랫폼 서비스가 매일 이용하는 국내 유일의 대량 촬영 솔루션',
    mobileDesc: '전국적으로 다수의 촬영이 필요할 때.\n티오더, 여기어때, 헤이딜러 등 잘 나가는 플랫폼 서비스가 매일 이용하는 국내 유일의 대량 촬영 솔루션',
    verified: '',
    portfolioName: '촬영이 간편해지는 방법',
    // portfolioSrc: {SOWImage01},
    portfolioDesc: '대량의 촬영 물량이 전국적으로 필요할 때 브리피의 대량 촬영 솔루션이 있습니다.\n\n01\t검증된 공급자를 통한 퀄리티 보장\n02\t지역 작가 연결을 통한 출장비 제로화\n03\t촬영 물량을 바탕으로 촬영 가격 50% 절감\n04\t촬영 스케줄링부터 결과물까지 전 촬영 과정 대행\n05\t촬영 후 48시간 이내 결과물 전달',
    portfolioClient: '티오더, 페이히어, 하이오더, 유플러스오더, 여기어때, 캐치테이블, 온다, 핸디즈, 지냄, 헤이딜러, 다짐, 푸드트래블, 큐마켓, 셔틀딜리버리',
    portfolioType: '',
    portfolioScope: ''
  },
  {
    num: '06',
    name: 'K-Celebrity',
    desc: '국내 최정상 셀럽 섭외를 필요로 하는 광고 촬영, 파트너십 및 브랜드 앰버서더 계약,\n국내 및 해외 콘서트, 이벤트, 페스티벌에 K-셀럽을 연결합니다.',
    mobileDesc: '국내 최정상 셀럽 섭외를 필요로 하는 광고 촬영, 파트너십 및 브랜드 앰버서더 계약, 국내 및 해외 콘서트, 이벤트, 페스티벌에 K-셀럽을 연결합니다.',
    verified: '',
    portfolioName: 'K-celebrity Agency',
    // portfolioSrc: {SOWImage01},
    portfolioDesc: ' - 인도네시아 윙스 그룹 라이브커머스 (슈퍼주니어 최시원)\n - 인도네시아 시나르마스 그룹 2022 캠페인 (배우 현빈)\n - 인도네시아 시나르마스 그룹 2023 캠페인 (BTS 뷔)\n - 인도네시아 시나르마스 그룹 2024 캠페인 (BTS 뷔 X 배우 성룡)',
    portfolioClient: '',
    portfolioType: '',
    portfolioScope: ''
  },
])
}