import React from 'react';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from 'react-feather';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment'
import ko from 'date-fns/locale/ko'; 
import calendarIcon from 'resources/common/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ko', ko); 

const DatePickerComponent = ({ date, setDate, width = 170, id, minDatetime = new Date() }) => {

  return (
    <DatePickerWrapper width={width}>
      <DatePicker
        locale='ko' 
        id={id}
        showPopperArrow={false}
        dateFormat="yyyy.MM.dd HH:mm"
        fixedHeight
        closeOnScroll={true} 
        selected={date}
        showTimeSelect
        disabledKeyboardNavigation
        onFocus={e => e.target.blur()}
        timeCaption='시간선택'
        minDate={minDatetime} // 이전 날짜 선택 불가
        dayClassName={(d) =>{
          return moment(d).format('YYYY.MM.DD') === moment(date).format('YYYY-MM-DD')
            ? 'custom-day selected-day'
            : moment(d).format('YYYY-MM') === moment().format('YYYY-MM')
            ? 'custom-day'
            : 'custom-day'
            // : 'custom-day gray-day' // 현재 달이 아닐 경우 회색 글씨 처리
          }
          
        }
        onChange={(d) =>
          setDate(d)
        }
        renderCustomHeader={({
          date:d,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="date-customheader">
            <button type='button' className="prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}><ArrowLeft size={20} /></button>
              <div className="custom-month">{d.getFullYear()}년 {d.getMonth()+1}월</div> 
            <button type='button' className="next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}><ArrowRight size={20} /></button>
          </div>
        )}
      />
    </DatePickerWrapper>
  )
}

export default DatePickerComponent;

const DatePickerWrapper = styled.span`
  input {
    width: ${props => props.width ? `${props.width}px` : `170px`};
    height: 34px;
    border: ${props => props.border === 'none' ? 'none' : '0.25px solid #D9D9D9'};
    outline: none;
    border-radius: 5px;
    padding-left: 48px;
    font-size: 16px;
    font-weight: 500;
    background: url(${calendarIcon}) no-repeat 18px 44% white;
  }

  .custom-day {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
  }

  .gray-day {
    color: #aba8b9;
  }
  
  .selected-day {
    background: ${props => props.theme.accent};
    border-radius: 50%;
    font-weight: 700;
  }
  
  .date-customheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;

    button {
      position: relative;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .next {
    }

    .prev {
    }
  }

  .react-datepicker__time-container 
  .react-datepicker__time 
  .react-datepicker__time-box 
  ul.react-datepicker__time-list 
  li.react-datepicker__time-list-item {
    padding: 9px 10px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    input {
      width: 100%;
      height: 48px;
      // margin-bottom: 15px;
    }
  }
`;