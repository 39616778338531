import { useEffect } from 'react';
import styled from 'styled-components';

import { mainApi } from 'utils/api';
import Step from './Step';
import NaverADLogAnalysticsChange from 'components/NaverADLogAnalyticsConvert';

const Quotation = () => {

  useEffect(() => {
  // 초기 화면 접속 시 접속 이력 저장
    mainApi.insertConnectInfo({pageDvnm: '견적'});
  }, []);

  return (
    <>
      <Container>
        <NaverADLogAnalysticsChange type='5' value='0' />
        <Menu>무료 촬영견적</Menu>
        <Step />
      </Container>
    </>
  )
}

export default Quotation;

const Container = styled.div`
  margin: 0 auto 180px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 62px);

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0 auto 200px;
    padding: 0 16px;
  }
`

const Menu = styled.div`
  width: 100%;
  margin: 120px auto 40px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 77px 0 27px;
    font-size: 20px;
    line-height: 24px;
  }
`