import styled, { keyframes } from 'styled-components';

import CartImage from 'resources/mainproduct/hero_full_cart.png';
import { useScrollFadeIn } from 'hooks/useScrollFadeIn';
// import {ReactComponent as CartImage} from 'resources/common/cart.svg';

const HeroSection = () => {

  const titleAnime = useScrollFadeIn({threshold: 0.3, delay:'0.3', direction: 'rightLeft'});

  return (
    <Container>
      <HeroContainer>
        <CartWrapper>
          <Flash></Flash>
          <CartIcon>
            <img src={CartImage} />
          </CartIcon>
        </CartWrapper>
        <TextContainer {...titleAnime}>
          <div>촬영을</div>
          <div>쇼핑하다</div>
        </TextContainer>
      </HeroContainer>
    </Container>
  )

}

export default HeroSection;

const Container = styled.div`
  display: flex;
  width: 100%;  
  margin: 0 auto;
  background-color: #F5F5F5;

  @media ${({theme}) => theme.device.mobile} {
    height: 528px;
  };
`

const HeroContainer = styled.div`
  width: 1088px;
  height: 710px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px auto 0px;
  padding-right: 27px;

  @media ${({theme}) => theme.device.mobile} {
    justify-content: flex-end;
    flex-direction: column-reverse;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  };
`

const CartWrapper = styled.div`
  position: relative;
  
  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin-top: 15px;
  };
`

const flash = keyframes`
  0% {
    width: 0;
    height: 0;
  }

  15% {
    width: 0;
    height: 0;
  }

  20% {
    width: 100px;
    height: 100px;

    @media ${({theme}) => theme.device.mobile} {
      width: 50px;
      height: 50px;
    }
  }
  
  25% {
    width: 0;
    height: 0;
  }
  
  30% {
    width: 400px;
    height: 400px;
    
    @media ${({theme}) => theme.device.mobile} {
      width: 200px;
      height: 200px;
    }
  }
  
  40% {
    width: 0;
    height: 0;
  }
  
  60% {
    width: 0;
    height: 0;
  }
  
  100% {
    width: 0;
    height: 0;
  }
`

const Flash = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  margin-top: 185px;
  margin-left: 337px;
  border-radius: 100%;
  z-index: 2;
  transform: translate(-50%, -50%);
  animation: 2s ${flash} infinite;
  background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  
  @media ${({theme}) => theme.device.mobile} {
    width: 30px;
    height: 30px;
    margin-top: 58px;
    margin-left: 193px;
  };
`

const CartIcon = styled.div`
  // position: absolute;
  // left: -40px;
  margin-left: -40px;
  margin-top: 79px;

  @media ${({theme}) => theme.device.mobile} {
    margin: 0;    
    margin-left: -10px;

    img {      
      width: 282px;
      height: 295px;
    }
  };
`

const TextContainer = styled.div`
  width: 443px;
  margin: auto 0;
  font-weight: 700;
  font-size: 128px;
  line-height: 154px;

  @media ${({theme}) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 0;
    margin-top: 88px;
    padding-right: 28px;    
    font-size: 52px;
    line-height: 62px;
    
    div {
      width: 180px;
    }
  };
`
