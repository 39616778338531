import styled from 'styled-components';

const Clients = () => {
  /**
   * 특정 폴더의 모든 이미지를 모두 대상으로 처리하기 위해 지정된 폴더를 읽어들여 변수로 담음
   */
  const images = require.context('resources/studio/clients/202406', true);
  const clients = images.keys().map(image => images(image));

  return (
    <Container>
      <PageTitle>CLIENTS</PageTitle>
      <ClientsIconWrapper>
      {clients.map((item, index) => {
        return (
        <ClientsIconItem key={index}>
          <img src={item} width={280} />
        </ClientsIconItem>
        )
      })}
      </ClientsIconWrapper>
    </Container>
  )
}

export default Clients;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.bgColorWhite }
`

const PageTitle = styled.div`
  font-family: Pretendard;
  font-size: 60px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;

  margin-top: 160px;
  margin-bottom: 100px;
`

const ClientsIconWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 340px 340px 340px 340px;
  // grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr;
  width: 100%;
  min-width: 1440px;
  gap: 24px;
  margin-bottom: 160px;
`

const ClientsIconItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 402px;
  height: 208px;
  border-radius: 20px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #212121
`