import { productApi } from 'utils/api';
const { TossPayments } = window;
const clientKey = `${process.env.REACT_APP_TOSSPAYMENT_CLIENT_KEY}`;
const tossPayments = TossPayments(clientKey);

export const getProductInfo = (prodId) => {
  return async dispatch => {
    await productApi.getProductInfo(prodId).then(response => {
      dispatch({
        type: 'GET_PRODUCT',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const getUserSimpleInfo = (custUserNo) => {
  return async dispatch => {
    await productApi.getUserSimpleInfo(custUserNo).then(response => {
      dispatch({
        type: 'GET_USERINFO',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

/**
 * 1. 주문 정보 DB insert
 * 2. 토스 결제창 호출
 * 2-1. 성공 및 실패 
 */
export const insertOrderInfo = (orderInfo, payElem) => {
  return async dispatch => {
    await productApi.insertOrderInfo(orderInfo).then(response => {
      // 1. 주문 정보 DB insert
      dispatch({
        type: 'INSERT_ORDERINFO',
        data: response.data
      })

      // 2. 토스 결제창 호출
      const formElem = {
        amount: payElem.amount,
        // amount: 1000, // FIXEDME 테스트 용
        orderId: response.data.ordId,
        orderName: payElem.orderName,
        customerName: payElem.customerName,
        successUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/success`,
        failUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/fail`,
      }

      // 가상계좌일 경우
      if (payElem.payMethod === '가상계좌') {
        formElem.validHours = `${process.env.REACT_APP_TOSSPAYMENT_VIRTUAL_VALIDHOURS}`; // 기본 168(7일) 최대 720시간(30일)
      }
      
      tossPayments
      .requestPayment(payElem.payMethod, formElem)
      .catch(function (error) {
        if(error.code) {
          alert(error.message)
        }
        // if (error.code === 'USER_CANCEL') {
        //   alert(error.message)
        // } else if (error.code === 'INVALID_CARD_COMPANY') {
        //   // 유효하지 않은 카드 코드에 대한 에러 처리
        // }
      });

    }).catch(err => console.log(err))
  }
}

// 비회원 주문결제
export const insertGuestOrderInfo = (orderInfo, payElem) => {
  return async dispatch => {
    await productApi.insertGuestOrderInfo(orderInfo).then(response => {
      dispatch({
        type: 'INSERT_ORDERINFO',
        data: response.data
      })

      const formElem = {
        amount: payElem.amount,
        // amount: 1000, // FIXEDME 테스트 용
        orderId: response.data.ordId,
        orderName: payElem.orderName,
        customerName: payElem.customerName,
        successUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/success`,
        failUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/fail`,
      }
      
      // 가상계좌일 경우
      if (payElem.payMethod === '가상계좌') {
        formElem.validHours = `${process.env.REACT_APP_TOSSPAYMENT_VIRTUAL_VALIDHOURS}`; // 기본 168(7일) 최대 720시간(30일)
      }
      
      tossPayments
      .requestPayment(payElem.payMethod, formElem)
      .catch(function (error) {
        if(error.code) {
          alert(error.message)
        }
        // if (error.code === 'USER_CANCEL') {
        //   alert(error.message)
        // } else if (error.code === 'INVALID_CARD_COMPANY') {
        //   // 유효하지 않은 카드 코드에 대한 에러 처리
        // }
      });

    }).catch(err => console.log(err))
  }
}

/**
 * 1. 토스 결제창 호출
 * 1-1. 성공 및 실패 
 */
export const callTossPayment = (payElem) => {
    // 1. 토스 결제창 호출
    const formElem = {
      ... payElem,
      // amount: payElem.amount,
      // // amount: 1000, // FIXEDME 테스트 용
      // orderId: payElem.ordId,
      // orderName: payElem.orderName,
      // customerName: payElem.custUserName,
      successUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/success`,
      failUrl: `${process.env.REACT_APP_BASE_FRONT_URL}/fail`,
    }
    
    // 가상계좌일 경우
    if (payElem.payMethod === '가상계좌') {
      formElem.validHours = `${process.env.REACT_APP_TOSSPAYMENT_VIRTUAL_VALIDHOURS}`; // 기본 168(7일) 최대 720시간(30일)
    }

    tossPayments
    .requestPayment(payElem.payMethod, formElem)
    .catch(function (error) {
      console.log(error)
      if(error.code) {
        alert(error.message)
      }
    });
}