import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { isObjEmpty } from 'utils/util';
import { insertHitItem } from './store/action';

// import VideoViewerModal from './VideoViewerModal';
// import VideoViewerModal from 'views/Portfolio/VideoViewerModal';
import VideoFullViewerModal from './VideoFullViewerModal';

import NoImage from 'resources/common/no_image_video.png';

const Video = ({ portfolioInfo, searchPortfolioNo, callCount, setConnectCallCount }) => {
  const dispatch = useDispatch();

  const [portfolioList, setPortfolioList] = useState();
  const [searchPtfoSeqno, setSearchPtfoSeqno] = useState();
  const [firstPortfolioInfo, setFirstPortfolioInfo] = useState();

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  const [currentVideoInfo, setCurrentVideoInfo] = useState();

  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  // 포트폴리오 클릭
  const clickItem = (item) => {

    // 뷰어 호출
    setCurrentVideoInfo(item);
    handleViewerModal();

    // 로그
    if (!viewerModal) {
      dispatch(insertHitItem(item.ptfoSeqno));
    }

  }

  /**
   * 포트폴리오 목록을 부모로 부터 가져와 내부 변수에 저장
   * 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
   */
  useEffect(() => {
    setPortfolioList(portfolioInfo);
    if (portfolioInfo.length) {
      setFirstPortfolioInfo(portfolioInfo[0]);
    }
    if (searchPortfolioNo !== null && searchPortfolioNo !== '') {
      setSearchPtfoSeqno(searchPortfolioNo)
    }
  }, [portfolioInfo, searchPortfolioNo]);

  useEffect(() => {
    if (!isObjEmpty(portfolioList) && searchPtfoSeqno !== null && searchPtfoSeqno !== '') {
      // 포트폴리오 목록과 URL로 넘어온 포트폴리오번호가 모두 존재한다면 바로 포트폴리오 팝업창 호출
      if (!isObjEmpty(portfolioList)) {
        const findItem = portfolioList.filter(item => item.ptfoSeqno === searchPtfoSeqno)
        if (!isObjEmpty(findItem)) {
          if (callCount < 1) {
            setTimeout(() => {
              clickItem(findItem[0])
              setConnectCallCount(1)
            }, 200)
          }
        }
      }
    }
  }, [portfolioList, searchPtfoSeqno]);

  return (
    // <VideoContainer>
    <ContentContainer>
      {!isObjEmpty(firstPortfolioInfo) ? ( 
          <TopItem onClick={() => clickItem(firstPortfolioInfo)}>
            <ThumbNailWrapper>
              <ThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${firstPortfolioInfo.atchFilePath}/${firstPortfolioInfo.atchFileSaveNm}`} />
              <ThumbnailMobile image={`${process.env.REACT_APP_AWS_S3_URL}/${firstPortfolioInfo.atchFilePath}/${firstPortfolioInfo.atchFileSaveNm}`} />
              <PlayTime>{firstPortfolioInfo.playTm}</PlayTime>
            </ThumbNailWrapper>
            <VideoInfoWrapper>
              <TopDescWrapper>
                <div className='title'>{firstPortfolioInfo.ptfoNm}</div>
                <div className='clnt'>{firstPortfolioInfo.clntNm}</div>
              </TopDescWrapper>
            </VideoInfoWrapper>
          </TopItem>
      ) : ''}
      {!isObjEmpty(portfolioList) && portfolioList.map((item, index) => {
        if (index !== 0) {
          return (
            <Item key={index} onClick={() => clickItem(item)}>
              <ThumbNailWrapper>
                <ThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
                <ThumbnailMobile image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
                <PlayTime>{item.playTm}</PlayTime>
              </ThumbNailWrapper>
              <VideoInfoWrapper>
                <DescWrapper>
                  <div className='title'>{item.ptfoNm}</div>
                  <div className='clnt'>{item.clntNm}</div>
                </DescWrapper>
              </VideoInfoWrapper>
            </Item>
          )
        }
      })}
      <VideoFullViewerModal 
        open={viewerModal}
        handleModal={handleViewerModal}
        videoInfo={currentVideoInfo}
      />
    </ContentContainer>
    // {/* </VideoContainer> */}
  )
}

export default Video;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0px 50px;
  // justify-content: center;
  margin-bottom: 80px;
  width: 1396px;
  
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`


const TopItem = styled.div`
  width: 1396px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    width: 91vw;
    // width: 320px;
  }
`

const Item = styled.div`
  width: 670px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    // width: 320px;
    width: 91vw;
    cursor: pointer;
  }
`

const ThumbNailWrapper = styled.div`
  position: relative;
  text-align: center;
  text-align: -webkit-center;
`

const ThumbNail = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
  background-size: cover, contain;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const ThumbnailMobile = styled.div`
  display: none;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
    width: 91vw;
    aspect-ratio: 16 / 9;
    background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
    background-size: cover, contain;
  };
`

const PlayTime = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
  margin: 10px;
  padding: 3px 4px;
  color: #FFFFFF;
  background: rgba(75, 75, 75, 0.8);
  border-radius: 3px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
`

const VideoInfoWrapper = styled.div`
`

const TopDescWrapper = styled.div`
  .title {
    // font-weight: 400;
    // font-size: 16px;
    // line-height: 19px;
    font-family: Prompt;
    font-size: 30px;
    font-weight: 700;
    line-height: 45.36px;
    letter-spacing: 0.2em;
    text-align: left;
    color: #FFFFFF;

    margin-top: 48px;
    margin-bottom: 10px;
  }

  .clnt {
    font-family: Prompt;
    font-size: 24px;
    font-weight: 700;
    line-height: 36.29px;
    letter-spacing: 0.2em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 80px;
  }

  @media ${({theme}) => theme.device.mobile} {
    display: block;

    .title {
      // font-weight: 400;
      // font-size: 16px;
      // line-height: 19px;
      font-family: Prompt;
      font-size: 20px;
      font-weight: 700;
      line-height: 30.24px;
      letter-spacing: 0.2em;
      text-align: left;
      color: #FFFFFF;
      word-break: keep-all;
  
      margin-top: 24px;
      // margin-bottom: 10px;
    }

    .clnt {
      font-family: Prompt;
      font-size: 14px;
      font-weight: 700;
      line-height: 21.17px;
      letter-spacing: 0.2em;
      text-align: left;
      margin-bottom: 60px;
    }
  };
`

const DescWrapper = styled.div`
  .title {
    font-family: Prompt;
    font-size: 20px;
    font-weight: 700;
    line-height: 30.24px;
    letter-spacing: 0.2em;
    text-align: left;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .clnt {
    color: #FFFFFF;
    font-family: Prompt;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.17px;
    letter-spacing: 0.2em;
    text-align: left;
    margin-bottom: 80px;
  }

  @media ${({theme}) => theme.device.mobile} {
    display: block;

    .title {
      // font-weight: 400;
      // font-size: 16px;
      // line-height: 19px;
      font-family: Prompt;
      font-size: 20px;
      font-weight: 700;
      line-height: 30.24px;
      letter-spacing: 0.2em;
      text-align: left;
      color: #FFFFFF;
      word-break: keep-all;
  
      margin-top: 24px;
      // margin-bottom: 10px;
    }

    .clnt {
      font-family: Prompt;
      font-size: 14px;
      font-weight: 700;
      line-height: 21.17px;
      letter-spacing: 0.2em;
      text-align: left;
      margin-bottom: 60px;
    }
  };
`