import styled, { keyframes } from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { mainApi } from 'utils/api';
import { checkPortfolioVisitorSession, setPortfolioVisitorSession } from 'utils/jwtUtil';
import { isObjEmpty } from 'utils/util';
import { getCategoryList, getStudioPortfolioList } from './store/action';

import Video from './Video';
import Photo from './Photo';
// import ContactPopup from './ContactPopup';
// import MobileContactPopup from './MobileContactPopup';

import CheckboxUnChecked from 'resources/portfolio/checkbox.svg';
import CheckboxChecked from 'resources/portfolio/checkbox_checked.svg';
import X from 'resources/portfolio/close_icon.svg';
import NoDataImage from 'resources/common/no_image.svg';
import EllipseIcon from 'resources/studio/ellipse_icon.png';

import NaverADLogAnalyticsConvert from 'components/NaverADLogAnalyticsConvert';
import Contact from '../Common/Contact';

const Portfolio = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.portfolio);
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);

  const [searchParams] = useSearchParams();
  const [isVideoCategory, setIsVideoCategory] = useState(true)

  // 1번 이상 중복 수행 방지용 카운트
  const [connectCallCount, setConnectCallCount] = useState(0);

  // URL로 영상 seqno가 전달되면 바로 팝업을 띄우기 위한 상태관리
  const [searchPortfolioNo, setSearchPortfolioNo] = useState();
  
  // 상위 카테고리 ID
  const [videoCategoryId, setVideoCategoryId] = useState(`${process.env.REACT_APP_VIDEO_CATEGORY_ID}`); // 영상
  const [photoCategoryId, setPhotoCategoryId] = useState(`${process.env.REACT_APP_PHOTO_CATEGORY_ID}`); // 사진

  const [isFirst, setIsFirst] = useState(true); // 최초 화면 여부
  const [isPortfolioFirst, setIsPortfolioFirst] = useState(true); // 최초 화면 여부

  const [currentType, setCurrentType] = useState(); // 선택한 상위 카테고리 ID
  const [currentCategoryId, setCurrentCategoryId] = useState(); // 선택한 카테고리 ID

  const [categoryList, setCategoryList] = useState([]); // 카테고리 목록

  const [selectedTagList, setSelectedTagList] = useState([]); // 선택한 태그 목록
  const [portfolioList, setPortfolioList] = useState([]); // 포트폴리오 목록

  const [toggleTagWrapper, setToggleTagWrapper] = useState(false); // 검색 필터창 토글
 
  const isMobileCheck = () => {
    let check = /android|webos|iphone|ipad|ipod|blackberry|iemobile|mobile|opera mini/i.test(navigator.userAgent.toLowerCase());
    if (!check) {
      check = window.innerWidth < 740;
    }
    return check;
  }

  // 검색 필터 초기화
  const initialFilter = () => {
    // 두 번 호출 중
    store.portfolioList = [];
    setCurrentCategoryId('');
    setSelectedTagList([]); 
    setPortfolioList([]);
    setToggleTagWrapper(false);

  }
  
  // 상위 카테고리 변경 시 클릭한 변수 초기화 및 데이터 세팅
  const setSelectedType = (e, type) => {
    setCurrentType(type);
    setCurrentCategoryId('');
    setToggleTagWrapper(false);

    // 버튼 애니메이션
    const target = e.target;
    // const highlight = document.querySelector('.highlight');
    const targetRect = target.getBoundingClientRect();
    // highlight.style.left = `${targetRect.left}px`;
  }

  // 카테고리 타입 슬라이드 CSS 적용
  const setHighlightCSS = () => {
    // let type = currentType;

    // if (!isObjEmpty(location.state) && location.state.ctgrId) {
    //   type = location.state.ctgrId;
    // }

    // const categoryContainer = document.querySelector('.categoryContainer');
    // const mobileCategoryContainer = document.querySelector('.mobileCategoryContainer');

    // const width = window.innerWidth;
    // if (type === videoCategoryId) {
    //   if (width > 740) {
    //     categoryContainer.style.display = 'none';
    //   } else {
    //     mobileCategoryContainer.style.display = 'none';
    //   }
    // } else if (type === photoCategoryId) {
    //   if (!isMobile) {
    //     if (width > 740) {
    //       categoryContainer.style.display = 'flex';
    //       mobileCategoryContainer.style.display = 'none';
    //     } else {
    //       categoryContainer.style.display = 'none';
    //       mobileCategoryContainer.style.display = 'flex';
    //     }
    //   } else {
    //     categoryContainer.style.display = 'none';
    //     mobileCategoryContainer.style.display = 'flex';
    //   }
    // } else {
    //   if (width > 740) {
    //     categoryContainer.style.display = 'none';
    //   } else {
    //     mobileCategoryContainer.style.display = 'none';
    //   }
    // }
  }

  const onChangeCategoryFilterView = () => {
    let type = currentType;

    if (!isObjEmpty(location.state) && location.state.ctgrId) {
      type = location.state.ctgrId;
    }
    const categoryContainer = document.querySelector('.categoryContainer');
    const mobileCategoryContainer = document.querySelector('.mobileCategoryContainer');

    if (type === photoCategoryId) {
      const width = window.innerWidth;

      if (!/android|webos|iphone|ipad|ipod|blackberry|iemobile|mobile|opera mini/i.test(navigator.userAgent.toLowerCase())) {
        if (width > 740) {
          categoryContainer.style.display = 'flex';
          mobileCategoryContainer.style.display = 'none';
        } else {
          categoryContainer.style.display = 'none';
          mobileCategoryContainer.style.display = 'flex';
        }
      } else {
        categoryContainer.style.display = 'none';
        mobileCategoryContainer.style.display = 'flex';
      }
    } else {
      categoryContainer.style.display = 'none';
      mobileCategoryContainer.style.display = 'none';      
    }

  }

  // 화면 너비 변화에 따른 카테고리 타입 슬라이드 CSS 적용
  useEffect (()=> {
    onChangeCategoryFilterView();
    // window.addEventListener('resize', setHighlightCSS);
    // return () => {
    //   window.removeEventListener('resize', setHighlightCSS);
    // };
  }, [window.innerWidth]);

  useEffect(() => {
    onChangeCategoryFilterView();
  }, [isMobile, currentType])

  /**
   * 카테고리 버튼 클릭
   * @param {Object} item 클릭한 카테고리 정보
   * @param {boolean} isActive 현재 카테고리와 클릭한 카테고리의 일치 여부
   */
  const clickCategoryButton = (ctgrId, isActive) => {
    setCurrentCategoryId(ctgrId);

    if (isActive) {
      setToggleTagWrapper(!toggleTagWrapper);
    } else {
      setToggleTagWrapper(true);
    }
  }

  // 검색 호출
  const searchPortfolioList = () => {
    const elem = {
      hgrkCtgrId: currentType ? currentType : videoCategoryId,
      ctgrId: currentCategoryId || null
      // arrTagSeqno: !isObjEmpty(selectedTagList) ? selectedTagList.toString() : null,
      // page: ,
      // rows: 
    }

    dispatch(getStudioPortfolioList(elem));
  }

  useEffect(() => {
    // if (currentCategoryId) {
      searchPortfolioList();
    // }
  }, [currentCategoryId])

  // 포트폴리오 목록 세팅
  useEffect(() => {
    setPortfolioList(store.portfolioList);
  }, [store.portfolioList]);

  // 카테고리 목록 세팅
  useEffect(() => {
    if (!isObjEmpty(store.categoryList)) {
      store.categoryList.filter(prev => isObjEmpty(prev.hgrkProdCtgrId)).map(item => {
        if (item.prodCtgrNm === '영상' && item.ptfoOnlyYn === 'Y') {
          setVideoCategoryId(item.prodCtgrId);
          if (currentType === null || currentType === undefined || currentType === '') {
            setCurrentType(item.prodCtgrId);
          }
        } else if (item.prodCtgrNm === '사진' && item.ptfoOnlyYn === 'Y') {
          setPhotoCategoryId(item.prodCtgrId);
        }
      });

      setCategoryList(store.categoryList);
    }

  }, [store.categoryList]);
  
  // 카테고리 조회
  useEffect(() => {
    // 접속 이력 생성
    if (connectCallCount < 1) {
      mainApi.insertConnectInfo({cnntParam: searchParams.get('cnntParam'), pageDvnm: '포트폴리오'});
    }
    if (searchParams.get('cnntParam')) {
      if (!checkPortfolioVisitorSession(searchParams.get('cnntParam'))) {
        setPortfolioVisitorSession(searchParams.get('cnntParam'));
      }
    }

    // URL로 전달된 카테고리 정보가 있다면 카테고리 정보를 먼저 반영
    if (searchParams.get('ctgrId')) {
      // console.log("ctgrId : ", searchParams.get('ctgrId'))
      setCurrentType(searchParams.get('ctgrId'))
    } else {
      if (location.state) {
        setCurrentType(location.state.ctgrId);
      }
    }
    
    // 접속 URL에 영상 지정 queryParameter가 있다면 추출
    if (searchParams.get('ptfoNo')) {
      setSearchPortfolioNo(searchParams.get('ptfoNo'))
    }
    setIsMobile(isMobileCheck());
    dispatch(getCategoryList());
    setHighlightCSS();
  }, []);

  useEffect(() => {
    if (currentType !== null && currentType !== undefined && currentType !== '') {
      setIsVideoCategory(currentType === videoCategoryId)
      initialFilter();
      setHighlightCSS();
      searchPortfolioList();
    }
  }, [currentType]);

  // useEffect(() => {
  //   // navigate(".", { replace: true });
  // }, [navigate]);

  return (
    <>
    <Container>
    {isPortfolioFirst ? (
      <NaverADLogAnalyticsConvert type='5' value='0' setIsPortfolioFirst={setIsPortfolioFirst} isExcludeConvert={true}/>
    ) : ''}
      <Menu>PORTFOLIO</Menu>
      <MobileMenu>PORT<br/>FOLIO</MobileMenu>
      {/* 문의하기 PC 팝업 */}
      {/* <ContactPopup /> */}
      <PortfolioContainer>
        <TopContainer>
          <TypeWrapper className='selector'>
            {/* <HighlightButton className='highlight' /> */}
            <SlideTypeButton className='selector-item' active={isVideoCategory} onClick={(e) => setSelectedType(e, videoCategoryId)}>Video</SlideTypeButton>
            <SlideTypeButton className='selector-item' active={!isVideoCategory} onClick={(e) => setSelectedType(e, photoCategoryId)}>Photo</SlideTypeButton>
          </TypeWrapper>
        </TopContainer>

          {/* PC 카테고리 */}
          <FilterContainer className='categoryContainer'>
            <CategoryWrapper>
                <CategoryButtonSet key={100}>
                  <CategoryButton
                    key={0}
                    onClick={() => clickCategoryButton('', currentCategoryId === '')}
                    active={currentCategoryId === ''}
                    id={''}
                  >
                    전체
                  </CategoryButton>
                  <CategoryButtonBullet
                    // key={item.prodCtgrId}
                    active={currentCategoryId === ''}
                    id={''}
                  >
                    <img src={EllipseIcon} />
                  </CategoryButtonBullet>
                  <CategoryDivide/>
                </CategoryButtonSet>
              {!isObjEmpty(categoryList) && categoryList.map(((item, index) => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <CategoryButtonSet key={index}>
                      <CategoryButton
                        key={item.prodCtgrId}
                        onClick={() => clickCategoryButton(item.prodCtgrId, currentCategoryId === item.prodCtgrId)}
                        active={currentCategoryId === item.prodCtgrId}
                        id={item.prodCtgrId}
                      >
                        {item.prodCtgrNm}
                      </CategoryButton>
                      <CategoryButtonBullet
                        // key={item.prodCtgrId}
                        active={currentCategoryId === item.prodCtgrId}
                        id={item.prodCtgrId}
                      >
                        <img src={EllipseIcon} />
                      </CategoryButtonBullet>
                      {categoryList.length -1 === index ? '' : (
                        <CategoryDivide/>
                      )}
                    </CategoryButtonSet>
                  )  
                }
              }))}
            </CategoryWrapper>
          </FilterContainer>

          {/* 모바일 카테고리 */}
          <MobileFilterContainer className='mobileCategoryContainer'>
            <MobileCategoryWrapper>
              <CategoryButtonSet key={100}>
                  <MobileCategoryButton
                    key={0}
                    onClick={() => clickCategoryButton('', currentCategoryId === '')}
                    active={currentCategoryId === ''}
                    id={''}
                  >
                    전체
                  </MobileCategoryButton>
                  <MobileCategoryButtonBullet
                    // key={item.prodCtgrId}
                    active={currentCategoryId === ''}
                    id={''}
                  >
                    <img src={EllipseIcon} />
                  </MobileCategoryButtonBullet>
                  <MobileCategoryDivide/>
                </CategoryButtonSet>
              {!isObjEmpty(categoryList) && categoryList.map(((item, index) => {
                if (currentType === item.hgrkProdCtgrId && item.useYn === 'Y') {
                  return (
                    <CategoryButtonSet key={item.prodCtgrId}>
                    <MobileCategoryButton
                      key={item.prodCtgrId}
                      onClick={() => clickCategoryButton(item.prodCtgrId, currentCategoryId === item.prodCtgrId)}
                      active={currentCategoryId === item.prodCtgrId}
                      id={item.prodCtgrId}
                    >
                      {item.prodCtgrNm}
                      {/* ({tagList.filter(prev => item.prodCtgrId === prev.ctgrId && selectedTagList.includes(prev.tagSeqno)).length}) */}
                    </MobileCategoryButton>
                    <MobileCategoryButtonBullet
                        // key={item.prodCtgrId}
                        active={currentCategoryId === item.prodCtgrId}
                        id={item.prodCtgrId}
                      >
                        <img src={EllipseIcon} />
                    </MobileCategoryButtonBullet>
                    {categoryList.length -1 === index ? '' : (
                    <MobileCategoryDivide/>
                    )}
                    </CategoryButtonSet>
                  )  
                }
              }))}
            </MobileCategoryWrapper>
          </MobileFilterContainer>

        {/* 포트폴리오 목록 */}
        { currentType === videoCategoryId ? <Video portfolioInfo={portfolioList} searchPortfolioNo={searchPortfolioNo} callCount={connectCallCount} setConnectCallCount={setConnectCallCount} /> : <Photo portfolioInfo={portfolioList} searchPortfolioNo={searchPortfolioNo} callCount={connectCallCount} setConnectCallCount={setConnectCallCount} /> }

      </PortfolioContainer>
      {/* 문의하기 Mobile 팝업 */}
      {/* <MobileContactPopup /> */}
    </Container>
    <Contact />
    </>
  )
}

export default Portfolio;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 62px);

  @media ${({theme}) => theme.device.mobile} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`
  
const Menu = styled.div`
  display: block;
  width: 1112px;
  height: 151px;
  margin-top: 258px;
  // left: 404px;
  gap: 0px;
  opacity: 0px;

  font-family: Prompt;
  font-size: 100px;
  font-weight: 700;
  line-height: 151.2px;
  letter-spacing: 0.35em;
  text-align: center;
  color: #FFFFFF;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const MobileMenu = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;

    font-family: Prompt;
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.3em;
    text-align: center;
    // width: 240px;
    // hegith: 140px;
    margin-top: 120px;
    color: #FFFFFF;
  }
`

const PortfolioContainer = styled.div`
  // width: 1088px;
  // margin: 0 auto 180px;
    display: flex;
    flex-direction: column;
    // align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    // margin: 0 auto 250px;
    padding: 0 18px;
  }
`

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;  
  margin-top: 68px;
  margin-bottom: 80px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 48px;
    // margin-bottom: 65px;
  }
`

const TypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 206px;
  height: 52px;
  gap: 10px;
  border-radius: 26px 26px 26px 26px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: #FFFFFF;
  border: 1px solid #000000

  @media ${({ theme }) => theme.device.mobile} {
    // width: 207px;
    // height: 32px;
    // padding: 2px;
    margin-bottom: 15px;
  }
`

const HighlightButton = styled.div`
  position: absolute;
  z-index: 1;
  width: 86px;
  height: 36px;
  gap: 16px;
  border-radius: 18px;
  background-color: ${props => props.theme.bgColorBlack};
  transition: left 0.2s ease-out;

  @media ${({ theme }) => theme.device.mobile} {
    // margin-left: 4px;
  }
`

const SlideTypeButton = styled.div`
  width: 88px;
  height: 36px;
  padding: 8px 16px 8px 16px;
  gap: 16px;
  border-radius: 18px;
  opacity: 0px;
  z-index: 2;
  
  cursor: pointer;
  color: ${props => props.active ? '#FFFFFF' : '#212121'};
  background-color: ${props => props.active ? '#212121' : '#FFFFFF'};
  font-weight: ${props => props.active ? 700 : 500};

  font-family: Prompt;
  font-size: 18px;
  // font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 88px;
    height: 36px;
    // padding: 5px;
  }
`

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const disappear = keyframes`
  from {
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
`
const FilterContainer = styled.div`
  // position: relative;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;

  // width: 1390px;
  height: 28px;
  gap: 38px;
  opacity: 0px;

  .appear {
    animation: ${appear} 0.2s;
    animation-fill-mode: forwards;
    // animation: ${appear};
    // animation-duration: 0.3s;
  }
  
  .disappear {
    animation: ${disappear} 0.2s;
    animation-fill-mode: forwards;
    // animation: ${disappear};
    // animation-duration: 0.3s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const CategoryWrapper = styled.div`
  display: inline-flex;
  gap : 0px 48px;


  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const CategoryButtonSet = styled.div`
  display: flex;
  flex-direction: row;
`

const CategoryButton = styled.div`
  // width: 100px;
  height: 18px;
  // padding: 7px;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
  transition: all .1s linear;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;

  text-decoration: ${props => props.active ? 'underline' : ''};
  text-underline-position: under;
  text-underline-offset: 8px;
  text-decoration-color: #FF5B34;
  text-decoration-thickness: 2px;
`

const CategoryButtonBullet = styled.div`
  width: 4px;
  display: ${props => props.active ? 'grid' : 'none'};
  margin-left: 5px;
`

const CategoryDivide = styled.div`
  margin-left: 48px;
  width: 1px;
  height: 18px;
  gap: 0px;
  opacity: 0px;
  border: 1px solid #FFFFFF

`

const Check = styled.input.attrs({ type: 'checkbox'})`
`;

const MobileFilterContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    height: 24px;
    margin-bottom: 48px;
  }
`

const MobileCategoryWrapper = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    width: 100%;
    gap: 0 20px;
    overflow-x: auto;
    white-space: nowrap;
    left: 0;
    position: absolute;
    z-index: 3;
    padding-left: 22px;
    padding-right: 24px;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const MobileCategoryButton = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    height: 30px;
    // padding: 0px 8px;
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;
    // background: #FFFFFF;
    // border: 1px solid #ABABAC;
    // border-radius: 35px;
    
    text-decoration: ${props => props.active ? 'underline' : ''};
    text-underline-position: under;
    text-underline-offset: 8px;
    text-decoration-color: #FF5B34;
    text-decoration-thickness: 2px;
  }
`

const MobileCategoryButtonBullet = styled.div`
  width: 4px;
  display: ${props => props.active ? 'grid' : 'none'};
  margin-left: 4px;
  // margin-right: 15px;
`

const MobileCategoryDivide = styled.div`
  margin-left: 20px;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 1px;
    height: 18px;
    gap: 0px;
    opacity: 0px;
    border: 1px solid #FFFFFF
  }
`

const itemKeyframes = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
