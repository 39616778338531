// import React from "react";
// import useScript from "react-script-hook/lib/use-script";

const NaverADLogAnalyticsConvert = ({type, value, setIsPortfolioFirst, isExcludeConvert, isPopupOpen}) => {
  const runMode = `${process.env.REACT_APP_RUN_MODE}`;
  const naverAdLogAnalyticsKey = `${process.env.REACT_APP_NAVER_AD_ANALYTICS_KEY}`;

  // 운영환경에서만 수행되도록 처리
  if (runMode === 'prod') {
    if (isPopupOpen === undefined || isPopupOpen) {

      if (setIsPortfolioFirst) setIsPortfolioFirst(false)
    
      var _nasa={};
      if (!isExcludeConvert) {
        if(window.wcs) _nasa["cnv"] = window.wcs.cnv(type, value); // 전환유형 
      }
    
      // var wcs_add={};
      if(!window.wcs_add) var wcs_add={};
      window.wcs_add["wa"] = naverAdLogAnalyticsKey; 
    
      if (window.wcs) {
        window.wcs.inflow("briphy.com");
        window.wcs_do(_nasa);
      }
    }
  }

}

export default NaverADLogAnalyticsConvert;
