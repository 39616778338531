// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import main from 'views/Main/store/reducer';
import mypages from 'views/Mypage/store/reducer';
import products from 'views/Product/store/reducer';
import portfolio from 'views/Portfolio/store/reducer';
import quotation from 'views/Quotation/store/reducer';
import reviews from 'views/Marketing/VideoLanding/store/reducer';
import studioPortfolio from 'views/Studio/Main/store/reducer';

const rootReducer = combineReducers({
  main,
  mypages,
  products,
  portfolio,
  quotation,
  reviews,
  studioPortfolio
})

export default rootReducer;
