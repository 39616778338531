import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Ring } from '@uiball/loaders';
import emailjs from '@emailjs/browser';
import { memberApi, productApi } from 'utils/api';
import jwt_decode from 'jwt-decode';
import { getGuestData, getUserData } from 'utils/jwtUtil';
import { handleAlertHtml, handleAlertText } from 'components/common/SweetAlert';
import axios from 'axios';
import { convertDateTime, convertDateTimeForSending, convertNumberFormat, convertPhoneNumberFormat, isObjEmpty, convertMaskingString } from 'utils/util';

const SuccessLoading = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [navigateYn, setNavigateYn] = useState('N');
  const categoryList = [ 
    {ctgrId: '10002', tmplNm: 'food', publickKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY_TORI}`},
    {ctgrId: '10003', tmplNm: 'interior', publickKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY_TORI}`},
    {ctgrId: '10005', tmplNm: 'product', publickKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY_DEV}`},
    {ctgrId: '11001', tmplNm: 'profile', publickKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY_DEV}`},
  ];

  const orderSuccessWebHookURL = `${process.env.REACT_APP_SLACK_ORDER_WEBHOOK_URL}`;

  /**
   * 비회원 로그인 
   * @param {string} ordId 주문ID
   */
  const guestLogin = (ordId) => {
    const guestInfo = {
      ordPerNm : localStorage.getItem('ordPerNm'),
      ordId : ordId
    }

    memberApi.guestLogin(guestInfo).then((response) => {
      const decodedResponseData = jwt_decode(response.data);
      if (decodedResponseData) {
        localStorage.setItem('briphyMarketplaceGuest', JSON.stringify(decodedResponseData));
        localStorage.setItem('accessToken', response.data); 
      }

      localStorage.removeItem('ordPerNm');
    });
  }

  const redirect = async (data) => {
    await productApi.callbackTossSuccess(data).then(response => {
      if (!isObjEmpty(response.data.code)) {
        handleAlertHtml('결제 실패', `${response.data.message}[${response.data.code}]<br />주문내역에서 재결제 해주시기 바랍니다.`, 'error').then((result) => {
          if (result.isConfirmed) {
            // 비회원일 경우 비회원 로그인 처리 
            if (getGuestData()) {
              guestLogin(response.data.ordId);
            }
  
            localStorage.removeItem('orderInfo');
            navigate('/mypage');
          }
        });
      } else {
        handleAlertText('결제 완료!', '결제가 완료되었습니다.', 'success').then((result) => {
          const orderInfo = JSON.parse(localStorage.getItem('orderInfo'));
          const formElem = {
            ...orderInfo,
            orderId: response.data.ordId,
            orderName: orderInfo.orderName,
            ordDtm: response.data.ordDtm,
            phgpyHopeDtm: orderInfo.phgpyHopeDtm,
            custUserNm: getUserData() ? getUserData().custUserNm : localStorage.getItem('ordPerNm'),
            payMethod: response.data.method,
            payCdCo: response.data.payCdCo,
            instMnthCnt: response.data.instMnthCnt,
          }
  
          sendEmailOrderInfo(formElem); // 주문 완료 시 메일 발송
          sendSlackOrderInfo(formElem); // 주문 완료 시 Slack 발송
  
          if (result.isConfirmed) {
            // 비회원일 경우 비회원 로그인 처리 
            if (getGuestData()) {
              guestLogin(response.data.ordId);
            }
  
            localStorage.removeItem('orderInfo');
            navigate('/complete', {state: formElem});
          }
        });
      }
    }, error => {
      handleAlertText('서버 오류!', '서버에 에러가 생겼습니다', 'error');
      navigate('/mypage');
    });
  }

  /**
   * 주문 완료 메일 발송
   * @param {object} orderInfo 주문정보
   */
  const sendEmailOrderInfo = (orderInfo) => {
    // 비회원일 경우 이름 마스킹 처리
    let userName = orderInfo.custUserNm;
    if (isObjEmpty(getUserData())) {
      userName = convertMaskingString(userName);
    }

    const templateParams = {
      userNm: userName,
      ordId: orderInfo.orderId,
      ordDtm: convertDateTime(orderInfo.ordDtm, 'Y'),
      ordNm: orderInfo.orderName,
      phgpyHopeDtm: convertDateTimeForSending(orderInfo.phgpyHopeDtm), // 미정 처리해야함
      phgpyAddr: `(${orderInfo.phgpyAddrZipCd}) ${orderInfo.phgpyAddr} ${orderInfo.phgpyAddrDtl}`,
      // TODO 개인정보 발송 안하도록 주석처리 - 발송 시 주석 해제 및 템플릿 수정 필요
      // hpNo: convertPhoneNumberFormat(orderInfo.hpNo),
      email: orderInfo.email,
      payMthStr: `${orderInfo.payCdCo}${orderInfo.payMethod}${!isObjEmpty(orderInfo.instMnthCnt) ? orderInfo.instMnthCnt === '0' ? ` 일시불` : `(${orderInfo.instMnthCnt}개월)` : ''}`,
      productAmount: convertNumberFormat(orderInfo.prodSumAmt, 'Y'),
      productOptionAmount: convertNumberFormat(orderInfo.optSumAmt, 'Y'),
      productTotalAmount: convertNumberFormat(orderInfo.prodOrdTamt, 'Y'),
      discountAmount: Number(orderInfo.copDscAplyAmt) ? `- ${convertNumberFormat(orderInfo.copDscAplyAmt, 'Y')}` : '0',
      productPayAmount: convertNumberFormat(orderInfo.prodPayAmt, 'Y'),
      cancelFee: convertNumberFormat(orderInfo.cnclFee, 'Y'),
      memberStr: getUserData() ? `‘마이페이지’ → ` : '',
    };

    let template = '';
    categoryList.map((category) => {
      if (category.ctgrId === orderInfo.prodCtgrId) {
        template = category.tmplNm;

        emailjs.send('briphy', template, templateParams, category.publickKey);
      }
    });
  }

  /**
   * 주문 완료 Slack 발송
   * @param {*} orderInfo 주문정보
   */
  const sendSlackOrderInfo = async(orderInfo) => {    
      const template = {
        "blocks": [
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "*───────🔔 주문내역 🔔───────*"
            }
          },
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": `*주문번호* : ${orderInfo.orderId}\n*상품명* : ${orderInfo.orderName}\n*주문일시* : ${convertDateTime(orderInfo.ordDtm, 'Y')}\n*촬영날짜* : ${convertDateTimeForSending(orderInfo.phgpyHopeDtm)}`
            }
          },
          {
            "type": "divider"
          }
        ]
      }

    let res = await axios.post(orderSuccessWebHookURL, JSON.stringify(template));
  }

  useEffect(() => {
    if (searchParams.toString()) {
      setNavigateYn('Y');
    } else {
      if (navigateYn === 'N') {
        handleAlertText('접근 불가!', '정상적인 접근경로가 아닙니다.', 'warning').then(() => {
          navigate('/');
        });
      }      
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('orderId') && searchParams.get('paymentKey') && searchParams.get('amount')) {
      const formElem = {
        ordId : searchParams.get('orderId'),
        paymentKey : searchParams.get('paymentKey'),
        totalAmount : searchParams.get('amount')
      }

      redirect(formElem);
    } 
  }, [searchParams]);
  
  useEffect(() => {
    navigate(".", { replace: true });
  }, [navigate]);
  
  return (
    <>
      <Container>
        <Ring 
          size={40}
          lineWeight={5}
          speed={2} 
          color="black"
        />
      </Container>
    </>
  )
}

export default SuccessLoading;

const Container = styled.div`
  display: flex;
  position: relative;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 62px);
`