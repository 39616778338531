import React, { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';

const { kakao } = window;

const PopupPostCode = (props) => {
  const [data, setData] = useState([]);
  const [coordX, setCoordX] = useState();
  const [coordY, setCoordY] = useState();

  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
  const handlePostCode = (data) => {
    if (data) {
      setData(data);
    }    
    
    let fullAddress = data.address;
    let extraAddress = '';
    
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      data.address = fullAddress;
    }
    
    // 주소-좌표 변환 객체를 생성한다.
    const geocoder = new kakao.maps.services.Geocoder();
    
    // 주소로 좌표를 검색한다.
    geocoder.addressSearch(fullAddress, function(result, status) {
      if (status === kakao.maps.services.Status.OK) {
        setCoordY(result[0].y);
        setCoordX(result[0].x);
      }
    }) 
  }

  useEffect(() => {
    if (coordX) {
      props.onClose({name:'zipCd', value:data.zonecode}, {name:'preAddr', value:data.address}, {name:'addrLattd', value:coordY}, {name:'addrLngtd', value:coordX});
    }
  }, [coordX])

  const postCodeStyle = {
    display: "block",
    width: "100%",
    minHeight: "550px" 
  }

  return (
    <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
  )
}

export default PopupPostCode;