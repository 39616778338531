import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

import CloseIcon from 'resources/common/close_icon.png';
import { useEffect } from 'react';
import NaverADLogAnalyticsConvert from 'components/NaverADLogAnalyticsConvert';

const BasicForm = ({open, handleModal}) => {

  useEffect(() => {
    // var _nasa={};
    // if(window.wcs) _nasa["cnv"] = window.wcs.cnv("4","0");
  }, [])

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      className='modal-dialog-centered'
      modalClassName='recatch_modal'
      backdrop='static'
      // style={{ background: '#212121'}}
    >
      <ModalBody>
        <NaverADLogAnalyticsConvert type='4' value='0' isPopupOpen={open} />
        <Header>
          <Title>문의하기</Title>
          <CloseButton onClick={handleModal} />
        </Header>
        <Body>
          <FormWrapper>
            <FormView>
              <iframe src={`${process.env.REACT_APP_RECATCH_FORM_URL}`} width="100%" height="700"></iframe>
            </FormView>
            <MobileFormView>
              <iframe src={`${process.env.REACT_APP_RECATCH_FORM_URL}`} width="100%" height="700"></iframe>
            </MobileFormView>
          </FormWrapper>
        </Body>
      </ModalBody>
    </Modal>
  )
}

export default BasicForm;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ACAAAC;
  background-color: #FFFFFF;
  color: #212121;
`

const Title = styled.div`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.96px;
  text-align: left;
`

const CloseButton = styled.button`
  height: 15px;
  width: 15px;
  margin: 5px 7px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }
`

const Body = styled.div`
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;
  height: 700px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const FormView = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
  
const MobileFormView = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: relative; 
    padding-bottom: 56.25%;
    height: 100%;
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`