import React, { useEffect, useState } from 'react';
import { useInView } from "react-intersection-observer";
import ReactPlayer from 'react-player'
import briphyVideo from 'resources/main/video/video.mp4';
import styled from 'styled-components';

const BriphyVideo = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.8
  });

  useEffect(() => {
    if (inView) {
      setIsLoaded(true);

      if (isLoaded) {
        setIsPlaying(true);
      }
    } else if (!inView && isLoaded) {
      setIsPlaying(false);

    }
  }, [inView]);

  return (
    <PlayerWrapper ref={ref}>
      {inView || isLoaded ? (
        <ReactPlayer 
          url={briphyVideo} 
          width={'100%'} 
          height={'50%'}
          playing = {isPlaying}
          muted
          controls
          playsinline
        />
      ) : null}
    </PlayerWrapper>
  )
}

export default BriphyVideo;

const PlayerWrapper = styled.div`
  width:100%;
  .player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player__preview {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
    box-shadow: 0px 0px 16px 6px #00000029;
  }

  .react-player__shadow {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate3d(-50%,-50%,0);
  }

  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }

  @media ${({ theme }) => theme.device.mobile} {
    video{
      padding: 0 16px;
    }
  }
`;