import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { EffectCoverflow } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { getSOWContentItems } from 'utils/util';

import SOWImage01 from 'resources/studio/workScope/SOW_image_01.png';
import SOWImage02 from 'resources/studio/workScope/SOW_image_02.jpg';
import SOWImage03 from 'resources/studio/workScope/SOW_image_03.png';
import SOWImage04 from 'resources/studio/workScope/SOW_image_04.jpg';
import SOWArrowLeft from 'resources/studio/workScope/SOW_arrow_left.png';
import SOWArrowRight from 'resources/studio/workScope/SOW_arrow_right.png';
import WorkScopeViewModal from './WorkScopeViewModal';

const WorkScopeSwiper = () => {
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedSOWNumber, setSelectedSOWNumber] = useState();
  const swiperRef = useRef();

  const scopeContentItems = getSOWContentItems();

  // Viewer 모달 팝업 
  const [viewerModal, setViewerModal] = useState(false);
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  /**
   * slide 이동에 따른 Active Slide만 하단 콘텐츠만 보여주고
   * 나머지 슬라이드는 하단 콘텐츠가 안보이도록 처리
   */
  useEffect(() => {
    if (swiper) {
      for(let i = 0; i < swiper.slides.length; i++) {
        const activeSlideId = 'slideContent_' + i;
        const elem = document.getElementById(activeSlideId)
        if (i === activeIndex) {
          if (elem) {
            elem.style.display = 'flex';
          }
        } else {
          if (elem) {
            elem.style.display = 'none';
          }
        }
      }
    }
  }, [activeIndex])

  /**
   * 최초 로딩 시 Active Slide만 하단 콘텐츠만 보여주고
   * 나머지 슬라이드는 하단 콘텐츠가 안보이도록 처리
   */
  useEffect(() => {
    if (swiper) {
      for(let i = 0; i < swiper.slides.length; i++) {
        const activeSlideId = 'slideContent_' + i;
        const elem = document.getElementById(activeSlideId)
        if (i === activeIndex) {
          if (elem) {
            elem.style.display = 'flex';
          }
        } else {
          if (elem) {
            elem.style.display = 'none';
          }
        }
      }
    }
    if (swiper) {
      for(let i = 0; i < swiper.slides.length; i++) {
        const activeSlideId = 'slideContent_' + i;
        const elem = document.getElementById(activeSlideId)
        if (i === activeIndex) {
          if (elem) {
            elem.style.display = 'flex';
          }
        } else {
          if (elem) {
            elem.style.display = 'none';
          }
        }
      }
    }
  }, [swiper])

  /**
   * Slide를 next, previous 버튼 클릭 시 
   * 실제 swipe의 slide가 이동이 되도록 처리하는 함수
   * 
   * @param {*} mode 
   */
  const onChangeSwiperImage = (mode) => {
    if (mode === 'L') {
      swiperRef.current.slidePrev();
    } else {
      swiperRef.current.slideNext();
    }
  }

  // SOW 클릭
  const clickItem = (itemNumber) => {
    // 뷰어 호출
    setSelectedSOWNumber(itemNumber);
    handleViewerModal();
  }
  
  return (
    <Container>
      <TextHead>
        SCOPE OF WORKS
      </TextHead>
      <NavigationContainer>
        <NavigationButton>
          <img src={SOWArrowLeft} onClick={() => onChangeSwiperImage('L')} />  
        </NavigationButton>
        <NavigationButton>
          <img src={SOWArrowRight} onClick={() => onChangeSwiperImage('R')} />  
        </NavigationButton>
      </NavigationContainer>
      <SwiperWrapper>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setSwiper(swiper);
          }}
          onSlidePrevTransitionStart={(slide) => {
            let moveIndex = activeIndex;
            if (activeIndex === 0) {
              moveIndex = slide.slides.length - 1;
            } else {
              moveIndex = activeIndex - 1;
            }
            setActiveIndex(moveIndex);
          }}
          onSlideNextTransitionStart={(slide) => {
            let moveIndex = activeIndex;
            if (activeIndex === slide.slides.length - 1) {
              moveIndex = 0;
            } else {
              moveIndex = activeIndex + 1;
            }
            setActiveIndex(moveIndex);
          }}
          effect={'coverflow'}
          centeredSlides={true}
          slidesPerView={1.4}
          coverflowEffect={{
            rotate: 0,
            stretch: 125,
            slideShadows: true,
            scale: 0.70
          }}
          loop={true}
          loopadditionalslides={1}
          modules={[EffectCoverflow]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 1
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1560: {
              slidesPerView: 2,
              spaceBetween: 40
            }
          }}

        >
        {scopeContentItems.map((item, index) => {
          return (
          <SwiperSlide className="slide-item" key={index} onClick={() => clickItem(item.num)}>
            <SwiperSlideWrapper>
              <SlideImageWrapper>
              { item.num === '01' ? (
                <img src={SOWImage01} width={950} />
              ) : item.num === '02' ? (
                <img src={SOWImage02} width={950}/>
              ) : item.num === '03' ? (
                <img src={SOWImage03} width={950} />
              ) : item.num === '04' ? (
                <img src={SOWImage04} width={950} />
              ) : item.num === '05' ? (
                <img src={SOWImage01} width={950} />
              ) : <img src={SOWImage01} width={950} />
              }
              </SlideImageWrapper>
              <SlideContentsWrapper id={'slideContent_'.concat(index)}>
                <SlideContentTitleWrapper>
                  <SlideContentNo>
                    {item.num}
                  </SlideContentNo>
                  <SlideContentTitle>
                    {item.name}
                  </SlideContentTitle>
                </SlideContentTitleWrapper>
                <SlideContentDescription>
                <pre>{item.desc}</pre>
                </SlideContentDescription>
                <SlideContentFooter>
                  {item.verified ? (
                    <>
                    <SlideContentBold>Verified by BRIPHY.</SlideContentBold>{item.verified}
                    </>
                  ) : ''}
                </SlideContentFooter>
              </SlideContentsWrapper>
            </SwiperSlideWrapper>
          </SwiperSlide>
          )
        })}
        </Swiper>
      </SwiperWrapper>
      <WorkScopeViewModal
        open={viewerModal}
        handleModal={handleViewerModal}
        sowNumber={selectedSOWNumber}
      />
    </Container>
  );
}

export default WorkScopeSwiper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 1920px;
  // margin: 0 auto;
  background: #FFFFFF;
`

const TextHead = styled.div`
  font-family: Prompt;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;

  color: ${props => props.theme.txtBlack };
  padding-top: 160px;
  padding-bottom: 2px;
`

const NavigationContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  margin-right: 120px;
  margin-bottom: 30px;
`

const NavigationButton = styled.div`
width: 48px;
height: 48px;
// top: 258px;
// left: 1744px;
margin-left: 8px;
opacity: 0px;
// angle: 180 deg;
cursor: pointer;

`

const SwiperWrapper = styled.div`
  width: 100%;
  // padding-top: 20px;
  padding-bottom: 90px;

  .swiper-slide-active {
    width: 840px;
  }

  .swiper-slide-prev {
    margin-top: -142px;
    // margin-left: 18px;
    // margin-right: ${16000/(window.innerWidth) + 10}px;

    // width: 25%;
  }

  .swiper-slide-next {
    margin-top: -142px;
    // margin-left: 18px;
    // margin-left: ${16000/(window.innerWidth) + 10}px;
    // margin-left: ${(((500000/(window.innerWidth-800)) - 80000/(window.innerWidth-1000)) - 200)/10}px;
    // margin-left: ${(1980-window.innerWidth)*(1.6) + (window.innerWidth-2100)*0.51}px;
  } 
  

`

const SwiperSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: self-start;
  overflow: hidden;

`

const SlideImageWrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
`

const SlideContentsWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  // margin-left: 15px;

`
const SlideContentTitleWrapper = styled.div`
  display:flex;
`

const SlideContentNo = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  margin-top: 40px;
  margin-right: 18px;
`

const SlideContentTitle = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;

  margin-top: 40px;
  margin-bottom: 10px;
`

const SlideContentDescription = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;

  margin-top: 12px;
  margin-bottom: 10px;
`

const SlideContentFooter = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  
  margin-top: 6px;
  margin-bottom: 10px;
  display: flex;
`

const SlideContentBold = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;

  margin-right: 5px;
`