import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from 'react-redux';
import { store } from 'redux/storeConfig/store';

const rootNode = document.getElementById('root');

ReactDOM.createRoot(rootNode).render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  // </React.StrictMode>,
);
