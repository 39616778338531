import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
// import required modules

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { getSOWContentItems } from 'utils/util';

import SOWImage01 from 'resources/studio/workScope/SOW_image_01.png';
import SOWImage02 from 'resources/studio/workScope/SOW_image_02.jpg';
import SOWImage03 from 'resources/studio/workScope/SOW_image_03.png';
import SOWImage04 from 'resources/studio/workScope/SOW_image_04.jpg';
import SOWImage05 from 'resources/studio/workScope/SOW_image_05.jpg';
import SOWImage06 from 'resources/studio/workScope/SOW_image_06.png';

const ScopeOfWorksAccordion = () => {

  const scopeContentItems = getSOWContentItems();

  
  return (
    <Container>
      <TextHead>
        SCOPE OF WORKS
      </TextHead>
      <Accordion defaultActiveKey='0' style={{width:'100vw', marginBottom:'80px'}}>
        {scopeContentItems.map((item, index) => {
          return (
          <Accordion.Item key={index} eventKey={`${index}`}>
            <Accordion.Header style={{border:'0px'}}>
              {item.name}
            </Accordion.Header>
            <Accordion.Body>
              <SwiperSlideWrapper>
                <SlideImageWrapper>
                { item.num === '01' ? (
                  <a href={item?.link} target='blank'><img src={SOWImage01} style={{width:'100%'}} /></a>
                ) : item.num === '02' ? (
                  <img src={SOWImage02} style={{width:'100%'}} />
                ) : item.num === '03' ? (
                  <img src={SOWImage03} style={{width:'100%'}} />
                ) : item.num === '04' ? (
                  <img src={SOWImage04} style={{width:'100%'}} />
                ) : item.num === '05' ? (
                  <img src={SOWImage05} style={{width:'100%'}} />
                ) : <img src={SOWImage06} style={{width:'100%'}} />
                }
                </SlideImageWrapper>
                <SlideContentsWrapper id={'slideContent_'.concat(index)}>
                  <SlideContentTitleWrapper>
                    <SlideContentNo>
                      {item.num}
                    </SlideContentNo>
                    <SlideContentTitle>
                      {item.name}
                    </SlideContentTitle>
                  </SlideContentTitleWrapper>
                  <SlideContentDescription>
                    {item.mobileDesc}
                  </SlideContentDescription>
                  <SlideContentFooter>
                    {item.verified ? (
                      <>
                      <SlideContentBold>Verified by BRIPHY.</SlideContentBold>{item.verified}
                      </>
                    ) : ''}
                  </SlideContentFooter>
                </SlideContentsWrapper>
              </SwiperSlideWrapper>
            </Accordion.Body>
          </Accordion.Item>
          )
        })}
      </Accordion>
    </Container>
  );
}

export default ScopeOfWorksAccordion;

const Container = styled.div`
  display: none;
  
  @media ${({ theme }) => theme.device.mobile} {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    // min-width: 1920px;
    // margin: 0 auto;
    background: #FFFFFF;
    // top: 2000px;
    margin-top: 1000px;
  }
`

const TextHead = styled.div`

  font-family: Prompt;
  font-size: 34px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;


  color: ${props => props.theme.txtBlack };
  margin-top: 80px;
  margin-bottom: 40px;
`

const SwiperWrapper = styled.div`
  width: 100%;
  // padding-top: 20px;
  padding-bottom: 48px;
  padding-left: 18px;
  padding-right: 18px;

`

const SwiperSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: self-start;
  overflow: hidden;

`

const SlideImageWrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
`

const SlideContentsWrapper = styled.div`
  // margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  // margin-left: 15px;

`
const SlideContentTitleWrapper = styled.div`
  display:flex;
`

const SlideContentNo = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;

  margin-top: 30px;
  margin-right: 18px;
`

const SlideContentTitle = styled.div`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;

  margin-top: 30px;
  margin-bottom: 10px;
`

const SlideContentDescription = styled.div`
  width: 100%;
  word-break: keep-all;

  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;

  margin-top: 12px;
  margin-bottom: 10px;
`

const SlideContentFooter = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  
  margin-top: 6px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

const SlideContentBold = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;

  margin-right: 5px;
`


const NavigationContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  // margin-right: 120px;
  margin-bottom: 60px;
`

const NavigationButton = styled.div`
  width: 48px;
  height: 48px;
  // top: 258px;
  // left: 1744px;
  margin-left: 8px;
  opacity: 0px;
  // angle: 180 deg;
  cursor: pointer;

`
