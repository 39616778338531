import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import InstagramLogo from 'resources/common/instagram.svg';
import PolicyModal from 'components/common/PolicyModal';

const Footer = () => {
  const location = useLocation();

  // 모달 여닫기 
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => {
    setOpenModal(!openModal);
  }

  // 동의서 내부에 보기 버튼 클릭시 모달타입설정
  const [modalType, setModalType] = useState();
  const chooseModalType = (id) => {
    setModalType(id);
    setOpenModal(!openModal);
  }

  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => {
          // cleanup
          window.removeEventListener("resize", handleResize);
      };
  }, []);

  useEffect(()=> {
    const marginTop = (1920 - width)/5.2 + 'px';
    document.getElementById('footerTextBriphy').style.marginTop = marginTop;
    // const elem = document.getElementById('footerTextBriphy');
    // if (elem) {
    //   elem.style.marginTop = fontSize;
    // }

  }, [width]);

  if (location.pathname !== '/login' && location.pathname !== '/join' && location.pathname !== '/welcomeSignup') { 
    return (
      <>
        <FooterContainer>
          <FooterTopContainer>
            <FooterLeftItems>
              <LeftItem>
                <Link to={'/mass'}>대량 촬영 솔루션</Link>
              </LeftItem>
              <LeftItem>
              <Link to={'/studio/portfolio'}>포트폴리오</Link>
              </LeftItem>
            </FooterLeftItems>
            <FooterRightItems>
              <RightTopItemWrapper>
                <RightTopSubItem1>
                광고문의
                </RightTopSubItem1>
                <RightDivide/>
                {/* <RightTopSubItem2>
                Operation Team
                </RightTopSubItem2>
                <RightDivide/> */}
                <RightTopSubItem3>
                support@briphy.com
                </RightTopSubItem3>
              </RightTopItemWrapper>
              <RightMiddleItem>
              서울시 종로구 효자로 15(통의동), 4층 (03044)
              </RightMiddleItem>
              <RightBottomItem>
              대표번호 : 02 6925 4411
              </RightBottomItem>
              <RightBottomInstagramItem>
              <a href="https://www.instagram.com/briphy.kr" target='_blank'><Instagram src={InstagramLogo} /></a>
              </RightBottomInstagramItem>
            </FooterRightItems>
          </FooterTopContainer>
          <FooterMiddleContainer>
            <FooterMiddleContent id='footerTextBriphy'>
            Briphy
            </FooterMiddleContent>
          </FooterMiddleContainer>
          <FooterCopyrightDivide/>
          <FooterBottomContainer>
          Copyright 2024 BRIPHY Inc. All rights reserved.
          </FooterBottomContainer>
        </FooterContainer>
        <MobileFooter>
          <MobileContainer>
            <MobileLinkWrapper>
              <MobileLinkItem>
                <Link to={'/mass'}>대량 촬영 솔루션</Link>
              </MobileLinkItem>
              <MobileLinkItem>
                <Link to={'/studio/portfolio'}>포트폴리오</Link>
              </MobileLinkItem>
            </MobileLinkWrapper>
            <MobileMiddleWrapper>
              <MobileMiddleItem>광고문의</MobileMiddleItem>
              <MobileMiddleDivide/>
              {/* <MobileMiddleItem>Operation Team</MobileMiddleItem>
              <MobileMiddleDivide/> */}
              <MobileMiddleItem>support@briphy.com</MobileMiddleItem>
            </MobileMiddleWrapper>
            <MobileAddressWrapper>
              <MobileAddressItem>서울시 종로구 효자로 15(통의동), 4층 (03044)</MobileAddressItem>
              <MobileAddressItem>대표번호 : 02 6925 4411</MobileAddressItem>
              <MobileAddressItemInstagram>
              <a href="https://www.instagram.com/briphy.kr" target='_blank'><Instagram src={InstagramLogo} /></a>
              </MobileAddressItemInstagram>
            </MobileAddressWrapper>
            <MobileFooterMiddleContainer>
              <MobileLogoContent>Briphy</MobileLogoContent>
            </MobileFooterMiddleContainer>
            <MobileCopyrightDivide/>
            <MobileCopyRight>Copyright 2024 BRIPHY Inc. All rights reserved.</MobileCopyRight>
          </MobileContainer>
        </MobileFooter>

        <PolicyModal
          modal={openModal}
          handleModal={openModalHandler}
          type={modalType}
        />
      </> 
    )
  }
}

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  // position: relative;
  // position: absolute;
  background-color: ${props => props.theme.bgColorBlack};
  color: white;
  width: 100vw;

  // ul:nth-child(1) {
  //   flex: 0.4;
  // }

  // ul:nth-child(2) {
  //   flex: 0.4;
  // }

  // ul:nth-child(3) {
  //   flex: 0.2;
  // }

  @media ${({theme}) => theme.device.mobile} {
    display:none;
  }
`;

const FooterTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  margin-bottom: 64px;
`

const FooterLeftItems = styled.div`
  display: flex;
  flex-direction: column;
  // width: 500px;
  margin-top: 100px;
  margin-left: 121px;
  gap: 40px;
`;

const LeftItem = styled.div`
// width: 157px;
height: 30px;
// top: 100px;
// left: 121px;
gap: 0px;
opacity: 0px;

font-family: Pretendard;
font-size: 24px;
font-weight: 500;
line-height: 30px;
text-align: left;
text-decoration: underline;
cursor: pointer;

`

const FooterRightItems = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 100px;
  margin-right: 180px;
  // width: 1088px;
  // padding: 22px 15px;
  // margin: 0 auto;
`;


const RightTopItemWrapper = styled.div`
  display:flex;
  // width: 484px;
  // height: 20px;
  // gap: 20px;
  // opacity: 0px;
  margin-bottom: 32px;
`

const RightTopSubItem1 = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

`

const RightTopSubItem2 = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

`

const RightTopSubItem3 = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

`
const RightDivide = styled.div`
  width: 1px;
  height: 18px;
  gap: 0px;
  opacity: 0px;
  background: #D9D9D9;
  margin-left: 20px;
  margin-right: 20px;
`

const RightMiddleItem = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  // width: 302px;
  height: 16px;
  gap: 0px;
  opacity: 0.5;
  margin-bottom : 16px;
`

const RightBottomItem = styled.div`
  // width: 167px;
  height: 16px;
  gap: 0px;
  opacity: 0.5;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-bottom : 24px;
`

const RightBottomInstagramItem = styled.div`
  // width: 167px;
  height: 16px;
  gap: 0px;
  // opacity: 0.5;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`

const Instagram = styled.img`
  cursor: pointer;
  vertical-align: middle;
  width: ${props => props.width ? `${props.width}px` : ''};
  height: ${props => props.width ? `${props.width}px` : ''};
  // background: #FFFFFF;
`

const FooterMiddleContainer = styled.div`
display: flex;
justify-content: center;
height: 360px;
top: 216px;
gap: 0px;
opacity: 0px;
overflow: hidden;
`

const FooterMiddleContent = styled.div`
height: 756px;
gap: 0px;
opacity: 0px;
font-family: Prompt;
font-size: 26.04vw;
font-weight: 400;
letter-spacing: 0.04em;
text-align: center;

`

const FooterCopyrightDivide = styled.div`
width: 100vw;
height: 0px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
angle: -0 deg;
border: 1px solid #FFFFFF
`

const FooterBottomContainer = styled.div`
  text-align : center;
  width: 100vw;
  background-color: ${props => props.theme.bgColorBlack};
  // z-index: 100;
  // height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Prompt;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.19px;
  text-align: center;
  margin-top: 48px;
`

// Mobile Style
const MobileFooter = styled.footer`
  display: none;
  background-color: ${props => props.theme.bgColorBlack};
  color: white;
  position: relative;
  width: 100%;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  margin: 0 auto;
  // padding: 15px 16px 10px;
  flex-direction: column;
`;

const MobileLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 80px;
  margin-left: 20px;
  margin-bottom: 60px;
`

const MobileLinkItem = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
`
const MobileMiddleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-bottom: 48px;
  row-gap: 20px;
`

const MobileMiddleItem = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-right: 20px;
  color: #FFFFFF;
`

const MobileMiddleDivide = styled.div`
width: 1px;
height: 16px;
background: #D9D9D9;
margin-right: 20px;
`

const MobileAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-left: 20px;
`;

const MobileAddressItem = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  opacity: 0.5;
`

const MobileAddressItemInstagram = styled.div`
  color: #FFFFFF;
`

const MobileFooterMiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 105px;
  margin-top: 40px;
  gap: 0px;
  opacity: 0px;
  overflow: hidden;
`

const MobileLogoContent = styled.div`
  height: 166px;
  font-family: Prompt;
  font-size: 110px;
  font-weight: 400;
  line-height: 166.32px;
  text-align: center;
`

const MobileCopyrightDivide = styled.div`
width: 100vw;
height: 0px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
angle: -0 deg;
border: 1px solid #FFFFFF
`

const MobileCopyRight = styled.div`
  font-family: Prompt;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.17px;
  text-align: center;
  margin-top: 32px;
  // margin-bottom: 24px;
`
