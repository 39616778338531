import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';

import { handleAlertText } from 'components/common/SweetAlert';
import PopupPostCode from 'components/common/PopupPostCode';
import { getGuestData, getUserData } from 'utils/jwtUtil';
import DatePickerComponent from 'components/common/DatePicker';
import FormError from 'components/common/FormError';
import PolicyModal from 'components/common/PolicyModal';
import { convertNumberFormat, convertPhoneNumberFormat, isObjEmpty } from 'utils/util';
import { getUserSimpleInfo, insertGuestOrderInfo, insertOrderInfo } from '../store/action';

import RadioButton from 'resources/common/radio.svg';
import CheckedRadioButton from 'resources/common/radio_checked.svg';
import CheckboxChecked from 'resources/common/checkbox_checked.png';
import CheckboxUnChecked from 'resources/common/checkbox.png';

const Payment = () => {
  const store = useSelector(state => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  /* 상품페이지로 부터 보내온 옵션정보 및 회원정보(이름, 휴대폰번호) 조회관련 변수 */
  const [joinDvcd, setJoinDvcd] = useState();
  const [custUserName, setCustUserName] = useState('');
  const [custUserHpno, setCustUserHpno] = useState('');
  const [custUserEmail, setCustUserEmail] = useState('');
  const [orderOptionInfo] = useState(location.state || {}); // 주문한 옵션 값

  const [currentProductId, setCurrentProductId] = useState(); // 상품 ID
  const [currentProductName, setCurrentProductName] = useState(); // 상품 명
  const [currentProductPrice, setCurrentProductPrice] = useState(); // 상품 가격(판매가)
  const [currentProductOrderCount, setCurrentProductOrderCount] = useState(); // 상품 주문 수량
  const [currentProductOrderAmount, setProductOrderAmount] = useState(); // 상품 합계 금액
  const [currentOrderAmount, setCurrentOrderAmount] = useState(); // 옵션 합계 금액
  const [currentProductSumAmount, setCurrentProductSumAmount] = useState(); // 상품주문총금액
  const [currentProductPayAmount, setCurrentProductPayAmount] = useState(); // 상품결제금액
  const [currentProductOptionList, setCurrentProductOptionList] = useState(); // 옵션 목록

  const [refundInfo, setRefundInfo] = useState(); // 환불 정보

  // 우편번호 검색 모달 팝업
  const [postModal, setPostModal] = useState(false);

  // 촬영 희망 날짜
  const [hopeDate, setHopeDate] = useState(); 

  // 구매 동의 체크 박스
  const [checkAgree, setCheckAgree] = useState(false);

  // 결제 수단
  const [payMethod, setPayMethod] = useState();
  const InfoSchema = yup.object().shape({
    addCntcNo: yup
      .string()
      .when('joinDvcd', {
        is: () => joinDvcd === '1' || joinDvcd === '2',
        then: yup.string().required('연락처는 필수값입니다.'),
        otherwise: yup.string().notRequired(),
      }),
    ordPerNm: yup
      .string()
      .when('joinDvcd', {
        is: () => joinDvcd !== '1' && joinDvcd !== '2',
        // is: () => joinDvcd === '3',
        then: yup.string().required('구매자명은 필수값입니다.'),
        otherwise: yup.string().notRequired(),
      }),
    hpNo: yup
      .string()
      .when('joinDvcd', {
        is: () => joinDvcd !== '1' && joinDvcd !== '2',
        then: yup.string().required('휴대폰번호는 필수값입니다.').matches(/^\d{3}-\d{4}-\d{4}$/,'전화번호 형식에 맞게 입력해주세요'),
        otherwise: yup.string().notRequired(),
      }),
    email: yup
      .string()
      .when('joinDvcd', {
        is: () => joinDvcd !== '1' && joinDvcd !== '2', 
        then: yup.string().required('이메일은 필수값입니다.'),
        otherwise: yup.string().notRequired(),
      }),
    preAddr: yup
      .string()
      .required('촬영주소를 입력해주세요.'),
    phgpyAddrDtl: yup
      .string()
      .when('preAddr', {
        is: (preAddr) => preAddr !== undefined,
        then: yup.string()
        .required('촬영주소를 입력해주세요.')
      }),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    trigger, 
    setValue,
    clearErrors
  } = useForm({
    // mode: 'onChange', 
    resolver: yupResolver(InfoSchema)
  });

  // 우편번호 검색 팝업 닫기
  const closePostCode = (zoneCode, preAddr, addrLattd, addrLngtd) => {
    setPostModal(!postModal);
    setValue('zipCd', zoneCode.value);
    setValue('preAddr', preAddr.value);
    setValue('phgpyAddrLattd', addrLattd.value);
    setValue('phgpyAddrLngtd', addrLngtd.value);
  }

  // 날짜 설정(기본 오전 9시)
  const setPhgpyDate = (date) => {
    const dateInput = document.querySelector('#phgpyHopeDtm').value;
    if (isObjEmpty(dateInput)) {
      let dateTime = date;
      dateTime = dateTime.setHours(9);
      setHopeDate(new Date(dateTime));
    } else {
      setHopeDate(date);
    }
  }

  // 모바일 사이즈 세팅
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode('mobile');
    } else {
      setMode('pc');
    }
  };
  
  const [mode, setMode] = useState('pc');
  useEffect (()=> {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  // 모달 여닫기 
   const [openModal, setOpenModal] = useState(false);
   const openModalHandler = () => {
     setOpenModal(!openModal);
   }
 
  // 동의서 내부에 보기 버튼 클릭시 모달타입설정
   const [modalType, setModalType] = useState();
   const chooseModalType = (id) => {
     setModalType(id);
     setOpenModal(!openModal);
   }

  /** 
   * 결제하기 버튼
   * 1. 주문 정보 DB insert
   * 2. 토스 결제창 호출
   *  2-1. 성공 및 실패 
   */
  const onOrderInfoSubmit = async(data) => {
    if (isObjEmpty(document.querySelector('#phgpyHopeDtm').value)) {
      handleAlertText('필수입력!', '촬영 희망 날짜를 입력해주세요.', 'warning');
      return;
    }

    if (getUserData() && !checkAgree) {
      handleAlertText('필수입력!', '구매에 동의해주시기 바랍니다.', 'warning');
      return;
    }

    if (isObjEmpty(errors)) {      
       // TODO
       // 현재 상품을 하나만 주문하는 방식이라 상품 목록에 바로 주문한 상품 정보를 담음
       // 추후 여러 상품 구매 시(장바구니) productList에 각 주문한 상품 정보를 담아야 함
      const productList = [];
      const productOptionInfo = {
        ...orderOptionInfo,
        // 현재 결제 페이지에서 주소 및 촬영날짜를 선택하기 때문에 추가로 넣어줘야 함
        addCntcNo: data.addCntcNo,
        phgpyAddrZipCd: data.zipCd,
        phgpyAddr: data.preAddr,
        phgpyAddrDtl: data.phgpyAddrDtl,
        phgpyAddrLattd: data.phgpyAddrLattd,
        phgpyAddrLngtd: data.phgpyAddrLngtd,
        phgpyHopeDtm: hopeDate ? moment(hopeDate).format('yyyyMMDDHHmm') : null,
        hpNo: getUserData() ? data.addCntcNo.replaceAll('-', '') : data.hpNo.replaceAll('-', ''),
        email: getUserData() ? custUserEmail : data.email,
      }
      
      productList.push(productOptionInfo);
      
      // 주문/결제 정보에 넣을 object
      const formElem = {
        ...data,
        ordNm: currentProductName,
        ordPerNo: getUserData() ? getUserData().custUserNo : getGuestData().custUserNo, // TODO 실제 로그인한 사용자와 값이 같은지 비교
        payProcSt: 'READY', // 결제진행상태: 결제전(10)
        totPayAmt: currentProductPayAmount, // 총 결제금액
        payMthdNm: payMethod, // 결제수단명
        ordProdList: productList,
        hpNo: getUserData() ? data.addCntcNo.replaceAll('-', '') : data.hpNo.replaceAll('-', ''),
      }

      // pg결제창에 보낼 request object
      const payElem = {
        payMethod: payMethod,
        amount: currentProductPayAmount,
        orderId: data.ordId,
        orderName: currentProductName,
        customerName: custUserName,
      }

      // 비회원일 경우 주문자명 저장
      if (getGuestData()) {
        localStorage.setItem('ordPerNm', data.ordPerNm);
      }

      // 주문 정보 로컬스토리지 저장
      const orderElem = {
        ...productOptionInfo,
        orderName: currentProductName, // 상품명
        phgpyHopeDtm: hopeDate ? moment(hopeDate).format('yyyyMMDDHHmm') : null // 촬영날짜
      }
      localStorage.setItem('orderInfo', JSON.stringify(orderElem));

      dispatch(getUserData() ? insertOrderInfo(formElem, payElem) : insertGuestOrderInfo(formElem, payElem));
    }
  }

  useEffect(() => {
    // 비로그인 상태에서 상품 구매하기 버튼 클릭 후 로그인 하였을 때 로그인 한 사용자의 정보 조회
    if (!isObjEmpty(getUserData())) {
      dispatch(getUserSimpleInfo(getUserData().custUserNo));
    }
    navigate('.', { replace: true });
  }, [navigate]);
  
  useEffect(() => {
    if (!isObjEmpty(store.custUserMinimumInfo)) {
      setCustUserName(store.custUserMinimumInfo.custUserNm);
      setCustUserHpno(store.custUserMinimumInfo.hpNo);
      setCustUserEmail(store.custUserMinimumInfo.email);
    }

  }, [store.custUserMinimumInfo]);
  
  // 환불 정책에 대한 정보 세팅
  useEffect(() => {
    if (!isObjEmpty(store.productInfo)) {
      const elem = {
        phgpyAddrNeedYn : store.productInfo.phgpyAddrNeedYn,
        cnclFee : store.productInfo.cnclFee,
      }
      setRefundInfo(elem);
    }
  }, [store.productInfo]);

  useEffect(() => {
    if (isObjEmpty(getUserData()) && isObjEmpty(getGuestData())) {
      handleAlertText('접근 불가!', '로그인 후 이용가능합니다.', 'error').then((result) => {
        if (result.isConfirmed) {
          navigate('/product/login');
        }
      });
    } else {
      if (!isObjEmpty(orderOptionInfo)) {
        setJoinDvcd(getUserData() ? getUserData().joinDvcd : getGuestData().joinDvcd);
        setCurrentProductId(orderOptionInfo.prodId); // 상품 ID
        setCurrentProductName(orderOptionInfo.prodNm); // 상품 명
        setCurrentProductPrice(orderOptionInfo.sellPrc); // 상품 가격(판매가)
        setCurrentProductOrderCount(orderOptionInfo.prodOrdCnt); // 상품 주문 수량
        setProductOrderAmount(orderOptionInfo.prodSumAmt); // 상품 합계 금액
        setCurrentOrderAmount(orderOptionInfo.optSumAmt); // 옵션 합계 금액
        setCurrentProductSumAmount(orderOptionInfo.prodOrdTamt); // 총 상품금액(상품주문총금액)
        setCurrentProductPayAmount(orderOptionInfo.prodPayAmt);  // 최종결제금액(상품결제금액)
        
        setCurrentProductOptionList(orderOptionInfo.ordProdOptList); // 옵션 목록
  
        if (payMethod === undefined) {
          const selectedPayMethod = document.querySelector(`input[name=payMethod]:checked`).value;
          setPayMethod(selectedPayMethod);
        }
      } else {
        handleAlertText('주문오류!', '다시 주문 하시기 바랍니다.', 'warning').then((result) => {
          if (result.isConfirmed) {
            navigate('/product');
          }
        });
      }
    }
  }, [orderOptionInfo]);

  return (
    <>
    <Container>
      <Menu>주문/결제</Menu>
      <PurchaseForm onSubmit={handleSubmit(onOrderInfoSubmit)}>
        <OrderContainer>
          <InfoContainer className='pd mpd'>
            <InfoTitle>구매자 정보</InfoTitle>
            { getUserData() ?
              <InputWrapper>
                <InputLabel className='mt20'>이름</InputLabel>
                <Input id='custUserNm' name='custUserNm' border={'none'} value={custUserName} readOnly />
              </InputWrapper> : 
              <>
                <InputWrapper>
                  <InputLabel htmlFor='ordPerNm'>구매자<span>*</span></InputLabel>
                  <Input id='ordPerNm' {...register('ordPerNm')} />
                </InputWrapper>
                <FormErrorWrapper>
                  <FormError message={errors.ordPerNm?.message} />
                </FormErrorWrapper>
              </>
            }
            { getUserData() ?
              <>
                <InputWrapper>
                  <InputLabel>휴대폰</InputLabel>
                  <Input id='hpNo' name='hpNo' border={'none'} value={convertPhoneNumberFormat(custUserHpno)}  readOnly />
                </InputWrapper>
                <InputWrapper>
                  <InputLabel htmlFor='addCntcNo'>연락처<span>*</span></InputLabel>
                  <Input type='number' id='addCntcNo' placeholder='긴급 연락처를 입력해주세요' {...register('addCntcNo')} />
                </InputWrapper>
                <FormErrorWrapper>
                  <FormError message={errors.addCntcNo?.message} />
                </FormErrorWrapper>
              </> :
              <>
                <InputWrapper>
                  <InputLabel htmlFor='hpNo'>휴대폰<span>*</span></InputLabel>
                  {/* <Input id='hpNo' {...register('hpNo')} /> */}
                  <Controller 
                    id='hpNo'
                    name='hpNo'
                    control={control}
                    render={({ field: {ref, ...field}}) => 
                    <NumberFormat {...field} 
                      type='text'
                      htmlFor='hpNo'
                      format='###-####-####'
                      mask='_'
                      innerRef={ref}
                      customInput={Input}
                    />}
                  />
                </InputWrapper>
                <FormErrorWrapper>
                  <FormError message={errors.hpNo?.message} />
                </FormErrorWrapper>
                <InputWrapper>
                  <InputLabel htmlFor='email'>이메일<span>*</span></InputLabel>
                  <Input                    
                    id='email'
                    placeholder='이메일을 입력해주세요'
                    {...register('email')}
                  />
                </InputWrapper>
                <FormErrorWrapper>
                  <FormError message={errors.email?.message} />
                </FormErrorWrapper>
              </>
            }
           
            <InputWrapper>
              <InputLabel htmlFor='zipCd'>촬영주소<span>*</span></InputLabel>
              <Address>
                <AddressWrapper className='mb10'>
                  <ZipcodeInput
                    id='zipCd' 
                    {...register('zipCd')}
                    width={110}
                    readOnly
                  />
                  <ZipcodeButton type='button' onClick={() => setPostModal(!postModal)} >우편번호</ZipcodeButton>
                </AddressWrapper>
                <Input
                  id='preAddr'
                  className='mb10'
                  {...register('preAddr')}
                  address={true} 
                  readOnly
                />
                <Input
                  id='phgpyAddrDtl'
                  {...register('phgpyAddrDtl')}
                  placeholder='상세주소를 입력해주세요'
                  address={true} 
                />
                <input type='hidden' name='phgpyAddrLattd' id='phgpyAddrLattd' />
                <input type='hidden' name='phgpyAddrLngtd' id='phgpyAddrLngtd' />
              </Address>
            </InputWrapper>
            <FormErrorWrapper>
              <FormError message={errors.phgpyAddrDtl?.message || errors.preAddr?.message} />
            </FormErrorWrapper>
            <InputWrapper className='mt10'>
              <InputLabel htmlFor='phgpyHopeDtm'>촬영 희망 날짜<span>*</span></InputLabel>
              <DatePickerComponent
                id='phgpyHopeDtm'
                date={hopeDate}
                setDate={(d) => setPhgpyDate(d)}
                width={715}
                minDatetime={new Date(Date.now() + (60 * 60 * 24 * 1000))} // 현재 날짜 + 1일 이후 부터 선택가능}
                // {...register('phgpyHopeDtm')}
              />
            </InputWrapper>
            { hopeDate ? <HopeDateTxt><span>**</span> 사진작가님께서 최종 일정 확인을 위해 연락을 드릴 예정입니다.</HopeDateTxt> : ''}
          </InfoContainer>
          
          <InfoContainer className='mpd'>
            <InfoTitle>결제 정보</InfoTitle>
            <OrderWrapper className={isObjEmpty(currentProductOptionList) ? 'mt7 mb5' : 'mt7'}>
              <OrderItem>기본{orderOptionInfo.optItemListYn === 'Y' ? `(${orderOptionInfo.optItemNm})`: '' }</OrderItem>
              <OrderItem weight={500}>{convertNumberFormat(currentProductPrice, 'Y')} 원</OrderItem>
            </OrderWrapper>
            {currentProductOptionList?.map((option, index) => {
              if (Number(option.optPrc)) {
                return (
                  <OrderWrapper key={index} className={(currentProductOptionList.length-1) === index ? 'mb5' : ''}>
                    <OrderItem>
                      {option.optNm} 가격
                      {option.plurPsyn === 'Y' ? ` (수량: ${option.optOrdCnt})` : ''}
                    </OrderItem>
                    <OrderItem weight={500}>{convertNumberFormat(option.optOrdAmt, 'Y')} 원</OrderItem>
                  </OrderWrapper>
                )
              }
            })}
              <div className='line' />
              <OrderWrapper className={orderOptionInfo.copDscYn === 'Y' && orderOptionInfo.isApplyCopDsc ? 'mt5' : 'm5 mm10'}>
                <OrderItem>총 상품 금액</OrderItem>
                <OrderItem weight={500}>{convertNumberFormat(orderOptionInfo.prodOrdTamt, 'Y')} 원</OrderItem>
              </OrderWrapper>
              { orderOptionInfo.copDscYn === 'Y' && orderOptionInfo.isApplyCopDsc ?
              <OrderWrapper className='mb5'>
                <OrderItem>제휴 상품 할인</OrderItem>
                <OrderItem weight={700} color='orange'>- {convertNumberFormat(orderOptionInfo.copDscAplyAmt, 'Y')} 원</OrderItem>
              </OrderWrapper> : ''
              }

            <div className='line' />
            <TotalAmount>
              <TotalItem weight={700}>최종 결제 금액<span className='vat'>(VAT 포함)</span></TotalItem>
              <TotalItem weight={800} size={20} color={'orange'}>{convertNumberFormat(currentProductPayAmount, 'Y')} 원</TotalItem>
            </TotalAmount>
          </InfoContainer>

          <InfoContainer className='pt60 mpd'>
            <InfoTitle>결제 수단</InfoTitle>
            <MethodWrapper>
              <MethodItem>
                <MethodRadioButton
                  id='card'
                  name='payMethod'
                  defaultChecked={true}
                  onChange={(e) => setPayMethod(e.target.value)}
                  value={'카드'}
                />
                <MethodLabel htmlFor='card'>신용카드</MethodLabel>
              </MethodItem>
              {/* TODO 결제수단 추가 시 주석 해제 */}
              {/* <MethodItem>
                <MethodRadioButton
                  id='virtual'
                  name='payMethod'
                  onChange={(e) => setPayMethod(e.target.value)}
                  value={'가상계좌'}
                />
                <MethodLabel htmlFor='virtual'>실시간 계좌이체</MethodLabel>
              </MethodItem>
              <MethodItem>
                <MethodRadioButton
                  id='transfer'
                  name='payMethod'
                  onChange={(e) => setPayMethod(e.target.value)}
                  value={'계좌이체'}
                />
                <MethodLabel htmlFor='transfer'>무통장 입금</MethodLabel>
              </MethodItem> */}
            </MethodWrapper>
          </InfoContainer>

          <EndContainer>
            <PurchaseAgreeField size={16}>
              <label htmlFor='checkAgree'>
                <Check 
                  id='checkAgree'
                  name='checkAgree'
                  onClick={() => setCheckAgree(!checkAgree)}
                /><i className='check-icon'/>
                {/* TODO pc/mobile 모달 통일 -> 디자인 작업 후 주석 제거  */}
                  {/* { mode === 'pc' ?
                    <>
                      주문할 상품의 정보와 <a href='/refund' target='_blank'>환불정책</a> 및 <a href='/terms/3' target='_blank'>개인정보 제3자 제공</a>에 관한 안내를 확인하였으며,
                      <span className='agree'> 구매에 동의합니다.</span>
                    </> : '' }
                  { mode === 'mobile' ?
                    <> */}
                      주문할 상품의 정보와 <span className='link' onClick={() => chooseModalType('refund')}>환불정책</span> 및 <span className='link' onClick={() => chooseModalType('terms/3')}>개인정보 제 3자 제공</span>에 관한 안내를 확인하였으며,
                      <span className='agree'> 구매에 동의합니다.</span>
                    {/* </>
                    : ''} */}
              </label>
            </PurchaseAgreeField>
            <BuyButton>결제하기</BuyButton>
          </EndContainer>
        </OrderContainer>
      </PurchaseForm>
      <Modal isOpen={postModal} toggle={() => setPostModal(!postModal)} modalTransition={{ timeout: 100 }} >
        <ModalBody>
          <PopupPostCode onClose={closePostCode} />
        </ModalBody>
      </Modal>
    </Container>
    <PolicyModal
      modal={openModal}
      handleModal={openModalHandler}
      type={modalType}
      refundInfo={refundInfo}
    />
    </>
  )
}

export default Payment;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 62px);
`
  
const Menu = styled.div`
  width: 100%;
  margin: 120px auto 40px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 77px 0 27px;
    font-size: 20px;
    line-height: 24px;
  }
`

const PurchaseForm = styled.form`
  display: flex;
  width: 100%;
  margin: 0 auto;
`

const OrderContainer = styled.div`
  width: 1088px;
  margin: 0 auto 250px;
  text-align: center;
  background: ${props => props.theme.bgColorWhite};
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  
  span {
    color: #FE5A1D;
  }

  .pd {
    padding: 40px 120px 0px;

    @media ${({ theme }) => theme.device.mobile} {
      padding: 0;
    }
  }

  .pt60 {
    padding-top: 60px;
  }
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 0px 16px;
    margin-bottom: 160px;
    box-shadow: none;
    background: transparent;

    .mpd {
      padding: 11px 15px 10px;
    }
  }
`

const InfoTitle = styled.div`
  text-align: left;
  font-size: 15px;
  font-weight: 700;  
  border-bottom: 1px solid #2C2C2C;
  padding: 5px;
  
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 19px;
  }
`
  
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 120px 0px;

  .mt5 {
    margin-top: 5px;
  }

  .mt7 {
    margin-top: 7px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .mb5 {
    margin-bottom: 5px;
  }

  .m5 {
    margin: 5px 0px;
  }

  .line {
    border-top: 0.25px solid #D9D9D9;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-bottom: 20px;
    background: ${props => props.theme.bgColorWhite};
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .mm10 {
      margin: 10px 0;
    }
  }
`
const FormErrorWrapper = styled.div`
  display: flex;
  margin-left: 110px;
  margin-top: -10px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: baseline;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin: 0;
    display: block;

    .mt20 {
      margin-top: 20px;
    }
  }
`
  
const InputLabel = styled.label`
  width: 110px;
  padding-left: 5px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 16px auto 6px;
    padding-left: 0px;
    line-height: 17px;
  }
`

const Input = styled.input`
  width: ${props => props.width ? `${props.width}px` : '715px'};
  height: 34px;
  border: ${props => props.border === 'none' ? 'none' : '0.25px solid #D9D9D9'};
  border-radius: 5px;
  margin: ${props => props.address ? '5px auto' : '10px 0 5px'};
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  
  ::placeholder {
    font-weight: 200;
    color: ${props => props.theme.txtWhiteGray};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;
    margin: 0px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    border: 0.25px solid #D9D9D9;
   
    ::placeholder {
      font-size: 15px;
    }
  }
`

const Address = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.mobile} {
    .mb10 {
      margin-bottom: 10px;
    }
  }
`

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;  
`

const ZipcodeInput = styled(Input)`
  margin: 5px 0 5px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
  }
`

const ZipcodeButton = styled.button`
  width: 100px;
  height: 34px;
  vertical-align: middle;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  background: #4B4B4B;
  border: none;
  color: ${props => props.theme.txtWhite};
  margin-left: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    min-width: 100px;
    height: 48px;
    font-size: 14px;
    line-height: 17px;
    border: 0.25px solid #2C2C2C;
  }
`

const HopeDateTxt = styled.div`
  text-align: left;
  margin-left: 123px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-left: 0;
    margin-top: 8px;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
  }
`

const OrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  
  @media ${({ theme }) => theme.device.mobile} {
    height: auto;
    min-height: 20px;

    & + & {
      margin-top: 7px;
    }
  }
`

const OrderItem = styled.div`
  padding-left: 5px;
  font-size: ${props => props.size ? `${props.size}px` : '14px'};
  font-weight: ${props => props.weight ? props.weight : 300};
  color: ${props => props.color === 'orange' ? '#FE5A1D' : ''};

  .vat {
    margin-left: 6px;
    font-size: 10px;
    font-weight: 300;
    color: ${props => props.theme.txtGray};
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 0px;
  }
`

const TotalAmount = styled(OrderWrapper)`
  padding-top: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 0px;
  }
`

const TotalItem = styled(OrderItem)`
  margin: 20px 0;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`

const MethodWrapper = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  margin-top: 7px;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    margin: 0;
    height: auto;
    min-height: 22px;
  }
`
  
const MethodItem = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    margin-top: 10px;
  }
`

const MethodLabel = styled.label`
  display: flex;
  height: 16px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 30px;
  align-items: center;
  margin-right: 30px;

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 20px;
    line-height: 17px;
  }
`

const MethodRadioButton = styled.input.attrs({type: 'radio'})`
  display: none;

  &:checked + ${MethodLabel} {
    background: url(${CheckedRadioButton}) no-repeat 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    @media ${({ theme }) => theme.device.mobile} {
      background: url(${CheckedRadioButton}) no-repeat;
    }
  }
  
  &:not(:checked) + ${MethodLabel} {
    background: url(${RadioButton}) no-repeat 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    
    @media ${({ theme }) => theme.device.mobile} {
      background: url(${RadioButton}) no-repeat;
    }
  }
`

const EndContainer = styled.div`
  margin: 60px auto 40px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 60px auto 0;
  }
`
  
const BuyButton = styled.button`
  width: 369px;
  height: 44px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${props => props.theme.txtWhite};
  background: ${props => props.theme.bgColorOrange};

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 54px;
    font-size: 18px;
    line-height: 22px;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;
const PurchaseAgreeField = styled.div`
  font-size: 13px;
  font-weight: 400; 
  line-height: 15px; 
  color: ${props => props.theme.txtGray};
  margin: 10px auto;  

  .agree {
    color: ${props => props.theme.txtBlack};
  }

  a {
    text-decoration: underline !important;
  }

  .link {
    text-decoration: underline !important;
    color: ${props => props.theme.txtGray};
    cursor: pointer;
  }

  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon {
    background-image: url(${CheckboxChecked}); 
  }

  .check-icon {
    display: inline-block; 
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    margin-right: 10px;
    background: url(${CheckboxUnChecked}) no-repeat 95%; 
    background-size: cover;
    vertical-align: middle; 
    transition-duration: .2s; 
    // transform: translated(0, -7%, 0);
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    margin-bottom: 8px;
    .agree {
      font-weight: 500;
    }
  }
`

const SeeButton = styled.input`
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  background-color: white;
  color: #ABABAC;
  line-height: 16px;
  margin:0px -5px;
`