import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

import { isObjEmpty } from 'utils/util';
import { handleAlertText } from 'components/common/SweetAlert';

import CloseIcon from 'resources/studio/portfolio_popup_close.png';
import NoImage from 'resources/common/no_image_video.png';
import LinkIcon from "resources/common/link.svg";


const VideoFullViewerModal = ({open, handleModal, videoInfo}) => {

  /**
   * 선택된 포트폴리오 팝업창을 바로 띄울 수 있는 link 정보를 클립보드에 copy
   */
  const linkCopy = () => {
    const url = `${process.env.REACT_APP_BASE_FRONT_URL}/portfolio?ctgrId=`.concat(videoInfo?.ctgrId, '&ptfoNo=', videoInfo?.ptfoSeqno)
    
    window.navigator.clipboard.writeText(url).then(() => {
      handleAlertText('복사 완료', '접속 URL 복사가 완료되었습니다.', 'info')
    })
  }

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      fullscreen={true}
      className='modal-dialog-centered'
      // backdrop='static'
      style={{margin: '0px', padding: '0px'}}
    >
      <ModalBody style={{margin: '0px', padding: '0px'}}>
        <ModalContainer 
          image={`${process.env.REACT_APP_AWS_S3_URL}/${videoInfo?.atchFilePath}/${videoInfo?.atchFileSaveNm}`}
          backgroundColor={videoInfo?.bkgrColr ? videoInfo.bkgrColr : '162, 195, 206'}
        >
          <Header>
            <div></div>
            <CloseButton onClick={handleModal} />
          </Header>
          <Body>
            <VideoWrapper>
              <Video>
                { !isObjEmpty(videoInfo) && videoInfo.viewLnkUrl ? 
                  <iframe src={`${videoInfo?.viewLnkUrl}?badge=0&autopause=0&player_id=0&autoplay=1&loop=0&muted=1`} width={1024 - (1920 - window.innerWidth)} height="576" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                  : <img src={NoImage} />
                }
                {/* background=1 컨트롤러 숨기기 (이것만 하면 autoplay=1 loop=1 자동) */}
                {/* <iframe src="https://player.vimeo.com/video/643009855?badge=0&autopause=0&player_id=0&autoplay=1&loop=1" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe> */}
              </Video>
              <MobileVideo>
                { !isObjEmpty(videoInfo) && videoInfo.viewLnkUrl ? 
                  <iframe src={`${videoInfo?.viewLnkUrl}?badge=0&autopause=0&player_id=0&autoplay=1&loop=0&muted=1`} width="56.25%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                  : <img src={NoImage} />
                }
              </MobileVideo>
              <ContentInfoWrapper>
                <Title>{videoInfo?.ptfoNm}</Title>
                <ClientName>{videoInfo?.clntNm}</ClientName>
              </ContentInfoWrapper>
            </VideoWrapper>
          </Body>
        </ModalContainer>
      </ModalBody>
    </Modal>
  )
}

export default VideoFullViewerModal;

const ModalContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-position: center;
    // background: ${props => props.backgroundColor};  /* fallback colour. Make sure this is just one solid colour. */
  // background-image: linear-gradient(rgba(195, 55, 100, 0.2), rgba(195, 55, 100, 0.8)), url(${props => props.image});
  // background-image: linear-gradient(${props => props.backgroundColor}, ${props => props.backgroundColor}, url(${props => props.image});
  background-image: linear-gradient(rgba(${props => props.backgroundColor}, 0.5), rgba(${props => props.backgroundColor}, 0.8)), url(${props => props.image});
  background-repeat : no-repeat;
  background-size : cover;

`

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 10px;
  // border-bottom: 1px solid #ACAAAC;
`

const CloseButton = styled.button`
  height: 80px;
  width: 80px;
  margin-top: 48px;
  margin-right: 48px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 24px;
    width: 24px;
    margin-right: 20px;
  }
`

const Body = styled.div`
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${1024 - (1920 - window.innerWidth)}px;
  // margin-top: 100px;

  @media ${({ theme }) => theme.device.mobile} {
    width: ${window.innerWidth-10}px;
  }
`
  
const Video = styled.div`
  
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
  
const MobileVideo = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: relative; 
    padding-bottom: 56.25%;
    height: 100%;
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`

const ContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  color: #FFFFFF;

`

const Title = styled.div`
  font-family: Prompt;
  font-size: 30px;
  font-weight: 700;
  line-height: 45.36px;
  letter-spacing: 0.2em;
  text-align: left;
  margin-bottom: 10px;
`

const ClientName = styled.div`
  font-family: Prompt;
  font-size: 24px;
  font-weight: 700;
  line-height: 36.29px;
  letter-spacing: 0.2em;
  text-align: left;


  @media ${({theme}) => theme.device.mobile} {
    min-height: auto;
    margin-bottom: 0px;
  }
`
