import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

import { isObjEmpty } from 'utils/util';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// import { Navigation, Pagination } from 'swiper';

import CloseIcon from 'resources/common/close_icon.png';
import NoImage from 'resources/common/no_image_photo.png';
import LinkIcon from "resources/common/link.svg";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { handleAlertText } from 'components/common/SweetAlert';

const PhotoViewerModal = ({open, handleModal, photoInfo}) => {
  const store = useSelector(state => state.portfolio);
  
  // 포트폴리오 사진 목록
  const [photoFileList, setPhotoFileList] = useState([]);

  useEffect(() => {
    setPhotoFileList(store.photoFileList);
  }, [store.photoFileList]);

  /**
   * 선택된 포트폴리오 팝업창을 바로 띄울 수 있는 link 정보를 클립보드에 copy
   */  
  const linkCopy = () => {
    const url = `${process.env.REACT_APP_BASE_FRONT_URL}/portfolio?ctgrId=`.concat(photoInfo?.ctgrId, '&ptfoNo=', photoInfo?.ptfoSeqno)
    
    window.navigator.clipboard.writeText(url).then(() => {
      handleAlertText('복사 완료', '접속 URL 복사가 완료되었습니다.', 'info')
    })
  }

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      className='modal-dialog-centered'
      backdrop='static'
      style={{maxWidth: 1000}}
    >
      <ModalBody>
        <Header>
          <div></div>
          <CloseButton onClick={handleModal} />
        </Header>
        <Body>
          <PhotoWrapper>
            <Photo>
              { !isObjEmpty(photoInfo) && photoInfo.viewLnkUrl && !isObjEmpty(photoFileList) ? 
                <SwiperContainer>
                  <Swiper
                    // cssMode={true} true : 드래그 안됨
                    navigation={true}
                    pagination={{
                      clickable: true,
                    }}
                    observer={true}
                    observeParents={true}
                    autoHeight={true}
                    modules={[Pagination, Navigation]}
                  >
                    { photoFileList?.map((thumb, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <PhotoItem><img src={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`} /></PhotoItem>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </SwiperContainer>
              : <PhotoItem image={`${process.env.REACT_APP_AWS_S3_URL}/${photoInfo?.atchFilePath}/${photoInfo?.atchFileSaveNm}`} /> }
              {/* : <PhotoItem image={NoImage} /> */}
            </Photo>

            <MobilePhoto>
              { !isObjEmpty(photoInfo) && photoInfo.viewLnkUrl && !isObjEmpty(photoFileList) ? 
                <SwiperContainer>
                  <Swiper
                    // cssMode={true} true : 드래그 안됨
                    // navigation={true}
                    pagination={{
                      clickable: true,
                    }}
                    observer={true}
                    observeParents={true}
                    autoHeight={true}
                    modules={[Pagination, Navigation]}
                  >
                    { photoFileList?.map((thumb, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <MobilePhotoItem><img src={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`} /></MobilePhotoItem>
                          {/* <MobilePhotoItem
                            image={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`}
                          /> */}
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </SwiperContainer>
              : <PhotoItem image={NoImage} />  } 
            </MobilePhoto>

            <TagWrapper>
              { photoInfo?.tagList.map((tag, i) => {
                return (
                  <Tag key={i}>{tag.tagNm}</Tag>
                )
              })}
            </TagWrapper>
          </PhotoWrapper>
          <ContentWrapper>
            <Title>{photoInfo?.ptfoNm}</Title>
            <SubDescription>
              {photoInfo?.ptfoDesc}
            </SubDescription>
            <InfoWrapper>
              <Row>
                <ContentTitle>클라이언트</ContentTitle>
                <ContentData>{ photoInfo?.clntNm ? photoInfo?.clntNm : '-' }</ContentData>
              </Row>
                <Row>
                  <ContentTitle>포토그래퍼</ContentTitle>
                  <ContentData>{ photoInfo?.prdnStdoNm ? photoInfo?.prdnStdoNm : '-'}</ContentData>
                </Row>
                <Row>
                  <ContentTitle>촬영 내용</ContentTitle>
                  <ContentData>{ photoInfo?.prdnPerd ? photoInfo?.prdnPerd : '-' }</ContentData>
                </Row>
              <Row>
                <ContentTitle>촬영 가격</ContentTitle>
                <ContentData className='price'>{ photoInfo?.prdnCost ? photoInfo?.prdnCost : '-' }</ContentData>
              </Row>
            </InfoWrapper>
            <LinkCopy><a href='#' onClick={() => linkCopy()}><CopyBox><img height={18} src={LinkIcon} alt={"link copy"} /></CopyBox></a></LinkCopy>
          </ContentWrapper>
        </Body>
      </ModalBody>
    </Modal>
  )
}

export default PhotoViewerModal;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ACAAAC;
`

const CloseButton = styled.button`
  height: 15px;
  width: 15px;
  margin: 5px 7px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }
`

const Body = styled.div`
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`
  
const Photo = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const SwiperContainer = styled.div`
  width: 600px;

  // navigation color
  --swiper-theme-color: #F5F5F5;
  
  // swiper bullet color
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
    opacity: 0.8;
  }
  .swiper-pagination-bullet-active {
    background-color: #FE5A1D !important;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`
  
const MobilePhoto = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`

const TagWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 7px;
  gap: 5px;
`

const Tag = styled.div`
  padding: 6px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ABABAC;
  background: #F5F5F5;
  border-radius: 3px;
`
  
const ContentWrapper = styled.div`
  width: 100%;
  margin-left: 12px;
  padding: 5px 5px 0 5px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-left: 0;
    padding: 8px 0 0;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
`

const SubDescription = styled.div`
  min-height: 150px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-top: 8px;
  color: #ABABAC;

  @media ${({theme}) => theme.device.mobile} {
    min-height: auto;
    margin-bottom: 0px;
  }
`

const InfoWrapper = styled.div`
  margin: 62px 0 0;
  padding: 15px 20px;
  border-radius: 8px;
  background: #F5F5F5;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 20px 0;
  }
`

const CopyBox = styled.div`
  padding : 0 3px
`

const LinkCopy = styled.div`
  height: 33px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  .price {
    font-weight: 700;
  }
  
  & + & {
    margin-top: 15px;
  }
`

const ContentTitle = styled.div`
  line-height: 19px;
  color: #ABABAC;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
`

const ContentData = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

const PhotoItem = styled.div`
  // cursor: pointer;
  position: relative;
  // width: 600px;
  // height: 400px;
  text-align: center;
  background: url(${props => props.image}) no-repeat 95%;
  background-size: cover;
  // background-size: contain;
   
  &:not(:first-child) {
    margin-left: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const MobilePhotoItem = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    // min-width: 100%;
    // width: 300px;
    // height: 200px;
    background: url(${props => props.image}) no-repeat 95%;
    background-size: cover;

    img {
      width: 100%;
    }
  }
`