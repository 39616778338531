import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import FormError from 'components/common/FormError';
import RoundChecked from 'resources/member/round_checkbox_checked.svg';
import RoundUnChecked from 'resources/member/round_checkbox.svg';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import PolicyModal from 'components/common/PolicyModal';
import { idReg, pwReg, emailReg, strictPhoneReg } from 'components/regulation';


const Join = () => {
  const navigate = useNavigate();
  
  const schema = yup.object().shape({
    loginId: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( idReg, '⚠ 아이디는 영문 대소문자와 숫자 4~12자리로 입력해주세요.'),
    pw: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( pwReg, '⚠ 비밀번호는 숫자/영문자/특수문자 조합으로 8~15자리 입력해주세요.'),
    pwConfirm: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .oneOf([yup.ref('pw'), null], '⚠ 비밀번호가 일치하지 않습니다'),
    name: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    email: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( emailReg, '⚠ 올바른 이메일 형식을 입력하셔야 합니다'),
    phone: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( strictPhoneReg, '⚠ 전화번호 형식에 맞게 입력해주세요'),
  });

  const { 
    handleSubmit, 
    register, 
    control,
    formState: {errors} 
  } = useForm({
    resolver: yupResolver(schema)
  });
  
  // 가입경로-드롭박스
  const options = [
    {value: '10', label: '인스타그램'},
    {value: '20', label: '페이스북'},
    {value: '30', label: '배너광고'},
    {value: '40', label: '네이버 검색'},
    {value: '41', label: '네이버 블로그'},
    {value: '50', label: '구글 검색'},
    {value: '60', label: '지인소개'},
    {value: '90', label: '기타'},
  ];

  const [selectedOption, setSelectedOption] = useState();

  const handleDropbox = (selectedOption) => {
    setSelectedOption(selectedOption); 
  }

  // 모바일 사이즈 세팅
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };
  const [mode, setMode] = useState("pc");
  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // 드롭박스 스타일링
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize : '15px',
      fontWeight : 700,
      boxShadow: 'none',
      borderColor: '#D9D9D9',
      width: mode === "pc" ? '477px' : mode === "mobile" ? '100%' : '',
      height: mode === "pc" ? '54px': mode ==="mobile" ? '48px' : '100px',
      paddingTop: '3px',
      paddingLeft: '8px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: mode === 'pc' ? '16px' : mode === 'mobile' ? '15px' : '',
      lineHeight: mode === 'pc' ? '19px' : mode === 'mobile' ? '18px' : '',
      color: '#ABABAC',
      fontWeight: 200,
      // paddingTop: '1px',
      // paddingLeft: '8px',
    }),
    option: (base, {isSelected, isFocused}) => ({
      ...base,
      backgroundColor: isFocused? '#4B4B4B' : '#FFFFFF',
      color: isFocused? '#FFFFFF' : '#2C2C2C',
      fontWeight: isFocused? '700' : '200',
      paddingTop : '10px',
    })
  }

  // 체크박스
  const [allCheck, setAllCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [personalInfoCheck, setPersonalInfoCheck] = useState(false);
  const [offerCheck, setOfferCheck] = useState(false);
  const [rcvCheck, setRcvCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [snsEmailCheck, setSNSEmailCheck] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);

  useEffect(() => {
    if (ageCheck && termCheck && personalInfoCheck && offerCheck) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [ageCheck, termCheck, personalInfoCheck, offerCheck]);
  
  useEffect(() => {
    if (smsCheck && snsEmailCheck) {
      setRcvCheck(true);
    } else {
      setRcvCheck(false);
    }
  }, [smsCheck, snsEmailCheck]);

  /* 전체 동의 체크박스 */
  const checkAll = (checked) => {
    setAllCheck(checked);    
    setTermCheck(checked);
    setPersonalInfoCheck(checked);
    setOfferCheck(checked);
    setRcvCheck(checked);
    setSNSEmailCheck(checked);
    setSmsCheck(checked);
    setAgeCheck(checked);
  }

  const rcvCheckAll = (checked) => {
    setRcvCheck(checked);
    setSNSEmailCheck(checked);
    setSmsCheck(checked);
  }

  // 아이디 중복확인버튼을 눌렀는지 안눌렀는지 확인
  const [isCheckedId , setIsCheckedId] = useState(false);

  // 아이디 중복확인 버튼 클릭 시
  const idDoubleChkHandler = async() => {
    const loginId = document.getElementById('loginId').value;
    const userId = {
      loginId : loginId
    }
    if (loginId) {
      const res = await memberApi.checkId(userId).then( response => {
        if (response.data > 0) { // response.data가 1이면 사용불가능, 0이면 사용가능
          handleAlertText('아이디 중복!', '입력하신 아이디는 이미 사용 중 입니다', 'error');
          setIsCheckedId(false);
        } else {
          handleAlertText('아이디 확인완료!', '사용 가능한 아이디입니다 ', 'success');
          setIsCheckedId(true);
        }
      }, error => {
        handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
      })
    } else {
      handleAlertText('아이디 오류!', '아이디를 입력하세요', 'error');
    }
  }

  // 이메일 중복확인버튼을 눌렀는지 안눌렀는지 확인
  const [isCheckedEmail , setIsCheckedEmail] = useState(false);

  // 이메일 중복확인 버튼 클릭 시
  const emailDoubleChkHandler = async() => {
    const email = document.getElementById('email').value;
    const userEmail = {
      email : email
    }
    if (email) {
      const res = await memberApi.checkEmail(userEmail).then( response => {
        if (response.data > 0) { // response.data가 1이면 사용불가능, 0이면 사용가능
          handleAlertText('이메일 중복!', '입력하신 이메일은 이미 사용 중 입니다', 'error');
          setIsCheckedEmail(false);
        } else {
          handleAlertText('이메일 확인완료!', '사용 가능한 이메일입니다 ', 'success');
          setIsCheckedEmail(true);
        }
      }, error => {
        handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
      })
    } else {
      handleAlertText('이메일 오류!', '이메일을 입력하세요', 'error');
    }
  }
  
  // 모달 여닫기 
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => {
    setOpenModal(!openModal);
  }

  // 동의서 내부에 보기 버튼 클릭시 모달타입설정
  const [modalType, setModalType] = useState();
  const chooseModalType = (id) => {
    setModalType(id);
    setOpenModal(!openModal);
  }
  
  /* 동의하고 가입하기 버튼 클릭시 */
  const onSubmit = async(data) => {
    if (isCheckedId && isCheckedEmail) {
      if (ageCheck && termCheck && personalInfoCheck && offerCheck) {
        const param = { 
          joinDvcd : '1', // 가입구분코드
          joinPathDvcd : selectedOption?.value || null, // 가입경로
          loginId : data.loginId || null, // 로그인ID(email)
          loginPwd : data.pw, // 로그인비밀번호
          email : data.email || null, // 이메일
          hpNo : data.phone.replaceAll('-','') || null, // 전화번호
          custUserNm : data.name || null, // 사용자명
          psinfClctAgrYn : termCheck ? 'Y' : 'N', // 개인정보수집이용동의여부
          psinfOfrAgrYn : personalInfoCheck ? 'Y' : 'N', // 개인정보제공동의여부
          trmsServAgrYn : offerCheck ? 'Y' : 'N', // 이용약관동의여부
          emailRcvAgrYn : snsEmailCheck ? 'Y' : 'N', // 이메일수신동의여부
          smsRcvAgrYn : smsCheck ? 'Y' : 'N' // SMS수신동의여부
        };
        memberApi.join(param).then(response => {
          const elem = {
            loginId : data.loginId,
            name : data.name,
          }
          navigate('/welcomeSignup', {state:elem});
        }).catch(error => {
          handleAlertText('오류!', '일시적인 오류로 회원가입에 실패하였습니다.', 'error');
        });
      } else {
        handleAlertText('오류!', '필수 항목에 모두 동의해주시기 바랍니다.', 'error');
        return;
      }
    } else if(!isCheckedId) {
      handleAlertText('오류!', '반드시 아이디를 중복확인 해주세요', 'error')
    } else if(!isCheckedEmail) {
      handleAlertText('오류!', '반드시 이메일을 중복확인 해주세요', 'error')
    }
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 회원가입</title>
        </Helmet>
      </HelmetProvider>

      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
        <ContentBox>
          <Title> 회원가입 </Title>
          <InputWrapper>
            <div className='idInput'>
              <label htmlFor='id' >
                아이디 <span>*</span>
              </label>
              
              <InputBox 
                type='text' 
                id='loginId' 
                placeholder='아이디를 입력해주세요' 
                {...register('loginId')} 
              />
              <IdDoubleChkBtn
                type='button' 
                onClick={idDoubleChkHandler}
              >중복확인
              </IdDoubleChkBtn>
              {errors.loginId ? 
                <FormErrorWrapper>
                  <FormError message={errors.loginId?.message}/>
                </FormErrorWrapper> : ''
              }
            </div>

            <div className='pwInput'>
              <label htmlFor='pw' >
                비밀번호 <span>*</span>
              </label>
              <InputBox 
                type='password' 
                id='pw' 
                placeholder='비밀번호를 입력해주세요'
                {...register('pw')}
              />
              {errors.pw ? 
                <FormErrorContainer>
                  <FormError message={errors.pw?.message}/>
                </FormErrorContainer> : ''
              }
              <div style={{marginTop:'10px'}}></div>
              
              <InputBox 
                type='password' 
                id='pwConfirm' 
                placeholder='비밀번호를 한번 더 입력해주세요' 
                {...register('pwConfirm')}
              /> 
              {errors.pwConfirm ? 
                <FormErrorContainer>
                  <FormError message={errors.pwConfirm?.message}/>
                </FormErrorContainer> : ''
              }
            </div>

            <div className='nameInput'>
              <label htmlFor='name'>
                이름 <span>*</span>
              </label>
              <InputBox 
                type='text' 
                id='name' 
                placeholder='이름을 입력해주세요'
                {...register('name')}
              />
              {errors.name ? 
                <FormErrorContainer>
                  <FormError message={errors.name?.message}/>
                </FormErrorContainer> : ''
              }
            </div>

            <div className='emailInput'>
              <label htmlFor='email'>
                이메일 <span>*</span>
              </label>
              <InputBox 
                type='text' 
                id='email' 
                placeholder='이메일을 입력해주세요'
                {...register('email')}
              />
              <EmailDoubleChkBtn
                type='button' 
                onClick={emailDoubleChkHandler}
                >중복확인
              </EmailDoubleChkBtn>
              {errors.email ? 
                <FormErrorWrapper>
                  <FormError message={errors.email?.message}/>
                </FormErrorWrapper> : ''
              }
            </div>

            <div className='phoneInput'>
              <label htmlFor='phone'>
                휴대폰 <span>*</span>
              </label>
              <Controller 
                id='phone'
                name='phone'
                control={control}
                render={({ field: {ref, ...field}}) => 
                <NumberFormat {...field} 
                  type='text'
                  htmlFor='phone'
                  placeholder='숫자만 입력해주세요'
                  format='###-####-####'
                  mask='_'
                  innerRef={ref}
                  customInput={InputBox}
                />}
              />
              {/* <InputBox 
                type='number' 
                id='phone' 
                placeholder='숫자만 입력해주세요'
                {...register('phone')}
              /> */}
              {errors.phone ? 
                <FormErrorContainer>
                  <FormError message={errors.phone?.message}/>
                </FormErrorContainer> : ''
              }
            </div>
          </InputWrapper>

          <div id="bottomContent">
            <label htmlFor='registerRoute' style={{fontWeight:700, marginBottom:'5px', marginTop:'20px'}}>
              가입경로
            </label>
            <Select
              className='select'
              options={options} 
              onChange={handleDropbox}
              value={selectedOption}
              styles={customStyles}
              placeholder='가입 경로를 선택해주세요'
            />
            <AgreementContainer>
              <AllCheckWrapper>              
                <CheckField size={'20px'}>     
                  <label htmlFor='allCheck'>           
                    <Check 
                      id='allCheck'
                      name='allCheck'
                      checked={allCheck}
                      onChange={() => checkAll(!allCheck)}
                      fontSize={16}
                    /><i className='check-icon'></i>모두 동의합니다
                  </label>
                </CheckField>
              </AllCheckWrapper>
              <OtherCheckboxContainer>
                <CheckboxWrapper>
                  <CheckField size={'16px'}>                
                    <label htmlFor='age'>
                      <Check 
                        id='age'
                        name='age'
                        checked={ageCheck}
                        fontSize={14}
                        onChange={() => setAgeCheck(!ageCheck)}
                      /><i className='check-icon'></i>[필수] 만 14세 이상
                    </label>
                  </CheckField>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CheckField size={'16px'}>                
                    <label htmlFor='term'>
                      <Check 
                        id='term'
                        name='term'
                        checked={termCheck}
                        fontSize={14}
                        onChange={() => setTermCheck(!termCheck)}
                      /><i className='check-icon'></i>[필수] 이용약관 동의 {mode === "pc" ? <a href='/terms/1' className='under' target='_blank'>보기</a> : mode === "mobile" ? 
                      <SeeButton type="button" value={"보기"} onClick={e => chooseModalType('terms/1')} /> : "" }
                    </label>
                  </CheckField>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CheckField size={'16px'}>                
                    <label htmlFor='personalInfo'>
                      <Check 
                        id='personalInfo'
                        name='personalInfo'
                        checked={personalInfoCheck}
                        onChange={() => setPersonalInfoCheck(!personalInfoCheck)}
                        fontSize={14}
                      /><i className='check-icon'></i>[필수] 개인정보 수집 및 이용 동의 {mode === "pc" ? <a href='/privacy' className='under' target='_blank'>보기</a> : mode === "mobile" ? 
                      <SeeButton type="button" value={"보기"} onClick={e => chooseModalType('privacy')} /> : "" }
                    </label>
                  </CheckField>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CheckField size={'16px'}>                
                    <label htmlFor='offer'>
                      <Check 
                        id='offer'
                        name='offer'
                        checked={offerCheck}
                        onChange={() => setOfferCheck(!offerCheck)}
                        fontSize={14}
                      /><i className='check-icon'></i>[필수] 개인정보 제공동의 {mode === "pc" ? <a href='/privacy' className='under' target='_blank'>보기</a> : mode === "mobile" ? 
                      <SeeButton type="button" value={"보기"} onClick={e => chooseModalType('privacy')} /> : "" }
                    </label>
                  </CheckField>
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CheckField size={'16px'}>                
                    <label htmlFor='rcvCheckAll'>
                      <Check 
                        id='rcvCheckAll'
                        name='rcvCheckAll'
                        checked={rcvCheck}
                        onChange={() => rcvCheckAll(!rcvCheck)}
                        fontSize={14}
                      /><i className='check-icon'></i>[선택] 광고성 정보 수신 및 마케팅 활용 동의
                    </label>
                  </CheckField>
                </CheckboxWrapper>
                <CheckboxWrapper style={{display: 'flex', marginLeft: '27px'}}>
                  <CheckField size={'16px'}>                
                    <label htmlFor='sms'>
                      <Check 
                        id='sms'
                        name='sms'
                        checked={smsCheck}
                        onChange={() => setSmsCheck(!smsCheck)}
                        fontSize={14}
                    /> <i className='check-icon'></i>SMS
                    </label>
                  </CheckField>
                  <CheckField size={'16px'}>                
                    <label htmlFor='snsEmail'>
                      <Check 
                        id='snsEmail'
                        name='snsEmail'
                        checked={snsEmailCheck}
                        onChange={() => setSNSEmailCheck(!snsEmailCheck)}
                        fontSize={14}
                      /><i className='check-icon'></i>이메일
                    </label>
                  </CheckField>
                </CheckboxWrapper>
              </OtherCheckboxContainer>
            </AgreementContainer>
            <SubmitButton 
              type='submit' 
              value={'동의하고 가입하기'}
            />
          </div> 
        </ContentBox>  
        </form>
      </Container>
      <PolicyModal
        modal={openModal}
        handleModal={openModalHandler}
        type={modalType}
      />
    </>
    
  )
}

export default Join;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    align-items: inherit;
  }
`

const ContentBox = styled.div`
  width: 477px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 94px auto 0px;
    padding: 0px 16px 0px 16px;

    #bottomContent {
      max-width: 477px;
      margin: 0 auto;
    }
  }
`

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  margin: 120px auto 0px;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 auto;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
  }
`

const InputWrapper = styled.div`
  margin-top: 41px;

  span {
    margin-left: -4px;
    color: #FE5A1D;
    font-weight: 700;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 7px;
  }

  .pwInput, .phoneInput {
    margin-top: -20px;
  }

  .nameInput, .emailInput {
    margin-top: 20px;
  }
  
  // chrome에서 자동입력/자동채우기 시 자동으로 input의 background-color 바꾸는 것 막기
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
  }


  @media ${({ theme }) => theme.device.mobile} {
    max-width: 477px;
    margin: 0 auto;
    margin-top: 42px;
  }
`

const InputBox = styled.input`
  width: 100%;
  height: 54px;
  border: none;
  background-color: ${(props) => props.theme.bgColorGray};
  border-radius: 3px;
  padding: 6px 18px;
  border-radius: 5px;
  z-index: 0;

  &::placeholder {
    color: #ABABAC;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    top: 1px;
  }

  &:focus{
    outline: 1px solid #ABABAC;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;

    &:placeholder {
      color: #ABABAC;
      font-weight: 200;
      font-size: 15px;
      line-height: 18px;
    }
  }
`

const EmailDoubleChkBtn = styled.button`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  width: 100px;
  height: 44px;
  left: 371px;
  bottom: 49px;
  background: #FFFFFF;
  border: 0.5px solid #ABABAC;
  border-radius: 5px;
  padding-top: 3px;
  z-index: 2;
  color: black;

  @media ${({ theme }) => theme.device.mobile} {
    height: 38px;
    left: 225px;
    bottom: 43px;
  }
`

const IdDoubleChkBtn = styled(EmailDoubleChkBtn)`
 
  @media ${({ theme }) => theme.device.mobile} {
    height: 38px;
    left: 225px;
    bottom: 43px;
  }
`

const FormErrorWrapper = styled.div`
  margin-top: -45px;
  margin-bottom: 45px;
  padding-left: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: -38px;
  }
`

const FormErrorContainer = styled.div`
  padding-left: 5px;

  @media ${({ theme }) => theme.device.mobile} {
  }
`

const AgreementContainer = styled.div`
  position: relative;
  text-align: center;
  width: 477px;
  background: #FFFFFF;
  border: 0.25px solid #D9D9D9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 32px;
  margin-top: 20px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 15px 10px 5px 8px;
  }
`

const AllCheckWrapper = styled.div`
  width: 443px;
  margin: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #D9D9D9;
  font-size: 16px;
  font-weight: 700;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 15px;
    margin:0;
    padding: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
`

const OtherCheckboxContainer = styled.div`
  margin: 12px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

const CheckboxWrapper = styled.div`
  margin-bottom: 12px;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 15px;
    margin-bottom: 10px;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;

const SeeButton = styled.input`
  border: none;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
  background-color: white;
  line-height: 18px;
  color: black;
`

const CheckField = styled.div`
  flex: 1;
  display: inline-block;
  width: 100%;
  text-align: left;

  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon { 
    background-image: url(${RoundChecked}); 
  }

  .check-icon { 
    display: inline-block; 
    width: ${props => props.size};
    height: ${props => props.size};
    margin: 0 10px 0 0;
    background: url(${RoundUnChecked}) no-repeat 95%; 
    background-size: cover;
    vertical-align: middle; 
    transition-duration: .2s; 
  }
  
  .under {
    text-decoration-line: underline !important;
  }
`

const SubmitButton = styled.input`
  width: 100%;
  height: 54px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 140px;
  background-color: #FE5A1D;
  color: white;
  text-align: center;
  padding: 8px 0px;
  padding-top: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  opacity: ${props=>props.disabled ? '0.5' : '1'};

    
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 60px;
  }
`