import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 개인정보처리방침</title>
        </Helmet>
      </HelmetProvider>
      <Container>
        <MenuTitle>개인정보처리방침</MenuTitle>
        {/* <ContentHeader>
          {`주식회사 브리피('www.briphy.com'이하 '브리피(Briphy)')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.`}
        </ContentHeader>
        <div style={{fontWeight: 400, marginBottom: 30}}>이 개인정보처리방침은 2022년 8월 1일부터 적용됩니다.</div> */}
        <Title>제1조(개인정보의 처리 목적)</Title>
        <Content>
{`주식회사 브리피 (www.briphy.com)(이하 회사)는 회사가 제공하고자 하는 서비스(이하 ‘서비스’)를 이용하는 개인(이하 ‘이용자’)의 정보(이하 ‘개인정보’)를 보호하기 위해, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)등 관련 법령을 준수하고, 서비스 이용자의 개인정보 보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침(이하 ‘본 방침’)을 수립합니다.`}
        </Content>
        <Title>제2조(개인정보 처리의 원칙)</Title>
        <Content>
{`개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보는 개인의 동의가 있는 경우에 한해 제3자에게 제공될 수 있습니다. 단, 법령의 규정 등에 의해 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.`}
        </Content>
        <Title>제3조(본 방침의 공개)</Title>
        <Content>
{`① 회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 회사 홈페이지 첫 화면 또는 첫 화면과의 연결화면을 통해 본 방침을 공개하고 있습니다.

② 회사는 제1항에 따라 본 방침을 공개하는 경우 글자 크기, 색상 등을 활용하여 이용자가 본 방침을 쉽게 확인할 수 있도록 합니다.`}
        </Content>
        <Title>제4조(본 방침의 변경)</Title>
        <Content>
{`① 본 방침은 개인정보 관련 법령, 지침, 고시 또는 정부나 회사 서비스의 정책이나 내용의 변경에 따라 개정될 수 있습니다.

② 회사는 제1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다.

1. 회사가 운영하는 인터넷 홈페이지의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법

2. 서면이나 전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는 방법

③ 회사는 제2항의 공지는 본 방침 개정의 시행일로부터 최소 7일 이전에 공지합니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 공지합니다.`}
        </Content>
        <Title>제5조(회원 가입을 위한 정보)</Title>
        <Content>
{`회사는 이용자의 회사 서비스에 대한 회원가입을 위하여 다음과 같은 정보를 수집합니다.

① 필수 수집 정보: 이름. 이메일 주소, 비밀번호, ID, 이름, 생년월일 및 휴대폰 번호`}
        </Content>
        <Title>제6조(본인 인증을 위한 정보)</Title>
        <Content>
{`회사는 이용자의 본인인증을 위하여 다음과 같은 정보를 수집합니다.

①  필수 수집 정보: 휴대폰 번호, 이메일 주소, 이름, 생년월일, 본인확인값(CI, DI), 이동통신사, 아이핀 정보(아이핀 확인 시) 및 내/외국인 여부`}
        </Content>
        <Title>제7조(결제 서비스를 위한 정보)</Title>
        <Content>
{`회사는 이용자에게 회사의 결제 서비스 제공을 위하여 다음과 같은 정보를 수집합니다.

① 필수 수집 정보: 카드번호, 카드비밀번호, 유효기간, 생년월일 6자리(yy/mm/dd), 은행명 및 계좌번호`}
        </Content>
        <Title>제8조(현금 영수증 발행을 위한 정보)</Title>
        <Content>
{`① 필수 수집 정보: 현금영수증 발행 대상자 이름, 현금영수증 발행 대상자 생년월일, 현금영수증 발행 대상자 주소, 휴대폰 번호 및 현금영수증 카드번호`}
        </Content>
        <Title>제9조 (회사 서비스 제공을 위한 정보)</Title>
        <Content>
{`회사는 이용자에게 회사의 서비스를 제공하기 위하여 다음과 같은 정보를 수집합니다.

① 필수 수집 정보: 아이디, 이메일 주소, 이름, 생년월일 및 연락처`}
        </Content>
        <Title>제10조 (서비스 이용 및 부정이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다.</Title>
        <Content>
{`① 필수 수집 정보: 서비스 이용기록, 쿠키, 접속지 정보 및 기기정보

※ 부정이용: 회원탈퇴 후 재가입, 상품구매 후 구매취소 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상 이익을 불·편법적으로 수취하는 행위, 이용약관 등에서 금지하고 있는 행위, 명의도용 등의 불·편법행위 등을 말합니다. 수집되는 정보는 회사 서비스 이용에 따른 통계·분석에 이용될 수 있습니다.`}
        </Content>
        <Title>제11조(개인정보 수집 방법)</Title>
        <Content>
{`회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.

① 이용자가 회사의 홈페이지에 자신의 개인정보를 입력하는 방식

② 어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식

③ 이용자가 회사가 발송한 이메일을 수신받아 개인정보를 입력하는 방식

④ 이용자가 고객센터의 상담, 게시판의 활동 등 회사의 서비스를 이용하는 과정에서 이용자가 입력하는 방식`}
        </Content>
        <Title>제12조(개인정보의 이용)</Title>
        <Content>
{`회사는 개인정보를 다음 각 호의 경우에 이용합니다.

① 공지사항 전달 등 회사의 운영에 필요한 경우

② 이용문의에 대한 회신, 불만의 처리 등 이용자에 대한 서비스 개선을 위한 경우

③ 회사의 서비스를 제공하기 위한 경우

④ 신규 서비스 개발을 위한 경우

⑤ 이벤트 및 행사 안내 등 마케팅을 위한 경우

⑥ 인구통계학적 분석, 서비스 방문 및 이용기록의 분석을 위한 경우

⑦ 개인정보 및 관심에 기반한 이용자간 관계의 형성을 위한 경우

⑧ 법령 및 회사 약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재를 위한 경우`}
        </Content>
        <Title>제13조(사전동의 등에 따른 개인정보의 제공)</Title>
        <Content>
{`① 회사는 개인정보 제3자 제공 금지에도 불구하고, 이용자가 사전에 공개하거나 다음 각호 사항에 대하여 동의한 경우에는 제3자에게 개인정보를 제공할 수 있습니다. 다만 이 경우에도 회사는 관련 법령 내에서 최소한으로 개인정보를 제공합니다.

1. ㈜토스페이먼츠에게 서비스 이용시 경제 대행사를 통한 결제를 위하여 이메일, 휴대폰 번호, 이름, 신용카드정보, 은행계좌정보, 결제기록을 제공

2. 제휴 사진작가 및 영상제작사에게 촬영 진행을 위하여 이름, 휴대폰 번호, 주소, 이메일을 제공

② 회사는 전항의 제3자 제공 관계에 변화가 있거나 제3자 제공 관계가 종결될 때도 같은 절차에 의해 이용자에게 고지 및 동의를 구합니다.`}
        </Content>
        <Title>제14조(개인정보의 처리 위탁)</Title>
        <Content>
{`회사는 원활한 서비스 제공과 효과적인 업무를 처리하기 위하여 다음 각 호와 같이 개인정보를 처리 위탁하고 있습니다.

① ㈜토스페이먼트에게 구매 및 요금결제, 본인인증(금융 거래, 금융서비스)를 위하여 위탁 계약 종료 시까지 개인정보처리를 위탁함`}
        </Content>
        <Title>제15조(개인정보의 보유 및 이용기간)</Title>
        <Content>
{`① 회사는 이용자의 개인정보에 대해 개인정보의 수집·이용 목적 달성을 위한 기간 동안 개인정보를 보유 및 이용합니다.

② 전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용 기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관합니다.`}
        </Content>
        <Title>제16조(법령에 따른 개인정보의 보유 및 이용기간)</Title>
        <Content>
{`회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.

① 전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유깐

1. 계약 또는 청약철회 등에 관한 기록: 5년

2. 대금 결제 및 재화 등의 공급에 관한 기록: 5년

3. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년

4. 표시·광고에 관한 기록: 6개월

② 통신비밀보호법에 따른 보유정보 및 보유기간

1. 웹사이트 로그 기록 자료: 3개월

③ 전자금융거래법에 따른 보유정보 및 보유기간

1. 전자금융거래에 관한 기록: 5년

④ 위치정보의 보호 및 이용 등에 관한 법률

1. 개인위치정보에 관한 기록: 6개월`}
        </Content>
        <Title>제17조(개인정보의 파기원칙)</Title>
        <Content>
{`회사는 원칙적으로 이용자의 개인정보 처리 목적의 달성, 보유·이용 기간의 경과 등 개인정보가 필요하지 않을 경우에는 해당 정보를 지체없이 파기합니다.`}
        </Content>
        <Title>제18조(서비스 미이용자에 대한 개인정보처리)</Title>
        <Content>
{`① 회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 원칙적으로 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장합니다.

② 회사는 장기 미이용 이용자의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 해당 이용자의 통지는 분리 보관 처리일을 기준으로 최소 30일 이전에 전자우편주소로 전송됩니다.

③ 장기 미이용 이용자는 회사가 미이용자 DB를 별도로 분리하기 전에 계속 서비스를 이용하고자 하는 경우 웹사이트(이하 ‘모바일앱’ 포함)에 로그인하시면 됩니다.

④ 장기 미이용 이용자는 웹사이트에 로그인할 경우 이용자의 동의에 따라 본인의 계정을 복원할 수 있습니다.

⑤ 회사는 분리 보관된 개인정보를 4년간 보관 후 지체없이 파기합니다.`}
        </Content>
        <Title>제19조(개인정보파기절차)</Title>
        <Content>
{`① 이용자가 회원가입 등을 위해 입력한 정보는 개인정보 처리 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 되어집니다.

② 회사는 파기 사유가 발생한 개인정보를 개인정보보호 책임자의 승인절차를 거쳐 파기합니다.`}
        </Content>
        <Title>제20조(개인정보파기방법)</Title>
        <Content>
{`회사는 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이로 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.`}
        </Content>
        <Title>제21조(광고성 정보의 전송 조치)</Title>
        <Content>
{`① 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 이용자의 명시적인 사전동의를 받습니다. 다만, 다음 각호 어느 하나에 해당하는 경우에는 사전 동의를 받지 않습니다.

1. 회사가 재화 등의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 경우, 거래가 종료된 날로부터 6개월 이내에 회사가 처리하고 수신자와 거래한 것과 동종의 재화 등에 대한 영리목적의 광고성 정보를 전송하려는 경우

2. 「방문판매 등에 관한 법률」에 따른 전화권유판매자가 육성으로 수신자에게 개인정보의 수집출처를 고지하고 전화 권유를 하는 경우

② 회사는 전항에도 불구하고 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않으며 수신거부 및 수신동의 철회에 대한 처리 결과를 알립니다.

③ 회사는 오후 9시부터 그다음 날 오전 8시까지의 시간에 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우에는 제1항에도 불구하고 그 수신자로부터 별도의 사전 동의를 받습니다.

④ 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음의 사항 등을 광고성 정보에 구체적으로 밝힙니다.

1. 회사명 및 연락처

2. 수신 거부 및 수신 동의의 철회 의사표시에 관한 사항의 표시

⑤ 회사는 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 경우 다음 각 호의 어느 하나에 해당하는 조치를 하지 않습니다.

1. 광고성 정보 수신자의 수신거부 또는 수신동의의 철회를 회피·방해하는 조치

2. 숫자·부호 또는 문자를 조합하여 전화번호·전자우편주소 등 수신자의 연락처를 자동으로 만들어내는 조치

3. 영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편주소를 자동으로 등록하는 조치

4. 광고성 정보 전송자의 신원이나 광고 전송 출처를 감추기 위한 각종 조치

5. 영리목적의 광고성 정보를 전송할 목적으로 수신자를 기망하여 회신을 유도하는 각종 조치`}
        </Content>
        <Title>제22조 (개인정보 조회 및 수집동의 철회)</Title>
        <Content>
{`① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 개인정보수집 동의 철회를 요청할 수 있습니다.

② 이용자 및 법정 대리인은 자신의 가입정보 수집 등에 대한 동의를 철회하기 위해서는 개인정보보호책임자 또는 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 회사는 지체 없이 조치하겠습니다.`}
        </Content>
        <Title>제23조(개인정보 정보변경 등)</Title>
        <Content>
{`① 이용자는 회사에게 전조의 방법을 통해 개인정보의 오류에 대 한 정정을 요청할 수 있습니다.

② 회사는 전항의 경우에 개인정보의 정정을 완료하기 전까지 개 인정보를 이용 또는 제공하지 않으며 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.`}
        </Content>
        <Title>제24조(이용자의 의무)</Title>
        <Content>
{`① 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이 용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용 자 자신에게 있습니다.

② 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상 실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.

③ 이용자는 전자우편주소, 비밀번호 등에 대한 보안을 유지할 책 임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.`}
        </Content>
        <Title>제25조(회사의 개인정보 관리)</Title>
        <Content>
{`회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이 기술적•관리적 보호대책을 강구하고 있습니다.`}
        </Content>
        <Title>제26조(삭제된 정보의 처리)</Title>
        <Content>
{`회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개 인정보는 회사가 수집하는 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`}
        </Content>
        <Title>제27조(비밀번호의 암호화)</Title>
        <Content>
{`이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가 능합니다.`}
        </Content>
        <Title>제28조(해킹 등에 대비한 대책)</Title>
        <Content>
{`① 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용 자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.

② 회사는 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출 또는 손상되지 않도록 방지하고 있습니다.

③ 회사는 만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다.

④ 회사는 민감한 개인정보(를 수집 및 보유하고 있는 경우)를 암 호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.`}
        </Content>
        <Title>제29조(개인정보 처리 최소화 및 교육)</Title>
        <Content>
{`회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준 수를 강조하고 있습니다.`}
        </Content>
        <Title>제30조(개인정보 유출 등에 대한 조치)</Title>
        <Content>
{`회사는 개인정보의 분실•도난 유출(이하 "유출 등"이라 한다) 사실을 안 때에는 지체 없이 다음 각 호의 모든 사항을 해당 이용자에게 알리고 방송통신위원회 또는 한국인터넷진흥원에 신고합니다.

① 유출 등이 된 개인정보 항목

② 유출 등이 발생한 시점

③ 이용자가 취할 수 있는 조치

④ 정보통신서비스 제공자 등의 대응 조치

⑤ 이용자가 상담 등을 접수할 수 있는 부서 및 연락처`}
        </Content>
        <Title>제31조(개인정보 유출 등에 대한 조치의 예외)</Title>
        <Content>
{`회사는 전조에도 불구하고 이용자의 연락처를 알 수 없는 등 정당한 사유가 있는 경우에는 회사의 홈페이지에 30일 이상 게시하는 방법으로 전조의 통지를 갈음하는 조치를 취할 수 있습니다.`}
        </Content>
        <Title>제32조(국외 이전 개인정보의 보호)</Title>
        <Content>
{`① 회사는 이용자의 개인정보에 관하여 개인정보보호법 등 관계 법규를 위반하는 사항을 내용으로 하는 국제계약을 체결하지 않습니다.

② 회사는 이용자의 개인정보를 국외에 제공(조회되는 경우를 포 함) • 처리위탁 . 보관(이하 "이전"이라 함)하려면 이용자의 동 의를 받습니다. 다만, 본 조 제3항 각 호의 사항 모두를 개인정보 보호법 등 관계 법규에 따라 공개하거나 전자우편 등 대통령령으로 정하는 방법에 따라 이용자에게 알린 경우에는 개인정보 처리위탁• 보관에 따른 동의절차를 거치지 아니할 수 있습니다.

③ 회사는 본 조 제2항 본문에 따른 동의를 받으려면 미리 다음 각 호의 사항 모두를 이용자에게 고지합니다.

1. 이전되는 개인정보 항목 

2. 개인정보가 이전되는 국가, 이전일시 및 이전방법 

3. 개인정보를 이전받는 자의 성명(법인인 경우 그 명칭 및 정보관리 책임자의 연락처를 말한다)

4. 개인정보를 이전받는 자의 개인정보 이용목적 및 보유 및 이용 기간

④ 회사는 본조 제2항 본문에 따른 동의를 받아 개인정보를 국외 로 이전하는 경우 개인정보보호법 대통령령등 관계법규에서 정하는 바에 따라 보호조치를 합니다.`}
        </Content>
        <Title>제33조(이용자의 쿠키 설치 선택권)</Title>
        <Content>
{`① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허 용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

② 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.`}
        </Content>
        <Title>제34조(쿠키 설치 허용 지정 방법)</Title>
        <Content>
{`쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.

1. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.

2. [개인정보 탭]을 클릭합니다.

3. [고급] 설정에서 설정하시면 됩니다.`}
        </Content>
        <Title>제35조(회사의 개인정보 보호 책임자 지정)</Title>
        <Content>
{`① 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만 을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호책임자를 지정하고 있습니다.

1. 개인정보 보호 책임자

가. 성명: 서욱진

나. 전화번호: 010-4164-5995

다. 이메일: Kevin@briphy.com

2. 개인정보 보호 담당자

가. 담당부서:

나. 담당자명: 서욱진

다. 전화번호: 010-4164-5995

라. 이메일: kevin@briphy.com`}
        </Content>
        <div style={{fontWeight: 300, marginTop:'20px'}}>부칙: 제1조 본 방침은 2023.04.01.부터 시행됩니다.</div>
      </Container>
    </MainContainer>
  )
}

export default PrivacyPolicy;


const MainContainer = styled.div`  
  display: flex;
  position: relative;
  margin: 0 auto;
`

const Container = styled.div`
  width: 1088px;
  min-height: calc(100vh - 62px);
  margin: 100px auto;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto;
    padding: 0 16px;
  }
`

const MenuTitle = styled.div`
  margin: 30px auto;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 20px auto 31px;
  }
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`

const Content = styled.pre`
  height: fit-content;
  margin-top: 3px;
  margin-bottom: 30px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  // line-height: ${props => props.lh ? `${props.lh}px` : '14px'};
  line-height: 14.4px;
  white-space: pre-line;
  background-color: ${props => props.theme.bgColorGray};
`

const ContentHeader = styled(Content)`
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 200; 
  white-space: pre-line;
  background-color: white;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

