import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// import './App.css';

function KeyMessage() {
  const [scrollPosition, setScrollPosition] = useState(0);

  // Hero Section 하단 메시지
  const HeroMessageLine1 = [
    { id:'heroMessageHidden1', text:'광고주는'},
    { id:'heroMessageHidden2', text:'광고가'},
    { id:'heroMessageHidden3', text:'시작부터'}, 
    { id:'heroMessageHidden4', text:'끝까지'}, 
    { id:'heroMessageHidden5', text:'읽히기를'}, 
    { id:'heroMessageHidden6', text:'바랍니다.'}];
  const HeroMessageLine2 = [
    { id:'heroMessageHidden7', text:'대중은'},
    { id:'heroMessageHidden8', text:'모든 광고를'},
    { id:'heroMessageHidden9', text:'함부로'}, 
    { id:'heroMessageHidden10', text:'성의 없이'}, 
    { id:'heroMessageHidden11', text:'소비합니다.'}];
  const HeroMessageLine3 = [
    { id:'heroMessageHidden12', text:'이'},
    { id:'heroMessageHidden13', text:'간극을'},
    { id:'heroMessageHidden14', text:'해결하는'}, 
    { id:'heroMessageHidden15', text:'방법이'}, 
    { id:'heroMessageHidden16', text:'있습니다.'}];
  const HeroMessageLine4 = [
    { id:'heroMessageHidden17', text:'설명하지'},
    { id:'heroMessageHidden18', text:'않고'}, 
    { id:'heroMessageHidden19', text:'보여주면'}, 
    { id:'heroMessageHidden20', text:'됩니다.'}];

  const maxImageHeight = window.innerHeight;
  const opacityOffset = 460;
  const defaultOpacity = 0;
  const distanceOffset = 10;
 
  /**
   * scroll 감지 useEffect
   * 스크롤 위치값 저장
   */
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * 스크롤 변화에 따른 
   */
  useEffect(() => {
    const scrollY = ((scrollPosition - maxImageHeight*2) * -1) + 'px';

    // Hero Section bottom text message 처리
    if(scrollPosition > maxImageHeight && scrollPosition < maxImageHeight*2 + opacityOffset) {
      const opacityInterval = maxImageHeight/30;
      HeroMessageLine1.forEach(item => {
        const itemNumber = Number(item.id.replaceAll('heroMessageHidden', ''))
        if (scrollPosition > opacityInterval * itemNumber + maxImageHeight + opacityOffset + itemNumber * distanceOffset) {
          document.getElementById(item.id).style.opacity = 0;
        } else {
          document.getElementById(item.id).style.opacity = defaultOpacity;
        }
      })
      HeroMessageLine2.forEach(item => {
        const itemNumber = Number(item.id.replaceAll('heroMessageHidden', ''))
        if (scrollPosition > opacityInterval * itemNumber + maxImageHeight + opacityOffset + itemNumber * distanceOffset) {
          document.getElementById(item.id).style.opacity = 0;
        } else {
          document.getElementById(item.id).style.opacity = defaultOpacity;
        }
      })
      HeroMessageLine3.forEach(item => {
        const itemNumber = Number(item.id.replaceAll('heroMessageHidden', ''))
        if (scrollPosition > opacityInterval * itemNumber + maxImageHeight + opacityOffset + itemNumber * distanceOffset) {
          document.getElementById(item.id).style.opacity = 0;
        } else {
          document.getElementById(item.id).style.opacity = defaultOpacity;
        }
      })
      HeroMessageLine4.forEach(item => {
        const itemNumber = Number(item.id.replaceAll('heroMessageHidden', ''))
        if (scrollPosition > opacityInterval * itemNumber + maxImageHeight + opacityOffset + itemNumber * distanceOffset) {
          document.getElementById(item.id).style.opacity = 0;
        } else {
          document.getElementById(item.id).style.opacity = defaultOpacity;
        }
      })
    }
    if (scrollPosition > maxImageHeight*2) {
      //  console.log('scrollPosition : ', scrollPosition)
      //  document.getElementById('HeroBottomMessage').style.transform = `translate(0px, ${(scrollPosition - maxImageHeight*2)})`;
    } else {

    }
  }, [scrollPosition])

  return (
    <Container>
      {/* <HeroContainer style={{ display: showHeroContainer2 ? 'none' : 'flex' }}> */}
      <HeroBottomContainer id='HeroBottomMessage'>
        <HeroMessageLineWrapper>
          {HeroMessageLine1.map(item => {
            return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
          })}
        </HeroMessageLineWrapper>
        <HeroMessageLineWrapper>
          {HeroMessageLine2.map(item => {
            return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
          })}
        </HeroMessageLineWrapper>
        <HeroMessageLineWrapper></HeroMessageLineWrapper>
        <HeroMessageLineWrapper>
          {HeroMessageLine3.map(item => {
            return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
          })}
        </HeroMessageLineWrapper>
        <HeroMessageLineWrapper>
          {HeroMessageLine4.map(item => {
            return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
          })}
        </HeroMessageLineWrapper>
      </HeroBottomContainer>
    </Container>
  );
}

export default KeyMessage;

const Container = styled.div`
  // display: flex;
  // width: 100%;  
  // height: 1024px;
  // margin: 0 auto;
  // // background-color: #F5F5F5;
  position: relative;
  display: contents;
  // margin-top: 100px;

  @media ${({theme}) => theme.device.mobile} {
    // height: 628px;
    // margin-bottom: 100px;
  };
`

const HeroBottomContainer = styled.div`
  width: 100%;
  height: 820px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top : 100px;
  margin-top : 320vh;
  // margin: 80px auto 0px;
  // padding-right: 27px;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
    justify-content: flex-end;
    // flex-direction: column-reverse;
    width: 100%;
    // height: 100vh;
    // height: 1000px;
    // margin-top : 220vh;
    padding: 0;
  };
`

const HeroMessageLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 2vh;
  margin-bottom: 10vh;
  // opacity: 0.1;
`

const HeroMessageLineItem = styled.div`
  margin-right: 15px;
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  text-align: center;
  opacity: 0;

  // font-size: 48px;
  // font-weight: 500;
  // line-height: 1.5;
  // letter-spacing: 2px;
  // color: ${props => props.theme.txtWhite };
`
