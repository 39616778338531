const initialState = {
  categoryList: [],
  tagList: [],
  portfolioList: [],
  mainPortfolioList: [],
  videoList: [],
  photoList: [],
  photoFileList: [],
}

const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CATEGORY_LIST' :
      return {
        ...state,
        categoryList: action.data.categoryList,
      }
    case 'GET_TAG_LIST' :
      return {
        ...state,
        tagList: action.data.tagList,
      }
    case 'GET_PORTFOLIO_LIST' :
      return {
        ...state,
        portfolioList: action.data.portfolioList,
      }
    case 'GET_MAIN_PORTFOLIO_LIST' :
      return {
        ...state,
        videoList: action.data.videoList,
        photoList: action.data.photoList,
      }
    case 'GET_PORTFOLIO_PHOTO_FILE_LIST' : 
      return {
        ...state,
        photoFileList: action.data.photoFileList
      }
    case 'GET_VIDEO_MAIN_PORTFOLIO_LIST' : 
      return {
        ...state,
        mainPortfolioList: action.data.portfolioList
      }
    default:
      return {...state}
  }
}

export default portfolio;