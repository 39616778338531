import React, { useRef, useState, useEffect } from 'react';
import styled from "styled-components";
import checkboxChecked from 'resources/main/common/black_checkbox_checked.svg'
import closeButton from "resources/main/main/temp/closeButton.svg";
import checkbox from 'resources/main/common/checkbox.svg';
import {emailReg,phoneReg} from 'components/regulation'
import { useForm } from "react-hook-form";
import FormError from "components/contact/FormError";
// import { ContactApi,siteSignApi } from 'api';
import swal from 'sweetalert';
import PartnerApplyModal from 'views/PartnerApplyModal';

const Contact = () => {

  const [formModal, setFormModal] = useState(false);
  
  // modal여닫기 toogle함수 - 
  const handleFindModal = () => {
    setFormModal(!formModal);
  }

  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // 스크롤
  const [scrollPosition, setScrollPosition] = useState(0);
  const [start, setStart] = useState(false);
  const updateScroll = () => {
      setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  }
  useEffect( ()=> {
      window.addEventListener('scroll', updateScroll);
  });

  useEffect( ()=> {
    if(mode === 'mobile') {
      if(scrollPosition>=2700) {
        setStart(true);
      } else {
        setStart(false);
      }
    } else {
      if(scrollPosition>=4600) {
        setStart(true);
      } else {
        setStart(false);
      }
    }
  }, [scrollPosition]);


  return (
    <Container id="contactForm">
      <Title>제휴 신청</Title>
      <Content>
        <Wrapper> 
          { mode === 'pc' ? <PcContent>
            <div>브리피는 다양한 분야에서 함께 발전할 수 있는 제안을 기다리고 있습니다.</div>
            <div>제휴에 대한 문의를 원하시는 분은, 아래 버튼을 클릭 하시고 제안서를 작성하여 주십시오.</div>
            <div>제휴내용에 따라 담당자에게 전달되며, 검토 후 제휴가 진행됩니다.</div>
          </PcContent>
          : mode === 'mobile' ? <MobileContent>
            <div>브리피는 다양한 분야에서 함께 발전할 수 있는 <br />제안을 기다리고 있습니다.</div>
            <div>제휴에 대한 문의를 원하시는 분은,<br /> 아래 버튼을 클릭 하시고 제안서를 작성하여 주십시오.</div>
            <div>제휴내용에 따라 담당자에게 전달되며, <br />검토 후 제휴가 진행됩니다.</div>
          </MobileContent> : ''
        }
        </Wrapper>
      </Content>
      <SubmitButton 
        onClick={()=> {setFormModal(!formModal)}}
        type="button" 
        margin={'0 auto'} 
        active={start===true} 
        text={"제휴신청하기"}
      />
        <PartnerApplyModal
          modal={formModal} // true/false 값을 통해 모달을 열건지 말건지 전달
          handleModal={handleFindModal} // 모달 여닫기 토글 함수 전달
        />
    </Container>
  )
}

const Container = styled.section`
  width:${({ theme }) => theme.style.desktopWidth};
  margin: 0px auto;
  padding-top: 128px;
  padding-bottom: 128px;
  
  // background-color: ${(props) => props.theme.bgColorWhite};
  background: #FFFFFF;

  @media ${({ theme }) => theme.device.mobile} {
    width:100%;
    margin: 58px auto;
    padding: 0px 16px;
  }
`;

const Title = styled.div`
  text-align: center;
  height: 48px;
  font-size: 40px;
  font-weight: 800;
  margin: 0 0 40px 0;
  @media ${({ theme }) => theme.device.mobile} {
    font-size:6.5vw;
    margin: 0 0 44px 0;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 71px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 14px;

  }
`;

const Wrapper = styled.div`
  div {
    margin-bottom: 10px;
  }
`;

const PcContent = styled.div`

`

const MobileContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
` 

const ContactForm = styled.form`
  width:608px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    width:100%;
    padding: 0 16px;
  }
`;

const InputField = styled.div`
  display:inline-block;
  width: 100%;
  background-color:white;
  &:not(:first-child) {
    margin-top: 15px;
  }
  

  label {
    line-height:1.6vw;
    color: black;
    font-weight: 600;
    margin-bottom: 10px;
    
    .highlight{
      color:#FE5A1D;
    }
  }
  input {
    padding: 0 0 0 10px;
    width: 100%;
    height:32px;
    outline: none;
    border-radius:5px;
    border: ${props=>props.hasError?'1px solid #FE5A1D':'1px solid #70707052'};
    font-family : "Apple SD Gothic", sans-serif;
    
    &::placeholder {
      color:#ACAAAC;
      font-weight:300;
    }
  }
  
  textarea {
    width: 100%;
    padding: 10px 0 0 10px;
    outline: none;
    border-radius:5px;
    border: 1px solid #70707052;
    resize:none;
    height: 100px;
    font-family : "Apple SD Gothic", sans-serif;
    &::placeholder {
      /* color:#c9c9c9e6; */
      color:#ACAAAC;
      font-weight:300;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    &:not(:first-child) {
      margin-top: 2.7vw;
    }
    label {
      font-size:3.8vw;
      line-height:6.5vw;
    }
    input {
      height:8.5vw;
      font-size:3vw;
    }
    textarea{
      font-size:3vw;
    }
  }
`;

const Check = styled.input.attrs({ type: 'checkbox' })`
  
`;

const PolicyField = styled.div`
  display: inline-block;
  width:100%;
  text-align:center;
  margin:65px 0 0 0;
  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon { 
      background-image: url(${checkboxChecked}); 
    }
  .check-icon  { 
    display: inline-block; 
    width: 20px;
    height: 20px; 
    margin: 0 10px 0 0;
    transform: translate3d(0, -7%, 0);
    background: url(${checkbox}) left center no-repeat; 
    border: 0.5px solid ${props=>props.hasError?'tomato':'none'};  
    vertical-align: middle; 
    transition-duration: .2s; 
  }

  .aTag {
    font-weight:800;
    text-decoration:underline !important;
  }
  
  label {
    font-weight: 300;
    .highlight{
      font-weight:800;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size:3.8vw;
    margin:16.5vw 0 0 0;
  }
`;

const ContactButtonWrapper = styled.div`
  text-align:center;
  margin-top: ${props=>props.mt};
  margin-bottom: ${props=>props.bt};
`;


const SubmitButton = styled.button`
  position:relative;
  display:block; 
  // width: 60%;
  width: 336px;
  margin: ${props=>props.margin===undefined?'0':props.margin};
  margin-top: 60px;
  line-height:49px;
  border: 3px solid #DBDBDB;
  cursor: pointer;
  text-align:center;
  background-color:white;
  color: #919191;   
  font-size: 22px;
  font-weight: 600;
  border-radius: 25px;
  box-shadow: inset 5px 4px 15px 1px #D9D9D9;
  &::before{
    content:'';
    position:absolute;
    top:1px;
    left:0;
    height:51px;
    width:${props=>props.active?'100%':'51px'};
    background-color: black;
    border-radius:26px; 
    transition: all 0.7s cubic-bezier(.74,.55,.24,1.05);
    z-index:1;
  }
  &::after{
    content:'${props=>props.text}';
    color: ${props=>props.active?'white':'#919191'};
    position:relative;
    font-family: 'Metropolis', sans-serif;
    width:100%;
    height:100%;
    z-index:2;
    transition: all 0.3s ease-in-out;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size:4.8vw;
    line-height:10.8vw;
    width: 80%;
    border-radius: 9vw;
    border: .6vw solid #DBDBDB;
    &::before{
      content:'';
      position:absolute;
      top:3px;
      left:0;
      height:10.67vw;
      width:${props=>props.active?'100%':'10.67vw'};
      background-color: black;
      border-radius:10vw; 
      transition: all 0.7s cubic-bezier(.74,.55,.24,1.05);
      z-index:1;
    }
  }

`;

export default Contact;