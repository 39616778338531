import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getResponseList } from './store/action';
import { handleAlertText } from 'components/common/SweetAlert';
import { convertNumberFormat, isObjEmpty } from 'utils/util';
import ContactModal from './ContactModal';
import RecatchBasicForm from 'views/RecatchForm/BasicForm';

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(state => state.quotation);

  // 응답 정보
  const [responseList, setResponseList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0); // 총 금액

  // 견적문의 슬랙 발송 중복 방지
  const [sendSlackYn, setSendSlackYn] = useState(false)
  const [viewQuality, setViewQuality] = useState('2'); // 견적서 화면에서 보여지는 품질구분코드

  // slack 채널
  const contactWebHookURL =  `${process.env.REACT_APP_SLACK_QUOTATION_WEBHOOK_URL}`;
  
  // 문의하기 모달 팝업
  // re:catch 폼으로 대체
  // const [contactModal, setContactModal] = useState(false);

  // re:catch Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }
  
  // 견적서에 표시할 응답 목록
  const [newResponseList, setNewResponseList] = useState([]);

  // 슬랙 발송
  const sendSlackContact = async() => {
    let responseInfo = '';

    store.responseList?.map(item => {
      if (item.qttDspNm) {
        responseInfo += `${item.qttDspNm} : ${item.qustItmCtnt}`
      } else {
        responseInfo += `> ${item.qustItmCtnt}`
      }
      responseInfo += `${!isObjEmpty(item.sbjtRespCtnt) ? `(${item.sbjtRespCtnt})` : '' }`
      responseInfo += `${Number(item.amt) ? ` [${convertNumberFormat(item.amt, 'Y')}원]` : ''}\n`
    });

    let totAmt = 0;
    store.amountList.map((item) => {
      if (item.qltyDvcd === store.quotationInfo.qltyDvcd) {
        if (!isObjEmpty(item.sbjtRespCtnt)) {
          totAmt += Number(item.sbjtRespCtnt) * Number(item.amt);
        } else {
          totAmt += Number(item.amt);
        }
      }
    });
    
    const template = {
      "blocks": [
        { 
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*────🔔 견적문의(무료촬영견적) 🔔 ────*"
          }
        }, 
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*📌견적내용📌*\n*견적번호* : ${store.quotationInfo.freQttSeqno}\n*구분* : ${store.quotationInfo.qttCtgrNm} \n*견적타입* : ${store.quotationInfo.qltyDvnm}\n*견적상세내용* :\n${responseInfo}\n*견적안내금액* : ${convertNumberFormat(totAmt, 'Y')}원\n`
          }
        },
        {
          "type": "divider"
        }, 
      ]
    }

    setSendSlackYn(true)
    await axios.post(contactWebHookURL, JSON.stringify(template));
  } 
  
  const handleContactModal = () => {
    // 무료 견적 정보 슬랙 발송, [상세견적 문의하기] 버튼 최초 눌렀을 때만 발송
    if (!sendSlackYn) {
      sendSlackContact();
    }
    // re:catch Form modal 오픈
    handleViewerModal();
    // setContactModal(!contactModal);
  };

  /**
   * 품질 버튼 클릭
   * @param {string} type 품질구분코드 1:최저가/2:합리적/3:럭셔리
   */
  const clickPriceType = (type) => {
    setViewQuality(type);
    calculate(type);
  }

  /**
   * 총 금액 계산
   * @param {string} type 
   */
  const calculate = (type) => {
    let totAmt = 0;

    store.amountList.map((item) => {
      if (item.qltyDvcd === type) {
        if (item.sbjtYn === 'Y' && !isObjEmpty(item.sbjtRespCtnt)) {
          totAmt += Number(item.sbjtRespCtnt) * Number(item.amt);
        } else {
          totAmt += Number(item.amt);
        }
      }
    });
    
    setTotalAmount(totAmt);
  }

  useEffect(() => {
    if (!isObjEmpty(responseList)) {
      let tempArray = [];
      // 응답 목록 중 다중선택인 항목에 대하여 하나로 합친다.

      let seqNo;
      responseList.forEach((respInfo, i) => {
        if (seqNo !== respInfo.qustSeqno) {
          tempArray.push(respInfo);
        } else {
          respInfo.qttDspNm = '';
          tempArray.push(respInfo);
        }

        seqNo = respInfo.qustSeqno;

      });

      setNewResponseList(tempArray);
    }
  }, [responseList]);

  useEffect(() => {
    setResponseList(store.responseList);
  }, [store.responseList]);

  useEffect(() => {
    if (!isObjEmpty(store.quotationInfo)) {
      clickPriceType(store.quotationInfo.qltyDvcd);
    }

  }, [store.quotationInfo]);

  useEffect(() => {
    const qttId = localStorage.getItem('qttId');
    if (!isObjEmpty(qttId)) {
      dispatch(getResponseList(qttId));
    }
  }, []);

  useEffect(() => {
    if (!location.state) {
      handleAlertText('접근 불가!', '정상적인 접근경로가 아닙니다.', 'warning').then(() => {
        navigate('/quotation');
      });
    }
    navigate('.', { replace: true });
  }, [navigate]);

  return (
    <Container>
      <ContainerT>
        <Title>예상 견적 결과</Title>
        <ResultContainer>
          <QuotationContainer>
            {newResponseList && newResponseList.map((res, index) => {
              return (
                <QuotationWrapper key={index}>
                  <div className='title'>{res.qttDspNm}</div>
                  <div className='ctnt'>
                    {res.qustItmCtnt}
                    {!isObjEmpty(res.sbjtRespCtnt) ? ` (${res.sbjtRespCtnt})` : ''}
                  </div>
                </QuotationWrapper>
              )
            })} 
          </QuotationContainer>
          <TypeWrapper>
            <TypeButton active={viewQuality === '1'} onClick={() => clickPriceType('1')}>최저가</TypeButton>
            <TypeButton active={viewQuality === '2'} onClick={() => clickPriceType('2')}>합리적</TypeButton>
            <TypeButton active={viewQuality === '3'} onClick={() => clickPriceType('3')}>럭셔리</TypeButton>
          </TypeWrapper>
          { viewQuality === '1' ? <TypeDesc>최소한의 퀄리티로 가성비 있게 제작합니다</TypeDesc> :
            viewQuality === '2' ? <TypeDesc>적당한 가격에 평균 이상의 퀄리티로 제작합니다</TypeDesc> :
            viewQuality === '3' ? <TypeDesc>가격에 상관없이 최고의 퀄리티로 제작합니다</TypeDesc> : '' }
          <ResultWrapper>
            <div className='txt'>총 견적(VAT)포함</div>
            <div className='prc'>{convertNumberFormat(totalAmount, 'Y')} 원</div>
            <div className='desc'>예상 견적이므로 자세한 상담을 위하여 상세견적 문의하기 버튼을 클릭하여 연락처를 남겨주세요</div>
          </ResultWrapper>
          <ButtonWrapper>
            <Button type='button' onClick={() => handleContactModal()}>상세견적 문의하기</Button>
          </ButtonWrapper>
        </ResultContainer>
      </ContainerT>
      {/* <ContactModal 
        open={contactModal}
        handleModal={handleContactModal}
      /> */}
      <RecatchBasicForm 
        open={viewerModal}
        handleModal={handleViewerModal}
      />
    </Container>
  )
}

export default Result;

const Container = styled.div`
  margin: 0 auto 180px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 62px);

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0 auto 200px;
    padding: 0 16px;
  }
`

const ContainerT = styled.div`
  margin: 0 auto;
  width: 500px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const Title = styled.div`
  width: 100%;
  margin: 120px auto 40px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 77px 0 27px;
    font-size: 20px;
    line-height: 24px;
  }
`

const ResultContainer = styled.div`
`

const QuotationContainer = styled.div`
  min-height: 300px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #FAFAFA;
`

const QuotationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // text-align: center;

  .title {
    min-width: 200px;
    height: 30px;
    padding: 8px;
    font-weight: 700;

    @media ${({ theme }) => theme.device.mobile} {
      min-width: 150px;
      padding: 5px;
    }
  }
  
  .ctnt {
    min-width: 150px;
    height: 30px;
    padding: 8px;

    @media ${({ theme }) => theme.device.mobile} {
      padding: 5px;
    }
  }
`

const TypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`

const TypeButton = styled.div`
  width: 80px;
  height: 25px;
  text-align: center;
  padding: 6px;
  border-radius: 10px;
  font-weight: ${props => props.active ? 500 : 300};
  color: ${props => props.active ? '#FFFFFF' : '#2C2C2C'};
  background-color: ${props => props.active ? '#2C2C2C' : '#FAFAFA'};
  cursor: pointer;
  transition: all 0.1s linear;

  & + & {
    margin-left: 5px;
  }
`

const TypeDesc = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
`

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;

  .txt {
  }

  .prc {
    margin: 10px 0 20px;
    color: #FE5A1D;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
  }

  .desc {
    font-size: 12px;
    line-height: 14px;
  }
`

const ButtonWrapper = styled.div`
  display: block;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background: #FFFFFF;
  text-align: center;
  text-align: -webkit-center;
  margin-top: 50px;
  padding: 10px 0;
  
  @media ${({ theme }) => theme.device.mobile} {
    padding: 6px 0;
  }
`

const Button = styled.div`
  width: 100%;
  height: 54px;
  text-align: center;
  padding: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #4B4B4B;
  border-radius: 5px;
  cursor: pointer;
`