import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getMainPortfolioList, insertHitItem } from 'views/Portfolio/store/action';
import VideoViewerModal from 'views/Portfolio/VideoViewerModal';

import NoImage from 'resources/common/no_image_video.png';

const PortfolioVideo = () => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.portfolio);
  const navigate = useNavigate();

  const [videoList , setVideoList] = useState([]);

  // Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  const [currentVideoInfo, setCurrentVideoInfo] = useState();

  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  };

  // 더보기 버튼
  const clickMoreButton = () => {
    const elem = {
      ctgrId: `${process.env.REACT_APP_VIDEO_CATEGORY_ID}`
    }

    navigate('/portfolio', {state: elem});
  }

  // 클릭 시 모달 호출
  const clickVideo = (item) => {
    // 로그
    dispatch(insertHitItem(item.ptfoSeqno));

    // 뷰어 호출
    setCurrentVideoInfo(item);
    handleViewerModal();
  }

  // 이미지 없을 경우 대체 이미지 설정
  const handleImageError = (e) => {
    e.target.src = NoImage;
  }

  useEffect(() => {
    // 포트폴리오 목록 조회
    const elem = {
      videoCtgrId: `${process.env.REACT_APP_VIDEO_CATEGORY_ID}`,
      photoCtgrId: `${process.env.REACT_APP_PHOTO_CATEGORY_ID}`,
    }
    dispatch(getMainPortfolioList(elem));
  }, []);

  useEffect(() => {
    setVideoList(store.videoList);
  }, [store.videoList]);

  return (
    <Container>
      <PortfolioContainer>
        <Title>영상 포트폴리오</Title>
        <MoreButton onClick={() => clickMoreButton()}>더보기 ▶</MoreButton>
        <ItemContainer>
          { videoList && videoList.map((item, index) => {
            return (
              <ItemWrapper key={item.ptfoSeqno} onClick={() => clickVideo(item)}>
                <ThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} />
                <PlayTime>{item.playTm}</PlayTime>
              </ItemWrapper>
            )
          })}
          { videoList && videoList.map((item, index) => {
            if (index < 6) {
              return (
                <MobileItemWrapper key={item.ptfoSeqno} onClick={() => clickVideo(item)}>
                  <ThumbnailMobile src={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} onError={handleImageError} />
                  <PlayTime>{item.playTm}</PlayTime>
                </MobileItemWrapper>
              )
            }
          })}
        </ItemContainer>
      </PortfolioContainer>
      <VideoViewerModal 
        open={viewerModal}
        handleModal={handleViewerModal}
        videoInfo={currentVideoInfo}
      />
    </Container>
  )
}

export default PortfolioVideo;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.device.mobile} {
    padding: 0 16px;
  };
`

const PortfolioContainer = styled.div`
  width: 1088px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto 160px;

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto 90px;
  };
` 

const Title = styled.div`
  width: 100%;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  @media ${({theme}) => theme.device.mobile} {
    font-size: 20px;
    line-height: 24px;
  };
`

const MoreButton = styled.div`
  width: 80px;
  height: 30px;
  padding: 7px; 
  margin-left: auto;
  margin-top: 6px;
  text-align: center;
  background: #F5F5F5;
  color: #4B4B4B;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 15px;
  cursor: pointer;

  @media ${({theme}) => theme.device.mobile} {
    height: 16px;
    text-align: right;
    padding: 0;
    background: #FFFFFF;
    color: #ABABAC;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
  };
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  gap: 20px 22px;
  margin: 20px 0;

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin: 10px 0 0;
    gap: 10px;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  cursor: pointer;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const MobileItemWrapper = styled.div`
  display: none;
  
  @media ${({theme}) => theme.device.mobile} {
    display: block;
    position: relative;
    width: 48%;
    cursor: pointer;
  }
`

const ThumbNail = styled.div`
  width: 255px;
  height: 144px;
  aspect-ratio: 16 / 9;
  background: url(${props  => props.image}), url(${NoImage}) no-repeat 50% 50%;
  background-size: cover, contain;
  border-radius: 6px;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const ThumbnailMobile = styled.img`
  display: none;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
    max-width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 6px; 
  };
`

const PlayTime = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
  margin: 10px;
  padding: 3px 4px;
  color: #FFFFFF;
  background: rgba(75, 75, 75, 0.8);
  border-radius: 3px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  @media ${({theme}) => theme.device.mobile} {
    margin: 5px;
  }
`