import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';

import { isObjEmpty } from 'utils/util';
import { handleAlertText } from 'components/common/SweetAlert';

import CloseIcon from 'resources/common/close_icon.png';
import NoImage from 'resources/common/no_image_video.png';
import LinkIcon from "resources/common/link.svg";


const VideoViewerModal = ({open, handleModal, videoInfo}) => {

  /**
   * 선택된 포트폴리오 팝업창을 바로 띄울 수 있는 link 정보를 클립보드에 copy
   */
  const linkCopy = () => {
    const url = `${process.env.REACT_APP_BASE_FRONT_URL}/portfolio?ctgrId=`.concat(videoInfo?.ctgrId, '&ptfoNo=', videoInfo?.ptfoSeqno)
    
    window.navigator.clipboard.writeText(url).then(() => {
      handleAlertText('복사 완료', '접속 URL 복사가 완료되었습니다.', 'info')
    })
  }

  return (
    <Modal
      isOpen={open}
      toggle={handleModal}
      className='modal-dialog-centered'
      backdrop='static'
      style={{maxWidth: 1000}}
    >
      <ModalBody>
        <Header>
          <div></div>
          <CloseButton onClick={handleModal} />
        </Header>
        <Body>
          <VideoWrapper>
            <Video>
              { !isObjEmpty(videoInfo) && videoInfo.viewLnkUrl ? 
                <iframe src={`${videoInfo.viewLnkUrl}?badge=0&autopause=0&player_id=0&autoplay=0&loop=0&muted=1`} width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                : <img src={NoImage} />
              }
              {/* background=1 컨트롤러 숨기기 (이것만 하면 autoplay=1 loop=1 자동) */}
              {/* <iframe src="https://player.vimeo.com/video/643009855?badge=0&autopause=0&player_id=0&autoplay=1&loop=1" width="640" height="360" allow="autoplay; fullscreen; picture-in-picture"></iframe> */}
            </Video>
            <MobileVideo>
              { !isObjEmpty(videoInfo) && videoInfo.viewLnkUrl ? 
                <iframe src={`${videoInfo.viewLnkUrl}?badge=0&autopause=0&player_id=0&autoplay=0&loop=0&muted=1`} width="56.25%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                : <img src={NoImage} />
              }
            </MobileVideo>
            <TagWrapper>
              { videoInfo?.tagList?.map((tag, i) => {
                return (
                  <Tag key={i}>{tag.tagNm}</Tag>
                )
              })}
            </TagWrapper>
          </VideoWrapper>
          <ContentWrapper>
            <Title>{videoInfo?.ptfoNm}</Title>
            <SubDescription>
              {videoInfo?.ptfoDesc}
            </SubDescription>
            <InfoWrapper>
              <Row>
                <ContentTitle>클라이언트</ContentTitle>
                <ContentData>{ videoInfo?.clntNm ? videoInfo?.clntNm : '-' }</ContentData>
              </Row>
              <Row>
                <ContentTitle>제작사</ContentTitle>
                <ContentData>{ videoInfo?.prdnStdoNm ? videoInfo?.prdnStdoNm : '-' }</ContentData>
              </Row>
              <Row>
                <ContentTitle>제작 기간</ContentTitle>
                <ContentData>{ videoInfo?.prdnPerd ? videoInfo?.prdnPerd : '-' }</ContentData>
              </Row>
              <Row>
                <ContentTitle>적정 제작비</ContentTitle>
                <ContentData className='price'>{ videoInfo?.prdnCost ? videoInfo?.prdnCost : '-' }</ContentData>
              </Row>
            </InfoWrapper>
            <LinkCopy><a href='#' onClick={() => linkCopy()}><CopyBox><img height={20} src={LinkIcon} alt={"link copy"} /></CopyBox></a></LinkCopy>
          </ContentWrapper>
        </Body>
      </ModalBody>
    </Modal>
  )
}

export default VideoViewerModal;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ACAAAC;
`

const CloseButton = styled.button`
  height: 15px;
  width: 15px;
  margin: 5px 7px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }
`

const Body = styled.div`
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 640px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`
  
const Video = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
  
const MobileVideo = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: relative; 
    padding-bottom: 56.25%;
    height: 100%;
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`

const TagWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 7px;
  gap: 5px;
`

const Tag = styled.div`
  padding: 6px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ABABAC;
  background: #F5F5F5;
  border-radius: 3px;
`
  
const ContentWrapper = styled.div`
  width: 100%;
  padding-left: 12px;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 8px 0 0;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
`

const SubDescription = styled.div`
  min-height: 150px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin-top: 8px;
  color: #ABABAC;

  @media ${({theme}) => theme.device.mobile} {
    min-height: auto;
    margin-bottom: 0px;
  }
`

const InfoWrapper = styled.div`
  margin: 32px 0 0;
  padding: 15px 20px;
  border-radius: 8px;
  background: #F5F5F5;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 20px 0;
  }
`

const CopyBox = styled.div`
  padding : 0 3px
`

const LinkCopy = styled.div`
  height: 33px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  .price {
    font-weight: 700;
  }
  
  & + & {
    margin-top: 13px;
  }
`

const ContentTitle = styled.div`
  line-height: 19px;
  color: #ABABAC;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
`

const ContentData = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`