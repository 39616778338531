import styled, { keyframes } from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { portfolioApi } from 'utils/api';
import FormError from 'components/common/FormError';
import { strictPhoneReg } from 'components/regulation';
import { handleAlertText } from 'components/common/SweetAlert';
import RecatchBasicForm from 'views/RecatchForm/BasicForm';

import X from 'resources/common/close_icon20.svg';
import CheckboxUnChecked from 'resources/common/checkbox.png';
import CheckboxChecked from 'resources/common/checkbox_checked.png';

const MobileContactPopup = () => {
  const navigate = useNavigate();

  // 문의 팝업 토글
  // const [clickToggle, setClickToggle]  = useState(true);

  // re:catch Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  // 개인정보 제공 동의
  // const [agree, setAgree] = useState(false);

  // slack 채널
  const contactWebHookURL =  `${process.env.REACT_APP_SLACK_CLIENT_WEBHOOK_URL}`;

  const InputSchema = yup.object().shape({
    name: yup
      .string()
      .required('필수 입력 값입니다'),
    hpNo: yup
    .string()
    .required('필수 입력 값입니다')
    .matches(strictPhoneReg, '전화번호 형식에 맞게 입력해주세요'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    trigger, 
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(InputSchema)
  });

  const clickContactIcon = () => {
    handleViewerModal();
    // setClickToggle(!clickToggle);
    // setValue('name', '');
    // setValue('hpNo', '');
    // clearErrors();
  }

  // 슬랙 발송
  const sendSlackContact = async(contactInfo) => {
    const template = {
      "blocks": [
        { 
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*────🔔 촬영문의(포트폴리오) 🔔 ────*"
          }
        }, 
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*이름* : ${contactInfo.enqPerNm}\n*휴대폰번호* : ${contactInfo.cntcTlNo}`
          }
        },
        {
          "type": "divider"
        }, 
      ]
    }
    await axios.post(contactWebHookURL, JSON.stringify(template));
  } 

  // 문의 등록
  // const onSubmit = (data) => {
  //   if (!agree) {
  //     handleAlertText('', '개인정보 제공에 동의해주시기 바랍니다', 'warning');
  //     return;
  //   }

  //   const formElem = {
  //     enqDvcd: 3, // 포폴문의
  //     enqPerNm: data.name,
  //     cntcTlNo: data.hpNo.replaceAll('-', '')
  //   }

  //   const contactInfo = {
  //     enqPerNm: data.name,
  //     cntcTlNo: data.hpNo
  //   }

  //   portfolioApi.insertContact(formElem).then((response) => {
  //     navigate('/portfolio/contact');
  //     sendSlackContact(contactInfo);
  //   });
  // }

  return (
    <Container>
      <PopupContainer>
        <PopupButtonWrapper>
          <PopupButton onClick={() => clickContactIcon()}>문의하기</PopupButton>
        </PopupButtonWrapper>
      </PopupContainer>
      {/* { clickToggle ? 
        <>
          <PopupContainer onSubmit={handleSubmit(onSubmit)}>
            <Header onClick={() => clickContactIcon()}>
              <CloseIcon src={X} />
            </Header>
            <InputContainer>
              <InputWrapper>
                <InputLabel htmlFor='name'>이름<span>*</span></InputLabel>
                <div className='wr'>
                  <Input id='name' name='name' placeholder='이름을 입력해주세요' {...register('name')} />
                  <FormError message={errors.name?.message} />
                </div>
              </InputWrapper>
              <InputWrapper>
                <InputLabel>휴대폰<span>*</span></InputLabel>
                <div className='wr'>
                  <Controller 
                    id='hpNo'
                    name='hpNo'
                    control={control}
                    render={({ field: {ref, ...field}}) => 
                    <NumberFormat {...field} 
                      type='text'
                      htmlFor='hpNo'
                      placeholder='휴대폰 번호를 입력해주세요'
                      format='###-####-####'
                      mask='_'
                      innerRef={ref}
                      customInput={Input}
                    />}
                  />
                  <FormError message={errors.hpNo?.message} />
                </div>
              </InputWrapper>
              <CheckWrapper>
                <Check 
                  id='agreeM' 
                  name='agreeM'
                  onChange={() => setAgree(!agree)}
                /> 
                <label htmlFor='agreeM'>개인정보 제공에 동의합니다</label>
              </CheckWrapper>
              <SubmitButton>문의하기</SubmitButton>
            </InputContainer>
          </PopupContainer>
        </>
        :
        <>
          <PopupButtonWrapper>
            <PopupButton onClick={() => setClickToggle(!clickToggle)}>문의하기</PopupButton>
          </PopupButtonWrapper>
        </>
      } */}
      <RecatchBasicForm 
        open={viewerModal}
        handleModal={handleViewerModal}
      />
    </Container>
  )
}

export default MobileContactPopup;

const Container = styled.div`
  display: none;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    width: 100%;
  }
`

const popupKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`

const PopupContainer = styled.form`
  width: 100%;
  padding: 10px 16px;

  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  animation: ${popupKeyframes} 0.2s ease-in-out;
`

const Header = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
`

const CloseIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: rotate(90deg);
  } 
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #FE5A1D;
  }

  .wr {
    width: 100%;
  }

  & + & {
    margin-top: 15px;
  }
`
const InputLabel = styled.label`
  width: 65px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const Input = styled.input`
  width: 100%;
  height: 48px;
  padding: 15px 8px;
  font-weight: 200;
  font-size: 15px;
  line-height: 18px;
  border: none;
  background: #F5F5F5;
  border-radius: 5px;

  ::placeholder {
    color: #ABABAC;
  }
`

const PopupButtonWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 6px 16px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background: #FFFFFF;
`

const PopupButton = styled.div`
  display: block;
  width: 100%;
  height: 54px;
  text-align: center;

  padding: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #4B4B4B;
  border-radius: 5px;
`


const Check = styled.input.attrs({ type: 'checkbox'})``;
const CheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 16px;
    height: 16px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  height: 54px;
  text-align: center;
  margin-top: 21px;

  padding: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #FE5A1D;
  border-radius: 5px;
`