import styled from 'styled-components';

const Quotation = () => {
  return (
    <Container>
      <QuotationContainer>
        <TextWrapper>
          지금 무료로 촬영견적을 받아보세요
        </TextWrapper>
      </QuotationContainer>
    </Container>
  )
}

export default Quotation;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  background-color: #2C2C2C;

  @media ${({theme}) => theme.device.mobile} {
    padding: 0 16px;
  };
`

const QuotationContainer = styled.div`
  width: 100%;
  padding: 152px 0 95px;
  text-align: center;

  @media ${({theme}) => theme.device.mobile} {
    padding: 60px 0 74px;
  };
`

const TextWrapper = styled.div`
  color: #FFFFFF;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;

  @media ${({theme}) => theme.device.mobile} {
    font-size: 18px;
    line-height: 20px;
  };
`