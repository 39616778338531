const initialState = {
  studioMainPortfolioList: []
}

const studioPortfolio = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STUDIO_MAIN_PORTFOLIO_LIST' : 
      return {
        ...state,
        studioMainPortfolioList: action.data.portfolioList
      }
    default:
      return {...state}
  }
}

export default studioPortfolio;