import Helmet from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const ThirdParty = () => {

  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <MainContainer>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 개인정보 제3자 제공동의</title>
        </Helmet>
      </HelmetProvider>
      { mode === 'pc' ? 
        <Container>
          <MenuTitle>개인정보 제3자 제공동의</MenuTitle>
          <Wrapper>
              <Title>개인정보의 제3자 제공에 관한 사항</Title>
            <Content>
              <TopContent>
                <TopContentMainLine>주식회사 브리피는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</TopContentMainLine>

                <TopFirstContent>
                  <FirstContentTitle>1. 촬영이 배정된 브리피 제휴 사진작가·영상제작사</FirstContentTitle>
                  <ContentUl>
                    <ContentLi>개인정보를 제공받는 자: 촬영이 배정된 브리피 제휴 사진작가·영상제작사</ContentLi>
                    <ContentLi>제공받는 자의 개인정보 이용목적: 이메일, 휴대전화번호, 이름, 촬영 장소(주소)</ContentLi>
                    <ContentLi>제공받는 자의 보유 · 이용기간: 서비스 제공 완료시까지</ContentLi>
                  </ContentUl>				
                </TopFirstContent>

                <TopSecondContent>
                  <FirstContentTitle>2. 토스페이먼츠(주) </FirstContentTitle>
                  <ContentUl>
                    <ContentLi>개인정보를 제공받는 자: 토스페이먼츠(주)</ContentLi>
                    <ContentLi><div style={{marginLeft:'15px', marginTop:'-13px'}}>제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 이름, 신용카드정보, 은행계좌정보, 결제기록</div></ContentLi>
                    <ContentLi>제공받는 자의 보유 · 이용기간:  5년	</ContentLi>
                    <ContentLi>관련법령 <br />
                      <span> (1) 대금결제 및 재화 등의 공급에 관한 기록: 5년</span> <br />
                      <span> (2) 계약 또는 청약철회 등에 관한 기록: 5년</span>
                    </ContentLi>
                  </ContentUl>
                </TopSecondContent>
              </TopContent>
            </Content>
              <SecondTitle>개인정보처리의 위탁에 관한 사항</SecondTitle>
              <Content>
              <BottomContent>
                <BottomContentMainLine>
                  주식회사 브리피는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                </BottomContentMainLine>

                <BottomFirstContent>
                  <ContentUl>
                    <div>결제대행 업무를 위한 개인정보처리의 위탁 </div>					
                    <ContentLi>위탁받는 자 (수탁자): 토스페이먼츠㈜	</ContentLi>							
                    <ContentLi>위탁하는 업무의 내용: 구매 및 요금 결제, 본인인증(금융거래, 금융서비스)</ContentLi>
                    <ContentLi>위탁기간: 2022년 8월 1일부터 서비스 종료 시까지</ContentLi>
                  </ContentUl>
                </BottomFirstContent>
              </BottomContent>
            </Content>
          </Wrapper>
        </Container> : mode === 'mobile' ? <Container>
          <MenuTitle>개인정보 제3자 제공동의</MenuTitle>
          <Wrapper>
              <Title>개인정보의 제3자 제공에 관한 사항</Title>
            <Content>
              <TopContent>
                <TopContentMainLine>주식회사 브리피는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</TopContentMainLine>

                <TopFirstContent>
                  <FirstContentTitle>1. 촬영이 배정된 브리피 제휴 사진작가·영상제작사</FirstContentTitle>
                  <div>
                    <span>개인정보를 제공받는 자: 촬영이 배정된 브리피 제휴 사진작가·영상제작사</span>
                    <span>제공받는 자의 개인정보 이용목적: 이메일, 휴대전화번호, 이름, 촬영 장소(주소)</span>
                    <span>제공받는 자의 보유 · 이용기간: 서비스 제공 완료시까지</span>
                  </div>				
                </TopFirstContent>

                <TopSecondContent>
                  <FirstContentTitle>2. 토스페이먼츠(주) </FirstContentTitle>
                  <div>
                    <span>개인정보를 제공받는 자: 토스페이먼츠(주)</span> <br />
                    <span>제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 이름, 신용카드정보, 은행계좌정보, 결제기록</span> <br />
                    <span>제공받는 자의 보유 · 이용기간:  5년	</span>
                    <div>관련법령 
                      <span> (1) 대금결제 및 재화 등의 공급에 관한 기록: 5년</span> <br />
                      <div style={{marginLeft:'44px'}}> (2) 계약 또는 청약철회 등에 관한 기록: 5년</div>
                    </div>
                  </div>
                </TopSecondContent>
              </TopContent>
            </Content>
              <SecondTitle>개인정보처리의 위탁에 관한 사항</SecondTitle>
              <Content>
              <BottomContent>
                <BottomContentMainLine>
                  주식회사 브리피는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                </BottomContentMainLine>

                <BottomFirstContent>
                  <div>
                    <div>1. 결제대행 업무를 위한 개인정보처리의 위탁 </div>					
                    <span>위탁받는 자 (수탁자): 토스페이먼츠㈜	</span><br />				
                    <span>위탁하는 업무의 내용: 구매 및 요금 결제, 본인인증(금융거래, 금융서비스)</span> <br />
                    <span>위탁기간: 2022년 8월 1일부터 서비스 종료 시까지</span>
                  </div>
                </BottomFirstContent>
              </BottomContent>
            </Content>
          </Wrapper>
        </Container> : ''
      }
    </MainContainer>
  )
}

export default ThirdParty;


const MainContainer = styled.div`  
  display: flex;
  position: relative;                      
  margin: 0 auto;
  width: 100%;
  background: white;
`

const Container = styled.div`
  width: 100%;
  margin: 0px auto;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100vh;
    margin: 20px auto;
    padding: 0 16px;
  }
`

const MenuTitle = styled.div`
  margin: 15px auto 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0px auto 31px;
    font-size: 20px;
    line-height: 24px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  background: #F5F5F5;
  padding: 15px 80px;
  margin-bottom: 40px;

  @media ${({ theme }) => theme.device.mobile} {
    background: white;
    padding: 0px;
    margin-bottom: 0px;
  }
`

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
    line-height: 19px;
  }
`

const SecondTitle = styled(Title)`
  margin-top: 20px;
`

const Content = styled.div`
  height: fit-content;
  margin-top: 10px;
  padding: 8px 12px 8px 10px;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  line-height: 14px;
  background-color: white;
  border-radius: 5px;


  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    white-space: pre-line;
    margin-top: 3px;
    padding: 10px;
    background: #F5F5F5;

  }
`

const TopContent = styled.div`

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const TopFirstContent = styled.div`
  padding 0px 10px;
  margin-top: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`

const TopContentMainLine = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 15px;
  }
`

const FirstContentTitle = styled.div`

`

const TopSecondContent = styled.div`
  margin-top: 10px;
  padding 0px 10px;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
    margin-top: 15px;
  }

`

const ContentUl = styled.ul`
  li + li {
    margin-top: 2px;
  }
`

const ContentLi = styled.li`
  ::before {
    content: "•"; 
    display: inline-block; 
    width: 1em;
    margin-left: 3px;
  }

  span {
    margin-left: 15px;
  }
`

const BottomContent = styled.div`
    div {
      width: 100%;
    }
    div + div {
      margin-top: 5px;
    }
`

const BottomContentMainLine = styled(TopContentMainLine)`
`

const BottomFirstContent = styled(TopFirstContent)`
  padding: 0;
`


