import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import HeroMainImage from 'resources/studio/hero_studio_main.png';
import HeroMainImage2 from 'resources/main/herosection/heroImage.png';
// import './App.css';

const HeroSectionMobile = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeroContainer2, setShowHeroContainer2] = useState(false);

  const maxImageScale = 3.5; // 이미지의 최대 확대 비율
  // const minImageWidth = window.innerWidth/3.5; // 이미지 최소 너비
  // const minImageHeight = window.innerHeight/3.5; // 이미지 최소 높이
  const minImageWidth = 544; // 이미지 최소 너비
  const minImageHeight = 306; // 이미지 최소 높이

  const maxImageHeight = window.innerHeight;
 
  const imageScale = 1 + (scrollPosition / minImageHeight) * (maxImageScale - 2.2);

  // Hero Section 하단 메시지
  const HeroMessageLine1 = [
    { id:'mobileHeroMessage1', text:'광고주는'},
    { id:'mobileHeroMessage2', text:'광고가'},
    { id:'mobileHeroMessage3', text:'시작부터'}]; 
  const HeroMessageLine2 = [
    { id:'mobileHeroMessage4', text:'끝까지'}, 
    { id:'mobileHeroMessage5', text:'읽히기를'}, 
    { id:'mobileHeroMessage6', text:'바랍니다.'}];
  const HeroMessageLine3 = [
    { id:'mobileHeroMessage7', text:'대중은'},
    { id:'mobileHeroMessage8', text:'모든 광고를'},
    { id:'mobileHeroMessage9', text:'함부로'}]; 
  const HeroMessageLine4 = [
    { id:'mobileHeroMessage10', text:'성의 없이'}, 
    { id:'mobileHeroMessage11', text:'소비합니다.'}];
  const HeroMessageLine5 = [
    { id:'mobileHeroMessage12', text:'이'},
    { id:'mobileHeroMessage13', text:'간극을'},
    { id:'mobileHeroMessage14', text:'해결하는'}]; 
  const HeroMessageLine6 = [
    { id:'mobileHeroMessage15', text:'방법이'}, 
    { id:'mobileHeroMessage16', text:'있습니다.'}];
  const HeroMessageLine7 = [
    { id:'mobileHeroMessage17', text:'설명하지'},
    { id:'mobileHeroMessage18', text:'않고'}]; 
  const HeroMessageLine8 = [
    { id:'mobileHeroMessage19', text:'보여주면'}, 
    { id:'mobileHeroMessage20', text:'됩니다.'}];

  // const opacityOffset = 460;
  const opacityOffset = 200;
  const defaultOpacity = 0.1;
  const distanceOffset = 10;

  /**
   * scroll 감지 useEffect
   * 스크롤 위치값 저장
   */
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /**
   * 스크롤 변화에 따른 이미지 확대 또는 이미지 스크롤링 처리
   */
  useEffect(() => {
 
    if(scrollPosition < maxImageHeight*3 + opacityOffset) {
      const opacityInterval = maxImageHeight/40;
      for (let i = 1; i < 21; i++) {
        if (scrollPosition > opacityInterval * i + opacityOffset + i * distanceOffset) {
          document.getElementById('mobileHeroMessage'.concat(i)).style.opacity = 1;
        } else {
          document.getElementById('mobileHeroMessage'.concat(i)).style.opacity = defaultOpacity;
        }
      }
    }
  }, [scrollPosition])

  useEffect(() => {
    if (imageScale >= maxImageScale) {
      setShowHeroContainer2(true);
    } else {
      setShowHeroContainer2(false);
    }
  }, [imageScale, maxImageScale]);

  const imageStyle = {
    width: `${minImageWidth}px`,
    height: `${minImageHeight}px`,
    marginTop: '70px',
    transform: showHeroContainer2 ? 'scale(0px, 0px)' : `scale(${imageScale > maxImageScale ? maxImageScale : imageScale})`,
    transition: 'transform 0.3s ease' // 부드러운 전환 효과
  };

  return (
    <Container>
      {/* <HeroContainer style={{ display: showHeroContainer2 ? 'none' : 'flex' }}> */}

      <HeroContainerWrapper id='MobileHeroImage'>
        <HeroContainer>
          <HeroImageContent>
          {/* <div className="hero-section" style={containerStyle}> */}
            <HeroTextWrapper id='MobileHeroTextHead'>
            <HeroTextHead>
              STUDIO
            </HeroTextHead>
            <HeroTextMiddle>
              BRIPHY
            </HeroTextMiddle>
            </HeroTextWrapper>
            {/* <HeroImageWrapper>
              <img src={HeroMainImage} style={imageStyle} alt="hero-image" />
            </HeroImageWrapper> */}
            <HeroTextBottomWrapper>
              <HeroTextBottom key={'02'}>
                <pre>설명하지 않고<br/>보여주면 됩니다.</pre>
              </HeroTextBottom>
            </HeroTextBottomWrapper>
          {/* </div> */}
          </HeroImageContent>
        </HeroContainer>
        <KeyMessageContainer>
          {/* <HeroContainer style={{ display: showHeroContainer2 ? 'none' : 'flex' }}> */}
          <HeroBottomContainer id='MobileHeroBottomMessage'>
            <HeroMessageLineWrapper>
              {HeroMessageLine1.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine2.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine3.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine4.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineMargin></HeroMessageLineMargin>
            <HeroMessageLineWrapper>
              {HeroMessageLine5.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine6.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine7.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              {HeroMessageLine8.map(item => {
                return (<HeroMessageLineItem key={item.id} id={item.id}>{item.text}</HeroMessageLineItem>)
              })}
            </HeroMessageLineWrapper>
            {/* <HeroMessageLineWrapper>
              <HeroMessageLineItem key={'blank1'}>-</HeroMessageLineItem>
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              <HeroMessageLineItem key={'blank2'}>-</HeroMessageLineItem>
            </HeroMessageLineWrapper>
            <HeroMessageLineWrapper>
              <HeroMessageLineItem key={'blank3'}>-</HeroMessageLineItem>
            </HeroMessageLineWrapper> */}
          </HeroBottomContainer>
        </KeyMessageContainer>
      </HeroContainerWrapper>
    </Container>
  );
}

export default HeroSectionMobile;

const Container = styled.div`
  display: none;
  // position: relative;
  // display: contents;
  // height: 2100px;
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
  }
`

const HeroContainerWrapper = styled.div`
  translate: none;
  rotate: none;
  scale: none;
  margin: 0px;
  // max-width: 1747px;
  // width: 1747px;
  max-height: 0px;
  height: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  display: grid;
  justify-items: center;
  min-height: 500px;
  max-height: 1300px;
`

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeroImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeroTextWrapper = styled.div`
  // z-index:10;
  opacity: 1;
  width: 279px;
  margin-top: 198px;
  gap: 8px;
  opacity: 0px;

`

const HeroTextHead = styled.div`
  width: 279px;
  font-family: Prompt;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1.6em;
  text-align: center;

  margint: 0px;
  color: ${props => props.theme.txtWhite };
`

const HeroTextMiddle = styled.div`
  width: 279px;
  font-family: Prompt;
  font-size: 54px;
  font-weight: 700;
  line-height: 81.65px;
  letter-spacing: 0.3em;
  text-align: center;

  color: ${props => props.theme.txtWhite };

`

const HeroTextBottomWrapper = styled.div`
  margin-top: 90px;
  margin-bottom: 151px;
`

const HeroTextBottom = styled.div`
  // width: 489px;
  // height: 40px;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 45px;
  text-align: center;

  color: ${props => props.theme.txtWhite };

`

const KeyMessageContainer = styled.div`
  margin-top: 250px;
  margin-bottom: 100px;
  // height: 544px;
`

const HeroBottomContainer = styled.div`
  width: 100%;
  // height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

const HeroMessageLineWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeroMessageLineItem = styled.div`
  margin-right: 10px;
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 58px;
  text-align: center;
  opacity: 0.1;

  color: ${props => props.theme.txtWhite };
`

const HeroMessageLineMargin = styled.div`
  margin-top: 40px;
`