import Helmet from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';

const MarketingTerms = () => {
  return (
    <MainContainer>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 광고성 정보 수신 및 마케팅 활용 동의</title>
        </Helmet>
      </HelmetProvider>
      <Container>
        <MenuTitle>광고성 정보 수신 및 마케팅 활용 동의</MenuTitle>
        <Content>
          {`1. 수집 및 처리 목적: 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용
              에 대한 통계 등

            2. 처리 및 보유 기간 : 동의일로부터 회원 탈퇴 시 또는 처리·보유에 관한 거부 의사를 표시한 때까지		

            3. 필수항목 : 이메일, 휴대전화번호, 이름, 서비스 이용 기록, 접속 로그, 접속 IP 정보, 결제기록	`}
        </Content>
      </Container>
    </MainContainer>
  )
}

export default MarketingTerms;


const MainContainer = styled.div`  
  display: flex;
  position: relative;
  margin: 0 auto;
`

const Container = styled.div`
  width: 1088px;
  min-height: calc(100vh - 62px);
  margin: 100px auto;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin: 20px auto;
    width: 100%;
    padding: 0 16px;
  }
`

const MenuTitle = styled.div`
  margin: 30px auto;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`

const Content = styled.pre`
  height: fit-content;
  margin-top: 3px;
  margin-bottom: 30px;
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  // line-height: ${props => props.lh ? `${props.lh}px` : '14px'};
  line-height: 14.4px;
  white-space: pre-line;
  background-color: ${props => props.theme.bgColorGray};
`