import styled, { keyframes } from 'styled-components';
// import { useScrollFadeIn } from 'hooks/useScrollFadeIn';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ButtonArrow from 'resources/studio/ic_arrow.png';

const WithClients = () => {
  const images = require.context('resources/studio/clients/202406', true);
  const clients = images.keys().map(image => images(image));

  const [mode, setMode] = useState('pc');
  // animation 속도 관리(건수에 따라 속도가 달라지는 현상 보정), default : 33s
  const [animationSpeed, setAnimationSpeed] = useState('45s');
  // 마우스 오버 시 롤링 stop 처리를 위한 변수
  const [isRolling, setIsRolling] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // PC/Mobile 화면 Resize
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode('mobile');
    } else {
      setMode('pc');
    }
  };

  useEffect(() => {
    setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|mobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
  }, [])

  useEffect(()=> {
    setIsMobile(!(window.innerWidth > 740));
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (clients) {
      if (isMobile) {
        setAnimationSpeed((clients.length * 2.3) + 's');
      } else {
        setAnimationSpeed((clients.length * 3.0) + 's');
      }
    }
  }, [clients])

  return (
    <Container>
      <ClientsContainer>
          <TopTitle>CLIENT</TopTitle>
          <Title>브리피와 함께하는 고객사입니다</Title>
          <CountWrapper>
            <CountItemWrapper>
              <InnerTitle>고객사</InnerTitle>
              <InnerContentLeft>100+</InnerContentLeft>
            </CountItemWrapper>
            <CountItemWrapper>
              <InnerTitle>프로젝트</InnerTitle>
              <InnerContentRight>15K+</InnerContentRight>
            </CountItemWrapper>
          </CountWrapper>
          {/* <ImageBox><img src={ClientLogo} /></ImageBox> */}
          <ClientViewPort className='viewport'>
            <CleintWrapper key='original' className={'wrapper original'.concat(isRolling ? '' : ' stop')} animationSpeed={animationSpeed}>
              { clients.map((item, index) => {
                return (
              <CleintItem key={index}>
                {isMobile ? (
                  // <img src={item} width={80} height={30}/>
                  <img src={item} style={{maxWidth:'140px', maxHeight:'70px'}} />
                ) : (
                  // <img src={item} width={160} />
                  <img src={item} style={{maxWidth:'280px', maxHeight:'140px'}} />
                )}
              </CleintItem>
                )
              })}
            </CleintWrapper>
            <CleintWrapper key='clone' className={'wrapper clone'.concat(isRolling ? '' : ' stop')} animationSpeed={animationSpeed}>
            { clients.map((item, index) => {
                return (
              <CleintItem key={index}>
                {isMobile ? (
                  <img src={item} style={{maxWidth:'140px', maxHeight:'70px'}} />
                ) : (
                  <img src={item} style={{maxWidth:'280px', maxHeight:'140px'}} />
                )}
              </CleintItem>
                )
              })}
            </CleintWrapper>
          </ClientViewPort>
          {/* <MobileImageBox><img src={MobileClientLogo} /></MobileImageBox> */}
          {/* <ViewMoreWrapper>
            <Link to={'/studio/clients'}>
              <ViewMoreButton>
                <ViewMoreText>View More</ViewMoreText>
                <ViewMoreArrow><img src={ButtonArrow}/></ViewMoreArrow>
              </ViewMoreButton>
            </Link>
          </ViewMoreWrapper> */}
      </ClientsContainer>
    </Container>
  )
}

export default WithClients;

const Container = styled.div`
  display: flex;
  justify-content: center;
  // width: 100vw;
  // margin: 0 auto;
  background-color: #F8F8F8;
  color : #212121;
  overflow: hidden;
`

const ClientsContainer = styled.div`
  padding: 120px 0px 40px 0px;
  background: #F8F8F8;
  display: grid;
  justify-items: center;
  overflow: hidden;
  img {
    opacity: 0.9;
  }

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    // height: 738px;
    margin: 0;
    // padding: 80px 16px;
  }
`

const TopTitle = styled.div`
  font-family: Prompt;
  font-size: 60px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 40px;
`

const Title = styled.div`
  // margin-bottom: 80px;
  // text-align: center;
  // color: #212121;
  // font-family: Prompt;
  // font-size: 48px;
  // font-weight: 700;
  // line-height: 48px;

  margin-bottom: 100px;
  font-family: Pretendard;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;


  @media ${({theme}) => theme.device.mobile} {
    width: 245px;
    word-break: keep-all;
    margin-bottom: 60px;
    font-family: Pretendard;
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
  }
`

const CountWrapper = styled.div`
  display: flex;
  // justify-content: center;
  justify-content: space-between;
  width: 725px;
  height: 164px;
  gap: 80px;
  opacity: 0px;
  margin-bottom: 120px;

  @media ${({theme}) => theme.device.mobile} {
    width: 95%;
    height: 104px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 48px;
    gap: 20px;
  }
`

const CountItemWrapper = styled.div`
  width: 350px;
  height: 164px;
  gap: 24px;
  opacity: 0px;
  
  @media ${({theme}) => theme.device.mobile} {
    width: 170px;
    height: 104px;
    gap: 0px;
  }
`

const InnerTitle = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`

const InnerContentLeft = styled.div`
  font-family: Prompt;
  font-size: 110px;
  font-weight: 600;
  line-height: 120px;
  text-align: center;


  @media ${({theme}) => theme.device.mobile} {
    font-size: 55px;
    font-weight: 600;
    line-height: 55px;
    margin-top: 24px;
  }
`

const InnerContentRight = styled.div`
  font-family: Prompt;
  font-size: 110px;
  font-weight: 600;
  line-height: 120px;
  text-align: center;

  @media ${({theme}) => theme.device.mobile} {
    font-size: 55px;
    font-weight: 600;
    line-height: 55px;
    margin-top: 24px;
  }
`

const ImageBox = styled.div`
  width: 100%;
  height: 120px;
  text-align: center;
  margin-bottom: 80px;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  }
`

const MobileImageBox = styled(ImageBox)`
  display: none;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
    height: 759px;
  }
`

const ViewMoreWrapper = styled.div`
  width: 180px;
  hegith: 56px;
  border-radius: 28px 28px 28px 28px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #212121
`

const ViewMoreButton = styled.div`
  display: grid;

  width: 180px;
  height: 56px;
  gap: 0px;

  cursor: pointer;
`

const ViewMoreText = styled.div`
  position: relative;
  width: 85px;
  height: 16px;
  top: 20px;
  left: 24px;
  gap: 0px;
  opacity: 0px;

  font-family: Prompt;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;

`

const ViewMoreArrow = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  top: -8px;
  left: 133px;
  gap: 0px;
  opacity: 0px;
`

const originalReview = keyframes`
  0% { transform: translateX(0%); }
	50% { transform: translateX(-100%); }
	50.01% { transform: translateX(100%); }
	100% { transform: translateX(0%); }
`;

const cloneReview = keyframes`
  0% { transform: translateX(0%); }
	100% { transform: translateX(-200%); }
`;

const ClientViewPort = styled.div`
  display: flex;
  margin: 0 50px 0 50px;
  width: 100%;
  overflow: hidden;
  margin-bottom : 118px;
  ::before {
    z-index: 1;
    width: 80px;
    // height: 300px;
    // position: absolute;
    // top: 308px;
    left: 0;
    content:'';
    background: linear-gradient(to right, #F5F5F5, #F5F5F500);
  }
  
  ::after {
    z-index: 1;
    width: 80px;
    // height: 300px;
    // position: absolute;
    // top: 308px;
    right: 0;
    content:'';
    background: linear-gradient(to right,  #F5F5F500, #F5F5F5);
  }

  @media ${({theme}) => theme.device.mobile} {
    // display: none;
    margin: 0 0 60px 0px;
  }
`;

const CleintWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  // gap: 24px;
  margin-right: 24px;

  &.original {
    animation: ${originalReview} ${props => props.animationSpeed} linear 0s infinite normal forwards running ;
  }

  &.clone {
    animation: ${cloneReview} ${props => props.animationSpeed} linear 0s infinite normal none running ;
  }

  &.stop {
    animation-play-state: paused;
  }

  @media ${({theme}) => theme.device.mobile} {
    // gap: 24px;
  }
`;

const CleintItem = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: center;
  width: 280px;
  // height: 300px;
  // padding: 30px;
  // margin-right: 30px;
  // border-radius: 10px;
  // background: #FFFFFF;
  // box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);


  @media ${({theme}) => theme.device.mobile} {
    width: 140px;
    margin-right: 0;
    // padding: 30px 34px;
  }
`