import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import KakaoLogin from '../SocialLogin/KaKaoLogin';
import checkboxChecked from 'resources/common/checkbox_checked.png';
import checkbox from 'resources/common/checkbox.png';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { memberApi, mypageApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import jwt_decode from 'jwt-decode';
import FormError from 'components/common/FormError';
import FindModal from './FindModal.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { idReg, pwReg } from 'components/regulation';
import { getGuestData, isGuestLoggedIn } from 'utils/jwtUtil';

const Login = () => {
  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [remember, setRemember] = useState({});
  const [findType, setFindType] = useState('');
  const [formModal, setFormModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [mode, setMode] = useState("pc");

   // 회원/ 비회원 tab 상태 관리
   const [memberClicked, setMemberClicked] = useState(true);
   const [guestClicked, setGuestClicked] = useState(false);
 
   const MemberHandler = () => {
     setMemberClicked(true);
     setGuestClicked(false);
   }
 
   const GuestHandler = () => {
     setMemberClicked(false);
     setGuestClicked(true);
   }

  // 가입회원(Member) Schema
  const memberSchema = yup.object().shape({
    id: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( idReg, '아이디는 영문 대소문자와 숫자 4~12자리로 입력해주세요.'),
    pw: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
      .matches( pwReg, '비밀번호는 숫자/영문자/특수문자 조합으로 8~15자리 입력해주세요.'),
  });
  
  // 비회원(Guest) Schema
  const guestSchema = yup.object().shape({
    ordName: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다'),
    ordNum: yup
      .string()
      .required('⚠ 필수로 입력하셔야 합니다')
  });

  // 회원/비회원 tab에 따라 yup schema 다르게 세팅하기
  const [schema, setSchema] = useState();
  useEffect(()=>{
    if (memberClicked) {
      setSchema(memberSchema);
    } else if(guestClicked) {
      setSchema(guestSchema);
    }
  }, [memberClicked, guestClicked]);

  const { 
    setValue, 
    handleSubmit, 
    register, 
    formState:{errors} 
  } = useForm ({
    resolver: yupResolver(schema)
  });
  
 
  const onSubmit = async(data) => {
    // 가입회원 - 로그인버튼 클릭 시
    if (memberClicked) { 

      const userInfo = {
        loginId : data.id,
        loginPwd : data.pw
      }
      
      if (check === true) { // 아이디 기억하기를 클릭 했을 경우
        localStorage.setItem('savingID', JSON.stringify(data.id));
      } else {
        localStorage.removeItem('savingID');
      }
      
      const res = await memberApi.login(userInfo).then( response => {
        if (response.data === '451') {
          handleAlertText('로그인 오류!', '가입되지 않은 ID 입니다.', 'error');
          return false;
        } else if (response.data === '452') {
          handleAlertText('비밀번호 오류!', '잘못된 비밀번호입니다.', 'error');
          return false;
        } else if (response.data === '453') {
          handleAlertText('로그인 오류!', '존재하지 않는 회원입니다.', 'error');
          return false;
        } else { // 정상적으로 로그인 성공 했을 경우
          const userData = jwt_decode(response.data); 
          localStorage.setItem('briphyMarketplaceUser', JSON.stringify(userData));
          localStorage.setItem('accessToken', response.data); 
          
          navigate( '/' ); // 모두 성공적으로 진행 됐을 시 '홈' 페이지로 이동
        }
      }, error => { 
        handleAlertText('서버오류!', '서버에 에러가 생겼습니다', 'error');
      })
    } else if (guestClicked) {
      const guestInfo = {
        ordPerNm : data.ordName,
        ordId : data.ordNum
      }
      memberApi.guestLogin(guestInfo).then(response=>{ // 비회원 로그인
        const decodedResponseData = jwt_decode(response.data); 
        if (decodedResponseData) {
          localStorage.setItem('briphyMarketplaceGuest', JSON.stringify(decodedResponseData));
          localStorage.setItem('accessToken', response.data); 
          navigate('/mypage');
        } 
      }).catch(error => {
          handleAlertText('오류!', '해당 주문 내역이 존재하지 않습니다', 'error');
      });
    }

  }
  
  // 아이디찾기 혹은 비밀번호 찾기 클릭 시 타입 결정
  const callFindModal = (type) => {
    if (mode === "pc") {
    setFindType(type);
    setFormModal(!formModal); 
  } else if (mode === "mobile") {
    // navigate(새로운 모바일용 find id/pw 파일)
    setFindType(type);
    navigate('/mobileFind', {state:{type:type}});
  }

    // 모바일일 경우 페이지 전환 - navigation을 통해서
  }

  // toogle함수 - modal여닫기
  const handleFindModal = () => {
    setFormModal(!formModal);
  }

  // 계정이 없을 경우 회원가입 페이지로 이동
  const noAccountHandler = () => {
    navigate('/join');
  }

  // 창 크기
  const [width, setWidth] = useState(window.innerWidth);
  
  const handleResize = () => {  
    setWidth(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  // 아이디 기억하기 세팅
  useEffect(()=>{
    if(document.querySelector(`input[name=rememberMe]`).checked){
      setCheck(true)
    } else {
      setCheck(false)
    }
  },[check])

  // 로컬 스토리지에서 저장된 아이디 불러오기 + 모바일용, 웹 용 화면 크기 세팅
  useEffect(() => {
    if( getGuestData()) { // 로컬스토리지에 비회원정보가 남아있을 경우(5분정도) 바로 비회원 주문내역으로 이동
      navigate('/mypage');
    }

    const savedID = JSON.parse(localStorage.getItem('savingID'));
    if (memberClicked) {
      if( savedID ) {
        setValue('id', savedID);
        setId(savedID);
        document.querySelector(`input[name=rememberMe]`).checked = true; 
        setCheck(true);
      } 
        // setValue('Mobileid', savedID);
        // MobilesetId(savedID)
    } else if (guestClicked) {
      setValue('ordName','');
      setValue('ordNum', '');
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 로그인</title>
        </Helmet>
      </HelmetProvider>

      <Container>
        <Wrapper>
          <ContentBox>
          <div className='mainTitle'> 로그인 </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Tab>
            <Member className={memberClicked ? 'focused':''} onClick={MemberHandler}> <span>가입 회원</span> </Member>
            <Guest className={guestClicked ? 'focused':''} onClick={GuestHandler}> <span>비회원 주문 조회</span> </Guest>
          </Tab>

          { memberClicked ? <>
            <InputWrapper>
              <MemberInput 
                type='text' 
                id='id' 
                name='id' 
                placeholder='아이디를 입력해주세요'
                {...register('id')}
              />
              <FormError message={errors.id?.message}/>
            
              <MemberInput
                type='password' 
                id='pw' 
                name='pw' 
                placeholder='비밀번호를 입력해주세요'
                {...register('pw')}
              />
              <FormError message={errors.pw?.message}/>
            </InputWrapper>
            <PolicyField>
              <RememberIdContainer>
                <label htmlFor='rememberMe'>
                  <Check 
                    id='rememberMe' 
                    name='rememberMe'
                    onClick={ 
                      e => setRemember(e.target.value)}
                    onChange={ 
                      e => setCheck(check=>!check)}
                  />
                  <i className = 'check-icon'></i>
                  { mode === "pc" ?
                      <RememberIdText>
                        아이디 기억하기
                      </RememberIdText> :
                    mode === "mobile" ? 
                      <MobileRememberIdText>
                        아이디 기억하기
                      </MobileRememberIdText> : ""
                  }
                  </label>
                </RememberIdContainer>
                <FindModalButtonContainer>
                  <FindModalButton type="button">
                    <FindIdText onClick={() => callFindModal('ID')}>
                      아이디 찾기
                    </FindIdText>
                    <Bar> |  </Bar>
                    <FindPWText onClick={() => callFindModal('PW')}>
                      비밀번호 찾기
                    </FindPWText>
                  </FindModalButton>
                </FindModalButtonContainer>
            </PolicyField>
            <SubmitButton 
              type='submit' 
              value= {'로그인'}
            />
              <KakaoLogin />
              <AskAccountWrapper>
                <AskAccount onClick={noAccountHandler}> 
                  계정이 없으신가요? <span>회원가입</span>
                </AskAccount> 
              </AskAccountWrapper> 
              </> : guestClicked ? <>
               <InputWrapper>
                <GuestInput 
                  type='text' 
                  id='ordName' 
                  name="ordName"
                  placeholder='주문자명을 입력해주세요'
                  {...register('ordName')}
                />
                <FormError message={errors.ordName?.message}/>
                <GuestInput
                  id='ordNum' 
                  name="ordNum"
                  placeholder='주문번호를 입력해주세요'
                  {...register('ordNum')}
                />
               <FormError message={errors.ordNum?.message}/>
             </InputWrapper>
             <GuestSubmitButton 
               type='submit' 
               value= {'주문 내역 조회하기'}
             /> </>
             : ""
            }
            </form>
            </ContentBox>
        </Wrapper>
          <FindModal 
            modal={formModal} // true/false 값을 통해 모달을 열건지 말건지 전달
            handleModal={handleFindModal} // 모달 여닫기 토글 함수 전달
            type={findType} // 아이디찾기를 클릭했는지 비밀번호 찾기를 클릭했는지 전달
          />
      </Container>
    </>
  )
}

export default Login;

const Container = styled.div`
  width: 100%;
  height: 1300px;
  display: flex;
  position: relative;
  flex-direction: column;
`

const ContentBox = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    max-width: 428px;
    margin: 0 auto;
  }
`

const Wrapper = styled.div`
  width: 477px;
  height: 438px;
  margin: 200px auto 100px;

  .mainTitle { 
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    width: 100%;
    margin: 0px auto 50px;
    text-align: center;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 0px 16px 0px 16px;
    margin-top: 95px;
    
    .mainTitle {
      margin: 0px auto 45px;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
    }
  }
`
const Tab = styled.div`
  width : 100%;
  display: flex;

  .focused {
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1.5px solid #2C2C2C;
    color: #2C2C2C;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .focused {
      font-size: 15px;
    }
  }
`
const Member = styled.div`
  width: 50%;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ABABAC;
  color: #D9D9D9;
  cursor: pointer;

  span {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-weight: 200;
    font-size: 15px;
    line-height: 18px;

    span {
      margin-bottom: 5px;
    }
  }
`

const Guest = styled(Member)`
`

const InputWrapper = styled.div`
  margin-top: 30px;

  #pw, #ordNum {
    margin-top: 15px;
  }

  // chrome에서 자동입력/자동채우기 시 자동으로 input의 background-color 바꾸는 것 막기
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 20px;
  }
`
const MemberInput = styled.input`
  border: none;
  background-color: ${(props) => props.theme.bgColorGray};
  width: 100%;
  height: 54px;
  padding: 6px 18px;
  border-radius: 5px;

  ::placeholder {
    color: #ABABAC;
    font-weight: 300;
    font-size: 16px;
    position: relative;
    top: 1px;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: 48px;
    ::placeholder {
      font-weight: 200;
      font-size: 15px;
      line-height: 18px;
    }
  }
`

const GuestInput = styled(MemberInput)`
`

const Check = styled.input.attrs( { type: 'checkbox' } )``;

const PolicyField = styled.div`
  color: #ABABAC;
  display: flex;
  width: 100%;
  margin-top: 10px;
  font-weight: 300;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;


  ${Check} {
    display: none;
  }

  ${Check}:checked + .check-icon {
    background-image: url(${checkboxChecked});
    background-size: 105%;
    border: none;
  }

  .check-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 3px 10px 0 0;
    transform: translate3d(0, -7%, 0);
    background-image: url(${checkbox});
    background-size: 100%;
    vertical-align: middle;
    transition-duration: .2s;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .check-icon {
      width: 14px;
      height: 14px;
      margin: 0px 4px 0 0;
    }
  }
`

const RememberIdContainer = styled.div`
  label {
    display: flex;
    align-items: center;
    height: 17px;
    width: 100%;
  }
`

const RememberIdText = styled.div`
`

const MobileRememberIdText = styled.div`
`

const FindModalButtonContainer = styled.div`
`
  
const FindModalButton = styled.button`
  display: flex;
  background-color: white;
  border: none;
  color: #ABABAC;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  padding: 1px 0px;
`

const FindIdText = styled.div`
`

const FindPWText = styled.div`
`

const Bar = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const SubmitButton = styled.input`
  width: 100%;
  height: 54px;
  border: none;
  border-radius: 5px;
  background-color: #FE5A1D;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  opacity: ${props=>props.disabled ? '0.5' : '1'};
  margin-top: 54px;
  margin-bottom: 15px;
  padding-top: 2px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 18px;
    line-height: 22px;
    margin-top: 36px;
    margin-bottom: 12px;
  }
`

const GuestSubmitButton = styled(SubmitButton)`
  margin-top: 50px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 60px;
  }
`

const AskAccountWrapper = styled.div`
  width: 100%;
  height: 21px;
  margin-top: 15px; 
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 10px; 
  }
`

const AskAccount = styled.button`
  color: #ABABAC;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: white;
  border: none;

  span {
    font-weight: 700;
    color: #FE5A1D;
  }

  @media ${({ theme }) => theme.device.mobile} {
    // margin-left: 135px;
    font-size: 14px;
    line-height: 17px; 
  }
`

