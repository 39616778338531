import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { convertNumberFormat, isObjEmpty } from 'utils/util';
import { handleAlertText } from 'components/common/SweetAlert';

import { deleteResponseInfo, getCategoryList, getQuestionList, insertQuotationInfo, insertResponseInfo, updateQuotationInfo } from './store/action';

import CheckboxUnChecked from 'resources/common/checkbox.png';
import CheckboxChecked from 'resources/common/checkbox_orange_checked.svg';
import RadioButton from 'resources/common/radio.svg';
import CheckedRadioButton from 'resources/common/radio_checked.svg';
import NoImage from 'resources/common/no_image_video.png';
import QuestionBlue from 'resources/product/question_blue.svg';

const Step = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector(state => state.quotation);

  const [categoryList, setCategoryList] = useState([]); // 카테고리 목록
  const [qualityCodeList, setQualityCodeList] = useState([]); // 품질구분코드 목록
  
  // 첫 질문 체크 여부(카테고리 선택)
  const [isCheckedCategory, setIsCheckedCategory] = useState(false);
  
  // 두 번째 질문 체크 여부(품질 선택)
  const [isCheckedPrice, setIsCheckedPrice] = useState(false);

  const [currentCategoryId, setCurrentCategoryId] = useState(); // 선택한 카테고리ID
  const [currentQuality, setCurrentQuality] = useState(); // 선택한 품질구분코드

  const [rowNum, setRowNum] = useState(1); // 문항 번호
  const [questionList, setQuestionList] = useState(); // 질문 목록
  const [questionItemList, setQuestionItemList] = useState(); // 질문 항목(선택지) 목록

  const [stepList, setStepList] = useState([]); // 응답한 목록
  const [responseList, setResponseList] = useState([]); // 답변 목록
  const [totalAmount, setTotalAmount] = useState(0); // 총 견적금액
  
  const [type, setType] = useState(); // 버튼 타입

  const { setValue, register } = useForm();

  /**
   * 카테고리 선택
   * @param {Object} item 카테고리 정보
   */
  const clickCategory = (item) => {
    localStorage.removeItem('qttId');

    setCurrentCategoryId(item.qttCtgrId);
    setIsCheckedCategory(true);
    
    const elem = {
      qttCtgrId: item.qttCtgrId
    }
    
    dispatch(insertQuotationInfo(elem));
  }

  /**
   * 품질 선택
   * @param {Object} item 품질구분코드 정보
   */
  const clickQuality = (item) => {
    setCurrentQuality(item.instCd);
    setIsCheckedPrice(true);

    const elem = {
      freQttSeqno: localStorage.getItem('qttId'),
      qltyDvcd:item.instCd
    }
    
    dispatch(updateQuotationInfo(elem));
    dispatch(getQuestionList(currentCategoryId));
    
  }

  /**
   * 문항 항목 클릭(문항 답변) - 라디오
   * @param {Object} question 문항 정보
   * @param {Object} item 문항 항목 정보
   * @param {number} index index
   */
  const clickQuestionItem = (question, item, index) => {
    const input = document.querySelector(`#subopt${item.qustSeqno}_${item.qustItmNo}`);

    let sbjtRespCtnt;
    if (input) {
      if (input.value) {
        sbjtRespCtnt = input.value;
      } else {
        if (item.sbjtYn === 'Y') {
          sbjtRespCtnt = 0;
        }
      }
    }
    
    const resElem = {
      freQttSeqno: localStorage.getItem('qttId'),
      qustSeqno: item.qustSeqno,
      qustItmNo: item.qustItmNo,
      qustItmCtnt: item.qustItmCtnt,
      qttDspNm: item.qttDspNm,
      amt: item.sbjtYn === 'Y' && input.value ? Number(input.value) * Number(item.amt) : item.amt,
      briphyAmt: item.briphyAmt,
      rnum: question.rnum,
      sbjtYn: item.sbjtYn,
      sbjtRespCtnt: sbjtRespCtnt,
      tagDspYn: item.tagDspYn,
      rowNum: rowNum,
    }

    // 답변 목록 세팅
    const tempArray = responseList.filter(prev => prev.qustSeqno !== item.qustSeqno);
    
    tempArray.push(resElem);
    setResponseList(tempArray);

  }

  /**
   * 문항 항목 클릭(문항 답변) - 체크박스
   * @param {Object} question 문항 정보
   * @param {Object} item 문항 항목 정보
   */
  const clickQuestionMultiItem = (question, item) => {
    let tempArray = [];

    const option = document.querySelector(`#opt${item.qustSeqno}_${item.qustItmNo}`);
    const input = document.querySelector(`#subopt${item.qustSeqno}_${item.qustItmNo}`);

    let amt = 0;
    if (item.sbjtYn === 'Y') {
      if (!isObjEmpty(input)) {
        amt = Number(input.value) * Number(item.amt);
      } 
    } else {
      amt = item.amt;
    }

    let sbjtRespCtnt;

    if (!isObjEmpty(input)) {
      if (input.value) {
        sbjtRespCtnt = input.value;
      } else {
        if (item.sbjtYn === 'Y') {
          sbjtRespCtnt = 0;
        }
      }
    }

    const resElem = {
      freQttSeqno: localStorage.getItem('qttId'),
      qustSeqno: item.qustSeqno,
      qustItmNo: item.qustItmNo,
      qustItmCtnt: item.qustItmCtnt,
      qttDspNm: item.qttDspNm,
      amt: amt,
      briphyAmt: item.briphyAmt,
      rnum: question.rnum,
      multiChcYn: item.multiChcYn,
      sbjtYn: item.sbjtYn,
      sbjtRespCtnt: sbjtRespCtnt,
      tagDspYn: item.tagDspYn,
      rowNum: rowNum,
    }

    if (option.checked) {
      tempArray = responseList.filter(prev => !(prev.qustSeqno === item.qustSeqno && prev.qustItmNo === item.qustItmNo));

      if (item.sbjtYn === 'Y') {
        if (!isObjEmpty(input)) { // 값 체크
        }
      }
        
      tempArray.push(resElem);
      setResponseList(tempArray);

      // dispatch(insertResponseInfo(resElem));
    } else {
      // 체크 해제
      tempArray = responseList.filter(prev => !(prev.qustSeqno === item.qustSeqno && prev.qustItmNo === item.qustItmNo));
      setResponseList(tempArray);
    }
  }
  
  /**
   * 단일선택(라디오) 슬라이더 클릭 시 동작하는 함수
   * @param {Object} question 문항정보
   * @param {Object} item 문항항목정보
   * @param {number} e 슬라이더 값
   */
  const clickRadioSlider = (question, item, e) => {
    const input = document.querySelector(`#subopt${item.qustSeqno}_${item.qustItmNo}`);

    input.value = e;

    clickQuestionItem(question, item);
  }
  
  /**
   * 다중선택(체크박스) 슬라이더 클릭 시 동작하는 함수
   * @param {Object} question 문항정보
   * @param {Object} item 문항항목정보
   * @param {number} e 슬라이더 값
   */
  const clickCheckboxSlider = (question, item, e) => {
    const option = document.querySelector(`#opt${item.qustSeqno}_${item.qustItmNo}`);
    const input = document.querySelector(`#subopt${item.qustSeqno}_${item.qustItmNo}`);

    input.value = e;

    if (Number(e)) {
      option.checked = true;
    } else {
      option.checked = false;
      input.value = 0;
    }

    clickQuestionMultiItem(question, item);
  }

  /**
   * 선행 문항 체크
   */
  const checkPreQuestion = () => {
    let number = rowNum;

    let isPreResp = false;

    // 문항정보
    const currentQuestion = questionList.filter(prev => prev.rnum === number.toString())[0];

    if (!isObjEmpty(currentQuestion)) {
      // 선행문항 정보에 내가 응답한 문항 및 문항항목(선택지)가 있는지 체크한다.
      const preQustList = currentQuestion.preQustList;

      if (!isObjEmpty(preQustList)) {
        isPreResp = preQustList.some((prev) => responseList.find(resp => resp.qustSeqno === prev.preQustSeqno && resp.qustItmNo === prev.preQustItmNo));

        if (!isPreResp) {
          if (type === 'NEXT') {
            number = number + 1;
          } else {
            number = number > 1 ? number - 1 : number;
          }
        }
        
        setRowNum(number);
      } 
    } 
  }

  /**
   * 버튼 클릭 시 이전 또는 다음 스텝 이동
   * @param {string} button 버튼 이전(PREV)/다음(NEXT)
   * @returns 
   */
  const clickStepButton = (button) => {
    let number = rowNum; // 문제 번호

    // 현재 문항 정보
    const question = questionList.filter(prev => prev.rnum === number.toString())[0];
    const nextQuestion = questionList.filter(prev => prev.rnum === (number + 1).toString())[0];

    if (!isObjEmpty(question)) {
      if (question.reqmYn === 'Y') {
        // 버튼 클릭 시 문항 선택 안했으면 필수 값 체크
        if (button === 'NEXT' && isObjEmpty(responseList.filter(prev => Number(prev.rnum) === rowNum))) {
          handleAlertText('', '항목을 선택해주세요', 'warning');
          return;
        }
      }
    }

    if (isObjEmpty(nextQuestion)) {
      // 견적서 페이지로 이동
      const elem = {
        freQttSeqno: localStorage.getItem('qttId'),
      }
      navigate('/quotation/result', {state: elem});
    }
    
    // rowNum(문제번호) 변경
    if (button === 'NEXT') {
      number = rowNum + 1;
    } else {
      number = rowNum > 1 ? rowNum - 1 : rowNum;
    }

    setType(button);
    setRowNum(number);

    // 견적 금액 설정
    let totAmt = 0;
    responseList.forEach((item) => {
      totAmt += Number(item.amt);
    });

    setTotalAmount(totAmt);

    // 응답 등록
    let responseArray = responseList;
    responseArray = responseArray.filter(prev => prev.qustSeqno === question.qustSeqno);
    
    const resElem = {
      freQttSeqno: localStorage.getItem('qttId'),
      qustSeqno: question.qustSeqno,
      responseList: responseArray
    }

    // 답변한 번호 목록
    let step = stepList.filter(prev => prev !== question.qustSeqno);
    
    
    if (button === 'NEXT') { // 다음 버튼 
      if (responseList.some(x => x.qustSeqno === question.qustSeqno)) {
        step.push(question.qustSeqno);
        setStepList(step);
      }

      dispatch(insertResponseInfo(resElem));
    } else if (button === 'PREV') { // 이전 버튼
      let resArr = responseList;
      resArr = responseList.filter(prev => prev.qustSeqno !== question.qustSeqno);

      setStepList(step);
      setResponseList(resArr);
      dispatch(deleteResponseInfo(resElem));
    }
  }

  useEffect(() => {
    // 번호가 바뀔 때마다 문항을 검증한다.
    if (!isObjEmpty(questionList)) {
      checkPreQuestion();
      
      // 현재 문항 정보
      const question = questionList.filter(prev => prev.rnum === rowNum.toString())[0];
      
      // 현재 문항에 응답정보가 있다면 체크한다. 
      const responseQuestion = responseList.filter(prev => prev.qustSeqno === question.qustSeqno);
      if (!isObjEmpty(responseQuestion)) {
        let optionId;
        let subOptionId;
        let option;
        let subOption;
        
        responseQuestion.map((resp) => {
          // 객관식 답
          optionId = `#opt${resp.qustSeqno}_${resp.qustItmNo}`;
          option = document.querySelector(optionId);
          option.checked = true;

          // 주관식 답
          if (resp.sbjtYn === 'Y') {
            subOptionId = `#subopt${resp.qustSeqno}_${resp.qustItmNo}`;
            subOption = document.querySelector(subOptionId);

            if (subOption) {
              setValue(`subopt${resp.qustSeqno}_${resp.qustItmNo}`, Number(resp.sbjtRespCtnt));
            }
          }
        });
      }
    }
  }, [rowNum]);
  
  useEffect(() => {
    if (!isObjEmpty(store.quotationInfo)) {
      localStorage.setItem('qttId', store.quotationInfo.freQttSeqno);
    }
  }, [store.quotationInfo]);

  // 카테고리 및 품질구분코드 세팅
  useEffect(() => {
    setCategoryList(store.categoryList);
    setQualityCodeList(store.qualityCodeList);
  }, [store.categoryList, store.qualityCodeList]);

  // 문항 및 문항 항목 목록 세팅
  useEffect(() => {
    setQuestionList(store.questionList);
    setQuestionItemList(store.questionItemList);
  }, [store.questionList, store.questionItemList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCategoryList());
  }, []);

  return (
    <Container>
      {/* 첫 번째 질문 - 카테고리 선택 */}
      { !isCheckedCategory ? 
        <Category>
          { categoryList && categoryList.map((item) => {
            return (
              <CategoryItem key={item.qttCtgrId} onClick={() => clickCategory(item)}>{item.qttCtgrNm}</CategoryItem>
            )
          })}
        </Category> : ''
      }

      {/* 두 번째 질문 - 품질 선택 */}
      { isCheckedCategory && !isCheckedPrice ? 
        <Price>
          { qualityCodeList && qualityCodeList.map((code) => {
              return (
                <PriceItem key={code.instCd} onClick={() => clickQuality(code)}>{code.instNm}</PriceItem>
              )
          })}
        </Price> : ''
      }

      {/* STEP */}
      { isCheckedCategory && isCheckedPrice ?
        <StepContainer>
          <TopContainer>
            <Progress>
              <ProgressBar><ColorBar percent={ Math.floor((rowNum / questionList.length) * 100) } /></ProgressBar>
              <PerText>{ Math.floor((rowNum / questionList.length) * 100) }%</PerText>
            </Progress>
            <TagWrapper>
              { responseList && responseList.map((tag, index) => {
                if (tag.tagDspYn === 'Y') {
                  return (
                    <Tag key={index}>{tag.qustItmCtnt}</Tag>
                  )
                }
              })}
            </TagWrapper>
          </TopContainer>
          <QuestionContainer>
            { questionList && questionList.map((question, index) => {
              if (rowNum === Number(question.rnum)) {
                return (
                  <QuestionWrapper key={question.qustSeqno}>
                    <Question>{`${question.qustCtnt}`} <span className={question.reqmYn === 'Y' ? 'red' : 'blue'}>({question.reqmYn === 'Y' ? '필수' : '선택'})</span></Question>
                    <OptionContainer>
                      { questionItemList && questionItemList.map((item, i) => {
                        if (question.qustSeqno === item.qustSeqno && item.multiChcYn === 'Y' && currentQuality === item.qltyDvcd) {
                          if (item.sbjtYn === 'N') {
                            return (
                              <OptionWrapper key={i}>
                                <OptionCheckWrapper>
                                  <Check
                                    id={`opt${item.qustSeqno}_${item.qustItmNo}`}
                                    name={`opt${item.qustSeqno}`}
                                    onChange={() => clickQuestionMultiItem(question, item)}
                                  />
                                  <label htmlFor={`opt${item.qustSeqno}_${item.qustItmNo}`}>{item.qustItmCtnt}</label>
                                </OptionCheckWrapper>
                              </OptionWrapper>
                            )
                          } else {
                            return (
                              <OptionWrapper key={i}>
                                <OptionCheckWrapper>
                                  <Check
                                    id={`opt${item.qustSeqno}_${item.qustItmNo}`}
                                    name={`opt${item.qustSeqno}`}
                                    onChange={() => clickQuestionMultiItem(question, item)}
                                  />
                                  <label htmlFor={`opt${item.qustSeqno}_${item.qustItmNo}`}>{item.qustItmCtnt}</label>
                                  <ShortAnswer 
                                    id={`subopt${item.qustSeqno}_${item.qustItmNo}`}
                                    {...register(`subopt${item.qustSeqno}_${item.qustItmNo}`)}
                                    defaultValue={0}
                                    disabled
                                  /><span>{item.unitDspNm}</span>
                                </OptionCheckWrapper>
                                { item.unitMaxVal ?
                                  <AnswerSlider>
                                    <Slider 
                                      max={item.unitMaxVal}
                                      marks={{ 
                                        0: {style: {width: 25}, label: `0`}, 
                                        [item.unitMaxVal/2]: `${item.unitMaxVal/2}`,
                                        [item.unitMaxVal]: {style: {width: 25}, label: `${item.unitMaxVal}${item.unitDspNm ? item.unitDspNm : ''}`}
                                      }}
                                      defaultValue={
                                        responseList.filter(prev => prev.rowNum === rowNum && prev.qustItmNo === item.qustItmNo) ? 
                                        responseList.filter(prev => prev.rowNum === rowNum && prev.qustItmNo === item.qustItmNo)[0]?.sbjtRespCtnt 
                                        : 0}
                                      handleStyle={{width: 12, height: 12, marginTop: '-4px', borderColor: '#4B4B4B', backgroundColor: '#4B4B4B'}}
                                      trackStyle={{backgroundColor: '#4B4B4B'}}
                                      railStyle={{backgroundColor: '#F5F5F5'}}
                                      dotStyle={{width: 6, height: 6, bottom: -1, heibackgroundColor: '#D9D9D9'}}
                                      activeDotStyle={{borderColor: '#4B4B4B', backgroundColor: '#4B4B4B'}}
                                      dots
                                      onChange={(e) => clickCheckboxSlider(question, item, e)}
                                    /> 
                                  </AnswerSlider>
                                : '' }
                              </OptionWrapper>
                            )
                          }
                        } else if (question.qustSeqno === item.qustSeqno && item.multiChcYn === 'N' && currentQuality === item.qltyDvcd) {
                          if (item.sbjtYn === 'N') {
                            return (
                              <OptionWrapper key={i}>
                                <OptionRadioWrapper>
                                  <Option
                                    id={`opt${item.qustSeqno}_${item.qustItmNo}`}
                                    name={`opt${item.qustSeqno}`}
                                    onChange={() => clickQuestionItem(question, item, i)}
                                  />
                                  <OptionLabel htmlFor={`opt${item.qustSeqno}_${item.qustItmNo}`}>{item.qustItmCtnt}</OptionLabel>
                                  <OptionDesc>
                                    <span className='summary'>
                                      {!isObjEmpty(item.atchFileSeqno) || !isObjEmpty(item.qustItmDesc) ?
                                        <span className='tooltip'>
                                          <QuestionIcon src={QuestionBlue} />
                                          <ToolTipBox className='tooltip-text'>
                                            { !isObjEmpty(item.qustItmDesc) ? <DescText>{item.qustItmDesc}</DescText> : '' }
                                            { !isObjEmpty(item.atchFileSeqno) ? <DescImage image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`} /> : '' }
                                          </ToolTipBox>
                                        </span> : '' }
                                    </span>
                                  </OptionDesc>
                                </OptionRadioWrapper>
                              </OptionWrapper>
                            )
                          } else {
                            return (
                              <OptionWrapper key={i}>
                                <OptionRadioWrapper>
                                  <Option
                                    id={`opt${item.qustSeqno}_${item.qustItmNo}`}
                                    name={`opt${item.qustSeqno}`}
                                    onChange={() => clickQuestionItem(question, item, i)}
                                  />
                                  <OptionLabel htmlFor={`opt${item.qustSeqno}_${item.qustItmNo}`}>{item.qustItmCtnt}</OptionLabel>
                                  <ShortAnswer 
                                      id={`subopt${item.qustSeqno}_${item.qustItmNo}`}
                                      {...register(`subopt${item.qustSeqno}_${item.qustItmNo}`)}
                                      defaultValue={0}
                                      disabled
                                    /><span>{item.unitDspNm}</span>
                                </OptionRadioWrapper>
                                { item.unitMaxVal ?
                                  <AnswerSlider>
                                    <Slider 
                                      max={item.unitMaxVal}
                                      marks={{ 
                                        0: {style: {width: 25}, label: `0`}, 
                                        [item.unitMaxVal/2]: `${item.unitMaxVal/2}`,
                                        [item.unitMaxVal]: {style: {width: 25}, label: `${item.unitMaxVal}${item.unitDspNm ? item.unitDspNm : ''}`}
                                      }}
                                      defaultValue={
                                        responseList.filter(prev => prev.rowNum === rowNum && prev.qustItmNo === item.qustItmNo) ? 
                                        responseList.filter(prev => prev.rowNum === rowNum && prev.qustItmNo === item.qustItmNo)[0]?.sbjtRespCtnt 
                                        : 0}
                                      handleStyle={{width: 12, height: 12, marginTop: '-4px', borderColor: '#4B4B4B', backgroundColor: '#4B4B4B'}}
                                      trackStyle={{backgroundColor: '#4B4B4B'}}
                                      railStyle={{backgroundColor: '#F5F5F5'}}
                                      dotStyle={{width: 6, height: 6, bottom: -1, heibackgroundColor: '#D9D9D9'}}
                                      activeDotStyle={{borderColor: '#4B4B4B', backgroundColor: '#4B4B4B'}}
                                      dots
                                      onChange={(e) => clickRadioSlider(question, item, e)}
                                    /> 
                                  </AnswerSlider> 
                                : '' }
                              </OptionWrapper>
                            )
                          }
                        }
                      })}
                    </OptionContainer>
                  </QuestionWrapper>
                )
              }
            })}
            <TotalAmountWrapper>
              <div className='txt'>총 견적(VAT)포함</div>
              <div className='prc'>{convertNumberFormat(totalAmount, 'Y')} 원</div>
              <div className='desc'>예상 견적이므로 자세한 상담을 위하여 연락처를 남겨주세요</div>
            </TotalAmountWrapper>
          </QuestionContainer>
          <StepButtonContainer>
            <StepButtonWrapper>
              { rowNum !== 1 ? <StepButton onClick={() => clickStepButton('PREV')}>이전</StepButton> : '' }
              <StepButton onClick={() => clickStepButton('NEXT')}>다음</StepButton>
            </StepButtonWrapper>
          </StepButtonContainer>
        </StepContainer>
        : '' }
    </Container>
  )
}

export default Step;

const Container = styled.div`
  width: 100%;
`

const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 120px 0;
  }
`

const CategoryItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #2C2C2C;
  cursor: pointer;

  &:hover {
    background-color: rgb(44, 44, 44, 0.9);
  }

  & + & {
    margin-left: 20px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 160px 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 120px 0;
  }
`

const PriceItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #2C2C2C;
  cursor: pointer;

  &:hover {
    background-color: rgb(44, 44, 44, 0.9);
  }

  & + & {
    margin-top: 20px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin: 0 auto 25px;
`

const Progress = styled.div`
  display: flex;
  flex: 0.9;
  width: 100%;
  height: 10px;
  margin-bottom: 10px;
`

const ProgressBar = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #F5F5F5;
`

const ColorBar = styled.div`
  width: ${props => `${props.percent}%`};
  height: 100%;
  border-radius: 10px;
  background-color: #FE5A1D;
`

const PerText = styled.div`
  display: flex;
  flex: 0.1;
  margin-left: 10px;
`

const TagWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  gap: 5px 10px;

`

const Tag = styled.div`
  padding: 4px 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  color: #4B4B4B;
  background: #F5F5F5;
`

const StepContainer = styled.div`
  margin: 0 auto;
  width: 500px;
  padding: 0 40px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 0 10px;
  }
`

const QuestionContainer = styled.div`
  min-height: 300px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  @media ${({ theme }) => theme.device.mobile} {
    min-height: 250px;
  }
`

const QuestionWrapper = styled.div`

`

const Question = styled.div`
  margin-bottom: 10px;

  .blue {
    color: blue;
  }

  .red {
    color: red;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 15px;
  }
`

const OptionContainer = styled.div`
  min-height: 300px;
  padding: 10px;
  
  @media ${({ theme }) => theme.device.mobile} {
    min-height: 250px;
  }
`

const OptionWrapper = styled.div`
  & + & {
    margin-top: 10px;
  }
`

const OptionRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

const OptionLabel = styled.label`
  padding-left: 24px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`

const OptionDesc = styled.div`
  padding-left: 10px;

  .summary {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .tooltip {
    display: contents;
  }
  
  .tooltip:hover .tooltip-text {
    display: block;
  }
`

const DescText = styled.div`
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 3px;
`

const DescImage = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: url(${props => props.image}), url(${NoImage}) no-repeat 50% 50%;
  background-size: cover, contain;
`

const QuestionIcon = styled.img`
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
`

const ToolTipBox = styled.span`
  display: none;
  width: 328px;
  padding: 10px;
  margin-top: 8px;
  position: absolute;

  background: #FFF7F4;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    left: 16px;
  }
`

const Option = styled.input.attrs({type: 'radio'})`
  display: none;

  &:checked + ${OptionLabel} {
    background: url(${CheckedRadioButton}) no-repeat;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    @media ${({ theme }) => theme.device.mobile} {
      background: url(${CheckedRadioButton}) no-repeat;
    }
  }

  &:not(:checked) + ${OptionLabel} {
    background: url(${RadioButton}) no-repeat;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    
    @media ${({ theme }) => theme.device.mobile} {
      background: url(${RadioButton}) no-repeat;
    }
  }
`

const AnswerSlider = styled.div`
  padding-left: 30px;
  height: 32px;
`

const ShortAnswer = styled.input`
  width: 26px;
  margin-left: 10px;
  padding-left: 5px;
  border: none;
  background-color: white;
`

const Check = styled.input.attrs({ type: 'checkbox'})``;

const OptionCheckWrapper = styled(OptionRadioWrapper)`
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 16px;
    height: 16px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const TotalAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .txt {
  }

  .prc {
    margin: 10px 0 20px;
    color: #FE5A1D;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
  }

  .desc {
    font-size: 12px;
    line-height: 14px;
  }
`

const StepButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    background: #FFFFFF;
    text-align: center;
    text-align: -webkit-center;
  }
`

const StepButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
`

const StepButton = styled.button`
  width: 100%;
  // width: 200px;
  height: 50px;
  padding: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 10px;
  background-color: #2C2C2C;

  &:hover {
    background-color: rgb(44, 44, 44, 0.9);
  }

  & + & {
    margin-left: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`