const deviceSizes = {
  mobile: "768px",
  tablet: "1024px",
  laptop: "1440px",
}

const device = {
  mobile: `(max-width: ${deviceSizes.mobile})`,
  tablet: `(max-width: ${deviceSizes.tablet})`,
  laptop: `(max-width: ${deviceSizes.laptop})`,  
}

const style = {
  desktopWidth: "1280px",
  containerMargin: "180px auto 0",
}

const theme = {
  device,
  style,
  
  /* 색상 */
  // 폰트 색상
  txtBlack: "#212121",
  txtDarkGray: "#4B4B4B", 
  txtGray: "#ABABAC", 
  txtWhiteGray: '#D9D9D9',
  txtWhite: "#FFFFFF",  
  
  // 배경 색상
  bgColorBlack: "#212121",
  bgColorDarkGray: "#2C2C2C",
  bgColorGray: "#F5F5F5",
  bgColorWhite: "#FFFFFF",
  bgColorOrange: "#FE5A1D",

  // 헤더 색상
  headerBack: "#FFFFFF",

  
}

export default theme;