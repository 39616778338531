import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { convertNumberFormat, isObjEmpty } from 'utils/util';
import { getMainProductList } from '../store/action';

const BestProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector(state => state.main);

  // 인기 상품 패키지 목록
  const [mainBestProductList, setMainBestProductList] = useState();

  /**
   * 상품 클릭 시 상품조회
   * @param {string} prodId 상품ID
   */
  const clickProduct = (prodId) => {
    navigate(`/product/${prodId}`);
  }

  // 초기 화면 접속 시 정보 받아오기
  useEffect(() => {
    dispatch(getMainProductList());
  }, []);

  // 메인 상품 정보세팅
  useEffect(() => {
    if (!isObjEmpty(store.mainProductList)) {
      setMainBestProductList(store.mainProductList);
    }
  }, [store.mainProductList]);

  return (
    <Container>
      <ProductContainer>
        <Title>브리피 인기 상품 패키지</Title>
        <ProductWrapper>
          {mainBestProductList?.map(item => {
            return (
              <ProductItem onClick={() => clickProduct(item.prodId)} key={item.prodId}>
                <Thumbnail image={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`}/>
                <ThumbnailMobile src={`${process.env.REACT_APP_AWS_S3_URL}/${item.atchFilePath}/${item.atchFileSaveNm}`}/>
                <ProductName>{item.prodNm}</ProductName>
                <Description>{item.prodSmryCtnt}</Description>
                <Price>
                  { Number(item.sellPrc) ? `${convertNumberFormat(item.sellPrc)}원` : `${convertNumberFormat(item.minOptPrc)}원` }
                </Price>
              </ProductItem>
            )
          })}
        </ProductWrapper>
      </ProductContainer>
    </Container>
  )
}

export default BestProduct;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.device.mobile} {
  };
`

const ProductContainer = styled.div`
  width: 1088px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto 200px;

  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto 90px;
  };
`

const Title = styled.div`
  width: 100%;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  @media ${({theme}) => theme.device.mobile} {
    font-size: 20px;
    line-height: 24px;
  };
`

const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 56px;

  @media ${({theme}) => theme.device.mobile} {
    margin-top: 26px;
    padding: 0 16px;
  };
`

const ProductItem = styled.div`
  width: 342px;
  margin-bottom: 50px;
  cursor: pointer;

  @media ${({theme}) => theme.device.mobile} {
    width: 48%;
    margin-bottom: 25px;
  };
`

const Thumbnail = styled.div`
  width: 342px;
  height: 228px;
  margin-bottom: 15px;
  background: url(${props => props.image}) center/cover no-repeat;

  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`
const ThumbnailMobile = styled.img`
  display: none;
  margin-bottom: 8px;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
    max-width: 100%;
    height: auto;
  };
`

const ProductName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;

  @media ${({theme}) => theme.device.mobile} {
    font-weight: 600;
    margin-bottom: 4px;
  };
`

const Description = styled.div`
  font-weight: 200;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 14px;

  @media ${({theme}) => theme.device.mobile} {
    height: 36px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 6px;
  };
`

const Price = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  @media ${({theme}) => theme.device.mobile} {
    
  };
`