import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { isObjEmpty } from 'utils/util';

import { ReactComponent as ArrowDown } from 'resources/product/arrow_down_black.svg';
import { ReactComponent as ArrowUp } from 'resources/product/arrow_up_black.svg';

const ProductDetail = ({ detailImageList, detailMobileImageList, boardList }) => {
  
  // 선택한 탭 정보
  const [selectedTab, setSelectedTab] = useState('PRODUCT');
  const prodRef = useRef();
  const faqRef = useRef();

  // 상세 이미지 목록
  const [productDetailImageList, setProductDetailImageList] = useState([]);
  // 모바일 상세 이미지 목록
  const [productDetailMobileImageList, setProductDetailMobileImageList] = useState([]);

  // FAQ 목록
  const [questionList, setQuestionList] = useState([]);
  const [replyList, setReplyList] = useState([]);
    
  /* 각 탭을 선택할 경우 해당 탭 내용으로 이동 */
  const clickTab = (tabName) => {
    setSelectedTab(tabName);
    
    if (tabName === 'PRODUCT') {
      prodRef.current?.scrollIntoView();
    } else if (tabName === 'FAQ') {
      faqRef.current?.scrollIntoView();
    }
  }
  
  /**
   * FAQ: 클릭 시 아코디언 메뉴 ON/OFF 
   */
  const clickAccordion = (index) => {
    const elemId = document.getElementById(`cont${index}`); 
    const elemPlusId = document.getElementById(`accP${index}`); // +아이콘
    const elemMinusId = document.getElementById(`accM${index}`); // - 아이콘

    if (elemId.style.display === 'none') {
      elemId.style.display = 'block';
      elemPlusId.style.display = 'none';
      elemMinusId.style.display = 'block';
    } else {
      elemId.style.display = 'none';
      elemPlusId.style.display = 'block';
      elemMinusId.style.display = 'none';
    }
  }

  /**
   * 모바일 상세이미지 더보기 버튼 : 클릭 시 전체 이미지 표시
   */
  const showMoreImage = (showYn) => {
    // 숨겨진 상품 상세이미지, 상품상세정보 접기 버튼
    const hideImageList = document.querySelectorAll('.more, .closeBtn');
    // 상품상세정보 더보기 버튼
    const moreBtn = document.querySelector('.moreBtn');
    
    if (showYn) {
      if (!isObjEmpty(hideImageList)) {
        hideImageList.forEach((image) => {
          image.style.display = 'block';
        });
      }
  
      moreBtn.style.display = 'none';
    } else {
      if (!isObjEmpty(hideImageList)) {
        hideImageList.forEach((image) => {
          image.style.display = 'none';
        });
      }
  
      moreBtn.style.display = 'block';
    }
  }

  // 상세페이지 세팅
  useEffect(() => {
    setProductDetailImageList(detailImageList); // 상세 페이지 이미지
    setProductDetailMobileImageList(detailMobileImageList); // 모바일 상세 페이지 이미지
    
    // setFaqList(boardList); // FAQ 목록
    const tempQ = [];
    const tempR = [];
    if (boardList) {
      boardList.forEach(item => {
        if (!item.rplyNo) {
          tempQ.push(item);
        } else {
          tempR.push(item);
        }
      });

      setQuestionList(tempQ);
      setReplyList(tempR);
    }

  }, [detailImageList, boardList]);

  // 모바일 사이즈 세팅
  const [mode, setMode] = useState('pc');

  useEffect (()=> {
    const handleResize = () => {
      setMode(window.innerWidth);
      if(window.innerWidth < 768) {
        setMode('mobile');
      } else {
        setMode('pc');
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * 스크롤 이벤트 감지
   */  
  const [hide, setHide] = useState(false);
  const onScroll = (e) => {
    if (e.deltaY > 0) {
      setHide(true);
    } else {
      setHide(false);
    }
  }

  useEffect(() => {
    window.addEventListener('wheel', (e) => {
      onScroll(e);
    });
    return () =>window.removeEventListener('wheel', onScroll);
  }, []);

  return (
    <Container>      
      <div> {/* Tab이 상품페이지에서만 sticky 속성 유지를 위하여 div로 감싸서 처리함 */}
        { mode === 'pc' ? 
          <Tab hide={hide}>
            <TabItem onClick={() => clickTab('PRODUCT')} isSelected={selectedTab === 'PRODUCT'}><span>상품상세정보</span></TabItem>
            <TabItem onClick={() => clickTab('FAQ')} isSelected={selectedTab === 'FAQ'} ><span>FAQ</span></TabItem>
          </Tab> : '' 
        }
        {mode === 'mobile' ? <ProductDetailTitle>상품상세정보</ProductDetailTitle> : ''}
        <ProductContainer ref={prodRef}>
          { mode === 'pc' ?
            productDetailImageList?.map(detail => {
              return (
                <div className='wrap' key={detail.atchNo}>
                  <DetailImage src={`${process.env.REACT_APP_AWS_S3_URL}/${detail.atchFilePath}/${detail.atchFileSaveNm}`} />
                </div>
              )
            }) :
            <>
              {productDetailMobileImageList?.map((detail, index) => {
                if (index === 0 ) {
                  return (
                    <div key={detail.atchNo}>
                      <div className='wrap'>
                        <DetailImage src={`${process.env.REACT_APP_AWS_S3_URL}/${detail.atchFilePath}/${detail.atchFileSaveNm}`} />
                      </div>
                      <ShowImageButton className='moreBtn' onClick={() => showMoreImage(true)}><span>상품상세정보 더보기</span><ArrowDown /></ShowImageButton>
                    </div>
                  )
                } else {
                  return (
                    <div className='wrap more' key={detail.atchNo}>
                      <DetailImage src={`${process.env.REACT_APP_AWS_S3_URL}/${detail.atchFilePath}/${detail.atchFileSaveNm}`} />
                    </div>
                  )
                }
              })}
              <ShowImageButton className='closeBtn' onClick={() => showMoreImage(false)}><span>상품상세정보 접기</span><ArrowUp /></ShowImageButton>
            </>
          }
        </ProductContainer>
        {/* FAQ 클릭 시 스크롤 위치 조정을 위한 div */}
        <SpaceDiv ref={faqRef} />
      </div>
      <FaqContainer>
        <FaqHeader>
          <span>상품 FAQ</span>
          <div>주문관련 문의 및 요청 사항은<br /> 브리피 contact@briphy.com에 보내주세요</div>
        </FaqHeader>

        {questionList?.map((item, index) => {
          return (       
            <FaqItem key={index} onClick={() => clickAccordion(index)}>
              <FaqTitle>
                <span>{item.ctnt}</span>
                <AccIcon id={'accP' + index}>+</AccIcon>
                <AccIcon id={'accM' + index} style={{display: 'none'}} >-</AccIcon>
              </FaqTitle>
              
              <FaqContent id={'cont' + index} style={{display: 'none'}} >
                {/* <span>{replyList[index].ctnt}</span> */}
                <div dangerouslySetInnerHTML={{ __html: replyList[index]?.ctnt }}></div>
              </FaqContent>
            </FaqItem>
          )
        })}
      </FaqContainer>
    </Container>
  )
}

export default ProductDetail;

const Container = styled.div`
  width: 1088px;
  margin: 0 auto;
  min-height: calc(100vh - 62px);

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 0px 16px 0px 16px;
  }
`
const Tab = styled.div`
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: ${props => props.hide ? '0px' : '80px'};
  transition: all .1s ease-in-out;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const TabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 544px;
  height: 60px;
  cursor: pointer;
  font-size: 15px;
  font-weight: ${props => props.isSelected ? 500 : 300};
  background: ${props => props.theme.bgColorWhite};
  border-bottom: ${props => props.isSelected ? '2px solid #2C2C2C' : '2px solid #D9D9D9'};
  `

const ProductDetailTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`

const ProductContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 70px;
  padding-top: 25px;

  .wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }  

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 60px;

    .more {
      display: none;
    }

    .closeBtn {
      display: none;
    }
  }
`

const DetailImage = styled.img`
  display: block;
  width: 100%;
`

const SpaceDiv = styled.div`
  width: 100%;
  height: 150px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const FaqContainer = styled.div`
  padding-top: 30px;
  margin-bottom: 250px;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 0;
    margin-bottom: 160px;
  }
`

const FaqHeader = styled.div`
  margin-bottom: 31px;
  font-size: 15px;
  font-weight: 700;

  div {
    margin-top: 7px;
    font-size: 12px;
    font-weight: 300;
    color: ${props => props.theme.txtGray};
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    div {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-top: 4px;
    }
  }
`

const FaqItem = styled.div`  
  cursor: pointer;
  border: 0.25px solid #ABABAB;
  border-radius: 5px;

  & + & {
    margin-top: 10px;
  }
`

const FaqTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin: 20px 20px 15px;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
    margin: 11px 13px 11px 10px;
  }
`

const AccIcon = styled.span`
  float: right;
  text-align: right;
  min-width: 26px;
  height: 15px;
`

const FaqContent = styled.div`
  margin: 0 20px 15px;
  padding-top: 15px;
  font-size: 14px;
  font-weight: 200;
  line-height: 17px;
  white-space: pre-line;
  border-top: 0.25px solid ${props => props.theme.bgColorDarkGray};
  line-height: 17px;

  b {
    font-weight: 500;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 10px;
    padding: 10px 0px;
    font-size: 14px;
  }
`

const ShowImageButton = styled.div`
  width: 100%;
  padding: 14px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  background: #FFFFFF;
  border: 0.5px solid #2C2C2C;
  
  span {
    margin-right: 5px;
  }
`