const initialState = {
  custUserInfo: {},
  orderList: {},
  orderProductOptionList: [],
  orderProductList: [],
  bankCodeList:[]
}

const mypages = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_INFO':
      return { ...state,
        custUserInfo : action.data.custUserInfo 
      }

    case 'GET_ORDER_LIST':
      return { ...state,
        orderList : action.data.orderList,
        orderProductList : action.data.orderProductList
      }
    
    case 'GET_ORDER_DETAIL_INFO':
      return { ...state,
        orderProductOptionList : action.data.orderProductOptionList
      }

    case 'GET_BANK_CODE_LIST':
      return { ...state,
        cancelReasonList : action.data.cancelReasonList,
        bankCodeList : action.data.bankCodeList
      }
    default:
      return { ...state }
  }
}

export default mypages;