import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import styled from 'styled-components';
import KakaoLoginImg from 'resources/member/kakao_login.png';
import MobileKakaoLoginImg from 'resources/member/mobile_kakao_login.png';
import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';

const { Kakao } = window;
Kakao.init(`${process.env.REACT_APP_KAKAO_JAVASCRIPT_APIKEY}`);
const isInit = Kakao.isInitialized();

const KakaoLogin = () => {
  
  const navigate = useNavigate();

  /**
   * 카카오 로그인 팝업 호출 (Kakao.Auth.login)
   * response : 토큰 값, 토큰만료 시간 등
   */
  const loginWithKakao = () => {
    // 초기화 안됐을 경우 초기화
    if (!isInit) {
      Kakao.init(`${process.env.REACT_APP_KAKAO_JAVASCRIPT_APIKEY}`);
    } else {
      Kakao.Auth.login({
        success: function(response) {
          requestUserInfo(); 
          // Kakao.Auth.setAccessToken(response.access_token);
        },
        fail: function(error) {
          console.log('error > ', error)
        },
      });
    }
  }

  /**
   * 회원 여부 체크 
   * response : id(고유id), connected_at(연결시각), kakao_account(카카오 계정정보)
   */
  const requestUserInfo = () => {
    Kakao.API.request({
      url: '/v2/user/me',
      data: {
        property_keys: ["kakao_account.email", "kakao_account.phone_number", "kakao_account.name"]
      },
      
      // 1. 카카오 로그인 성공 시 가입 여부 확인
      success: function(response) {
        // 전화번호 replace
        let hpNo = response.kakao_account.phone_number;
        hpNo = hpNo.replace('+82 ', '0');
        hpNo = hpNo.replaceAll('-', '');

        const param = {
          type : '11',
          snsId : response.id,
          email : response.kakao_account.email,
          hpNo : hpNo,
          name : response.kakao_account.name 
        }

        memberApi.snsLogin(param).then(res => {
          if (res.data === '451') {
            // 2-1. 가입되어 있지 않을 경우(451) 회원가입 동의 받기
            navigate('/agree', {state: param});
          } else {
            // 2-2. 가입되어 있을 경우 로그인 처리
            const userData = jwt_decode(res.data);
            localStorage.setItem('briphyMarketplaceUser', JSON.stringify(userData));
            localStorage.setItem('accessToken', res.data);

            // 메인 화면 이동
            navigate('/');
          }
        }).catch(error => {
          localStorage.removeItem('snsLoginId');
          handleAlertText('', '일시적인 오류로 회원가입에 실패하였습니다.', 'warning');
        });
      },
      fail: function(error) {
        alert(error.msg);
      }
    });
  }

  /* 카카오 토큰 로그아웃 */
  const logout = () => {
    if (!Kakao.Auth.getAccessToken()) {
      console.log('Not logged in.');
      return;
    }
    Kakao.Auth.logout(function() {
      console.log('logout ok\naccess token -> ' + Kakao.Auth.getAccessToken());
    })
  }

  return (
    <> 
      <KakaoLoginButton src={KakaoLoginImg} onClick={loginWithKakao} /> 
      <MobileKakaoLoginButton src={MobileKakaoLoginImg} onClick={loginWithKakao} /> 
    </>
  )
}

export default KakaoLogin;

const KakaoLoginButton = styled.img`
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const MobileKakaoLoginButton = styled.img`
  display: none;
  cursor: pointer;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 54px;
  }
`
