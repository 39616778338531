import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Logo from 'resources/common/briphy_logo2.svg';

const ContactSuccess = () => {
 return (
  <Container>
    <SuccessWrapper>
      <ContentWrapper>
        <BriphyLogo src={Logo}/>
        <Content>
          <div>문의 감사합니다!</div>
          <div>빠른 시간 내 연락드리겠습니다.</div>
        </Content>
      </ContentWrapper>
      <Button><Link to='/portfolio'>포트폴리오 구경하기</Link></Button>
      <MobileButtonWrapper>
        <MobileButton><Link to='/portfolio'>포트폴리오 구경하기</Link></MobileButton>
      </MobileButtonWrapper>
    </SuccessWrapper>
  </Container>
 )
}

export default ContactSuccess;

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px auto;
  padding: 165px;
  width: 1079px;
  height: 700px;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    margin: 58px 0;
    padding: 20px 16px;
    box-shadow: none;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 37px 0;
  }
`

const Content = styled.div`
  margin: 40px auto;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  @media ${({ theme }) => theme.device.mobile} {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    div + div {
      margin-top: 9px;
    }
  }
`

const BriphyLogo = styled.img`
  width: 194px;
  height: 197px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 154px;
    height: 157px;
  }
`

const Button = styled.button`
  width: 477px;
  height: 54px;
  padding: 16px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background: #FE5A1D;
  border-radius: 5px;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const MobileButtonWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    width: 100%;
    padding: 6px 0;
  }
`

const MobileButton = styled.button`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    height: 54px;
    padding: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FE5A1D;
    background: #FFFFFF;
    border: 1px solid #FE5A1D;
    border-radius: 5px;
  }
`