import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

/**
 * icon 종류
 * success/error/warning/info/question
 */

export const handleConfirmText = (title, text, icon) => {
  return MySwal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonText: 'YES',
    allowOutsideClick: false,
    reverseButtons: true,
    // customClass: {
    //   confirmButton: 'btn btn-primary',
    //   cancelButton: 'btn btn-outline-danger ml-1'
    // },
    buttonsStyling: true
  })
}

export const handleAlertText = (title, text, icon) => {
  return MySwal.fire({
    title,
    text,
    icon,
    allowOutsideClick: false,
    // customClass: {
    //   confirmButton: 'btn btn-primary'
    // },
    buttonsStyling: true
  })
}

// html 태그를 넣을 경우(줄바꿈 등)
export const handleAlertHtml = (title, html, icon) => {
  return MySwal.fire({
    title,
    html,
    icon,
    allowOutsideClick: false,
    // customClass: {
    //   confirmButton: 'btn btn-primary'
    // },
    buttonsStyling: true
  })
}
