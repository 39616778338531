import styled, { keyframes } from 'styled-components';

import { useScrollFadeIn } from 'hooks/useScrollFadeIn';
import CartImage from 'resources/mainproduct/why_cart.png';
import FirstImage from 'resources/mainproduct/why_first.svg';
import SecondImage from 'resources/mainproduct/why_second.svg';
import ThirdImage from 'resources/mainproduct/why_third.svg';

const WhyBriphy = () => {

  const moveCart = useScrollFadeIn({threshold: 0.1, delay: '0.5', direction: 'rightLeft100'});
  const moveCartMobile = useScrollFadeIn({threshold: 0.1, delay: '0.3', direction: 'rightLeft'});
  const textAnime = useScrollFadeIn({threshold: 0.2, delay: '0.9', direction: 'notmove', use: 'static'});
  const textAnimeSmall = useScrollFadeIn({threshold: 0.2, delay: '0.9', direction: 'notmove', use: 'static'});
  const whyAnime1 = useScrollFadeIn({threshold: 0.3, delay: '0.3', direction: 'leftRight'});
  const whyAnime2 = useScrollFadeIn({threshold: 0.3, delay: '0.3', direction: 'rightLeft'});
  const whyAnime3 = useScrollFadeIn({threshold: 0.3, delay: '0.3', direction: 'leftRight'});
  const whyAnimeMobile1 = useScrollFadeIn({threshold: 0.1, delay: '0.3', direction: 'bottomUp'});
  const whyAnimeMobile2 = useScrollFadeIn({threshold: 0.1, delay: '0.3', direction: 'bottomUp'});
  const whyAnimeMobile3 = useScrollFadeIn({threshold: 0.1, delay: '0.3', direction: 'bottomUp'});
  const whyAnimeContent1 = useScrollFadeIn({threshold: 0.3, delay: '0.3',direction: 'bottomUp'});
  const whyAnimeContent2 = useScrollFadeIn({threshold: 0.3, delay: '0.3',direction: 'bottomUp'});
  const whyAnimeContent3 = useScrollFadeIn({threshold: 0.3, delay: '0.3',direction: 'bottomUp'});
  const whyAnimeContentMobile1 = useScrollFadeIn({threshold: 0.1, delay: '0.3',direction: 'bottomUp'});
  const whyAnimeContentMobile2 = useScrollFadeIn({threshold: 0.1, delay: '0.3',direction: 'bottomUp'});
  const whyAnimeContentMobile3 = useScrollFadeIn({threshold: 0.1, delay: '0.3',direction: 'bottomUp'});

  return (
    <Container>
      <WhyContainer>
        <TopContainer>
          <WhyWrapper>
            <TextWrapper>
              <p {...textAnime}>Why<br/>BRIPHY</p>
              <p  {...textAnimeSmall} className='small-text'>검증된 촬영 전문가가 찍은<br/>최고의 사진을 경험해보세요</p>
            </TextWrapper>
            <ImageWrapper>
              <Cart {...moveCart} ><img src={CartImage} /></Cart>
              <CartMobile {...moveCartMobile} ><img src={CartImage} /></CartMobile>
            </ImageWrapper>
          </WhyWrapper>
        </TopContainer>

        <WhyItemWrapper>
          <WhyItem> 
            <WhyItemImage {...whyAnime1}><img src={FirstImage} /></WhyItemImage>            
            <Content {...whyAnimeContent1}>
              <div className='title'>부담없는 가격</div>
              <div className='desc'>
                <div>사진이 필요한데 촬영 가격이 부담되셨나요?</div>
                <div>거품 뺀 착한 촬영가격으로 준비했습니다</div>
              </div>
              <div className='last'>부담없이 촬영을 신청하세요</div>
            </Content>
          </WhyItem>
          <WhyItem> 
            <Content {...whyAnimeContent2}>
              <div className='title'>검증된 전문가</div>
              <div className='desc'>
                <div>사진 결과물이 잘 나올까 걱정되셨나요?</div>
                <div>여기어때, 쿠팡이츠, 요기요 전문 촬영팀이</div>
                <div>최고의 퀄리티를 보장합니다</div>
              </div>
              <div className='last'>걱정말고 촬영을 신청하세요</div>
            </Content>
            <WhyItemImage {...whyAnime2}><img src={SecondImage} /></WhyItemImage>
          </WhyItem>
          <WhyItem> 
            <WhyItemImage {...whyAnime3}><img src={ThirdImage} /></WhyItemImage>
            <Content className='pr' {...whyAnimeContent3}>
              <div className='title'>최적의 상품 구성</div>
              <div className='desc'>
                <div>당신에게 꼭 필요한 촬영 상품을 구성했습니다</div>
              </div>
              <div className='last'>간편하게 촬영을 신청하세요</div>
            </Content>
          </WhyItem>
        </WhyItemWrapper>
        <WhyItemWrapperMobile>
          <WhyItem> 
            <WhyItemImageMobile className='p20' {...whyAnimeMobile1}><img src={FirstImage} /></WhyItemImageMobile>
            <Content {...whyAnimeContentMobile1}>
              <div className='title'>부담없는 가격</div>
              <div className='desc'>
                <div>사진이 필요한데 촬영 가격이 부담되셨나요?</div>
                <div>거품 뺀 착한 촬영가격으로 준비했습니다</div>
              </div>
              <div className='last'>부담없이 촬영을 신청하세요</div>
            </Content>
          </WhyItem>
          <WhyItem> 
            <WhyItemImageMobile className='p16' {...whyAnimeMobile2}><img src={SecondImage} /></WhyItemImageMobile>
            <Content {...whyAnimeContentMobile2}>
              <div className='title'>검증된 전문가</div>
              <div className='desc'>
                <div>사진 결과물이 잘 나올까 걱정되셨나요?</div>
                <div>여기어때, 쿠팡이츠, 요기요 전문 촬영팀이</div>
                <div>최고의 퀄리티를 보장합니다</div>
              </div>
              <div className='last'>걱정말고 촬영을 신청하세요</div>
            </Content>            
          </WhyItem>
          <WhyItem> 
            <WhyItemImageMobile {...whyAnimeMobile3}><img src={ThirdImage} /></WhyItemImageMobile>
            <Content className='pr' {...whyAnimeContentMobile3}>
              <div className='title'>최적의 상품 구성</div>
              <div className='desc'>
                <div>당신에게 꼭 필요한 촬영 상품을 구성했습니다</div>
              </div>
              <div className='last'>간편하게 촬영을 신청하세요</div>
            </Content>
          </WhyItem>
        </WhyItemWrapperMobile>
      </WhyContainer>
    </Container>
  )
}

export default WhyBriphy;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.device.mobile} {
  };
`
  
  const WhyContainer = styled.div`
  width: 100%;
  margin-bottom: 250px;
  overflow: hidden;
  
  @media ${({theme}) => theme.device.mobile} {
    margin-bottom: 160px;
  };
`

const TopContainer = styled.div`
  height: 656px;
  
  @media ${({theme}) => theme.device.mobile} {
    height: 525px;
  };
`

const WhyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 502px;
  background: #F5F5F5;
  
  @media ${({theme}) => theme.device.mobile} {
    height: 443px;
    align-items: flex-start;
  };
`

const TextWrapper = styled.div`
  width: 1088px;
  margin: auto 0;
  position: relative;
  font-weight: 800;
  font-size: 150px;
  line-height: 180px;
  color: white;
  
  .small-text {
    color: #F5F5F5;
    position: absolute;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin-left: 340px;
    margin-top: -286px;
  }

  
  @media ${({theme}) => theme.device.mobile} {
    margin: 0;
    padding: 30px 16px;
    font-size: 64px;
    line-height: 67px;

    .small-text {
      position: static;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
      margin-top: 3px;
    }
  };
`

const ImageWrapper = styled.div`
  position: relative;

  img {
    width: 502px;
    height: 539px;
  }

  
  @media ${({theme}) => theme.device.mobile} {
    img {
      width: 287px;
      height: 308px;
    }
  };
`

const Cart = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -35px;
  margin-top: -140px;

  
  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`

const CartMobile = styled.div`
  display:none;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 19px;
  margin-top: 215px;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
  };
`

const WhyItemWrapper = styled.div`
  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`

const WhyItemWrapperMobile = styled.div`
  display: none;
  padding: 0 16px;
  margin-top: 70px;

  @media ${({theme}) => theme.device.mobile} {
    display: block;

    .p20 {
      padding: 20px;
    }

    .p16 {
      padding: 16px;
    }
  };
`

const WhyItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;

  .pr {
    padding-right: 29px;
  }

  
  @media ${({theme}) => theme.device.mobile} {
    flex-direction: column;
    margin-top: 100px;

    .pr {
      padding-right: 0px;
    }
    
    img {
      width: 100%;
    }
  };
`

const WhyItemImage = styled.div`
  @media ${({theme}) => theme.device.mobile} {
    display: none;
  };
`

const WhyItemImageMobile = styled.div`
  display: none;

  @media ${({theme}) => theme.device.mobile} {
    display: block;
  };
`

const Content = styled.div`
  padding: 112px 100px; 

  .title {
    font-weight: 800;
    font-size: 64px;
    line-height: 77px;
  }

  .desc {
    margin: 40px 0px 17px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    div + div {
      margin-top: 2px;
    }
  }

  .last {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FE5A1D;
  }
  
  @media ${({theme}) => theme.device.mobile} {
    padding: 0;
    margin-top: 15px;
    text-align: center;

    .title {
      font-size: 32px;
      line-height: 38px;
    }

    .desc {
      margin: 16px auto 9px;
      font-size: 14px;
      line-height: 17px;

      div + div {
        margin-top: 4px;
      }
    }

    .last {
      font-size: 16px;
      line-height: 19px;
    }
  };
`