import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { convertNumberFormat, isObjEmpty } from 'utils/util';
import { quotationApi } from 'utils/api';
import FormError from 'components/common/FormError';
import { strictPhoneReg } from 'components/regulation';
import { handleAlertText, handleConfirmText } from 'components/common/SweetAlert';

import CloseIcon from 'resources/common/close_icon.png';
import CheckboxUnChecked from 'resources/common/checkbox.png';
import CheckboxChecked from 'resources/common/checkbox_checked.png';

const ContactModal = ({ open, handleModal }) => {

  const navigate = useNavigate();
  const store = useSelector(state => state.quotation);

  // 문의 팝업 토글
  const [clickToggle, setClickToggle]  = useState(false);

  // 견적정보
  const [quotationInfo, setQuotationInfo] = useState();

  // 개인정보 제공 동의
  const [agree, setAgree] = useState(false);

  // slack 채널
  const contactWebHookURL =  `${process.env.REACT_APP_SLACK_QUOTATION_WEBHOOK_URL}`;

  const InputSchema = yup.object().shape({
    name: yup
      .string()
      .required('필수 입력 값입니다'),
    hpNo: yup
    .string()
    .required('필수 입력 값입니다')
    .matches(strictPhoneReg, '전화번호 형식에 맞게 입력해주세요'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    trigger, 
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(InputSchema)
  });

  const clickContactIcon = () => {
    setClickToggle(!clickToggle);
    setValue('name', '');
    setValue('hpNo', '');
    setValue('enqCtnt', '');
    clearErrors();
  }

  // 슬랙 발송
  const sendSlackContact = async(contactInfo) => {
    const template = {
      "blocks": [
        { 
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*────🔔 견적문의(무료촬영견적) 🔔 ────*"
          }
        }, 
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*이름* : ${contactInfo.name}\n*휴대폰번호* : ${contactInfo.hpNo}\n*문의내용* :\n${contactInfo.enqCtnt}\n\n*📌견적내용📌*\n*견적번호* : ${quotationInfo.freQttSeqno}\n*구분* : ${quotationInfo.qttCtgrNm} \n*견적타입* : ${quotationInfo.qltyDvnm}\n*견적상세내용* :\n${quotationInfo.responseList}\n*견적안내금액* : ${quotationInfo.totalAmount}원\n`
          }
        },
        {
          "type": "divider"
        }, 
      ]
    }

    await axios.post(contactWebHookURL, JSON.stringify(template));
  } 

  // 문의 등록
  const onSubmit = (data) => {
    if (!agree) {
      handleAlertText('', '개인정보 제공에 동의해주시기 바랍니다', 'warning');
      return;
    }

    const formElem = {
      freQttSeqno: store.quotationInfo.freQttSeqno,
      name: data.name,
      hpNo: data.hpNo.replaceAll('-', ''),
      enqCtnt: data.enqCtnt,
      psinfOfrAgrDtm: true
    }

    const contactInfo = {
      name: data.name,
      hpNo: data.hpNo,
      enqCtnt: data.enqCtnt
    }

    handleConfirmText('', '문의하시겠습니까?', 'question').then((result) => {
      if (result.isConfirmed) {
        quotationApi.updateQuotationInfo(formElem);
        sendSlackContact(contactInfo);
        localStorage.removeItem('qttId');
        navigate('/quotation/contact');
      }
    });
  }

  useEffect(() => {
    if (!open) {
      clickContactIcon();
    }

    const qttId = localStorage.getItem('qttId');
    if (!isObjEmpty(qttId)) {
      quotationApi.getResponseList(qttId)
    }

  }, [open]);

  useEffect(() => {
    let responseInfo = '';

    store.responseList?.map(item => {
      responseInfo += `${item.qttDspNm} : ${item.qustItmCtnt}`
      responseInfo += `${!isObjEmpty(item.sbjtRespCtnt) ? `(${item.sbjtRespCtnt})` : '' }`
      responseInfo += `${Number(item.amt) ? ` [${convertNumberFormat(item.amt, 'Y')}원]` : ''}\n`
    });

    let totAmt = 0;
    store.amountList.map((item) => {
      if (item.qltyDvcd === store.quotationInfo.qltyDvcd) {
        if (!isObjEmpty(item.sbjtRespCtnt)) {
          totAmt += Number(item.sbjtRespCtnt) * Number(item.amt);
        } else {
          totAmt += Number(item.amt);
        }
      }
    });

    const info = {
      freQttSeqno: store.quotationInfo.freQttSeqno,
      qttCtgrNm: store.quotationInfo.qttCtgrNm,
      qltyDvnm: store.quotationInfo.qltyDvnm,
      responseList: responseInfo,
      totalAmount: convertNumberFormat(totAmt, 'Y')
    }

    setQuotationInfo(info);
  }, [store.responseList])

  return (
    <>
      <Modal
        isOpen={open}
        toggle={handleModal}
        className='modal-dialog-centered'
        backdrop='static'
      >
        <ModalBody style={{padding: '10px 16px 16px'}}>
          <div style={{width: '100%', textAlign: 'right', marginBottom: 10}}>
            <CloseButton onClick={handleModal} />
          </div>
          <InputContainer onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper>
              <InputLabel htmlFor='name'>이름<span>*</span></InputLabel>
              <div className='wr'>
                <Input id='name' name='name' placeholder='이름을 입력해주세요' {...register('name')} />
                <FormError message={errors.name?.message} />
              </div>
            </InputWrapper>
            <InputWrapper>
              <InputLabel>휴대폰<span>*</span></InputLabel>
              <div className='wr'>
                <Controller 
                  id='hpNo'
                  name='hpNo'
                  control={control}
                  render={({ field: {ref, ...field}}) => 
                  <NumberFormat {...field} 
                    type='text'
                    htmlFor='hpNo'
                    placeholder='휴대폰 번호를 입력해주세요'
                    format='###-####-####'
                    mask='_'
                    innerRef={ref}
                    customInput={Input}
                  />}
                />
                <FormError message={errors.hpNo?.message} />
              </div>
            </InputWrapper>
            <InputWrapper className='ta'>
              <InputLabel htmlFor='enqCtnt' className='pt2'>문의내용</InputLabel>
              <div className='wr'>
                <Textarea placeholder='내용을 입력해주세요' {...register('enqCtnt')}></Textarea>
              </div>
            </InputWrapper>
            <CheckWrapper>
              <Check 
                id='agreeM' 
                name='agreeM'
                onChange={() => setAgree(!agree)}
              /> 
              <label htmlFor='agreeM'>개인정보 제공에 동의합니다</label>
            </CheckWrapper>
            <SubmitButton>문의하기</SubmitButton>
          </InputContainer>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ContactModal;

const CloseButton = styled.button`
  height: 15px;
  width: 15px;
  
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CloseIcon});
  z-index: 11;
  border: white;
  background-color: transparent;

  &:hover {
    transform: rotate(90deg);
  }
`

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  // padding: 5px 10px;
  
  .ta {
    align-items: flex-start;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #FE5A1D;
  }

  .wr {
    width: 100%;
  }

  .pt2 {
    padding-top: 2px;
  }

  & + & {
    margin-top: 15px;
  }
`
const InputLabel = styled.label`
  width: 65px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const Input = styled.input`
  width: 100%;
  height: 48px;
  padding: 15px 8px;
  font-weight: 200;
  font-size: 15px;
  line-height: 18px;
  border: none;
  background: #F5F5F5;
  border-radius: 5px;

  ::placeholder {
    color: #ABABAC;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 15px 8px;
  font-weight: 200;
  font-size: 15px;
  line-height: 18px;
  resize: none;
  border: none;
  background: #F5F5F5;
  border-radius: 5px;

  ::placeholder {
    color: #ABABAC;
  }

  &:focus{
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    // height: 150px; 

    ::placeholder {
      font-size: 15px;
    }
  }
`

const PopupButtonWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 6px 0;
  // padding: 6px 16px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background: #FFFFFF;
`

const PopupButton = styled.div`
  display: block;
  width: 100%;
  height: 54px;
  text-align: center;

  padding: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #4B4B4B;
  border-radius: 5px;
`


const Check = styled.input.attrs({ type: 'checkbox'})``;
const CheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
    // margin-top: 1px;
  }

  ${Check} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 16px;
    height: 16px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  height: 54px;
  text-align: center;
  margin-top: 21px;

  padding: 16px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #FE5A1D;
  border-radius: 5px;
`