// ** Initial State
const initialState = {
  mainVideoReviewList: []
}

const reviews = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MAIN_VIDEO_REVIEW_LIST':
      return { 
        ...state, 
        mainVideoReviewList: action.data.mainVideoReviewList
      }
    default:
      return { ...state }
  }
}
export default reviews;