import React, {useEffect, useState} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import PartnerApplyPic from 'views/PartnerApplyModal/resized_partnerapply_pic.png';
import { handleAlertText } from 'components/common/SweetAlert';
import FormError from 'components/common/FormError';
import checkboxChecked from 'resources/common/checkbox_checked.png';
import checkbox from 'resources/common/checkbox.png';
import { isObjEmpty, convertPhoneNumberFormat } from 'utils/util.js';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalBody from 'react-bootstrap/ModalBody';
import CloseIcon from 'resources/common/thin_close_icon.svg';
import { emailReg, strictPhoneReg } from "components/dist/regulation.dev";
import Select, {components} from 'react-select';
import DownArrowIcon from 'resources/common/downarrow.svg';
import RadioButton from 'resources/common/radio.svg';
import CheckedRadioButton from 'resources/common/radio_checked.svg';
import { mainApi } from "utils/api";

import NumberFormat from 'react-number-format';
import NaverADLogAnalyticsConvert from "components/NaverADLogAnalyticsConvert";


const PartnerApplyModal = ({modal, handleModal}) => {
  // 기업고객 휴대폰
  const [phone, setPhone] = useState();
  // 작가 휴대폰
  const [photoPhone, setPhotoPhone] = useState();

  // 활동지역
  const [regnCdOptions, setRegnCdOptions] = useState([]);
  const [currentRegnCd, setCurrentRegnCd] =  useState([]);

  // 촬영 카테고리
  const [shootCategory, setShootCategory] = useState([]);
  const [currentShootCategory, setCurrentShootCategory] =  useState([{id:'', name:''}]);

  // 고객/작가 tab
  const [clientClicked, setClientClicked] = useState(true);
  const [photographerClicked, setPhotographerClicked] = useState(false);

  const clientHandler = () => {
    setClientClicked(true);
    setPhotographerClicked(false);
  }

  const photographerHandler = async() => {
    setClientClicked(false);
    setPhotographerClicked(true);

    const res = await mainApi.getPartnerCommonInfo().then( response => {
      // 활동 지역 option 불러오기
      const regnCdListTemp = [];
      response.data.regnCdList.map(item => {
        regnCdListTemp.push({
          value: item.instCd,
          label: item.instNm
        })
        setRegnCdOptions(regnCdListTemp);
      })

      // 촬영카테고리 option 불러오기
      const shootCategoryTemp = [];
      response.data.productCategoryList.map(item => {
        if(item.depth === '2' && item.useYn === 'Y') {
          shootCategoryTemp.push({
            id: item.prodCtgrId,
            name: item.prodCtgrNm
          })
        }
      }) 
      shootCategoryTemp.push({
        id: '99999',
        name: '기타'
      });
      setShootCategory(shootCategoryTemp);
    })
  }

  const {
    setValue, 
    handleSubmit, 
    register, 
    formState:{errors}, 
    reset 
  } = useForm({});

  // 활동지역 select box
  const typeSelectStyle = {
    control: (base, state, isFocused) => ({
      ...base,
      fontSize : '14px',
      borderColor: 'transparent',
      maxWidth: '535px',
      height: mode === "mobile" ? '48px' : '54px',
      minHeight: '32px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '16px',
      color: '#ABABAC',
      fontWeight: 300,
      paddingLeft: '2px',
      paddingTop: '1px',
    }),
    option: (base, {isSelected, isFocused}) => ({
      ...base,
      position:'relative',
      backgroundColor: isFocused? '#4B4B4B' : '#FFFFFF',
      color: isFocused? '#FFFFFF' : '#2C2C2C',
      fontWeight: isFocused? '700' : '200',
    }),
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={DownArrowIcon} />
      </components.DropdownIndicator>
    );
  };
 
  // 화면 최초 진입 시
  useEffect(() => { 
    setCurrentRegnCd('');
  }, []);
  
  // 반응형
  const [mode, setMode] = useState("pc");
  useEffect (()=> {
    const handleResize = () => {
      setMode(window.innerWidth);
      if(window.innerWidth < 768) {
        setMode("mobile");
      } else {
        setMode("pc");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  /*
    * 촬영 카테고리
  */
  const [checkedCategory, setCheckedCategory] = useState({
    checkedOptions: [],
    response: [],
  });
  
  const handleShootCategory = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { checkedOptions } = checkedCategory;
      
    // Case 1 : The user checks the box
    if (checked) {
      setCheckedCategory({
        checkedOptions: [...checkedOptions, value],
        response: [...checkedOptions, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setCheckedCategory({
        checkedOptions: checkedOptions.filter((e) => e !== value),
        response: checkedOptions.filter((e) => e !== value),
      });
    }
  };

  const [selectedCategory, setSelectedCategory] = useState();
  useEffect( () => {
    setSelectedCategory(checkedCategory.checkedOptions);
  }, [checkedCategory]);

  // check if the checkbox is checked 
  const [checkStatus, setCheckStatus] = useState(false);
  const [photoCheckStatus,setPhotoCheckStatus] = useState(false);
  
  const agreeHandler = (e) => {
    if(e.target.checked) {
      setCheckStatus(true);
    } else {
      setCheckStatus(false);
    }
  }

  const photoAgreeHandler = (e) => {
    if(e.target.checked) {
      setPhotoCheckStatus(true);
    } else {
      setPhotoCheckStatus(false);
    }
  }
  
  // 보내기 버튼을 클릭 할 시
  const onSubmit = async(val) => {
    // 문의사항을 받을 slack 방 URL - 유출되지 않게 하기 위해 env파일에 저장
    const webHookURL = `${process.env.REACT_APP_SLACK_CLIENT_WEBHOOK_URL}`;
    if ( clientClicked ) { 
      // 개인정보 동의 validation check
      if ( !checkStatus ) {
        handleAlertText("보내기 실패!", "개인정보 제공에 반드시 동의 해주세요", 'error');
      }

      // 휴대폰 validation check
      if ( phone === "" || phone === undefined  || isObjEmpty(phone)) {
        handleAlertText("보내기 실패!", "휴대폰을 반드시 입력해주세요", 'error');
      }  
      if ( strictPhoneReg.test(phone) === false) {
        handleAlertText("보내기 실패!", "휴대폰번호를 형식에 맞게 입력해주세요", 'error');
      }
    
      // 이메일 validation check
      if ( val.email === "" || val.email === undefined || isObjEmpty(val.email)) {
        handleAlertText("보내기 실패!", "이메일을 반드시 입력해주세요", 'error');
      }
      if ( emailReg.test(val.email) === false) {
        handleAlertText("보내기 실패!", "이메일을 형식에 맞게 입력해주세요", 'error');
      }

      // 이름 validation check
      if ( val.name === "" || val.name === undefined || isObjEmpty(val.name)) {
        handleAlertText("보내기 실패!", "이름을 반드시 입력해주세요", 'error');
      } 
      
      const data = {
        "blocks": [
          { 
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "*────🔔 기업고객 제휴신청서 🔔 ────*"
            }
          }, 
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": `*회사명* : ${val.companyName}\n*이름* : ${val.name}\n*이메일* : ${val.email}\n*휴대폰번호* : ${phone}\n*촬영내용* :\n${val.question}`
            }
          },
          {
            "type": "divider"
          }, 
        ]
      }
      if(!isObjEmpty(val.name) && !isObjEmpty(val.email) && !isObjEmpty(phone) && checkStatus && emailReg.test(val.email) && strictPhoneReg.test(phone)) {
        let res = await axios.post(webHookURL, JSON.stringify(data))
        if (res.status === 200) {
          handleAlertText('제출완료!', '문의사항이 정상적으로 제출되었습니다', 'success');
          
          // 보내기 후 폼 초기화 -> 모달 창 닫기
          setPhone('');
          setCheckStatus(false);
          reset();
          handleModal();
        } else { 
          alert("문의사항을 전달하는데 문제가 생겼습니다. 다시 시도해주세요");
        }
      }
    } else { // 작가_ 제휴신청
      // 문의사항을 받을 slack 방 URL - 유출되지 않게 하기 위해 env파일에 저장
      const photographerWebHookURL = `${process.env.REACT_APP_SLACK_PHOTOGRAPHER_WEBHOOK_URL}`;

      // 활동지역 
      const addRegnCdList = [];
      if(currentRegnCd!=='') {
        currentRegnCd.map(item => {
          addRegnCdList.push(item.label);
        })
      }

      // 개인정보 동의 validation check
      if ( !photoCheckStatus ) {
        handleAlertText("보내기 실패!", "개인정보 제공에 반드시 동의 해주세요", 'error');
      }

      // 촬영 카테고리 validation check
      if ( selectedCategory.length === 0 || selectedCategory === '' || isObjEmpty(selectedCategory)) {
        handleAlertText("보내기 실패!", "촬영카테고리를 반드시 선택해주세요", 'error');
      }

      // 활동지역 validation check
      if ( currentRegnCd.length === 0 || currentRegnCd === '' || isObjEmpty(currentRegnCd) || addRegnCdList === '' || addRegnCdList.length === 0 || isObjEmpty(addRegnCdList)) {
        handleAlertText("보내기 실패!", "활동지역을 반드시 선택해주세요", 'error');
      }

      // 이메일 validation check
      if ( val.photoEmail === "" || val.photoEmail === undefined || isObjEmpty(val.photoEmail)) {
        handleAlertText("보내기 실패!", "이메일을 반드시 입력해주세요", 'error');
      } 
      if ( emailReg.test(val.photoEmail) === false) {
        handleAlertText("보내기 실패!", "이메일을 형식에 맞게 입력해주세요", 'error');
      }

      // 휴대폰 validation check
      if ( photoPhone === "" || photoPhone === undefined || isObjEmpty(photoPhone) ) {
        handleAlertText("보내기 실패!", "휴대폰을 반드시 입력해주세요", 'error');
      } 
      if ( strictPhoneReg.test(photoPhone) === false) {
        handleAlertText("보내기 실패!", "휴대폰번호를 형식에 맞게 입력해주세요", 'error');
      }

      // 이름 validation check
      if ( val.photoName === "" || val.photoName === undefined || isObjEmpty(val.photoName)) {
        handleAlertText("보내기 실패!", "이름을 반드시 입력해주세요", 'error');
      } 

      const data = {
        "blocks": [
          { 
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": "*────🔔 작가 제휴신청서 🔔 ────*"
            }
          },
          {
            "type": "section",
            "text": {
              "type": "mrkdwn",
              "text": `*이름* : ${val.photoName}\n*휴대폰번호* : ${photoPhone}\n*이메일* : ${val.photoEmail}\n*활동지역* : ${addRegnCdList}\n*촬영 카테고리* : ${selectedCategory}\n*문의사항* :\n${val.photoQuestion}`
            }
          },
          {
            "type": "divider"
          }, 
        ]
      }

      if (!isObjEmpty(val.photoName) && !isObjEmpty(val.photoEmail) && !isObjEmpty(photoPhone) 
      && photoCheckStatus && emailReg.test(val.photoEmail) && strictPhoneReg.test(photoPhone) && !isObjEmpty(currentRegnCd) && !isObjEmpty(selectedCategory) ) {
        let res = await axios.post(photographerWebHookURL, JSON.stringify(data))
        if (res.status === 200) {
          handleAlertText('제출완료!', '문의사항이 정상적으로 제출되었습니다', 'success');
  
          // 제출 후 폼 초기화 -> 모달 닫기
          setPhotoPhone('');
          setCurrentRegnCd([]);
          setCheckedCategory(
            {checkedOptions: [],
            response: []});
          setPhotoCheckStatus(false);
          reset();
          handleModal();
        } else { 
          alert("문의사항을 전달하는데 문제가 생겼습니다. 다시 시도해주세요");
        }
      }
    } 
  }


  return (
    <>
    <Modal 
      show={modal}
      fullscreen={true}
      onHide={handleModal}
      tabIndex='-1'
      backdrop='static'
    >
      <ModalBody
        style={{padding:0}}
      >
        <ModalDialog fullscreen={true}>
          <NaverADLogAnalyticsConvert type='4' value='0' isPopupOpen={modal} />
          <Container>
            <Wrapper>
              <ContentBox>
                {/* <LeftBox></LeftBox> */}
                <RightBox>
                  <CloseButtonWrapper>
                    <CloseButton onClick={handleModal} src={CloseIcon}/>
                  </CloseButtonWrapper>
                  <Content>
                    <Title>제휴신청</Title>
                    {clientClicked && mode==='pc' ? <SubTitle>{'일반촬영 문의를 원하시면 채널톡으로 이용해 주세요'}</SubTitle>
                     : clientClicked && mode==='mobile'? '' : ''}
                    <Tab>
                      <ClientTab type="button" className={clientClicked ? 'focused':''} onClick={clientHandler}>기업고객</ClientTab>
                      <PhotographerTab  type="button" className={photographerClicked ? 'focused':''} onClick={photographerHandler}>작가</PhotographerTab>
                    </Tab>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      {clientClicked? <>
                        <InputContainer>
                          <InputWrapper id="companyName">
                            <Label htmlFor="companyName">회사명</Label>
                            <InputBox 
                              id = "companyName" 
                              placeholder="회사명을 입력해주세요" 
                              {...register("companyName")}
                            />
                          </InputWrapper>
    
                          <InputWrapper id="name">
                            <Label htmlFor="name">이름<span>*</span></Label>
                            <InputBox 
                              id = "name" 
                              placeholder="이름을 입력해주세요" 
                              {...register("name")}
                            />
                          </InputWrapper>
    
                          <InputWrapper id="email">
                            <Label htmlFor="email">이메일<span>*</span></Label>
                            <InputBox 
                              placeholder="이메일을 입력해주세요"
                              {...register("email")}
                            />
                          </InputWrapper>

                          <InputWrapper id="phone">
                            <Label htmlFor="phone">휴대폰<span>*</span></Label>
                             <NumberFormat 
                                type='text'
                                name='phone'
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                format='###-####-####' 
                                mask='_'
                                customInput={InputBox}
                                placeholder='숫자만 입력해주세요'
                            />
                          </InputWrapper>
    
                          <QuestionWrapper id="question">
                            <Label htmlFor="question">촬영내용</Label>
                            <Textarea 
                              id="question" 
                              placeholder="필요하신 사항을 자유롭게 남겨주세요"
                              {...register("question")}
                            />
                          </QuestionWrapper>
                        </InputContainer>  

                        <Agreement>
                          <PersonalAgree>
                            <Label htmlFor="agree">
                              <Check 
                                id="agree" 
                                onChange={agreeHandler}
                                // {...register("agree")}
                              />
                              <i className = "check-icon"></i>
                              <span style={{fontWeight:"200", fontSize:"14px", color:"#ABABAC"}}>개인정보 제공에</span>
                              <span style={{fontWeight:"400", fontSize:"14px", marginLeft:"5px",  color:"black" }}>동의합니다</span> 
                            </Label>
                          </PersonalAgree>
                        </Agreement> </>: photographerClicked ? <>
                        <InputContainer>
                          <InputWrapper id="photoName">
                            <Label htmlFor="photoName">이름<span>*</span></Label>
                            <PhotoInputBox 
                              id = "photoName" 
                              placeholder="이름을 입력해주세요" 
                              {...register("photoName")}
                            />
                          </InputWrapper>

                          <InputWrapper id="photoPhone">
                            <Label htmlFor="photoPhone">휴대폰<span>*</span></Label>
                            <NumberFormat 
                              type='text'
                              name='photoPhone'
                              value={photoPhone}
                              onChange={e => setPhotoPhone(e.target.value)}
                              format='###-####-####' 
                              mask='_'
                              customInput={InputBox}
                              placeholder='숫자만 입력해주세요'
                            />
                          </InputWrapper>
                         
                          <InputWrapper id="photoEmail">
                            <Label htmlFor="photoEmail">이메일<span>*</span></Label>
                            <PhotoInputBox 
                              placeholder="이메일을 입력해주세요"
                              {...register("photoEmail")}
                            />
                          </InputWrapper>
                          
                          <SelectWrapper>
                            <Label>활동지역<span>*</span></Label>
                              <SelectBox
                                className='select' 
                                options={regnCdOptions}
                                onChange={data => setCurrentRegnCd(data)}
                                value={currentRegnCd}
                                styles={typeSelectStyle}
                                components={{DropdownIndicator}}
                                isMulti
                                placeholder='선택해주세요'
                                // {...register("region")}
                              >
                              </SelectBox>
                          </SelectWrapper>
                              
                          <ShootCategoryWrapper>
                            <ShootCategoryContainer>
                              <Label>촬영 카테고리<span>*</span></Label>
                              <CategoryWrapper>
                                {shootCategory.map((item, index) => {
                                  return (
                                    <Label htmlFor={`shootCategory${index}`} key={item.id}>
                                      <Check
                                        id={`shootCategory${index}`} 
                                        onChange={handleShootCategory}
                                        value={item.name}
                                      />
                                        <i className="check-icon"></i>
                                        <Category><span>{item.name}</span></Category>
                                    </Label>
                                  )
                                })}
                              </CategoryWrapper>
                            </ShootCategoryContainer>
                          </ShootCategoryWrapper>

                          <QuestionWrapper id="photoQuestion">
                            <Label htmlFor="photoQuestion">촬영내용</Label>
                            <Textarea 
                              id="photoQuestion" 
                              placeholder="필요하신 사항을 자유롭게 남겨주세요"
                              {...register("photoQuestion")}
                            />
                          </QuestionWrapper>
                        </InputContainer>

                        <PhotoAgreement>
                          <PhotoPersonalAgree>
                            <Label htmlFor="photoAgree">
                              <Check 
                                id="photoAgree" 
                                onChange={photoAgreeHandler}
                                // {...register("photoAgree")}
                              />
                              <i className = "check-icon"></i>
                              <span style={{fontWeight:"200", fontSize:"14px", color:"#D9D9D9"}}>개인정보 제공에</span>
                              <span style={{fontWeight:"400", fontSize:"14px", marginLeft:"5px",  color:"black" }}>동의합니다</span> 
                            </Label>
                          </PhotoPersonalAgree>
                        </PhotoAgreement> </> : ""
                      } 
                      <SendBtn type="submit">보내기</SendBtn> 
                    </Form>
                    
                  </Content>
                </RightBox> 
              </ContentBox>
            </Wrapper>
          </Container>
        </ModalDialog>
      </ModalBody>
    </Modal>
    </>
  );
}

export default PartnerApplyModal;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justfiy-content: center;
  flex-direction: column;
  margin: 0 auto;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto;
  }
`
 
const ContentBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    max-width: 535px;
    margin: 0px auto 0px;
    display: flex;
    justify-content: center;
    padding: 0px 16px;
  }
`

const RightBox = styled.div`
  padding-bottom: 80px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto 0px 0px;
    position: relative;
    left: 0px;
  }
`
  
const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0px 0px 160px;
  margin: 0px auto 0px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0px auto 0px 0px;
    position: relative;
    left: 0px;
    padding: 0;
  }
`

const Tab = styled.div`
  width: 200px;
  height: 32px;
  display: flex;
  background: #FAFAFA;
  box-shadow: inset 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px 0px 3px 3px;
  margin: 40px 0px 30px 335px; 
  margin-top: 40px;
  margin-bottom: 30px;

  div + div {
    margin-left: 6px;
  }

  .focused {
    color: white;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    background: #FE5A1D;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding-top: 2px;
    transition: all .3s linear;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 34px;
    margin: 0px auto 0px 0px;
    padding: 3px;

    .focused {
      font-size: 15px;
      line-height: 18px;
      padding-top: 2px;
    }
  }
`

const ClientTab = styled.div`
  width: 94px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 200;
  line-height: 13px;
  color: #ABABAC;
  padding-top: 2px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 14px;
    line-height: 17px;
    width: 50%;
    height:  27.62px;
    margin: 0px auto 0px 0px;
    position: relative;
    left: 0px;
  }
`

const PhotographerTab = styled(ClientTab)``

const Title = styled.div`
  width: 535px;
  height: 38px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 24px;
    margin-top: 44px;
    margin-bottom: 42px;
    font-size: 20px;
    line-height: 24px;
  }
`

const SubTitle = styled.div`
  width: 535px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 5px;
  margin-bottom: -19px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const Form = styled.form``

const InputContainer = styled.div`
  width: 535px;
  height: 518px;

  input {
    margin-bottom: 20px;
  }
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 430px;
    margin-top: 31px;

    input {
      margin-bottom: 16px;
    }
  }
`

const InputWrapper = styled.div`
`

const SelectWrapper = styled.div`
  margin-bottom: 20px;
`

const ShootCategoryWrapper = styled.div`
  margin-bottom: 7px;
`
 
const Label = styled.label`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 7px;
  span{
    color:#FE5A1D;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 5px;
  }
`

const CategoryWrapper = styled.div`
  display: flex;
  // background: pink;
  width: 535px;

  label {
    display: flex;
    margin-bottom: 0px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

`

const Category = styled.div`
  margin-right: 20px;

  span {
    font-size: 15px;
    line-height: 19.2px;
    font-weight: 400;
    color: #ABABAC;
    position: relative;
    top: -2px;
  }
`

const InputBox = styled.input`
  width: 535px;
  height: 54px; 
  border: none;
  background: #F5F5F5;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 5px;

  ::placeholder {
    font-color: #D9D9D9;
    font-weight: 200;
    font-size: 16px;
    position: relative;
    top: 1px;
  }

  &:focus {
    outline: 1px solid #ABABAC;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 48px;

    ::placeholder {
      font-color: #D9D9D9;
      font-weight: 200;
      font-size: 15px;
      position: relative;
      top: -2px;
    }
  }
`

const PhotoInputBox = styled(InputBox)`
`

const QuestionWrapper = styled.div`
  #photoQuestion {
    height: 80px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    #photoQuestion {
      height: 150px; 
    }
  }
`

const Textarea = styled.textarea`
  resize: none;
  height: 120px;
  width: 535px;
  border-radius: 5px;
  border: none;
  background-color: #F5F5F5;
  padding-top: 20px;
  padding-left: 15px;

  ::placeholder {
    font-color: #D9D9D9;
    font-weight: 200;
    font-size: 16px;
  }

  &:focus{
    outline: 1px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 150px; 

    ::placeholder {
      font-size: 15px;
    }
  }
`

const Check = styled.input.attrs( { type: 'checkbox' } )``;

const Agreement = styled.div`
  width: 200px;
  height: 17px;
  margin: 50px 170px 11px;

  ${Check} {
    display: none;
  }

  ${Check}:checked + .check-icon {
    background-image: url( ${checkboxChecked} );
    background-size: 105%;
    border: none;
  }

  .check-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px 1px 0;
    transform: translate3d(0, -7%, 0);
    background-image: url(${checkbox});
    background-size: 100%;
    vertical-align: middle;
    transition-duration: .2s;
    border: 0.25px solid #ABABAC;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 128px 0px 11px;
    // padding-left: 80px;
  }
`
const PersonalAgree = styled.div`
  padding: 0;
  width: 100%;
  text-align: center;
`

const PhotoAgreement = styled(Agreement)`
  margin-top: 85px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 246px 0px 11px;
    // padding-left: 80px;
  }
`

const PhotoPersonalAgree = styled(PersonalAgree)`
`

const ShootCategoryContainer = styled.div`
  margin: 0;
  label{
    margin-bottom: 15px;
  }

  ${Check} {
    display: none;
  }

  ${Check}:checked + .check-icon {
    background-image: url( ${checkboxChecked} );
    background-size: 105%;
    border: none;
  }

  .check-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px 1px 0;
    transform: translate3d(0, -7%, 0);
    background-image: url(${checkbox});
    background-size: 100%;
    vertical-align: middle;
    transition-duration: .2s;
    border: 0.25px solid #ABABAC;
  }

`

const SendBtn = styled.button`
  position: relative;
  width: 535px;
  height: 54px;
  border: none;
  background: #2C2C2C;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 700;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const CloseButtonWrapper = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-right: 3px;
    // background-color: red;
  }
`

const CloseButton = styled.img`
  width: 15px;
  height: 15px;
  background-color: white;
  cursor: pointer;  
  margin: 41.5px 41.5px 0px 799px;
  transition: all 200ms linear;

  &:hover {
    transform: rotate(90deg);
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 20px;
    height: 20px;
    margin: 0;
  }
`;

const SelectBox = styled(Select)`
  span {
    display: none;
  }

  svg {
    position: relative;
  }
`