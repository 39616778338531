import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import jwt_decode from 'jwt-decode';
import { memberApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';

import PolicyModal from 'components/common/PolicyModal';

import BriphyLogo from 'resources/common/briphy_logo2.svg';
import RoundChecked from 'resources/member/round_checkbox_checked.svg';
import RoundUnChecked from 'resources/member/round_checkbox.svg';

const Agreement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit } = useForm();
  const [mode, setMode] = useState('pc');

  const [custUserNm, setCustUserNm] = useState(); // 이름

  // 체크박스
  const [allCheck, setAllCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [personalInfoCheck, setPersonalInfoCheck] = useState(false);
  // const [offerCheck, setOfferCheck] = useState(false);
  const [rcvCheck, setRcvCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);

  // SNS 가입 정보가 없을 경우 로그인 화면으로 return 
  useEffect(() => {
    if (location.state === null) {
      handleAlertText('접근불가!', '잘못된 접근입니다.', 'error').then((result) => {
        if (result.isConfirmed) {
          navigate('/login');
        }
      });
    } else {      
      setCustUserNm(location.state.name);
    }
  }, []);

  useEffect(() => {
    if (termCheck && personalInfoCheck && ageCheck) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [termCheck, personalInfoCheck, ageCheck]);
  
  useEffect(() => {
    if (smsCheck && emailCheck) {
      setRcvCheck(true);
    } else {
      setRcvCheck(false);
    }
  }, [smsCheck, emailCheck]);

  /* 전체 동의 체크박스 */
  const checkAll = (checked) => {
    setAllCheck(checked);    
    setTermCheck(checked);
    setPersonalInfoCheck(checked);
    // setOfferCheck(checked);
    setRcvCheck(checked);
    setEmailCheck(checked);
    setSmsCheck(checked);
    setAgeCheck(checked);
  }

  const rcvCheckAll = (checked) => {
    setRcvCheck(checked);
    setEmailCheck(checked);
    setSmsCheck(checked);
  }

  // 모달 여닫기 
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => {
    setOpenModal(!openModal);
  }
  
  // 동의서 내부에 보기 버튼 클릭시 모달타입설정
  const [modalType, setModalType] = useState();
  const chooseModalType = (id) => {
    setModalType(id);
    setOpenModal(!openModal);
  }

  // 창 크기_mode - pc / mobile 설정
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {  
    setWidth(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode('mobile');
    } else {
      setMode('pc');
    }
  };

  /* 동의 버튼 */
  const handleAgreement = () => {
    if(!custUserNm) {
        handleAlertText('', '이름을 입력해주세요.', 'warning');
        return;
      }
   
    // if (ageCheck && termCheck && personalInfoCheck && offerCheck) {
    if (ageCheck && termCheck && personalInfoCheck) {
      const param = {
        joinDvcd : '2',
        snsDvcd : location.state.type,
        snsId : location.state.snsId,
        email : location.state.email || null,
        hpNo : location.state.hpNo || null,
        custUserNm : location.state.name || null,
        ageOver14Yn : ageCheck? 'Y' : 'N',
        trmsServAgrYn : termCheck ? 'Y' : 'N',
        psinfClctAgrYn : personalInfoCheck? 'Y' : 'N',
        // trmsServAgrYn : offerCheck ? 'Y' : 'N',
        emailRcvAgrYn : emailCheck ? 'Y' : 'N',
        smsRcvAgrYn : smsCheck ? 'Y' : 'N'
      };  

      memberApi.snsJoin(param).then(response => {
        handleAlertText('가입완료!', '회원가입이 완료되었습니다.', 'success').then((result) => {
          if (result.isConfirmed) {
            memberApi.snsLogin(param).then(res => {
              if (res.data !== '451') {
                const userData = jwt_decode(res.data);
                localStorage.setItem('briphyMarketplaceUser', JSON.stringify(userData));
                localStorage.setItem('accessToken', res.data);
                
                // 메인 화면 이동
                navigate('/');
              }
            });
          }
        });
      }).catch(error => {
        handleAlertText('', '일시적인 오류로 회원가입에 실패하였습니다.', 'warning');
      });
    } else {
      handleAlertText('', '필수 항목에 모두 동의해주시기 바랍니다.', 'warning');
      return;
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AgreementForm onSubmit={handleSubmit(handleAgreement)}>
        <Container>
          <LogoContainer>
            <BriphyLogoSquare src={BriphyLogo} />
          </LogoContainer>
          <InputWrapper>
            <label htmlFor='name'>이름<span>*</span></label>
            <InputText>
              <input type='text' name='name' id='name' placeholder='이름을 입력해주세요' value={custUserNm} readOnly={custUserNm} />
            </InputText>
          </InputWrapper>
          <AgreementContainer>
            <AllCheckWrapper>              
              <CheckField size={20}>     
                <label htmlFor='allCheck'>           
                  <Check 
                    id='allCheck'
                    name='allCheck'
                    checked={allCheck}
                    onChange={() => checkAll(!allCheck)}
                    fontSize={16}
                  /><i className='check-icon'></i>모두 동의합니다
                </label>
              </CheckField>
            </AllCheckWrapper>
            <OtherCheckboxContainer>
              <CheckboxWrapper>
                <CheckField size={16}>                
                  <label htmlFor='age'>
                    <Check 
                      id='age'
                      name='age'
                      checked={ageCheck}
                      fontSize={14}
                      onChange={() => setAgeCheck(!ageCheck)}
                    /><i className='check-icon'></i>[필수] 만 14세 이상
                  </label>
                </CheckField>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckField size={16}>                
                  <label htmlFor='term'>
                    <Check 
                      id='term'
                      name='term'
                      checked={termCheck}
                      fontSize={14}
                      onChange={() => setTermCheck(!termCheck)}
                    /><i className='check-icon'></i>[필수] 이용약관 동의 
                      {mode === 'pc' ? <a href='/terms/1' className='under' target='_blank'>보기</a> : mode === 'mobile' ? 
                        <SeeButton type='button' value={'보기'} onClick={e => chooseModalType('terms/1')} /> : '' }
                  </label>
                </CheckField>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckField size={16}>                
                  <label htmlFor='personalInfo'>
                    <Check 
                      id='personalInfo'
                      name='personalInfo'
                      checked={personalInfoCheck}
                      onChange={() => setPersonalInfoCheck(!personalInfoCheck)}
                      fontSize={14}
                    /><i className='check-icon'></i>[필수] 개인정보 수집 및 이용 동의 
                       {mode === 'pc' ? <a href='/privacy' className='under' target='_blank'>보기</a> : mode === 'mobile' ? 
                        <SeeButton type='button' value={'보기'} onClick={e => chooseModalType('privacy')} /> : '' }
                  </label>
                </CheckField>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckField size={16}>                
                  <label htmlFor='rcvCheckAll'>
                    <Check 
                      id='rcvCheckAll'
                      name='rcvCheckAll'
                      checked={rcvCheck}
                      onChange={() => rcvCheckAll(!rcvCheck)}
                      fontSize={14}
                    /><i className='check-icon'></i>[선택] 광고성 정보 수신 및 마케팅 활용 동의
                  </label>
                </CheckField>
              </CheckboxWrapper>
              <CheckboxWrapper style={{display: 'flex', marginLeft: '27px'}}>
                <CheckField size={16}>                
                  <label htmlFor='sms'>
                    <Check 
                      id='sms'
                      name='sms'
                      checked={smsCheck}
                      onChange={() => setSmsCheck(!smsCheck)}
                      fontSize={14}
                    /><i className='check-icon'></i>SMS
                  </label>
                </CheckField>
                <CheckField size={16} id='emailfield'>                
                  <label htmlFor='email'>
                    <Check 
                      id='email'
                      name='email'
                      checked={emailCheck}
                      onChange={() => setEmailCheck(!emailCheck)}
                      fontSize={14}
                    /><i className='check-icon'></i>이메일
                  </label>
                </CheckField>
              </CheckboxWrapper>
            </OtherCheckboxContainer>
          </AgreementContainer>
          <AgreementButton>동의하기</AgreementButton>
        </Container>
      </AgreementForm>
      <PolicyModal
        modal={openModal}
        handleModal={openModalHandler}
        type={modalType}
      />
    </>
  )
}

export default Agreement;

const AgreementForm = styled.form`
  width: 100%;
  height: 1088px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    height: 113vh;
  }

`
const Container = styled.div`
  width: 100%;
  max-width: 477px;
  position: relative;
  height: 53%; 
  text-align: center; 
  margin-top: 200px;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0px 16px 0px 16px;
  }
`

const LogoContainer = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }
`

const BriphyLogoSquare = styled.img`
  margin-bottom: 50px;
  
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 48px;
  }
`

const InputWrapper = styled.div`
  text-align: left;

  label {
    font-weight: 600;
    
    span {
      color: #FE5A1D;
    }
  }
  `
  
const InputText = styled.div`
  margin-top: 7px;
  margin-bottom: 20px;

  input {
    width: 477px;
    height: 54px;
    background: ${props => props.theme.bgColorGray};
    border: none;
    border-radius: 5px; 
    padding-left: 18px;

    ::placeholder {
      font-weight: 300;
      font-size: 16px;
      color: #ABABAC;
      line-height: 19px;
      position: relative;
      top: 2px;
    }
  }

  
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 5px;
    margin-bottom: 16px;

    input {
      width: 100%;
      height: 48px;

      ::placeholder {
        font-size: 15px;
        color: #D9D9D9;
        font-weight: 200;
      }
    }
  }
`

const AgreementContainer = styled.div`
  width: 100%;
  text-align: center;
  background: #FFFFFF;
  border: 0.25px solid #2C2C2C;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 20px 14px 6px 20px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    max-width: 477px;
    padding: 14px 10px 7px 8px;
    margin-bottom: 0px; 
  }
`

const AllCheckWrapper = styled.div`
  width: 100%;
  border-bottom: 0.25px solid #2C2C2C;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 15px;
  padding-bottom: 15px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 15px;
    line-height: 18px;
  }
`

const OtherCheckboxContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 15px;
    line-height: 18px;
  }
`

const CheckboxWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 10px;

  #emailfield {
    margin-left: -240px;
  }


  @media ${({ theme }) => theme.device.mobile} {
    #emailfield {
      margin-left: -95px;
    }
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;

const CheckField = styled.div`
  flex: 1;
  display: inline-block;
  width: 100%;
  text-align: left;


  ${Check} {
    display:none;
  }

  ${Check}:checked + .check-icon { 
    background-image: url(${RoundChecked}); 
  }

  .check-icon { 
    display: inline-block; 
    width: ${props => `${props.size}px`};
    height: ${props => `${props.size}px`};
    margin: 0 10px 0 0;
    background: url(${RoundUnChecked}) no-repeat 95%; 
    background-size: cover;
    vertical-align: middle; 
    transition-duration: .2s; 
  }
  
  .under {
    text-decoration-line: underline !important;
    margin-left: 10px;
  }
`

const SeeButton = styled.input`
  border: none;
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
  background-color: white;
  line-height: 16px;
`


const AgreementButton = styled.button`
  width: 477px;
  height: 54px;
  color: white;
  background-color: ${props => props.theme.bgColorOrange};
  border-radius: 5px;
  border-style: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    margin-top: 60px;
  }
`
