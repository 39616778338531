import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import questionmark from 'resources/common/questionmark.png';
import  DisableCancelBtn from 'resources/main/mypage/cancel_disable_button.svg';
import  DisableCancelBtnMobile from 'resources/main/mypage/cancel_disable_button_mobile.svg';

import { compareRefundDate, convertDate, convertDateTime, convertNumberFormat, isObjEmpty, todayDateTime } from 'utils/util';
import { getGuestData, getUserData, isUserLoggedIn, isGuestLoggedIn } from 'utils/jwtUtil';

import { orderList } from './store/action';
import ModalController from './modal/ModalController';
import OrderDetail from './OrderDetail';
import MobileModal from './modal/MobileModal';
import { callTossPayment } from 'views/Product/store/action';

const  ListBody = ({searchDate}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.mypages);
  const [selectedOrdId, setSelectedOrdId] = useState();
  const [selectedProduct, setSelectedProduct] = useState({});

  // 모바일 사이즈 세팅
  const [mode, setMode] = useState("pc");

  const handleResize = () => {
    setMode(window.innerWidth);
    if (window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  

  // 주문상세- '보기' 버튼을 누를 시
  const [show, setShow] = useState(false);
  const showDetail = (ordId, type) => {
    setSelectedOrdId(ordId);
    // setShow(!show);
    if (type === 'close') {
      setSelectedOrdId('');
      setShow(false);
    } else if (type === 'open') {
      setShow(true);
    }
  }

  // 모바일) 상세보기 > 를 클릭 시
  const mobileshowDetail = (ordId) => {
    setSelectedOrdId(ordId);
    handleMobileModal();
  }

  // '다운로드' 버튼 클릭 시
  const downloadBtnHandler = (taskSeqno, outpUrl) => {
    if(taskSeqno) {
      if (outpUrl) {
        // const elem = {
        //   tskSeqno: taskSeqno,
        //   outpProcDvcd: "D"
        // }
        // dispatch(insertOutputUrl(elem));
            
        window.open(outpUrl);
      } else if(outpUrl===null){
        alert ('다운 받을 내용이 없습니다');
      }
    }
  }

  // 촬영 취소여부에 따른 모달 여닫기 
  const [openModal, setOpenModal] = useState(false);
  const openModalHandler = () => {
    setOpenModal(!openModal);
  }

  // 버튼에 따라 다른 모달 열기
  const [modalType, setModalType] = useState();
  const chooseModalType = (id, orderInfo) => {
    setModalType(id);
    setOpenModal(!openModal);
    setSelectedOrdId(orderInfo.ordId);

    const elem = {
      ordId: orderInfo.ordId,
      prodNm: orderInfo.prodNm,
      cnclFee: orderInfo.cnclFee,
      ordProcStcd: orderInfo.ordProcStcd,
      phgpyAddrNeedYn: orderInfo.phgpyAddrNeedYn
    }
    setSelectedProduct(elem);
  }
  
  let today = moment().format('YYYYMMDD');

  const getOrderList = () => {
    let formElem = {
      searchFrOrderDt: searchDate.concat('000000'),
      searchToOrderDt: today.concat('999999')
    }
  
    // guest user인 경우에는 custUserNo를 추가로 넣어줌
    if ( isGuestLoggedIn() ) {
      formElem = {
        ...formElem,
        custUserNo: getGuestData().custUserNo
      }
    } else {
      formElem = {
        ...formElem,
        custUserNo: getUserData().custUserNo
      }
    }

    dispatch(orderList(formElem));
  }

  // 토스 결제창 호출
  const callRepayment = (item) => {
    const payElem = {
      payMethod: item.payMthdNm,
      amount: item.prodSumAmt,
      orderId: item.ordId,
      orderName: item.prodNm,
      customerName: item.ordPerNm,
    }

    callTossPayment(payElem);
  }

  // 최초 진입 시 orderlist 정보 가져오기
  useEffect (() => { 
    getOrderList();
  }, []);

 // 해당 개월 수 (6,12,24개월) 마다 정보 읽어오기
  useEffect (() => { 
    getOrderList();
  }, [searchDate]);

  // 모바일 상세보기 버튼 클릭 시 모달열기
  const [mobileModal, setMobileModal] = useState(false);
  const handleMobileModal = () => {
    setMobileModal(!mobileModal);
  }

  return (
    <>
    { isObjEmpty(store.orderProductList) ? <EmptyOrderContainer>
        <EmptyContent>
          <EmptyTitle>주문한 내역이 없습니다</EmptyTitle>
          <EmptyTitle>이전 구매내역은 기간별 조회를 통해 확인 가능합니다</EmptyTitle>
        </EmptyContent>
      </EmptyOrderContainer>  : store.orderProductList && store.orderProductList.map( item => {
      return (
        <div key={item.ordId}>
        { mode === 'mobile' ?   
          <MobileContainer key={item.ordId}> 
            <MobileOrderWrapper>
              <MobileOrderHeader>
                <MobileOrderDetailWrapper>
                  <button 
                    type='button' 
                    onClick={(e) => { mobileshowDetail(item.ordId);} }
                  > 상세보기 >
                  </button>
                </MobileOrderDetailWrapper>
              </MobileOrderHeader>
              <MobileOrderContentWrapper>
                <MobileOrderInfo>
                  <div>
                    <span style={{fontSize:'13px', fontWeight:200}}> [주문일] </span>
                    <span style={{fontSize:'14px', fontWeight:400}}> {convertDate(item.ordDtm)} </span>
                    <p style={{marginBottom:'3px'}}></p>
                    <span style={{fontSize:'13px', fontWeight:200}}> [주문번호] 
                    <span style={{fontSize:'14px', fontWeight:400}}> {item.ordId} </span>
                    </span>
                  </div>
                  <MobileButtonStatus>
                    { item.ordProcStnm === '접수대기중' || item.ordProcStnm === '제품수령대기중' || item.ordProcStnm === '주문접수' ?  
                      <CancelButton 
                        type='button' 
                        onClick={e => item.payMthdNm === '가상계좌' ? chooseModalType('virtualAcc', item) : chooseModalType('cancelBtn', item)}
                      >취소</CancelButton> : item.ordProcStnm === '진행중' || item.ordProcStnm === '주문취소'  ?  
                      <CantCancelButton
                        type='button' 
                        id='banCancel'
                        onClick={e => chooseModalType('banCancel', item)}
                      > <img src={DisableCancelBtnMobile} />
                      </CantCancelButton> 
                      : item.ordProcStnm === '결제전' ?
                      <PaymentButton type='button' onClick={() => callRepayment(item)}>결제</PaymentButton>
                      : item.ordProcStnm === '완료' ?  
                      <DownloadButton
                        type='button'
                        onClick={() => downloadBtnHandler(item.ordId, item.outpUrl)} 
                      >다운로드
                      </DownloadButton> : '' }
                  </MobileButtonStatus>
                </MobileOrderInfo>
                <MobileOrderContent>
                  <div id="leftContent">
                    <span> [진행상태] </span>
                    <span id="bold"> {item.ordProcStnm} </span>
                    <p></p>
                    <span> [상품정보] 
                    <span id="bold"> {item.prodNm} </span>
                    </span>
                  </div>
                  <div id="rightContent">
                    <span> [촬영날짜] </span>
                    <span id="bold"> {convertDateTime(item.phgpyHopeDtm)} </span>
                    <p></p>
                    <span> [촬영금액] 
                    <span id="bold"> {convertNumberFormat(item.prodOrdTamt)} 원 </span>
                    </span>
                  </div>
                </MobileOrderContent>
              </MobileOrderContentWrapper>
            </MobileOrderWrapper>
          </MobileContainer> : mode === 'pc' ?
          <>
            <ListItem Item key={item.ordId}>
              <div>
                {(show && item.ordId === selectedOrdId) ?
                  <button 
                    type='button' 
                    id='closeBtn' 
                    onClick={(e) => showDetail(item.ordId, 'close')}
                  > 닫기
                  </button> :
                  <button 
                    type='button' 
                    onClick={(e) => showDetail(item.ordId, 'open')}
                  > 보기
                  </button>
                }
              </div>

              <div className='orderDate'>
                <span style={{fontSize:'12px'}}>
                  {convertDate(item.ordDtm)}
                </span>
                <p style={{marginBottom:'1px'}}></p>
                <span> {item.ordId} </span>
              </div>
              <div>
                <span className='productInfo' style={{fontSize:'12px'}}>{item.prodNm}</span>
                <p style={{marginBottom:'1px'}}></p>
                <span className='shootDate'>{convertDateTime(item.phgpyHopeDtm)}</span>
              </div>
              <div className='shootPrice'>{convertNumberFormat(item.prodPayAmt)} 원</div>
              <div className='progresss'>{item.ordProcStnm}</div>

              <Status>
                { compareRefundDate(item.phgpyHopeDtm, todayDateTime()) !== 'noRefund' && (item.ordProcStnm === '접수대기중' || item.ordProcStnm === '제품수령대기중' || item.ordProcStnm === '주문접수') ?  
                  <button 
                    type='button' 
                    style={{width:'85px', height:'30px', position:'relative', color:'#ABABAC', fontWeight:400, border:'0.5px solid #ABABAC', backgroundColor:'#FFFFFF', borderRadius:'35px', padding:'7px 0px'}}
                    onClick={e => item.payMthdNm === '가상계좌' ? chooseModalType('virtualAcc', item) : chooseModalType('cancelBtn', item)}
                  > 취소 
                  </button> : 
                  item.ordProcStnm === '진행중' || item.ordProcStnm === '주문취소' || compareRefundDate(item.phgpyHopeDtm, todayDateTime()) === 'noRefund' ?
                  <button 
                    type='button' 
                    onClick={e => chooseModalType('banCancel', item)}
                    id = 'banCancel'
                    // style={{position:'relative', top:'-3px', left:'5px', color:'#ABABAC', fontWeight:300, border:'none', backgroundColor:'transparent', padding:'7px 0px'}}
                  > <img src={DisableCancelBtn}/>
                  </button> :
                  item.ordProcStnm === '결제전' ?
                  <PaymentButton type='button' onClick={() => callRepayment(item)}>결제</PaymentButton> : 
                  item.ordProcStnm === '완료' ?  
                  <button 
                    type='button' 
                    onClick={() => downloadBtnHandler(item.ordId, item.outpUrl)} 
                    style={{width:'85px', height:'30px', position:'relative', color:'#FFFFFF', fontWeight:400, border:'none', backgroundColor:'#FE5A1D', borderRadius:'35px', padding:'7px 0px'}}
                  > 다운로드
                  </button> : ' ' }
              </Status>
            </ListItem>
          <OrderDetail 
            isOpenYn={show} 
            orderId={item.ordId}
            selectedOrdId={selectedOrdId}
            rowItems={(item)} 
          />
        </>
        : " " }
      </div> 
      )
     })} 
      <ModalController 
        modal={openModal}
        handleModal={openModalHandler}
        type={modalType}
        selectedOrdId={selectedOrdId}
        productInfo={selectedProduct}
      />
      <MobileModal
        modal={mobileModal}
        handleModal={handleMobileModal}
        selectedOrdId={selectedOrdId}
      />
    </>
  );
} 

export default ListBody;

const EmptyOrderContainer = styled.div`
  width: 100%;
  height: 100%;
  // background: red;
  padding-top: 60px;
`

const EmptyContent = styled.div`
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 200;
  color: #ABABAC;
  line-height: 17px; 
`

const EmptyTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`

const MobileContainer = styled.div`
  @media ${({theme}) => theme.device.mobile} {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 40px; 
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
  };  
`

const MobileOrderWrapper = styled.div`
  width: 100%;
`

const MobileOrderHeader = styled.div`
  width: 100%;
  height: 32px;
  background-color: #F5F5F5;
  padding: 7px 10px 0px;
`

const MobileOrderDetailWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0;
    width: 100px;
    height: 20px;
    text-align: end;
    color: #0094FF; 
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    // background-color: pink;
    background-color: #F5F5F5;
  }
`

const MobileOrderContentWrapper = styled.div`
  padding: 0 10px;
`

const MobileOrderInfo = styled.div`
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: space-between; 
  border-bottom: 0.25px solid #2C2C2C;
  padding-top: 11px;
`

const MobileButtonStatus = styled.div`
  width: 100px;
  height: 32px;
  
  button {
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CancelButton = styled.button`
  width: 100px; 
  height: 36px;
  position: relative; 
  color: #ABABAC;
  font-size: 16px; 
  font-weight: 400; 
  border: 0.5px solid #ABABAC;
  background-color: #FFFFFF;
  border-radius: 35px;
  padding: 7px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`

const CantCancelButton = styled.button`
  // width: 100%;
  // height: 100%;
  padding: 0;
`

const DownloadButton = styled.button`
  width: 100px;
  height: 36px; 
  position: relative; 
  color: #FFFFFF; 
  font-size: 16px; 
  font-weight: 400; 
  border: none; 
  background-color: #FE5A1D; 
  border-radius: 35px;
  padding: 7px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`

const MobileOrderContent = styled.div`
  width: 100%;
  padding: 11px 0px 9px;
  display: flex;
  justify-content: space-between;
  line-height: 16px;
  
  p {
    margin-bottom: 10px;
  }

  #leftContent {
    width: 100%;
    font-size: 14px;
    font-weight: 200;

    #bold {
      font-size: 16px;
      font-weight: 400;
    }
  }
  
  #rightContent {
    width: 100%;
    font-size: 14px;
    font-weight: 200;

    #bold {
      font-size: 16px;
      font-weight: 400;
    }
  }

  @media (max-width:460px) {
    display: flex;
    flex-wrap: wrap;
 
    #leftContent {
      width: 100%;
    }

    #rightContent {
      width: 100%;
      margin-top: 10px;

      #bold {
        margin-top: 10px;
      }
  };  
`

const EmptyList = styled.div`
  width: 782px;
  height: 34px;
  color: #ABABAC;
  font-size: 14px;
  font-weight: 200;
  text-align:center;
  
  position: relative;
  top: 60px;
  // background-color: black;
`

const ListItem = styled.div`
  width: 792px;
  height: 60px;
  border-bottom:  0.5px solid #D9D9D9; 
  border-left: none;
  border-right: none;
  display: flex;
  // padding: 15px 5px 0px;
  padding-top: 15px;
  div {
    &:nth-child(1) {
      // flex:.125;
      width: 90px;
      padding-left: 5px;
      // background-color:orange;
      #closeBtn {
        width: 50px;
        height 20px;
        background: #F5F5F5;
        box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
        border: none;

        color: #ABABAC;
        font-size: 12px;
        font-weight: 200;
      }

      button {
        width: 50px;
        height 20px;
        background: #FFFFFF;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
        border: none;
        color: #4B4B4B;
        font-size: 12px;
        font-weight: 200;
      }
      padding-top: 5px;
    }

    &:nth-child(2) {
      // flex:.195;
      width: 147px;
      padding-left: 5px;
      // background-color:yellow;
    }
    &:nth-child(3) {
      // flex:.159;
      width: 180px;
      padding-left: 5px;
      // background-color:green;
    }
    &:nth-child(4) {
      // flex:.21;
      width: 160px;
      padding-left: 5px;
      // background-color:blue;
    }
    &:nth-child(5) {
      // flex:.17;
      width: 125px;
      height: 20px;
      padding-left: 5px;
      // background-color: aqua;
    }
    &:nth-child(6) {
      // flex:.12;
      width: 90px;
      padding-right: 5px;
      // background-color:purple;
    }
  }

  font-weight: 400;
  font-size: 14px;
  padding-top: 15px;

  .productInfo, .shootDate, .shootPrice, .progresss {
    padding-top: 7px;
  }
`

const Status = styled.div`
  button {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  #banCancel {
    background-color: transparent;
    box-shadow: none;
    padding : 0;
  }
`

const PaymentButton = styled.button`
  width: 85px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  background: #2C2C2C;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 35px;
`