import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import closeIcon from 'resources/common/close_icon.png';
import { convertDate, convertDateTime } from 'utils/util';
import {orderDetailInfo, orderList } from '../store/action';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetail from '../OrderDetail';
// import MobileOrderDetail from '../MobileOrderDetail';

const  MobileModal = ( {modal, handleModal, selectedOrdId } ) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.mypages);
  const [selectedItem, setSelectedItem] = useState({});


  useEffect (() => {
    if (selectedOrdId !== undefined) {
      store.orderProductList && store.orderProductList?.forEach( item => {
        if (item.ordId === selectedOrdId) {
          setSelectedItem(item);
          dispatch(orderDetailInfo(selectedOrdId));
        }
      })
    }
  }, [selectedOrdId]);

  return (
    <Modal 
      isOpen={modal}
      toggle={handleModal}
      modalClassName="customModal"
      style={{
        width: '100%',
        minWidth: '360px',
        position: 'absolute',
        marginTop: '30px',
        marginLeft: '0px',
        padding: '0px',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}
    >
      <ModalBody 
        style={{
          width: '100%',
          border:'0px solid #2C2C2C',
          background: '#F5F5F5',
          borderRadius: '10px',
          padding: '0',
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
      >
        <Closeicon type='button' className='closeIcon' onClick={handleModal}></Closeicon> 
        <Container>
          <Title> 주문상세 </Title>
          <ContentBox>
            <TopContent>
              <div id='top'>
                <span>[주문일] {convertDate(selectedItem.ordDtm)}</span>
                <span>[주문번호] {selectedItem.ordId}</span>
              </div>
              <div id='bottom'>
                <span>[상품정보] {selectedItem.prodNm}</span> 
              </div>
            </TopContent>
            <BottomContent>
              <OrderDetail
                orderId={selectedItem.ordId}
                rowItems={(selectedItem)} 
              />
            </BottomContent>
          </ContentBox>
        </Container>
      </ModalBody>
    </Modal> 
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`

const Title = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  // margin-bottom: 35px;
  margin-top: 50px;

  // background-color: red;
`
  
const ContentBox = styled.div`
  width: 100%;
  margin-top: 35px;
  // background-color: green;
`

const Content = styled.div`
`

const TopContent = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  padding: 8px 15px 8px 15px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);

  div {
    line-height: 16px;
    display: flex;
    justify-content: space-between;
  }

 #bottom {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    margin-top: 6px;
 }
`

const BottomContent = styled.div`
`

const Closeicon = styled.button`
  position: absolute;
  top: 15px;
  right: 9px;
  height: 15px;
  width: 15px;
  display: block;
  overflow: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${closeIcon});
  cursor: pointer;
  z-index: 11;
  border:white;
  background-color: transparent;

  &:hover{
    transform: rotate(90deg);
  }

`


export default  MobileModal;