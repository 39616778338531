import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper';
import { Pagination } from 'swiper/modules';

import { getProductInfo, getUserSimpleInfo } from './store/action';
import { convertNumberFormat, isObjEmpty } from 'utils/util';
import { checkVisitorSession, getGuestData, getUserData, setVisitorSession } from 'utils/jwtUtil';
import { mainApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import ProductDetail from './ProductDetail';

import CheckboxChecked from 'resources/common/checkbox_checked.png';
import CheckboxOrangeChecked from 'resources/common/checkbox_orange_checked.svg';
import CheckboxUnChecked from 'resources/common/checkbox.png';
import RadioButton from 'resources/common/radio.svg';
import CheckedRadioButton from 'resources/common/radio_checked.svg';
import Question from 'resources/product/question.svg';
import QuestionBlue from 'resources/product/question_blue.svg';
import { ReactComponent as ArrowUp } from 'resources/product/arrow_up.svg';
import { ReactComponent as ArrowDown } from 'resources/product/arrow_down.svg';

import 'swiper/css';
import 'swiper/css/pagination';

const Product = () => {
  const store = useSelector(state => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prodId } = useParams(); // url path로부터 받은 상품ID 
  const [searchParams] = useSearchParams();

  /* 상품 정보 관련 변수 */
  const [productInfo, setProductInfo] = useState({}); // 상품 정보
  const [optionList, setOptionList] = useState([]); // 옵션 목록
  const [optionItemList, setOptionItemList] = useState([]); // 옵션 항목 목록
  const [thumbNailList, setThumbNailList] = useState([]); // 썸네일 이미지 목록
  const [detailImageList, setDetailImageList] = useState([]); // 상품 상세 이미지 목록
  const [detailMobileImageList, setDetailMobileImageList] = useState([]); // 상품 모바일 상세 이미지 목록
  const [boardList, setBoardList] = useState([]); // 상품 FAQ 목록
  const [currentOptionSeqnoList, setCurrentOptionSeqnoList] = useState([]); // 선택한 옵션 Seqno 목록
  const [currentOptionItemInfo, setCurrentOptionItemInfo] = useState(); // 선택한 옵션 항목 정보(라디오버튼)
  const [currentOptionItemId, setCurrentOptionItemId] = useState(); // 선택한 옵션 항목Id(라디오버튼)
  const [currentOptionItemIdPopup, setCurrentOptionItemIdPopup] = useState(); // 선택한 옵션 항목Id(라디오버튼) 팝업
  const [currentOptItemNm, setCurrentOptItemNm] = useState(''); // 선택한 옵션 항목명
  
  const [mainThumbNail, setMainThumbNail] = useState(); // 메인 썸네일

  /* 금액관련 변수 */
  const [basicAmount, setBasicAmount] = useState(0); // 기본 금액
  const [totalAmount, setTotalAmount] = useState(0); // 총 상품금액(상품주문총금액)
  const [totalPayAmount, setTotalPayAmount] = useState(0); // 최종 결제 금액(상품결제금액)

  /* 제휴 */
  const [isApplyCopDsc, setIsApplyCopDsc] = useState(false); // 제휴할인가격 적용여부
  const [copDscKey, setCopDscKey] = useState(); // 제휴 키값

  /* 구매하기 슬라이드 팝업 관련 변수 */
  const [clickToggle, setClickToggle]  = useState(false); // 구매하기 버튼 토글

  /**
   * 주문을 위한 선택한 옵션 목록 갱신 함수
   * @param {Map} optionInfo : 옵션 정보
   * @param {number} optCount : 옵션 주문 수량
   * @param {number} optAmount : 옵션 주문 금액
   * @param {Map} optionItemInfo : 옵션 항목 정보
   * @param {boolean} isSplice : 주문 목록에서 선택 항목 삭제 여부
   */
  const handleOrderInfo = (optionInfo, optCount, optAmount, optionItemInfo, isSplice) => {
    let optOrderInfo = {}; // 옵션 주문 정보
    let orderDupl = false; // 이미 담겨있는 옵션인지 확인

    currentOptionSeqnoList.map((optItem, i) => {
      // 이미 주문항목에 담겨있을 경우 해당 옵션 정보 갱신
      if (optItem.optSeqno === optionInfo.optSeqno) {
        orderDupl = true;

        // 체크박스 해제할 경우 해당 항목 삭제
        if (isSplice) {
          currentOptionSeqnoList.splice(i, 1);
        } else {
          currentOptionSeqnoList[i].optOrdCnt = optCount;
          currentOptionSeqnoList[i].optOrdAmt = optAmount;
        }
      }
    });

    // 새로 담는 옵션이거나 라디오 항목일 경우
    if (!orderDupl || !isObjEmpty(optionItemInfo)) { 
      optOrderInfo = {
        optSeqno: optionInfo.optSeqno, // 옵션 seqno
        optNm: optionInfo.optNm, // 옵션 명
        plurPsyn: optionInfo.plurPsyn, // 복수 가능 여부
        optOrdCnt: optCount, // 옵션 주문 수량
        optPrc: Number(optionInfo.optPrc), // 옵션 단가
        optOrdAmt: Number(optAmount), // 옵션 주문 금액
        optItmSeqno: optionItemInfo?.optItmSeqno,
        optItmOrdCnt: optionItemInfo?.optItmOrdCnt,
        optItmOrdAmt: optionItemInfo?.optItmPrc,
      }
    }

    if (!Number(optionInfo.optPrc)) {
      setCurrentOptionItemInfo(optOrderInfo);
    }

    orderDupl ? setCurrentOptionSeqnoList(currentOptionSeqnoList) : setCurrentOptionSeqnoList([...currentOptionSeqnoList, optOrderInfo])
  }

  useEffect(() => {
    if (isObjEmpty(currentOptionSeqnoList) && !isObjEmpty(currentOptionItemInfo)) {
      setCurrentOptionSeqnoList([currentOptionItemInfo]);
    }
  }, [currentOptionSeqnoList]);

  /**
   * 선택한 라디오 옵션 동작
   * @param {*} option 선택한 옵션 정보
   * @param {*} optItem 선택한 라디오 옵션 정보(옵션항목)
   * @param {*} modal 모달호출 시 값 세팅여부
   */
  const clickRadioItem = (option, optItem, modal) => {
    const opt = document.querySelectorAll('input[type=checkbox][id^=opt], input[type=checkbox][id^=optP]');
    const optBox = document.querySelectorAll('div[id^=optBox], div[id^=optBoxP]');
    const optCountBox = document.querySelectorAll('div[id^=optCntBox], div[id^=optCntBoxP]');
    const optCountInput = document.querySelectorAll('input[id^=optOrdCnt], input[id^=optOrdCntP]');

    // 옵션 정보
    let optPrice = Number(option.optPrc); // 옵션 가격(단일 가격)
    let optCount = 1; // 옵션 개수
    let optAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    // 옵션항목 정보
    let optItmPrice = Number(optItem.optItmPrc); // 옵션 가격(단일 가격)
    let optItmCount = 1; // 옵션 개수
    let optItmAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    optAmount = optPrice * optCount;
    optItmAmount = optItmPrice * optItmCount;

    let optionItem = {
      ...optItem,
      // optItmOrdAmt: optItmAmount,
      optItmOrdCnt: optItmCount,
    }

    // checkbox css 초기화 및 복수 input 1로 초기화
    opt?.forEach((item) => {
      item.checked = false;
    });

    optBox?.forEach((item) => {
      item.classList.add('opacity');
    });

    optCountBox?.forEach((item) => {
      item.classList.add('none');      
    });

    optCountInput?.forEach((item) => {
      item.value = 1;
    });

    setCurrentOptionItemId(`opt${optItem.optSeqno}_${optItem.optItmSeqno}`); // 선택한 라디오 버튼 css
    setCurrentOptionItemIdPopup(`optP${optItem.optSeqno}_${optItem.optItmSeqno}`); // 선택한 라디오 버튼 css
    setCurrentOptItemNm(optItem.optItmNm); // 옵션항목명
    setBasicAmount(optItem.optItmPrc); // 기본금액

    // 선택한 옵션 목록 저장
    handleOrderInfo(option, optCount, optAmount, optionItem, false);
  }

  /**
   * 선택한 옵션
   * @param {*} item 선택한 옵션
   * @param {*} index index
   * @param {boolean} modal 모달호출 시 값 세팅여부
   */
  const clickCheckItem = (item, index, modal) => {
    // TODO opt_itm_prc 고려 필요
    // 선택한 옵션 element 
    const elemOpt = document.querySelector(`#opt${item.optSeqno}`);
    const elemOptBox = document.querySelector(`#optBox${item.optSeqno}`); // 클릭 시 css 적용
    const elemOptCntBox = document.querySelector(`#optCntBox${item.optSeqno}`); // 클릭 시 css 적용

    // 슬라이더 팝업 변수
    const elemOptP = document.querySelector(`#optP${item.optSeqno}`);
    const elemOptBoxP = document.querySelector(`#optBoxP${item.optSeqno}`); // 클릭 시 css 적용
    const elemOptCntBoxP = document.querySelector(`#optCntBoxP${item.optSeqno}`); // 클릭 시 css 적용
 
    let totAmount = Number(totalAmount); // 총 금액
    let totPayAmount = Number(totalAmount); // 최종 결제 금액
    let optPrice = Number(item.optPrc); // 옵션 가격(단일 가격)
    let optCount = 1; // 옵션 개수
    let optAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    // 복수여부가 Y일 경우(개수를 선택할 수 있는 옵션)
    if (item.plurPsyn === 'Y') {
      optCount = Number(document.querySelector(`#optOrdCnt${item.optSeqno}`).value);
    }
    
    optAmount = optPrice * optCount;

    if (elemOpt.checked) {
      // css 적용
      elemOptBox.classList.remove('opacity');
      if (item.plurPsyn === 'Y') {
        elemOptCntBox.classList.remove('none');
      }

      handleOrderInfo(item, optCount, optAmount, null, false); // 현재 선택한 옵션 목록 저장
      
      // 총 상품금액 갱신
      totAmount += optAmount;
    } else {
      // css 적용
      if (item.plurPsyn === 'Y') {
        elemOptCntBox.classList.add('none');
      }
      elemOptBox.classList.add('opacity');

      handleOrderInfo(item, optCount, optAmount, null, true); // 현재 선택한 옵션 목록 저장

      // 총 상품금액 갱신
      totAmount -= optAmount;
    }
    
    // 제휴 할인 상품일 경우 제휴할인금액 최종 결제금액에 포함
    if (productInfo.copDscYn === 'Y' && isApplyCopDsc) {
      totPayAmount = Number(totAmount - productInfo.copDscAmt);
    } else {
      totPayAmount = totAmount;
    }

    if (!modal) {
      // 총 상품금액 중복 계산되지 않도록 모달이 닫혀있을 때만 값 세팅
      setTotalAmount(totAmount);
      setTotalPayAmount(totPayAmount);
    }
    
    if (modal) {
      // 옵션이 체크 되어있을 경우 
      if (elemOpt.checked) {
        elemOptP.checked = true;

        // css 적용
        elemOptBox.classList.remove('opacity');
        elemOptBoxP.classList.remove('opacity');
        if (item.plurPsyn === 'Y') {
          elemOptCntBox.classList.remove('none');
          elemOptCntBoxP.classList.remove('none');
          document.querySelector(`#optOrdCntP${item.optSeqno}`).value = optCount;
        }
      } else {
        elemOptP.checked = false;

        // css 적용
        if (item.plurPsyn === 'Y') {
          elemOptCntBoxP.classList.add('none');
        }
        elemOptBoxP.classList.add('opacity');
      }
    }
  }

  /**
   * 선택한 옵션 (팝업)
   * @param {*} item 선택한 옵션
   * @param {*} index index
   */
  const clickPopupCheckItem = (item, index) => {
    // TODO opt_itm_prc 고려 필요
    // 선택한 옵션 element 
    const elemOpt = document.querySelector(`#opt${item.optSeqno}`);
    const elemOptBox = document.querySelector(`#optBox${item.optSeqno}`); // 클릭 시 css 적용
    const elemOptCntBox = document.querySelector(`#optCntBox${item.optSeqno}`); // 클릭 시 css 적용
   
    // 슬라이더 팝업 변수
    const elemOptP = document.querySelector(`#optP${item.optSeqno}`);
    const elemOptBoxP = document.querySelector(`#optBoxP${item.optSeqno}`); // 클릭 시 css 적용
    const elemOptCntBoxP = document.querySelector(`#optCntBoxP${item.optSeqno}`); // 클릭 시 css 적용
    
    let totAmount = Number(totalAmount); // 총 금액
    let totPayAmount = Number(totalAmount); // 최종 결제 금액
    let optPrice = Number(item.optPrc); // 옵션 가격(단일 가격)
    let optCount = 1; // 옵션 개수
    let optAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    // 복수여부가 Y일 경우(개수를 선택할 수 있는 옵션)
    if (item.plurPsyn === 'Y') {
      optCount = Number(document.querySelector(`#optOrdCntP${item.optSeqno}`).value);
    }
    
    optAmount = optPrice * optCount;

    // 옵션이 체크 되어있을 경우 
    if (elemOptP.checked) { 
      elemOpt.checked = true;
    } else {
      elemOpt.checked = false;
    }

    if (elemOptP.checked) {
      // css 적용
      elemOptBox.classList.remove('opacity');
      elemOptBoxP.classList.remove('opacity');
      if (item.plurPsyn === 'Y') {
        elemOptCntBox.classList.remove('none');
        elemOptCntBoxP.classList.remove('none');
      }

      handleOrderInfo(item, optCount, optAmount, null, false); // 현재 선택한 옵션 목록 저장

      // 총 상품금액 갱신
      totAmount += optAmount;
    } else {
      // css 적용
      if (item.plurPsyn === 'Y') {
        elemOptCntBox.classList.add('none');
        elemOptCntBoxP.classList.add('none');
      }
      elemOptBox.classList.add('opacity');
      elemOptBoxP.classList.add('opacity');

      handleOrderInfo(item, optCount, optAmount, null, true); // 현재 선택한 옵션 목록 저장

      // 총 상품금액 갱신
      totAmount -= optAmount;
    }

    // 제휴 할인 상품일 경우 제휴할인금액 최종 결제금액에 포함
      if (productInfo.copDscYn === 'Y' && isApplyCopDsc) {
      totPayAmount = Number(totAmount - productInfo.copDscAmt);
    } else {
      totPayAmount = totAmount;
    }

    setTotalAmount(totAmount);
    setTotalPayAmount(totPayAmount);
  }

  /**
   * 상품 옵션 개수 + 버튼
   * @param {*} item 선택한 옵션
   * @param {*} index index
   * @param {boolean} modal 모달호출 시 값 세팅여부
   */
  const clickPlusCount = (item, index, modal) => {
    const elemOpt = document.querySelector(`#opt${item.optSeqno}`);
    const elemOptCnt = document.querySelector(`#optOrdCnt${item.optSeqno}`);
    const elemOptCntP = document.querySelector(`#optOrdCntP${item.optSeqno}`);

    let totAmount = Number(totalAmount); // 총 금액
    let totPayAmount = Number(totalAmount); // 최종 결제 금액
    let optPrice = Number(item.optPrc); // 옵션 가격(단일 가격)
    let optCount = 1; // 옵션 개수
    let optAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    let elemIdVal = Number(elemOptCnt.value); // 옵션 개수
    
    // 증가한 옵션수를 화면에 표시
    elemIdVal += 1;
    elemOptCnt.value = elemIdVal;

    if (modal) {
      elemOptCntP.value = elemIdVal;
    }

    optCount = Number(elemIdVal);
    optAmount = optPrice * optCount;

    if(elemOpt.checked) {
      // 주문 옵션 갱신
      handleOrderInfo(item, optCount, optAmount, null, false);

      totAmount += Number(item.optPrc);
      setTotalAmount(totAmount);

      // 제휴 할인 상품일 경우 제휴할인금액 최종 결제금액에 포함
      if (productInfo.copDscYn === 'Y' && isApplyCopDsc) {
        totPayAmount = Number(totAmount - productInfo.copDscAmt);
      } else {
        totPayAmount = totAmount;
      }
      setTotalPayAmount(totPayAmount);
    }
  }

  /**
   * 상품 옵션 개수 - 버튼
   * @param {*} item 선택한 옵션
   * @param {*} index index
   * @param @param {boolean} modal 모달호출 시 값 세팅여부
   */
  const clickMinusCount = (item, index, modal) => {
    const elemOpt = document.querySelector(`#opt${item.optSeqno}`);
    const elemOptCnt = document.querySelector(`#optOrdCnt${item.optSeqno}`);
    const elemOptCntP = document.querySelector(`#optOrdCntP${item.optSeqno}`);

    let totAmount = Number(totalAmount); // 총 금액
    let totPayAmount = Number(totalAmount); // 최종 결제 금액
    let optPrice = Number(item.optPrc); // 옵션 가격(단일 가격)
    let optCount = 1; // 옵션 개수
    let optAmount = 0; // 옵션 총 가격(optPrice * optAmount)

    let elemIdVal = Number(elemOptCnt.value); // 옵션 개수

    // 감소한 옵션수를 화면에 표시
    if (elemIdVal > 1) {
      elemIdVal -= 1;
      elemOptCnt.value = elemIdVal;

      if (modal) {
        elemOptCntP.value = elemIdVal;
      }

      optCount = Number(elemIdVal);
      optAmount = optPrice * optCount;
  
      if(elemOpt.checked) {
        // 주문 옵션 갱신
        handleOrderInfo(item, elemIdVal, optAmount, null, false);

        totAmount -= Number(item.optPrc);
        setTotalAmount(totAmount);
        
        // 제휴 할인 상품일 경우 제휴할인금액 최종 결제금액에 포함
        if (productInfo.copDscYn === 'Y' && isApplyCopDsc) {
          totPayAmount = Number(totAmount - productInfo.copDscAmt);
        } else {
          totPayAmount = totAmount;
        }
        setTotalPayAmount(totPayAmount);
      } 
    } 
  }

  /* 썸네일 작은 이미지 클릭 시 메인 썸네일로 표시 */
  const showImage = (thumbNail) => {
    setMainThumbNail(thumbNail.atchFilePath + '/' + thumbNail.atchFileSaveNm);
  }

  /* 모바일 구매하기/결제하기 버튼 */
  const clickMobileButton = () => {
    const buyBtn = document.querySelector('.buy');
    const payBtn = document.querySelector('.pay');

    setClickToggle(!clickToggle);
    
    if (!clickToggle) {
      buyBtn.style.display = 'none';
      payBtn.style.display = 'block';
    } else {
      buyBtn.style.display = 'block';
      payBtn.style.display = 'none';
    }
  }

  /* 구매하기 */
  const handleBuyProduct = () => {
    // 비회원 로그인 정보 존재 할 경우 remove
    if (getGuestData()) {
      localStorage.removeItem('briphyMarketplaceGuest');
    }

    // 체크한 옵션 목록 가져오기
    // 옵션 총 금액
    let totalOptionAmount = 0;
    currentOptionSeqnoList.map(item => {
      totalOptionAmount += (Number(item.optPrc) * Number(item.optOrdCnt));
    });

    // 상품 합계 금액
    let productSumAmount = Number(basicAmount) * 1; // 1: 상품 주문 수량

    // TODO 추후 확장 시 항목 변경 필요
    const formElem = {
      prodId : productInfo.prodId, // 상품ID
      prodNm: productInfo.prodNm, // 상품명
      ordProcStcd: '10', // 주문진행상태: 결제전(10)
      sellPrc: Number(basicAmount), // 상품 금액      
      prodOrdCnt: 1, // 상품 주문 수량
      prodSumAmt: productSumAmount, // 상품 합계 금액 
      optSumAmt: totalOptionAmount, // 옵션 합계 금액
      prodOrdTamt: totalAmount, // 상품주문총금액
      copDscAplyAmt: isApplyCopDsc ? Number(productInfo.copDscAmt) : 0, // 제휴할인적용금액
      prodPayAmt: isApplyCopDsc ? Number(totalAmount - productInfo.copDscAmt) : Number(totalAmount), // 상품결제금액((상품+옵션)*수량 - 제휴할인적용금액)
      // prodPayAmt: Number(productSumAmount + totalOptionAmount) - Number(productInfo.copDscAmt), // 상품 결제금액((상품+옵션)*수량 - 제휴할인적용금액)
      cnclFee: productInfo.cnclFee, // 취소수수료
      ordProdOptList: currentOptionSeqnoList, // 선택한 옵션 정보
      copDscYn: productInfo.copDscYn, // 제휴할인여부
      isApplyCopDsc: isApplyCopDsc, // 제휴할인가격 적용 여부
      optItemNm: currentOptItemNm, // 선택한 라디오 옵션명
      optItemListYn : !isObjEmpty(optionItemList) ? 'Y' : 'N',
      prodCtgrId: productInfo.prodCtgrId, // 카테고리ID
    }

    // 회원/비회원 정보 체크 후 주문/결제 페이지로 이동
    if (getUserData()) {
      dispatch(getUserSimpleInfo(getUserData().custUserNo));
      navigate('/payment', {state: formElem});
    } else if (getGuestData()) {      
      navigate('/payment', {state: formElem});      
    } else {
      // TODO 문구 변경 필요
      handleAlertText('접근 불가!', '로그인 후 이용가능합니다.', 'error').then((result) => {
        if (result.isConfirmed) {
          navigate('/product/login', {state: formElem});
        }
      });
    }
  }

  useEffect(() => {
    store.productOptionItemList = [];
    store.productBoardList = [];
  }, []);

  /**
   * 구매하기 모달 팝업 슬라이드 토글에 따라 선택한 옵션 값 세팅
   */
  useEffect(() => {
    if (clickToggle) {
      // 현재 선택된 옵션 목록 세팅
      currentOptionSeqnoList.map((item, index) => {
        if (Number(item.optPrc)) {
          clickCheckItem(item, index, true);
        }
      });
    }
  }, [clickToggle]);

  // 초기 화면 접속 시 상품 정보 받아오기
  useEffect(() => {
    dispatch(getProductInfo(prodId));

    // 초기 화면 접속 시 접속 이력 저장
    if (!checkVisitorSession(prodId)) {
      mainApi.insertConnectInfo({prodId: prodId, pageDvnm: '상품'});
      setVisitorSession(prodId);
    }

    // 제휴상품 URL 접속 시 query param
    if (searchParams.get('copDscKey')) {
      setCopDscKey(searchParams.get('copDscKey'));
    }
  }, []);

  // 상품 정보 세팅
  useEffect(() => {
    if (!isObjEmpty(store.productInfo)) {
      setProductInfo(store.productInfo);
      setOptionList(store.productOptionList);
      setOptionItemList(store.productOptionItemList);
      setBasicAmount(store.productInfo.sellPrc);
      setTotalAmount(store.productInfo.sellPrc);
      setTotalPayAmount(store.productInfo.sellPrc);

      // URL로 접속한 제휴상품일 경우 제휴키값 검증
      let isApply = store.productInfo.copDscKey === copDscKey; // 제휴할인가격 적용여부
      setIsApplyCopDsc(isApply);
      if (!isObjEmpty(copDscKey) && !isApply) {
        handleAlertText('접근불가!', '유효하지 않은 URL 입니다.', 'error');
      }

      // 제휴 상품일 경우 제휴할인금액을 최종 결제 금액에 적용
      if (store.productInfo.copDscYn === 'Y' && isApply) {
        let totPayAmount = Number(store.productInfo.sellPrc - store.productInfo.copDscAmt);
        setTotalPayAmount(totPayAmount);
      }
      
      const thumbTemp = [];
      const detailTemp = [];
      const detailMobileTemp = [];
      store.productAttachFileList?.map(row => {
        // 상품 썸네일 이미지
        if (row.atchFileDvcd === '041') {
          thumbTemp.push(row);
        }

        // 상품 상세 이미지
        if (row.atchFileDvcd === '042') {
          detailTemp.push(row);
        }

        // 상품 모바일 상세 이미지
        if (row.atchFileDvcd === '043') {
          detailMobileTemp.push(row);
        }
      });
      
      // 메인에 표시할 이미지가 없을경우 (썸네일 클릭 안했을 경우) 기본으로 첫 번째 썸네일 보여줌
      setMainThumbNail(thumbTemp[0].atchFilePath + '/' + thumbTemp[0].atchFileSaveNm);
      setThumbNailList(thumbTemp);
      setDetailImageList(detailTemp);
      setDetailMobileImageList(detailMobileTemp);

      // FAQ 목록
      if (store.productBoardList?.length) {
        setBoardList(store.productBoardList);
      }
    }
  }, [store.productInfo]);

  /**
   * 기본 상품 금액이 변할경우 값 세팅
   * 라디오 옵션에 의해 기본 상품 금액이 변함
   */
  useEffect(() => {
    let totAmount = Number(basicAmount);
    if (productInfo.copDscYn === 'Y' && isApplyCopDsc) {
      totAmount -= Number(productInfo.copDscAmt);
    } 
    setTotalAmount(basicAmount);
    setTotalPayAmount(totAmount);
    setCurrentOptionSeqnoList([]);
  }, [basicAmount]);

  useEffect(() => {
    // 초기 화면 로딩 시 선택한 옵션 없을 경우 + 라디오 옵션이 있을경우
    if (isObjEmpty(currentOptionItemId) && !isObjEmpty(store.productOptionItemList)) { 
      // 첫 번째 항목을 선택하도록 호출
      setTimeout(() => {
        let optionInfo = {};
        store.productOptionList.map(item => {
          if (item.optSeqno === store.productOptionItemList[0].optSeqno) {
            optionInfo = item;
          }
        });
        clickRadioItem(optionInfo, store.productOptionItemList[0]);
      }, 100);
    }
  }, [store.productOptionItemList]);

  useEffect(() => {
    navigate(".", { replace: true});
  }, [navigate]);

  return (
    <Container>
      <ProductContainer>
        <Menu><Link to={'/product'}>촬영상품</Link>{` > ${productInfo.prodCtgrNm}`}</Menu>
        <ProductOrder>
          <ThumbNailWrapper>
            <MainThumbNail image={`${process.env.REACT_APP_AWS_S3_URL}/${mainThumbNail}`} />
            <ThumbNailList>
              {thumbNailList?.map(thumb => {
                return (
                  <ThumbNailItem
                    onClick={() => showImage(thumb)}
                    key={thumb.atchNo} 
                    image={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`}
                  />
                )
              })}
            </ThumbNailList>
          </ThumbNailWrapper>
          <ThumbnailSwiper>
            <Swiper
              pagination={true}
              observer={true}
              observeParents={true}
              autoHeight={true}
              modules={[Pagination]}
            >
              {thumbNailList?.map(thumb => {
                return (
                  <SwiperSlide key={thumb.atchNo}>
                    <MobileThumbNailItem
                      image={`${process.env.REACT_APP_AWS_S3_URL}/${thumb.atchFilePath}/${thumb.atchFileSaveNm}`}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </ThumbnailSwiper>

          <OrderDetail>
            <ProductTitle>
              <TitleItem>
                <span className='productName'>{productInfo.prodNm}</span>
              </TitleItem>
              <TitleItem>
                {productInfo.sellPrc !== '0' ?
                  <>
                    <span className='priceTxt'>판매가</span>
                    <span className='price'>{convertNumberFormat(productInfo.sellPrc)}원</span>
                  </> : ''
                }
                <span className='summary'>
                  {/* 상품 설명 */}
                  {/* {`${productInfo.prodSmryCtnt} `} */}
                  {!isObjEmpty(productInfo.descAtchFileSeqno) ?
                    <span className='tooltip'>
                      서비스 설명 <QuestionIcon src={QuestionBlue} />
                      <ToolTipBox className='tooltip-text'>
                        <img src={`${process.env.REACT_APP_AWS_S3_URL}/${productInfo.descAtchFilePath}/${productInfo.descAtchFileSaveNm}`} />
                      </ToolTipBox>
                    </span> : '' }
                </span>
              </TitleItem>
            </ProductTitle>

            {/* optPrc(옵션가격) = 0 : 라디오버튼 */}
            {!isObjEmpty(optionItemList) ?
              <Option isOption={optionItemList}>
                {optionList?.map((item) => ( 
                  Number(item.optPrc) === 0 ?
                    optionItemList?.map((optItem, index) => (
                      <OptionRadioItem key={`${optItem.optSeqno}_${optItem.optItmSeqno}`}>
                        <OptionRadioButton
                          id={`opt${optItem.optSeqno}_${optItem.optItmSeqno}`}
                          name={`opt${optItem.optSeqno}`}
                          onChange={() => clickRadioItem(item, optItem, index)}
                          checked={currentOptionItemId === `opt${optItem.optSeqno}_${optItem.optItmSeqno}`}
                        />
                        <OptionLabel 
                          htmlFor={`opt${optItem.optSeqno}_${optItem.optItmSeqno}`} 
                          className={currentOptionItemId === `opt${optItem.optSeqno}_${optItem.optItmSeqno}` ? 'selected' : ''}
                        >
                          <div>{optItem.optItmNm}<span> {optItem.rmk}</span></div>
                          <div className='price'>{convertNumberFormat(optItem.optItmPrc)}원</div>
                        </OptionLabel>
                      </OptionRadioItem>
                    ))
                  : ''
                ))}
              </Option> : ''
            }

            {/* optPrc(옵션가격) != 0 : 체크박스 */}
            {/* 아래 조건은 라디오 옵션만 있을 경우 출력 X */}
            {optionList.length === 1 && !isObjEmpty(optionItemList) ? '' :
              <Option isOption={optionList} >
                {optionList?.map((item, index) => ( 
                  Number(item.optPrc) !== 0 ?
                  <OptionItem id={`optBox${item.optSeqno}`} key={item.optSeqno} className='opacity'>
                    <OptionNameField key={index}>
                      <label htmlFor={`opt${item.optSeqno}`}>
                        <Check 
                          id={`opt${item.optSeqno}`}
                          name={`opt${item.optSeqno}`}
                          onClick={() => clickCheckItem(item, index)}
                        /><i className='check-icon'/>
                        <span className='opt'>{item.optNm}</span>
                      </label>
                    </OptionNameField>
                    {item.plurPsyn === 'Y' ?
                      <>
                        <OptionCount id={`optCntBox${item.optSeqno}`} className='none'>
                          <span type='button' className='button' onClick={() => clickMinusCount(item, index, false)}>-</span>
                          <input type='text' id={`optOrdCnt${item.optSeqno}`} name={`optOrdCnt${item.optSeqno}`} defaultValue={1} readOnly />
                          <span type='button' className='button' onClick={() => clickPlusCount(item, index, false)}>+</span>
                        </OptionCount>
                      </> : ''
                    }
                    <OptionPrice>{convertNumberFormat(item.optPrc)} 원</OptionPrice>
                    <OptionComment>{item.optDesc}</OptionComment>
                  </OptionItem> : ''
                ))}
              </Option>
            }

            {/* 제휴 옵션 */}
            { productInfo.copDscYn === 'Y' && isApplyCopDsc ?
              <Option isOption={true}>
                <DisCountOptionItem>
                  <OptionNameField>
                    <label htmlFor=''>
                      <Check id='' name='' defaultChecked/><i className='check-icon-color'/>
                      <span className='opt'>제휴상품 <QuestionIcon src={Question} /></span>
                      {/* 하단 코드 주석 해제 시 tooltip 적용  */}
                      {/* <span className='opt'>제휴상품<span className='tooltip'><QuestionIcon /><span className='tooltip-text'>테스트한다</span></span></span> */}
                    </label>
                  </OptionNameField>                
                  <DisCountWrapper>
                    {/* 하단 코드 주석 해제 시 할인율 적용 */}
                    {/* <OptionPrice className='discount'>80% <span>165,000 원</span></OptionPrice> */}
                    <OptionPrice color='orange'>{convertNumberFormat(productInfo.copDscAmt)} 원</OptionPrice>
                  </DisCountWrapper>
                </DisCountOptionItem>
              </Option> : ''
            }

            <SelectContainer>
              <SelectOptionWrapper>
                <SelectOption>기본{!isObjEmpty(optionItemList) ? `(${currentOptItemNm})`: '' }</SelectOption>
                {/* <SelectOption weight={500}>{convertNumberFormat(productInfo.sellPrc)} 원</SelectOption> */}
                <SelectOption weight={500}>{convertNumberFormat(basicAmount)} 원</SelectOption>
              </SelectOptionWrapper>
              {currentOptionSeqnoList.map((item, index) => {
                if (Number(item.optPrc) !== 0) {
                  return (
                    <SelectOptionWrapper key={index}>
                      <SelectOption>{item.optNm} {item.plurPsyn === 'Y' ? `(수량: ${item.optOrdCnt})` : ''}</SelectOption>
                      <SelectOption weight={500}>{convertNumberFormat(item.optOrdAmt, 'Y')} 원</SelectOption>
                    </SelectOptionWrapper>
                  )
                }
              })}
            </SelectContainer>

            {/* 제휴 상품 */}
            { productInfo.copDscYn === 'Y'  && isApplyCopDsc ?
              <SelectContainer className='line'>
                <SelectOptionWrapper>
                  <SelectOption>총 상품 금액</SelectOption>
                  <SelectOption weight={500}>{convertNumberFormat(totalAmount, 'Y')} 원</SelectOption>
                </SelectOptionWrapper>
                  <SelectOptionWrapper>
                    <SelectOption>제휴 상품 할인</SelectOption>
                    <SelectOption weight={700} color='orange'>- {convertNumberFormat(productInfo.copDscAmt, 'Y')} 원</SelectOption>
                  </SelectOptionWrapper>
              </SelectContainer> : ''
            }

            <TotalAmount>
              <TotalItem>
                <span className='totalTxt'>최종 결제 금액</span>
                <span className='vatTxt'> (VAT 포함)</span>
              </TotalItem>
              <TotalItem>
                <span className='totalAmount'>{convertNumberFormat(totalPayAmount, 'Y')} 원</span>
              </TotalItem>
            </TotalAmount>
            <BuyButton onClick={() => handleBuyProduct()}>구매하기</BuyButton>
          </OrderDetail>
        </ProductOrder>        
      </ProductContainer>

      {/* 상품 상세정보 및 FAQ  */}
      <ProductDetail 
        detailImageList={detailImageList} 
        detailMobileImageList={detailMobileImageList}
        boardList={boardList}
      />

      {/* 구매하기 슬라이드 팝업 */}
      <OrderPopupSlider>
        { clickToggle ? <ShadowBox onClick={() => clickMobileButton()} /> : '' }
        <ButtonWrapper>
          <PopupBuyButton onClick={() => setClickToggle(!clickToggle)}>
            <span>구매하기</span> <span>{clickToggle ? <ArrowDown /> : <ArrowUp />}</span>
          </PopupBuyButton>
        </ButtonWrapper>
        { clickToggle ?
          <PopupContainer>
            <PopupWrapper>
              <PopupOrderDetail>
                <ProductTitle>
                  <TitleItem>
                    <span className='productName'>{productInfo.prodNm}</span>
                  </TitleItem>
                  <TitleItem>
                  {productInfo.sellPrc !== '0' ?
                    <>
                      <span className='priceTxt'>판매가</span>
                      <span className='price'>{convertNumberFormat(productInfo.sellPrc)}원</span>
                    </> : ''
                  }
                  {/* 상품 설명 */}
                  {/* <span className='summary'>{productInfo.prodSmryCtnt}</span>*/}
                  </TitleItem>
                </ProductTitle>

                {/* optPrc(옵션가격) = 0 : 라디오버튼 */}
                {!isObjEmpty(optionItemList) ? 
                  <PopupOption isOption={optionItemList} >
                    {optionList?.map((item) => ( 
                      Number(item.optPrc) === 0 ?
                        optionItemList?.map((optItem, index) => (
                          <OptionRadioItem key={`${optItem.optSeqno}_${optItem.optItmSeqno}`}>
                            <OptionRadioButton
                              id={`optP${optItem.optSeqno}_${optItem.optItmSeqno}`}
                              name={`optP${optItem.optSeqno}`}
                              onChange={() => clickRadioItem(item, optItem, index)}
                              checked={currentOptionItemIdPopup === `optP${optItem.optSeqno}_${optItem.optItmSeqno}`}
                            />
                            <OptionLabel 
                              htmlFor={`optP${optItem.optSeqno}_${optItem.optItmSeqno}`} 
                              className={currentOptionItemIdPopup === `optP${optItem.optSeqno}_${optItem.optItmSeqno}` ? 'selected' : ''}
                            >
                              <div>{optItem.optItmNm}<span> {optItem.rmk}</span></div>
                              <div className='price'>{convertNumberFormat(optItem.optItmPrc)}원</div>
                            </OptionLabel>
                          </OptionRadioItem>
                        ))
                      : ''
                    ))}
                  </PopupOption> : ''
                }

                {/* optPrc(옵션가격) != 0 : 체크박스 */}
                {/* 아래 조건은 라디오 옵션만 있을 경우 출력 X */}
                {optionList.length === 1 && !isObjEmpty(optionItemList) ? '' : 
                  <PopupOption isOption={optionList} >
                    {optionList?.map((item, index) => (
                      Number(item.optPrc) !== 0 ?
                      <OptionItem id={`optBoxP${item.optSeqno}`} key={`p${item.optSeqno}`} className='opacity'>
                        <OptionNameField key={`p${item.optSeqno}`}>
                          <label htmlFor={`optP${item.optSeqno}`}>
                            <Check 
                              id={`optP${item.optSeqno}`}
                              name={`optP${item.optSeqno}`}
                              onClick={() => clickPopupCheckItem(item, index)}
                            /><i className='check-icon'/>
                            <span className='opt'>{item.optNm}</span>
                          </label>
                        </OptionNameField>
                        {item.plurPsyn === 'Y' ?
                          <>
                            <OptionCount id={`optCntBoxP${item.optSeqno}`} key={`p${item.optSeqno}`} className='none'>
                              <span type='button' className='button' onClick={() => clickMinusCount(item, index, 'Y')}>-</span>
                              <input type='text' id={`optOrdCntP${item.optSeqno}`} name={`optOrdCntP${item.optSeqno}`} defaultValue={1} readOnly />
                              <span type='button' className='button' onClick={() => clickPlusCount(item, index, 'Y')}>+</span>
                            </OptionCount>
                          </>
                          : ''
                        }
                        <OptionPrice>{convertNumberFormat(item.optPrc)} 원</OptionPrice>
                        <OptionComment>{item.optDesc}</OptionComment>
                      </OptionItem> : ''
                    ))}

                    {/* 제휴 옵션 */}
                    {productInfo.copDscYn === 'Y' && isApplyCopDsc ?
                      <DisCountOptionItem>
                        <OptionNameField>
                          <label htmlFor=''>
                            <Check id='' name='' defaultChecked/><i className='check-icon-color'/>                          
                            <span className='opt'>제휴상품 <QuestionIcon src={Question} /></span>
                            {/* 하단 코드 주석 해제 시 tooltip 적용  */}
                            {/* <span className='opt'>제휴상품<span className='tooltip'><QuestionIcon /><span className='tooltip-text'>테스트한다</span></span></span> */}
                          </label>
                        </OptionNameField>                
                        <DisCountWrapper>
                          {/* 하단 코드 주석 해제 시 할인율 적용 */}
                          {/* <OptionPrice className='discount'>80% <span>165,000 원</span></OptionPrice> */}
                          <OptionPrice color='orange'>{convertNumberFormat(productInfo.copDscAmt)} 원</OptionPrice>
                        </DisCountWrapper>            
                      </DisCountOptionItem> : ''
                    }
                  </PopupOption>
                }
              </PopupOrderDetail>
              
              <PopupSelectWrapper>
                <SelectContainer>
                  <SelectOptionWrapper>
                    <SelectOption>기본{!isObjEmpty(optionItemList) ? `(${currentOptItemNm})`: '' }</SelectOption>
                    <SelectOption weight={500}>{convertNumberFormat(basicAmount)} 원</SelectOption>
                  </SelectOptionWrapper>
                  {currentOptionSeqnoList.map((item, index) => {
                    if (Number(item.optPrc) !== 0) {
                      return (
                        <SelectOptionWrapper key={index}>
                          <SelectOption>{item.optNm} {item.plurPsyn === 'Y' ? `(수량: ${item.optOrdCnt})` : ''}</SelectOption>
                          <SelectOption weight={500}>{convertNumberFormat(item.optOrdAmt, 'Y')} 원</SelectOption>
                        </SelectOptionWrapper>
                      )
                    }
                  })}
                </SelectContainer>

                {/* 제휴 상품 */}
                { productInfo.copDscYn === 'Y' && isApplyCopDsc ?
                  <SelectContainer className='line'>
                    <SelectOptionWrapper>
                      <SelectOption>총 상품 금액</SelectOption>
                      <SelectOption weight={500}>{convertNumberFormat(totalAmount, 'Y')} 원</SelectOption>
                    </SelectOptionWrapper>
                      <SelectOptionWrapper>
                        <SelectOption>제휴 상품 할인</SelectOption>
                        <SelectOption weight={700} color='orange'>- {convertNumberFormat(productInfo.copDscAmt, 'Y')} 원</SelectOption>
                      </SelectOptionWrapper>
                  </SelectContainer>  : ''
                }

                <TotalAmount>
                  <TotalItem>
                    <span className='totalTxt'>최종 결제 금액</span>
                    <span className='vatTxt'> (VAT 포함)</span>
                  </TotalItem>
                  <TotalItem>
                    <span className='totalAmount'>{convertNumberFormat(totalPayAmount, 'Y')} 원</span>
                  </TotalItem>
                </TotalAmount>
                <BuyButton onClick={() => handleBuyProduct()}>구매하기</BuyButton>
              </PopupSelectWrapper>
            </PopupWrapper>
          </PopupContainer> : ''
        }
      </OrderPopupSlider>
      <MobileOrderPopupSlider>
        <MobileButtonWrapper>
          <MobilePopupBuyButton className='buy' onClick={() => clickMobileButton()}>
            <span>구매하기</span>
          </MobilePopupBuyButton>
          <MobilePopupBuyButton className='pay' onClick={() => handleBuyProduct()}>
            <span>결제하기</span>
          </MobilePopupBuyButton>
        </MobileButtonWrapper>
      </MobileOrderPopupSlider>
    </Container>
  )
}

export default Product;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 62px);
`
  
const Menu = styled.div`
  width: 100%;
  margin: 120px auto 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
  
const ProductContainer = styled.div`
  margin: 0 auto 80px;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 48px 0 30px;
  }
`

const ProductOrder = styled.div`
  display: flex;
  width: 100%;
  text-align: center;  

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`

const ThumbNailWrapper = styled.div`
  width: 619px;
  margin-right: 40px;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
  
const MainThumbNail = styled.div`
  width: 100%;
  height: 404px;
  margin-bottom: 20px;
  background: url(${props => props.image}) no-repeat 95%;
  background-size: cover;
`

const ThumbnailSwiper = styled.div`
  display: none;
  width: 100%;

  // swiper bullet color
  .swiper-pagination-bullet {
    background-color: #FFFFFF;
  }
  .swiper-pagination-bullet-active {
    background-color: #FE5A1D !important;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
  }
`

const OrderDetail = styled.div`  
  width: 429px;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .line {
    border-top: 0.25px solid #D9D9D9;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: auto;
    margin: 30px 16px;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
`

const ProductTitle = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 0px;
  }
`
  
const TitleItem = styled.div`
  display: flex;
  align-items: center;
  
  & + & {
    margin-top: 5px;
  }
  
  .productName {
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
  }
  
  .priceTxt {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  
  .price {
    margin-right: 5px;
    font-size: 17px;
    font-weight: 800;
    line-height: 16px;
  }
  
  .summary {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .tooltip {
    display: contents;
    // display: inline-block;
  }

  // .tooltip-text {
  //   display: none;
  //   position: absolute;
  //   max-width: 200px;
  //   border: 1px solid;
  //   border-radius: 5px;
  //   padding: 5px;
  //   font-size: 0.8em;
  //   color: white;
  //   background: deeppink;
  // }

  .tooltip:hover .tooltip-text {
    display: block;
  }
  
  @media ${({ theme }) => theme.device.mobile} {
    & + & {
      margin-top: 4px;
    }

    .productName {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
    }
    
    .priceTxt {
      margin-right: 7px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
    
    .price {
      font-size: 14px;
      font-weight: 800;
      line-height: 17px;
    }
    
    .summary {
      // display: none;
    }
  }
`

const ToolTipBox = styled.span`
  display: none;
  // right: 28px;
  // display: block;
  margin-top: 8px;
  position: absolute;
  // max-width: 200px;
  // border: 1px solid;
  // border-radius: 5px;
  // padding: 5px;
  // font-size: 0.8em;

  width: 328px;
  background: #FFF7F4;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  @media ${({ theme }) => theme.device.mobile} {
    left: 16px;
  }
`

const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid ${props => props.isOption ? props.theme.bgColorDarkGray : props.theme.bgColorWhite};
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;

  .opacity {
    color: #ABABAC;
    background: #F5F5F5;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
`

const OptionItem = styled.div`  
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 58px;
  padding: 10px 10px 7px;
  background: #FFFFFF;
  box-shadow: inset 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  justify-content: space-between;

  & + & {
    margin-top: 15px;
  }

  .none {
    display: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 9px 10px 7px;
  }
`

const OptionRadioItem = styled.div`
  width: 100%;
  height: 16px;
  background: #FFFFFF;
  border-radius: 5px;
  color: #ABABAC;

  .selected {
    color: #2C2C2C;
  }

  & + & {
    margin-top: 15px;
  }
`

const OptionLabel = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding-left: 30px;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 13px;
    line-height: 16px;
  }

  .price {
    font-size: 16px;
    line-height: 18px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    
  }
`

const OptionRadioButton = styled.input.attrs({type: 'radio'})`
  display: none;

  &:checked + ${OptionLabel} {
    background: url(${CheckedRadioButton}) no-repeat 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  &:not(:checked) + ${OptionLabel} {
    background: url(${RadioButton}) no-repeat 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
`

const DisCountOptionItem = styled(OptionItem)`  
  height: 51px;
  background: #FFF5F2;
  box-shadow: inset 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  align-content: space-around;
    
  // .tooltip {
  //   display: inline-block;
  // }

  // .tooltip-text {
  //   display: none;
  //   position: absolute;
  //   max-width: 200px;
  //   border: 1px solid;
  //   border-radius: 5px;
  //   padding: 5px;
  //   font-size: 0.8em;
  //   color: white;
  //   background: deeppink;
  // }

  // .tooltip:hover .tooltip-text {
  //   display: block;
  // }
`

const QuestionIcon = styled.img`
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
`

const DisCountWrapper = styled.div`
  .discount {
    font-weight: 200;
    font-size: 10px;
    line-height: 12px;
    text-align: right;

    span {
      text-decoration: line-through;
    }
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;
const OptionNameField = styled.div`
  flex: 1;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  line-height: 17px;
  
  .opt {
    cursor: pointer;
    line-height: 15px;
  }

  label {
    display: flex;
    align-items: center;
  }

  ${Check} {
    display: none;
  }

  ${Check}:checked + .check-icon {
    cursor: pointer;
    background-image: url(${CheckboxChecked}); 
  }

  ${Check}:checked + .check-icon-color {
    background-image: url(${CheckboxOrangeChecked}); 
  }

  .check-icon, .check-icon-color {
    display: inline-block; 
    width: 16px;
    height: 16px;
    margin-right: 15px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%; 
    background-size: cover;
    // background: url(${CheckboxUnChecked}) left center/cover no-repeat; 
    vertical-align: middle; 
    transition-duration: .2s; 
    // transform: translated(0, -7%, 0);
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 15px;
    
    .check-icon, .check-icon-color {
      margin-right: 10px;
    }
  }
`

const OptionCount = styled.div`
  // flex: 0.3;
  font-size: 14px;
  font-weight: 400;
  
  input {
    width: 21px;
    height: 16px;
    padding: 0;
    text-align: center;
    line-height: 15px;
    border: 0.25px solid #4C4C4C;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 13px;
      padding-bottom: 1px;
    }
  }

  .button {
    cursor: pointer;
    margin: 0 5px;
  }
`

const OptionPrice = styled.div`
  width: 85px;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;

  color: ${props => props.color === 'orange' ? '#FE5A1D' : ''};
  
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 15px;
  }
`

const OptionComment = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
  margin-left: 31px; 
  font-size: 10px;
  font-weight: 200;

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    align-items: center;
    margin-left: 27px;
    min-height: 23px;
    margin-bottom: 0px;
  }
`

const SelectContainer = styled.div`
  padding: 10px 0;
  
  @media ${({ theme }) => theme.device.mobile} {
    
  }
`
const SelectOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  & + & {
    padding-top: 8px;
  }
`

const SelectOption = styled.div`
  font-size: 12px;
  line-height: 14px;  
  font-weight: ${props => props.weight ? props.weight : 200};
  color: ${props => props.color === 'orange' ? '#FE5A1D' : ''};
 
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 13px;
    line-height: 13px;
  }
`

const TotalAmount = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
  border-top: 0.25px solid #D9D9D9;

  @media ${({ theme }) => theme.device.mobile} {
    min-height: 50px;
    padding: 0;
    padding-top: 15px;
  }
`

const TotalItem = styled.div`
  .totalTxt {
    font-size: 16px;
    font-weight: 400;
  }

  .vatTxt {
    font-size: 14px;
    font-weight: 300;
    color: ${props => props.theme.txtGray};
  }

  .totalAmount {
    font-size: 24px;
    font-weight: 800;
  }
  
  @media ${({ theme }) => theme.device.mobile} {
    .totalTxt {
      font-size: 14px;
      line-height: 17px;
    }
    
    .vatTxt {
      font-size: 10px;
      line-height: 14px;
    }

    .totalAmount {
      font-size: 20px;
      line-height: 24px;
    }
  }
`

const ThumbNailList = styled.div`
  display: flex;
  width: 100%;
  height: 96px;
  padding: 11px;
  background: ${props => props.theme.bgColorWhite};
  box-shadow: inset 2px 2px 8px 2px rgba(0, 0, 0, 0.15);

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
const ThumbNailItem = styled.div`
  cursor: pointer;
  position: relative;
  width: 111px;
  height: 74px;  
  background: url(${props => props.image}) no-repeat 95%;
  background-size: cover;
   
  &:not(:first-child) {
    margin-left: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
const MobileThumbNailItem = styled.div`
  display: none;
  // position: relative;
  width: 360px;
  height: 239px;
  background: url(${props => props.image}) no-repeat 95%;
  background-size: cover;
   
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    min-width: 100%;
  }
`

const BuyButton = styled.button`
  width: 369px;
  height: 44px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  color: ${props => props.theme.txtWhite};
  background: ${props => props.theme.bgColorOrange};

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
    // width: 100%;
    // height: 54px;
    // // margin: 6px 16px;
    // position: sticky;
    // bottom: 0;
    // font-size: 18px;
    // line-height: 22px;
    // // margin-left: -198px;
    // // position: fixed;
  }
`

// 구매하기 슬라이드 팝업 관련 스타일 
const OrderPopupSlider = styled.div`
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 50;
  width: 100%;
`

const MobileOrderPopupSlider = styled(OrderPopupSlider)``

const ShadowBox = styled.div`
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.65);
`

const ButtonWrapper = styled.div`
  position: relative;
  margin: 0px auto;
  width: 1088px;

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
    // width: 100%;
  }
`

const PopupBuyButton = styled.button`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  width: 130px;
  height: 40px;
  top: -40px;
  right: 0px;
  padding: 11px;
  background: #FE5A1D;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;

  span + span {
    margin-left: 10px;
  }
`

const MobileButtonWrapper = styled.div`
  display: none;
  width: 100%;
  height: 74px;
  padding: 10px 16px;
  background-color: #FFFFFF;
  text-align: center;
  text-align: -webkit-center;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: block;

    .pay {
      display: none;
      background: #2C2C2C;
    }
  }
`

const MobilePopupBuyButton = styled.button`
  display: none;
  // position: absolute;
  width: 100%;
  // width: 328px;
  height: 54px;
  top: -54px;
  // left: 50%;
  // transform: translate(-50%);
  padding: 16px;
  background: #FE5A1D;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: inline-flex;
    justify-content: center;
  }
`

const PopupContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  display: flex;
  padding: 50px 0px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 19px 16px;
    border-radius: 5px;
    margin-bottom: 63px;
  }
`

const PopupWrapper = styled.div`
  display: flex;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;    
    margin: 0;
    width: 100%;
  }
`

const PopupOrderDetail = styled(OrderDetail)`
  width: 619px;
  background: #FFFFFF;
  margin-right: 70px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
`

const PopupSelectWrapper = styled.div`
  display: flex;
  width: 369px;
  justify-content: flex-end;
  flex-direction: column;
  
  .line {
    border-top: 0.25px solid #D9D9D9;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const PopupOption = styled(Option)`
  border-bottom: none;

  @media ${({ theme }) => theme.device.mobile} {
    border-bottom: 1px solid ${props => props.isOption ? props.theme.bgColorDarkGray : props.theme.bgColorWhite};
  }
`