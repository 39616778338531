import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'components/common/PrivateRoute';
import { useState } from "react";
// import { useLocation } from "react-router-dom";
// import { useState, useEffect } from 'react';
// import Header from 'components/Header';
// import Footer from 'components/Footer';
import Header from 'components/Header/studio';
import Footer from 'components/Footer/studio';
import PlatformMainContainer from 'views/Home/MainContainer';
import ProductMainContainer from 'views/Main/MainContainer';
import PolicyModal from 'components/common/PolicyModal';
import PrivacyPolicy from 'views/Main/PrivacyPolicy';
import Terms from 'views/Main/Terms';
import MarketingTerms from 'views/Main/MarketingTerms';
import ThirdParty from 'views/Main/ThirdParty';
import Refund from 'views/Main/Refund';
import Mypage from 'views/Mypage';
import Product from 'views/Product';
import Login from 'views/Member/Login';
import ProductLogin from 'views/Product/Login';
import MobileFind from 'views/Member/Login/MobileFind';
import Join from 'views/Member/Join';
import Welcome from 'views/Member/Join/Welcome';
import Agreement from 'views/Member/SocialLogin/Agreement';
import DeleteAccount from 'views/Mypage/DeleteAccount';
import Payment from 'views/Product/payment';
import SuccessLoading from 'views/Product/payment/SuccessLoading';
import FailLoading from 'views/Product/payment/FailLoading';
import OrderComplete from 'views/Product/payment/OrderComplete';
import ProductMain from 'views/ProductMain';
import UserInfo from 'views/Mypage/UserInfo';
import ScrollToTop from 'components/common/ScrollToTop';
import Portfolio from 'views/Portfolio';
import PortfolioContactSuccess from 'views/Portfolio/ContactSuccess';
import Quotation from 'views/Quotation';
import QuotationResult from 'views/Quotation/Result';
import QuotationContactSuccess from 'views/Quotation/ContactSuccess';
import StudioLanding from 'views/Studio/Main'
import StudioPortfolio from 'views/Studio/Portfolio';
import StudioWorkScope from 'views/Studio/WorkScope';
import StudioClients from 'views/Studio/Clients';

// import ArrowLeft from 'resources/studio/workScope/SOW_arrow_left.png';

const RootRouter = () => {
  
  const [isTop, setIsTop] = useState(false);

  /**
   * scroll 감지 useEffect
   * 스크롤 위치값 저장
   */
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />
      {/* {showButton && (
      <ScrollToTopButton><img src={ArrowLeft} onClick={() => handleScroll()} /></ScrollToTopButton>
      )} */}
      <Header isTop={isTop} />
      <Routes>
        <Route path='/' element={<StudioLanding />} />
        <Route path='/photo' element={<ProductMainContainer />} />        
        <Route path='/mass' element={<PlatformMainContainer />} />
        <Route path='/policyModal' element={<PolicyModal />} />        
        <Route path='/login' element={<Login />} />
        <Route path='/mobileFind' element={<MobileFind />} />
        <Route path='/join' element={<Join />} />
        <Route path='/welcomeSignup' element={<Welcome />} />
        <Route path='/agree' element={<Agreement />} />
        <Route path='/mypage' element={<PrivateRoute element={<Mypage />}/>} />
        <Route path='/userinfo' element={<PrivateRoute element={<UserInfo />}/>} />
        <Route path='/success' element={<SuccessLoading />} />
        <Route path='/complete' element={<OrderComplete />} />
        <Route path='/fail' element={<FailLoading />} />
        <Route path='/deleteAccount' element={<DeleteAccount />} />
        {/* <Route path='/product' element={<ProductMain />} /> 
        <Route path='/product/:prodId' element={<Product />} />
        <Route path='/product/login' element={<ProductLogin />} />
        <Route path='/payment' element={<Payment />} />  */}
        <Route path='/studio/portfolio' element={<StudioPortfolio />} />
        <Route path='/studio/workScope' element={<StudioWorkScope />} />
        <Route path='/studio/clients' element={<StudioClients />} />
        <Route path='/portfolio/old/version' element={<Portfolio />} />
        {/* <Route path='/portfolio' element={<Portfolio />} /> */}
        <Route path='/portfolio' element={<StudioPortfolio />} />
        <Route path='/portfolio/contact' element={<PortfolioContactSuccess />} />
        {/* <Route path='/quotation' element={<Quotation />} />
        <Route path='/quotation/result' element={<QuotationResult />} />
        <Route path='/quotation/contact' element={<QuotationContactSuccess />} /> */}
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/terms/1' element={<Terms />} /> 
        <Route path='/terms/2' element={<MarketingTerms />} />
        <Route path='/terms/3' element={<ThirdParty />} />        
        <Route path='/refund' element={<Refund />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default RootRouter;

const ScrollToTopButton = styled.div`
  position: sticky;
  bottom: 70px;
  float: right;
  z-index: 10000;
  right: 40px;
  background-color: #FFFFFF;
  height: 48px;
  border-radius 24px;
  rotate: 90deg;
  cursor: pointer;
`