import styled, { keyframes } from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { portfolioApi } from 'utils/api';
import FormError from 'components/common/FormError';
import { strictPhoneReg } from 'components/regulation';
import { handleAlertText } from 'components/common/SweetAlert';
import RecatchBasicForm from 'views/RecatchForm/BasicForm';

import X from 'resources/common/close_icon.png';
import Logo from 'resources/common/briphy_logo4.svg';
import CheckboxUnChecked from 'resources/common/checkbox.png';
import CheckboxChecked from 'resources/common/checkbox_checked.png';

const ContactPopup = () => {
  const navigate = useNavigate();

  // 문의 팝업 토글
  // const [clickToggle, setClickToggle]  = useState(true);

  // re:catch Viewer 모달 팝업
  const [viewerModal, setViewerModal] = useState(false);
  // Viewer 모달 팝업 
  const handleViewerModal = () => {
    setViewerModal(!viewerModal);
  }

  // 개인정보 제공 동의
  // const [agree, setAgree] = useState(false);

  // slack 채널
  const contactWebHookURL =  `${process.env.REACT_APP_SLACK_CLIENT_WEBHOOK_URL}`;

  const InputSchema = yup.object().shape({
    name: yup
      .string()
      .required('필수 입력 값입니다'),
    hpNo: yup
    .string()
    .required('필수 입력 값입니다')
    .matches(strictPhoneReg, '전화번호 형식에 맞게 입력해주세요'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(InputSchema)
  });

  // const clickContactIcon = () => {
  //   setClickToggle(!clickToggle);
  //   setValue('name', '');
  //   setValue('hpNo', '');
  //   clearErrors();
  // }

  // 슬랙 발송
  const sendSlackContact = async(contactInfo) => {
    const template = {
      "blocks": [
        { 
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": "*────🔔 촬영문의(포트폴리오) 🔔 ────*"
          }
        }, 
        {
          "type": "section",
          "text": {
            "type": "mrkdwn",
            "text": `*이름* : ${contactInfo.enqPerNm}\n*휴대폰번호* : ${contactInfo.cntcTlNo}`
          }
        },
        {
          "type": "divider"
        }, 
      ]
    }
    await axios.post(contactWebHookURL, JSON.stringify(template));
  } 

  // 문의 등록
  // const onSubmit = (data) => {
  //   if (!agree) {
  //     handleAlertText('', '개인정보 제공에 동의해주시기 바랍니다', 'warning');
  //     return;
  //   }
    
  //   const formElem = {
  //     enqDvcd: 3, // 포폴문의
  //     enqPerNm: data.name,
  //     cntcTlNo: data.hpNo.replaceAll('-', '')
  //   }

  //   const contactInfo = {
  //     enqPerNm: data.name,
  //     cntcTlNo: data.hpNo
  //   }

  //   portfolioApi.insertContact(formElem).then((response) => {
  //     navigate('/portfolio/contact');
  //     sendSlackContact(contactInfo);
  //   });
  // }

  return (
    <Container>
      <PopupContainer>
        <PopupButton onClick={() => handleViewerModal()}>
          <BriphyLogo />
          <div>문의하기</div>
        </PopupButton>
      </PopupContainer>
      {/* { !clickToggle ?
        <PopupButton onClick={() => clickContactIcon()}>
          <BriphyLogo />
          <div>문의하기</div>
        </PopupButton>
        :
        <>
          <PopupContainer onSubmit={handleSubmit(onSubmit)}>
            <Header onClick={() => clickContactIcon()}>
              <CloseIcon src={X} />
            </Header>
            <InputContainer>
              <InputWrapper>
                <InputLabel htmlFor='name'>이름<span>*</span></InputLabel>
                <div className='wr'>
                  <Input id='name' name='name' placeholder='이름을 입력해주세요' {...register('name')} />
                  <FormError message={errors.name?.message} />
                </div>
              </InputWrapper>
              <InputWrapper>
                <InputLabel>휴대폰<span>*</span></InputLabel>
                <div className='wr'>
                  <Controller 
                    id='hpNo'
                    name='hpNo'
                    control={control}
                    render={({ field: {ref, ...field}}) => 
                    <NumberFormat {...field} 
                      type='text'
                      htmlFor='hpNo'
                      placeholder='휴대폰 번호를 입력해주세요'
                      format='###-####-####'
                      mask='_'
                      innerRef={ref}
                      customInput={Input}
                    />}
                  />
                  <FormError message={errors.hpNo?.message} />
                </div>
              </InputWrapper>
              <CheckWrapper>
                <Check 
                  id='agree' 
                  name='agree'
                  onChange={() => setAgree(!agree)}
                /> 
                <label htmlFor='agree'>개인정보 제공에 동의합니다</label>
              </CheckWrapper>
              <SubmitButton>문의하기</SubmitButton>
            </InputContainer>
          </PopupContainer>
        </>
      } */}
      <RecatchBasicForm 
        open={viewerModal}
        handleModal={handleViewerModal}
      />
    </Container>
  )
}

export default ContactPopup;

const Container = styled.div`
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const PopupButton = styled.div`
  position: fixed;
  right: 10px;
  // width: 80px;
  cursor: pointer;

  div {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
`

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const BriphyLogo = styled.div`
  width: 80px;
  height: 80px;
  background: url(${Logo}) no-repeat 95%;
  background-size: cover;
`

const PopupContainer = styled.form`
  // position: absolute;
  // right: 40px;
  // width: 0px;
  // padding: 10px 16px 16px;
  // background: #FFFFFF;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  // border-radius: 10px;

  // animation: ${appear} 0.2s ease-in-out;
`

const Header = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
`

const CloseIcon = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    transform: rotate(90deg);
  } 
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #FE5A1D;
  }

  .wr {
    width: 240px;
  }

  & + & {
    margin-top: 15px;
  }
`
const InputLabel = styled.label`
  width: 57px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const Input = styled.input`
  width: 240px;
  height: 48px;
  padding: 15px 8px;
  font-weight: 200;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: #F5F5F5;
  border-radius: 5px;

  ::placeholder {
    color: #ABABAC;
  }
`

const Check = styled.input.attrs({ type: 'checkbox'})``;
const CheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4B4B4B;
    cursor: pointer;
  }

  ${Check} {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    cursor: pointer;
    background: url(${CheckboxUnChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }

  ${Check}:checked {
    width: 15px;
    height: 15px;
    background: url(${CheckboxChecked}) no-repeat 95%;
    background-size: cover;
    transition: all .1s linear;
  }
`

const SubmitButton = styled.button`
  width: 100%;
  height: 48px;
  padding: 10px;
  margin-top: 10px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  background: #4B4B4B;
  border-radius: 5px;
`