import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { convertNumberFormat } from 'utils/util';

const Refund = ({refundInfo}) => {
  
  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };

  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <MainContainer>
      <HelmetProvider>
        <Helmet>
          <title>브리피 | 취소 및 환불 정책</title>
        </Helmet>
      </HelmetProvider>
      <Container>
        <MenuTitle>취소 및 환불 정책</MenuTitle>
        { mode === 'pc' ? 
        <Content>
          { refundInfo?.phgpyAddrNeedYn === 'Y' ? 
            <LongDetailContent>
              <div>촬영일 오전 00시 이전 취소:<Bold>100% 환불</Bold></div>
              <div>촬영 당일 취소: 취소 수수료 <Bold>{convertNumberFormat(refundInfo?.cnclFee, 'Y')}원 발생</Bold></div>
              <div>촬영 시작 이후 취소:<Bold>환불 불가</Bold></div>
            </LongDetailContent>
            : 
            <ShortDetailContent>
              <div>촬영 상품 발송 전 주문 취소 시, 수수료가 발생하지 않습니다.</div>
              <div>촬영 상품 발송 후 주문 취소 시, 취소 수수료 <Bold>{convertNumberFormat(refundInfo?.cnclFee, 'Y')}원 발생합니다. (반송비 포함)</Bold></div>
            </ShortDetailContent>
          }
          <Bottomcontent>
            <div>브리피가 연결해드리는 사진작가는 현업에서 프리랜서로 활동하시는 분들입니다. </div> 
            <div style={{marginTop:'1.3px'}}> 당일 취소 발생 시, 고객님과의 약속을 위해 비워 둔 촬영 시간에 대한 보상 비용을 적용하고 있습니다.</div>
            <BigBold>하루 일당이 사라지는 프리랜서 사진작가를 위한 부득이한 조치이니 양해 부탁드립니다. </BigBold>
          </Bottomcontent>
        </Content> 
        : mode === 'mobile' ?
          <MobileContent>
            { refundInfo?.phgpyAddrNeedYn === 'Y' ? 
              <MobileLongDetailContent>
                <div>촬영일 오전 00시 이전 취소:<p>100% 환불</p></div>
                <div>촬영 당일 취소: 취소 수수료 <p>{convertNumberFormat(refundInfo?.cnclFee, 'Y')}원 발생</p></div>
                <div>촬영 시작 이후 취소:<p>환불 불가</p></div>
              </MobileLongDetailContent> 
              : 
              <MobileShortDetailContent>
                <div>1. 촬영 상품 발송 전 주문 취소 시, <div style={{margin: '15px 0px 5px -146px'}}>수수료가 발생하지 않습니다.</div></div>
                <div>2. 촬영 상품 발송 후 주문 취소 시, <div style={{marginTop: '15px', marginLeft: '-146px', fontWeight:'800'}}>취소 수수료 {convertNumberFormat(refundInfo?.cnclFee, 'Y')}원 발생합니다. (반송비 포함)</div></div>
              </MobileShortDetailContent>
            }
            <MobileBottomcontent>
              <div>
                브리피가 연결해드리는 사진작가는 현업에서 프리랜서로 활동하시는 분들입니다.
                당일 취소 발생 시, 고객님과의 약속을 위해 비워 둔 촬영 시간에 대한 보상 비용을 적용하고 있습니다.
                하루 일당이 사라지는 프리랜서 사진작가를 위한 부득이한 조치이니 양해 부탁드립니다.
              </div>
            </MobileBottomcontent>
          </MobileContent> : ''
      }

      </Container>
    </MainContainer>
  )
}

export default Refund;


const MainContainer = styled.div`  
  display: flex;
  margin: 0 auto;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  margin: 15px auto 40px;
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100vh;
    margin: 20px auto;
  }
`

const MenuTitle = styled.div`
  margin: 0px auto 20px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`

const Content = styled.pre`
  height: fit-content;
  padding: 15px 80px;
  font-size: 11px;
  font-weight: 300;
  overflow: hidden;
  // line-height: ${props => props.lh ? `${props.lh}px` : '14px'};
  line-height: 14.4px;
  white-space: pre-line;
  background-color: ${props => props.theme.bgColorGray};
`

const LongDetailContent = styled.div`
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  line-height: 13px;
  padding: 10px 0px 10px 11px;

  div {
    display: flex;
  }

  div + div {
    margin-top: 5px;
  }
`

const ShortDetailContent = styled.div`
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  line-height: 13px;
  padding: 10px 0px 10px 11px;

  div + div {
    margin-top: 5px;
  }

  div {
    display: flex;
  }
`

const Bold = styled.div`
  font-weight: 800;
  margin-left: 3px;
`

const Bottomcontent = styled.div`

`

const BigBold = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  margin-top: 7px;
`

const MobileContent = styled(Content)`
  padding: 10px 26px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
 `

const MobileLongDetailContent = styled(LongDetailContent)`
  background-color: #F5F5F5;
  padding: 0px;

  div + div {
    margin-top: 0px;
  }
`

const MobileShortDetailContent = styled(ShortDetailContent)`
  background-color: #F5F5F5;
  padding: 0px;

  div + div {
    margin-top: 0px;
  }
`

const MobileBottomcontent = styled(Bottomcontent)`
  background-color: #F5F5F5;

`