import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FindID from './FindID';
import FindPassword from './FindPassword';
// import { Modal, ModalBody } from 'reactstrap';
// import closeIcon from 'resources/common/close_icon.png';
import ViewID from './ViewID';
import NewPassword from './NewPassword';
import { useNavigate, useLocation } from 'react-router-dom';

const MobileFind = () => {
  const [clickVal, setClickVal] = useState('');
  const [userId, setUserId] = useState(''); // userId = 찾은 아이디
  const [custUserNo, setCustUserNo] = useState(''); // custUserNo = 고객사용자 번호
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;

  // const userName = location.state.name;

  /** 
   * FindId.js에서 userId를 찾아서 받아올 것 
   * 자식인 ViewId.js에 userId를 보내기 위한 함수
   */ 
  const sendId = (userId) => {
    setUserId(userId);
  }

  /** 
   * FindPassword.js에서 custUserNo를 찾아서 받아올 것
   * 자식인 NewPassword.js 에 custUserNo를 보내기 위한 함수 
   */
  const sendPW = (custUserNo) => {
    setCustUserNo(custUserNo);
  }

  /** 
   * ViewId.js 에서 쓰일 함수
   * 일단 아이디를 찾은 상태에서 '로그인 하러 가기' , '비밀번호찾기' 버튼관련 함수
   */
  const moveAction = (action) => {
    if (action === 'login') { // action이 '로그인 하러가기' 일 때
      // handleModal(); // 모달 닫아주기 
      navigate('/login');
    } else if (action === 'findPW') { // action이 '비밀번호 찾기'일 때
      setClickVal('PW');
    }
  }

  
  useEffect(() => {
    setUserId(''); //새로고침을 할 때마다 Id 값 초기화
    setCustUserNo('');
    setClickVal(type); // 마지막에 navbar의 클릭한 값과 무관하게 클릭한 버튼에 따라 초기화설정
  }, []);  // handleModal은 함수라 manipulate이 어려우니 modal 변수로 하는 것이 더 확실하게 할 수 있음.-> 하단 참조
  
  // useEffect(() => {
    // if(modal===false) { //modal이 닫힐때
      //setUserId(''); 
      //   setCustUserNo('');
      //   setClickVal(type); 
    //}
  // }, [modal]);  


  /**  
   * 받는 id에 따라 toggle 및 클릭 할 때 마다 색이 바뀌고 bold 처리 해주는 함수 
   */
  const styleController = (id, color, fontWeight) => {
    if (id && color && fontWeight) {
      document.getElementById(id).style.color = color;
      document.getElementById(id).style.fontWeight = fontWeight;
    } 
  }

  /**  
   * '아이디찾기'인지 '비밀번호찾기'인지에 따라 clickVal 세팅 
   */
  useEffect(() => {
    setClickVal(type);
  }, [type])

 /**  
   * clickVal이 변경될 때마다 '아이디찾기'인지 '비밀번호찾기'인지에 따라 스타일다르게주기
   */  
 useEffect(() => {
    if (clickVal === 'ID') {
      styleController('findIDtext', '#2C2C2C', 700);
      styleController('findPWtext', '#ABABAC', 300);
    } else if (clickVal === 'PW') {
      styleController('findIDtext', '#ABABAC', 300);
      styleController('findPWtext', '#2C2C2C', 700);
    }
  }, [clickVal])

  // 아이디찾기/비밀번호 찾기 nav 변경함수
  const clickHandler = (event) => {
    setUserId(''); // ID찾기 결과 초기화

    if (event.currentTarget.id === 'idBtn') {
      setClickVal('ID');
      styleController('findIDtext', '#2C2C2C', 700);
      styleController('findPWtext', '#ABABAC', 300);
    }
    if (event.currentTarget.id === 'pwBtn') { 
      setClickVal('PW');
      styleController('findIDtext', '#ABABAC', 300);
      styleController('findPWtext', '#2C2C2C', 700);
    }
  };

  return (
  <Container>
    <Wrapper>
    {/* <div style={{backgroundColor:'black', height:'400px', border:'1px solid white'}}> */}
      {(clickVal === 'ID' && userId) ? ('') : (clickVal === 'PW' && custUserNo) ? ('') : (
        <BtnContainer>
          <FindNavBtn 
            // className={idOn ? 'active' : null} 
            onClick={clickHandler} 
            id='idBtn'
            style={clickVal === 'ID' ? { fontWeight: 700 } : { fontWeight: 300, color: '#ABABAC'}}
          >
            <p id = 'findIDtext'> 아이디 찾기 </p>
          </FindNavBtn>

          <Bar></Bar>

          <FindNavBtn
            // className={idOn ? null : 'active'}
            onClick={clickHandler}
            id='pwBtn'
            style={clickVal === 'PW' ? { fontWeight: 700 } : { fontWeight: 300, color: '#ABABAC' }}
          >
            <p id='findPWtext'>비밀번호 찾기</p>
          </FindNavBtn>   
        </BtnContainer> )}
        
        <InputWrapper>
        { clickVal === 'ID' ? userId ? ( <ViewID userId={userId} moveFunction={moveAction}/> ) : ( 
          <FindID sendId={sendId}/> ) : ( 
            // custUserNo가 있다면 비밀번호 재설정, 없다면 비밀번호 찾기를 통해 custUserno 전송
            // 정상인 경우에는 고객사용자번호(custUserNo)를  return,
            // 비밀번호 변경 화면으로 이동하여 비밀번호 변경 시 고객사용자번호를 함께 전달
            // 비밀번호 찾기 후 변경 시 호출- NewPassword
            clickVal === 'PW' ? custUserNo ? ( <NewPassword custUserNo={custUserNo} moveFunction={moveAction}/> ) :
            <FindPassword userId={userId} sendPW={sendPW} /> : '' ) } 
        </InputWrapper>
      {/* </div> */}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`

const Wrapper = styled.div`
  // background-color: orange;
  // border: 1px solid white;
  width: 100%;
  // height: 730px;
  // height: 100vh;
  padding: 0px 16px 0px 16px;
  margin: 50px auto;

  
  @media ${({theme}) => theme.device.mobile} {
    margin-bottom: 160px;
  };
`

const BtnContainer = styled.div`
  // background-color: red;
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0px 20px;
`

const FindNavBtn = styled.button`
  background-color: white;
  width: 100%;
  border: white;
  font-size: 16px;
  line-height: 19px;
  padding-right: 0;
  padding-left: 0;
  #findIDtext {
    text-align: right;
  }
  #findPWtext {
    text-align: left;
  }
`

const Bar = styled.div`
  height: 20px;
  border-right: 1px solid black;
  margin-left: 48px;
  margin-right: 41px;
`

const InputWrapper = styled.div`
  background-color: white;
`


export default MobileFind;