import React, { useEffect } from 'react';
import styled from "styled-components";
import HeroSection from "../HeroSection";
import Video from "../Video";
import Partners from "../Partners";
import Contact from "../Contact";
import Service from "../Service";
import Whys from "../Whys";
import NaverADLogAnalyticsConvert from 'components/NaverADLogAnalyticsConvert';


const MainContainer = ()=> {
  useEffect(()=> {
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <Main>
      <NaverADLogAnalyticsConvert type='5' value='0' />
      <HeroSection />
      <Video />
      <Service />
      <Whys />
      <Partners />
      <Contact />
    </Main>
  )
}

const Main = styled.main`
  min-width: 1280px;
  background-color: #FFFFFF;
  
  @media ${({ theme }) => theme.device.mobile} {
      min-width:0;
  }
`;
export default MainContainer;