const initialState = {
  productInfo: {},
  productAttachFileList: [],
  productOptionList: [],
  productOptionItemList: [],
  productBoardList: [],
  custUserMinimumInfo: {},
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT':
      return {
        ...state,
        productInfo: action.data.productInfo,
        productAttachFileList: action.data.productAttachFileList,
        productOptionList: action.data.productOptionList,
        productOptionItemList: action.data.productOptionItemList,
        productBoardList: action.data.productBoardList
      }
    case 'GET_USERINFO':
      return {
        ...state,
        custUserMinimumInfo: action.data.custUserMinimumInfo
      }
    case 'INSERT_ORDERINFO':
      return {
        ...state,
        payInfoVO: action.data
      }
    default: 
      return {...state}
  }
}

export default products;