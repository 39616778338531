import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components"
import { orderDetailInfo, orderList } from './store/action';
import { useDispatch, useSelector } from 'react-redux';
import { convertNumberFormat, convertDateTime, convertPhoneNumberFormat, isObjEmpty } from "utils/util";
import { isUserLoggedIn } from "utils/jwtUtil";


const OrderDetail = ({ title, isOpenYn, rowItems, backgroundColor = "transparent" , orderId, selectedOrdId}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.mypages);

  const [isOpen, setIsOpen] = useState(isOpenYn);
  
  useEffect(() => {
    setIsOpen(isOpenYn);
    if (isOpenYn && selectedOrdId === orderId) {
      dispatch(orderDetailInfo(orderId));
    }
  }, [isOpenYn]);

  // 모바일 버전 세팅
  const [mode, setMode] = useState("pc");
  const handleResize = () => {
    setMode(window.innerWidth);
    if(window.innerWidth < 768) {
      setMode("mobile");
    } else {
      setMode("pc");
    }
  };
  useEffect (()=> {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
    { mode === "mobile" ? <>
      <DetailContainer>
        <DetailRight>
          <DetailTitle>촬영 금액<div id="border"></div></DetailTitle>
          <ShootDetailPrice>
            <DetailPriceItem>
              <PriceItem>기본</PriceItem>
              <PriceItemm>{convertNumberFormat(rowItems.prodSumAmt)} 원</PriceItemm>
            </DetailPriceItem>

            {store.orderProductOptionList.map(item => {
              if (Number(item.optPrc)) {
                return (
                  <DetailPriceItem key={item.optSeqno}>
                    <PriceItem>{item.optNm} {item.plurPsyn === 'Y' ? `(수량: ${item.optOrdCnt})` : ''}</PriceItem>
                    <PriceItemm>{convertNumberFormat(item.optOrdAmt)} 원</PriceItemm>
                  </DetailPriceItem>
                )
              }
            })}

            <div style={{border:"none", borderBottom:"0.5px solid #D9D9D9", marginTop:"10px", marginRight:"5px", width:"100%"}}></div>
 
            <DetailPriceItem>
              <PriceItem>총 상품 금액</PriceItem>
              <PriceItemm>{convertNumberFormat(rowItems.prodOrdTamt)} 원</PriceItemm>
            </DetailPriceItem>

            { rowItems.copDscYn === 'Y' && isUserLoggedIn() && Number(rowItems.copDscAplyAmt) ?
                <DetailPriceItem>
                  <PriceItem>제휴 상품 할인</PriceItem>
                  <PriceItemm style={{color:"#FE5A1D", fontWeight: 700}}>- {convertNumberFormat(rowItems.copDscAplyAmt)} 원</PriceItemm>
                </DetailPriceItem>
              : ''
            }

            <div style={{border:"none", borderBottom:"0.5px solid #D9D9D9", marginTop:"10px", marginRight:"5px", width:"100%"}}></div>
            <DetailPriceItem>
              <PriceItem id='finalPay'>최종 결제 금액 
                <div style={{fontSize:"10px", fontWeight:300, color:"#ABABAC", paddingTop:"3px", marginLeft:"5px"}}>(VAT 포함)</div>
              </PriceItem>
              <PriceItemm id='finalPay' style={{color:"#FE5A1D", fontWeight: 800, fontSize:'16px'}}>{convertNumberFormat(rowItems.prodPayAmt)} 원</PriceItemm>
            </DetailPriceItem> 
          </ShootDetailPrice>
        </DetailRight>
        
        { isUserLoggedIn() ? 
          //회원
          <DetailLeft>
            <DetailTitle>촬영 정보<div id="border"></div></DetailTitle>
            <ShootDetailInfo>
              <DetailItem>
                <Item>촬영주소</Item>
                <Itemm className='addr'>{rowItems?.phgpyAddr}{rowItems?.phgpyAddrDtl}</Itemm>
              </DetailItem>
              <DetailItem>
                <Item id='margin-top'>휴대폰</Item>
                <Itemm id='margin-top'>{convertPhoneNumberFormat(rowItems.ordPerHpNo)}</Itemm>
              </DetailItem>
              <DetailItem>
                <Item>연락처</Item>
                <Itemm>{convertPhoneNumberFormat(rowItems?.addCntcNo)}</Itemm>
              </DetailItem>
              <DetailItem>
                <Item>결제수단</Item>
                <Itemm>
                  {rowItems.payCdCo ? 
                    `${rowItems.payCdCo}${rowItems.payMthdNm}${!isObjEmpty(rowItems.instMnthCnt) ? rowItems.instMnthCnt === '0' ? ` 일시불` : `(${rowItems.instMnthCnt}개월)` : ''}`
                  : (rowItems.simpPayNm ? `${rowItems.simpPayNm}` : `${rowItems.payMthdNm}`)}
                </Itemm>
              </DetailItem>
            </ShootDetailInfo>
          </DetailLeft> : 
          // 비회원
          <GuestDetailLeft>
            <DetailTitle>촬영 정보<div id="border"></div></DetailTitle>
            <ShootDetailInfo>
              <DetailItem>
                <Item>촬영주소</Item>
                <Itemm>{rowItems?.phgpyAddr} {rowItems?.phgpyAddrDtl}</Itemm>
              </DetailItem>
              <DetailItem>
                <Item id='margin-top'>구매자</Item>
                <Itemm id='margin-top'>{rowItems.ordPerNm}</Itemm>
              </DetailItem> 
              <DetailItem>
                <Item>휴대폰</Item>
                <Itemm>{convertPhoneNumberFormat(rowItems.ordPerHpNo)}</Itemm>
              </DetailItem>
              <DetailItem>
                <Item>이메일</Item>
                <Itemm>{rowItems.ordPerEmail }</Itemm>
              </DetailItem> 
              <DetailItem>
                <Item id='margin-bottom'>결제수단</Item>
                <Itemm id='margin-bottom'>
                  {rowItems.payCdCo}{rowItems.payMthdNm}{!isObjEmpty(rowItems.instMnthCnt) ? rowItems.instMnthCnt === '0' ? ` 일시불` : `(${rowItems.instMnthCnt}개월)` : ''}
                </Itemm>
              </DetailItem>
            </ShootDetailInfo>
          </GuestDetailLeft>
        }
      </DetailContainer>
    </> : mode === "pc" ?
    <motion.div>
      <AnimatePresence>
        { (isOpen &&  selectedOrdId === orderId) &&
          <AccordionBody
            variants={accordionVariants}
            initial="start" animate="end" exit="exit"
            bgcolor={backgroundColor}
          >
            <DetailContainer>
            { isUserLoggedIn() ? 
              //회원
              <DetailLeft>
                <DetailTitle>촬영 정보<div id="border"></div></DetailTitle>
                <ShootDetailInfo>
                  <DetailItem style={{lineHeight:'17px'}}>
                    <Item>촬영주소</Item>
                    <Itemm style={{lineHeight:'17px'}}>{rowItems?.phgpyAddr} {rowItems?.phgpyAddrDtl}</Itemm>
                  </DetailItem>
                  <DetailItem>
                    <Item id='margin-top'>휴대폰</Item>
                    <Itemm id='margin-top'>{convertPhoneNumberFormat(rowItems.ordPerHpNo)}</Itemm>
                  </DetailItem>
                  <DetailItem>
                    <Item id='margin-top'>연락처</Item>
                    <Itemm id='margin-top'>{convertPhoneNumberFormat(rowItems?.addCntcNo)}</Itemm>
                  </DetailItem>
                  <DetailItem>
                    <Item id='margin-top'>결제수단</Item>
                    <Itemm id='margin-top'>
                      {rowItems.payCdCo ? 
                        `${rowItems.payCdCo}${rowItems.payMthdNm}${!isObjEmpty(rowItems.instMnthCnt) ? rowItems.instMnthCnt === '0' ? ` 일시불` : `(${rowItems.instMnthCnt}개월)` : ''}`
                      : (rowItems.simpPayNm ? `${rowItems.simpPayNm}` : `${rowItems.payMthdNm}`)}
                    </Itemm>
                  </DetailItem>
                </ShootDetailInfo>
              </DetailLeft> : 
              // 비회원
              <GuestDetailLeft>
                <DetailTitle>촬영 정보<div id="border"></div></DetailTitle>
                <ShootDetailInfo>
                  <DetailItem style={{lineHeight:'17px'}}>
                    <Item>촬영주소</Item>
                    <Itemm>{rowItems?.phgpyAddr} {rowItems?.phgpyAddrDtl}</Itemm>
                  </DetailItem>
                  <DetailItem>
                    <Item id='margin-top'>구매자</Item>
                    <Itemm id='margin-top'>{rowItems.ordPerNm}</Itemm>
                  </DetailItem> 
                  <DetailItem>
                    <Item id='margin-top'>휴대폰</Item>
                    <Itemm id='margin-top'>{convertPhoneNumberFormat(rowItems.ordPerHpNo)}</Itemm>
                  </DetailItem>
                  <DetailItem>
                    <Item id='margin-top'>이메일</Item>
                    <Itemm id='margin-top'>{rowItems.ordPerEmail }</Itemm>
                  </DetailItem> 
                  <DetailItem>
                    <Item id='margin-top'>결제수단</Item>
                    <Itemm id='margin-top'>
                      {rowItems.payCdCo}{rowItems.payMthdNm}{!isObjEmpty(rowItems.instMnthCnt) ? rowItems.instMnthCnt === '0' ? ` 일시불` : `(${rowItems.instMnthCnt}개월)` : ''}
                    </Itemm>
                  </DetailItem>
                </ShootDetailInfo>
              </GuestDetailLeft>
            }
      
              <DetailRight>
                <DetailTitle>촬영 금액<div id="border"></div></DetailTitle>
                <ShootDetailPrice>
                  <DetailPriceItem>
                    <PriceItem>기본</PriceItem>
                    <PriceItemm>{convertNumberFormat(rowItems.prodSumAmt)} 원</PriceItemm>
                  </DetailPriceItem>

                  {store.orderProductOptionList.map(item => {
                    if (Number(item.optPrc)) {
                      return (
                        <DetailPriceItem key={item.optSeqno}>
                          <PriceItem>{item.optNm} {item.plurPsyn === 'Y' ? `(수량: ${item.optOrdCnt})` : ''}</PriceItem>
                          <PriceItemm>{convertNumberFormat(item.optOrdAmt)} 원</PriceItemm>
                        </DetailPriceItem>
                      )
                    }
                  })}

                  {rowItems.copDscYn === 'Y' && isUserLoggedIn() && Number(rowItems.copDscAplyAmt)? 
                    <>
                      <div style={{border:"none", borderBottom:"0.5px solid #D9D9D9", marginTop:"10px", marginRight:"5px", width:"345px"}}></div>

                      <DetailPriceItem>
                        <PriceItem>총 상품 금액</PriceItem>
                        <PriceItemm>{convertNumberFormat(rowItems.prodOrdTamt)} 원</PriceItemm>
                      </DetailPriceItem>
                    
                      { Number(rowItems.copDscAplyAmt) ?
                        <DetailPriceItem>
                          <PriceItem>제휴 상품 할인</PriceItem>
                          <PriceItemm style={{color:"#FE5A1D", fontWeight: 700}}>- {convertNumberFormat(rowItems.copDscAplyAmt)} 원</PriceItemm>
                        </DetailPriceItem> : ''
                      }

                      <div style={{border:"none", borderBottom:"0.5px solid #D9D9D9", marginTop:"10px", marginRight:"5px", width:"345px"}}></div>
                      <DetailPriceItem>
                        <PriceItem id='finalPay'>최종 결제 금액 
                          <div style={{fontSize:"10px", fontWeight:300, color:"#ABABAC", paddingTop:"3px", marginLeft:"5px"}}>(VAT 포함)</div>
                        </PriceItem>
                        <PriceItemm id='finalPay' style={{color:"#FE5A1D", fontWeight: 800, fontSize:'16px'}}>{convertNumberFormat(rowItems.prodPayAmt)} 원</PriceItemm>
                      </DetailPriceItem> 

                    </> :     
                    <> 
                      <div style={{border:"none", borderBottom:"0.5px solid #D9D9D9", marginTop:"10px", marginRight:"5px", width:"345px"}}></div>

                      <DetailPriceItem>
                        <PriceItem id='finalPay'>최종 결제 금액 
                          <div style={{fontSize:"10px", fontWeight:300, color:"#ABABAC", paddingTop:"3px", marginLeft:"5px"}}>(VAT 포함)</div>
                        </PriceItem>
                        <PriceItemm id='finalPay' style={{color:"#FE5A1D", fontWeight: 800, fontSize:'16px'}}>{convertNumberFormat(rowItems.prodOrdTamt)} 원</PriceItemm>
                      </DetailPriceItem> 
                    </>
                  }

                </ShootDetailPrice>
              </DetailRight>
            </DetailContainer>
          </AccordionBody>
        }
      </AnimatePresence>
    </motion.div> : " "
    }
    </>
  );
};

export default OrderDetail;

const accordionVariants = {
  start: {
    opacity: 0,
    y: -20
  },
  end: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 0.5,
      bounce: 0.5
    }
  },
  exit: {
    opacity: 0
  }
}

const AccordionBody = styled(motion.div)`
  background-color: #F5F5F5;
  // box-shadow: 1px 1px 10px #00000029;
  position: relative;
  width: 792px;
  // height: 208px;
  padding-top: 15px;
  padding-left: 15px;
  border-bottom:  0.5px solid #D9D9D9;
`

const DetailContainer = styled.div`
  // padding: 9px 5px 0px 5px;
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    margin-top: 20px;

  }
`

const DetailLeft = styled.div`
  position: relative;
  width: 375px;
  height: fit-content;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-right: 12px;
  padding: 13px 0px 10px 15px;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 22px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 11px 15px 1px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

const GuestDetailLeft = styled.div`
  position: relative;
  width: 375px;
  height: fit-content;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-right: 12px;
  padding: 13px 0px 10px 15px;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 22px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding-bottom: 1px;
    padding-right: 15px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  }
`

const DetailTitle = styled.div`
  font-size: 14px;
  font-weight: 700;

  #border {
    padding-top: 10px;
    width: 345px;
    border-bottom: 1px solid #2C2C2C;
    padding-right: 15px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
    font-weight: 700;

    #border {
      width: 100%;
      border-bottom: 0.25px solid #2C2C2C;
    }
  }
`

const ShootDetailInfo = styled.div`
  padding-top: 12px;

  // background: yellow;
`

const DetailItem = styled.div`
  display: flex;
  // margin-bottom: 10px;

  #margin-top {
    margin-top: 10px; 
  }
 
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 7px;

    .addr {
      line-height: 17px;
    }

    #margin-top {
      margin-top: 0;
    }
  }
`

const Item = styled.div`
  width: 49px;
  height: 17px;
  margin-right: 30px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 45%;
    height: 100%;
    min-height: 20px;
    margin-right: 0px;
  }
`

const Itemm = styled.div`
  font-weight: 500;
  width: 266px;
  // height: 17px;
  height: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 100%;
    min-height: 20px;
  }
`

const DetailRight = styled.div`
  width: 375px;
  min-height: 158px;
  height: fit-content;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  position: relative; 
  padding: 13px 0px 0px 15px;
  font-weight: 300;
  font-size: 14px;
  
  margin-bottom: 25px;
  

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 20px;
    width: 100%;
    padding-top: 11px;
    padding-right: 15px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  }
`
const ShootDetailPrice = styled.div`
  padding-bottom: 12px;
`

const DetailPriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-top: 10px;

  #finalPay {
    margin-top: 4px;
  }

  // background-color: pink;

  @media ${({ theme }) => theme.device.mobile} {
    // padding: 0;
    width: 100%;
    line-height: 20px;
    padding-right: 0px;
  }
`

const PriceItem = styled.div`
  display: flex;
  height: 17px;

  @media ${({ theme }) => theme.device.mobile} {
  }
`

const PriceItemm = styled.div`
  flex-basis: 100px;
  font-weight: 500;
  text-align: right;
  font-size: 15px;

  @media ${({ theme }) => theme.device.mobile} {
    // background-color: red;
    width: 100%;
    font-size: 15px;
    line-height: 18px;
  } 
`