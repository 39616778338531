import styled from 'styled-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isGuestLoggedIn, isUserLoggedIn } from 'utils/jwtUtil';
import { handleAlertText } from 'components/common/SweetAlert';

const OrderComplete = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [orderInfo] = useState(location.state || {});

  const callOrderInfo = () => {
    navigate('/mypage', { replace: true });
  }

  useEffect(() => {
    if (!location.state) {
      handleAlertText('접근 불가!', '정상적인 접근경로가 아닙니다.', 'warning').then(() => {
        navigate('/');
      });
    }
  }, [navigate]);

  return (
    <Container>
      <Menu>주문 완료</Menu>
      <CompleteContainer>
        <div className='title'>
          <div><span>[{orderInfo.orderName}]</span>에 대한</div>
          <div>주문이 정상적으로 처리되었습니다.</div>
        </div>
        <div className='info'>
          {isUserLoggedIn() ? <div>주문 내역 확인은 마이페이지의 주문내역에서 하실 수 있습니다. </div> : ''}
          <div>주문 정보는 고객님의 이메일 또는 휴대폰으로 전송됩니다.</div>
        </div> 
        <OrderInfo>
          주문 번호: <span className='accent'>{orderInfo.orderId}</span>
        </OrderInfo>
        <ButtonWrapper>
          <Button onClick={() => navigate('/')}>홈으로</Button>
          <Button onClick={() => callOrderInfo()} accent={true}>주문내역 보기</Button>
        </ButtonWrapper>
      </CompleteContainer>
    </Container>
  )
}

export default OrderComplete;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 62px);

  @media ${({ theme }) => theme.device.mobile} {
    min-height: 100vh;
    padding: 0 16px;
    justify-content: flex-start;
  }
`
  
const Menu = styled.div`
  width: 100%;
  margin: 120px auto 40px;
  text-align: center;
  font-size: 32px;
  font-weight: 700;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 77px auto 27px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`

const CompleteContainer = styled.div`
  width: 1088px;
  margin: 0 auto 250px;
  padding: 100px;
  text-align: center;
  background: ${props => props.theme.bgColorWhite};
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .title {
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 30px;

    span {
      font-weight: 800;
    }

    div + div {
      margin-top: 10px;
    }
  }

  .info {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    div + div {
      margin-top: 6px;
    }
  }
  
  .accent {
    color: #FE5A1D;
    font-weight: 700;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-bottom: 160px;
    padding: 35px 15px 20px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 12px;

      div + div {
        margin-top: 4px;
      }
    }

    .info {
      font-size: 12px;
      line-height: 14px;

      div + div {
        margin-top: 2px;
      }
    }
  }
`

const OrderInfo = styled.div`
  margin: 78px auto;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 66px auto;
    font-size: 16px;
    line-height: 19px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column-reverse;
  }
`
  
const Button = styled.div`
  width: ${props => props.accent ? `210px` : `140px`};
  height: 44px;
  padding: 12px 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: ${props => props.accent ? `#FFFFFF` : `#FE5A1D`};
  background: ${props => props.accent ? `#FE5A1D` : `#FFFFFF`};
  border: 1px solid #FE5A1D;
  border-radius: 5px;
  
  & + & {
    margin-left: 10px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;

    & + & {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
`