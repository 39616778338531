import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import backgroundImg from 'resources/member/welcomePic.png';
import Logo from 'resources/common/briphy_logo2.svg';

const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state.loginId;
  const userName = location.state.name;

  // 회원가입아이디와 이름이 이전 페이지인 회원가입페이지에서 넘어오지 않았을 경우
  useEffect(() => {
    if (location.state === null) {
      alert('잘못된 접근입니다.');
      navigate('/join');
    }
  }, []);

  // '로그인 바로가기' 클릭시
  const gotoLogin = () => {
    navigate('/login');
  }

  return (
    <Container>
      <Wrapper>
        <ContentBox>
          <Content>
            <BriphyLogo src={Logo}/>
            <Welcometxt> 
              <div style={{display:'inline' , fontWeight:700, fontSize:"18px"}}>{userName}님</div>
              (<div style={{display:'inline' , fontWeight:700}}>{userId}</div>)의 회원가입이
              <div style={{marginBottom:'4px'}}></div> 성공적으로 완료되었습니다.
            </Welcometxt>
              <SubmitButton type='submit' onClick={gotoLogin} value={'로그인 바로가기'}/>
          </Content>
        </ContentBox>
      </Wrapper>
    </Container>
  );
}

export default Welcome;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 1080px;
  position: relative;

  @media ${({ theme }) => theme.device.mobile} {
    height: 726px;
  }
`
  
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  background: url(${backgroundImg}) 95%;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${({ theme }) => theme.device.mobile} {
   background: transparent;
   max-width: 428px;
   margin: 0 auto;
  }
`
const ContentBox = styled.div`
  width: 50%;
  height: 53%;
  margin: 200px auto 0px;
  padding-top: 130px;
  background-color: #FFFFFF;
  opacity: 90%;
  border-radius: 20px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    margin-top: 70px;
    border-radius: 0;
    padding: 0px 16px 0px 16px;
  }
`

  const Content = styled.div`
    width: 100%;
    height: 325px;
    margin: 0px auto 10px;
    text-align: center;
    
    @media ${({ theme }) => theme.device.mobile} {
      height: 231px;
      background-color: #F5F5F5;
    } 
  `

  const BriphyLogo = styled.img`
    width: 150px;
    height: 150px;
    
    @media ${({ theme }) => theme.device.mobile} {
      height: 100%;
    }
  `

  const Welcometxt = styled.div`
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 40px;
    
    @media ${({ theme }) => theme.device.mobile} {
      height: 120px;
      padding-top: 39px;
      margin-top:-5px;
      margin-bottom: 0px;
      background-color: #F5F5F5;
    }
  `
  
  const SubmitButton = styled.input`
    width: 50%;
    height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 140px;
    background-color: #FE5A1D;
    color: white;
    text-align: center;
    padding: 8px 0px;
    padding-top: 12px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    opacity: 100%;
    // opacity: ${props=>props.disabled ? '0.5' : '1'};

    @media ${({ theme }) => theme.device.mobile} {
      width: 100%;
      font-size: 18px;
      line-height: 22px;
      margin-top: 190px;
    }
  `