import {memberApi, mypageApi} from "utils/api";
import { handleAlertText } from "components/common/SweetAlert";

export const getCustUserInfo = custUserNo => {  
  return async dispatch => {
    await memberApi.getCustUserInfo(custUserNo).then(response => { // get the data
      dispatch({
        type: 'GET_USER_INFO',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const modifyUserInfo = (custUserNo, item) => {
  return async dispatch => {
    await memberApi.modifyUserInfo(item).then(response => { // put the data
      if (response.data === "452") {
        handleAlertText("비밀번호 오류!", "현재 비밀번호가 일치하지 않습니다" , "error");
      } else {
        handleAlertText('수정완료!', '고객님의 정보 수정이 정상적으로 완료되었습니다.', 'success');
        dispatch(getCustUserInfo(custUserNo));
      }
    }).catch(err => console.log(err))
  }
}

// export const deleteUserInfo = item => {  
//   return async dispatch => {   
//     await memberApi.deleteUserInfo(item).then(response => {
//         handleAlertText('회원탈퇴완료', '회원탈퇴가 정상적으로 완료되었습니다.','success')
//         dispatch(getCustUserInfo())
//       }).catch(err => console.log(err))
//   }
// }

export const orderList = obj => {  
  return async dispatch => {
    await mypageApi.orderList(obj).then(response => {  // get the data
      // console.log("response.data is:",response.data);
      dispatch({
        type: 'GET_ORDER_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const orderDetailInfo = ordId => {  
  return async dispatch => {
    await mypageApi.orderDetailInfo(ordId).then(response => {  
      dispatch({
        type: 'GET_ORDER_DETAIL_INFO',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}

export const cancelCodeList = obj => {  
  return async dispatch => {
    await mypageApi.cancelCodeList(obj).then(response => {  
      dispatch({
        type: 'GET_BANK_CODE_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}