import { mainApi } from 'utils/api';

export const getMainProductList = (condition) => {
  return async dispatch => {
    await mainApi.getMainProductList(condition).then(response => {
      dispatch({
        type: 'GET_MAIN_PRODUCT_LIST',
        data: response.data
      })
    }).catch(err => console.log(err))
  }
}
