import styled from 'styled-components';
import React,{useState,useEffect} from 'react';
import ListBody from './ListBody';
import { isGuestLoggedIn, isUserLoggedIn } from 'utils/jwtUtil';
import { convertDateFormat } from 'utils/util';

import moment from 'moment';

const OrderList = () => {
  const [period, setPeriod] = useState('SixMonth');
  let after6mnth = moment().subtract(6,'month').calendar();
  const [searchDate, setSearchDate] = useState(convertDateFormat(after6mnth));

  let after12mnth = moment().subtract(12,'month').calendar();
  let after24mnth = moment().subtract(24,'month').calendar();

  // '기간별조회' 변경 함수
  const periodClickHandler = (event) => {
    if (isUserLoggedIn() && event.currentTarget.id === 'sixMonth') {
      setPeriod('SixMonth');
      setSearchDate(convertDateFormat(after6mnth));
      // setTodate(dateString);
    }
    if (isUserLoggedIn() && event.currentTarget.id === 'twelveMonth') {
      setPeriod('TwelveMonth');
      setSearchDate(convertDateFormat(after12mnth));
    }
    if (isUserLoggedIn() && event.currentTarget.id === 'twofourMonth') {
      setPeriod('TwoFourMonth');
      setSearchDate(convertDateFormat(after24mnth));
    }
  };

  return (
    <Container>
      {isUserLoggedIn() ? <Title>주문내역</Title> : <GuestTitle>비회원 주문내역</GuestTitle>} 
        <Content>
          {isUserLoggedIn() ? 
            <ByPeriod>
              <ByPeriodTitle>
                기간별조회
              </ByPeriodTitle>
              <ButtonContainer>
                <button 
                  type='button' 
                  id='sixMonth' 
                  className={period==='SixMonth'? 'focused' : ''}
                  onClick={periodClickHandler}
                > 6개월
                </button>
                <button 
                  type='button' 
                  id='twelveMonth'
                  className={period==='TwelveMonth'? 'focused' : ''}
                  onClick={periodClickHandler}
                > 12개월
                </button>
                <button 
                  type='button'
                  id='twofourMonth' 
                  className={period==='TwoFourMonth'? 'focused' : ''}
                  onClick={periodClickHandler}
                > 24개월
                </button>
              </ButtonContainer>
            </ByPeriod> : <GuestBlank></GuestBlank>
          }

          <ListContents guest={isGuestLoggedIn()? 'Y' : 'N'}>
            <ListHeader>
              <div>주문상세</div>
              <div>주문일/주문번호</div>
              <div>상품정보/촬영날짜</div>
              <div>촬영금액</div>
              <div>진행 상태</div>
            </ListHeader>

            <ListContainer>
              <Lists>
                <ListBody searchDate={searchDate}/>
              </Lists> 
            </ListContainer>

          </ListContents>
        </Content>
    </Container>
  )
}

export default OrderList;

const Container = styled.div`

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-top: 48px;
  }
`

const Title = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    text-align: inherit;
    font-size: 20px;
    line-height: 24px;
    padding-top: 25px;
    margin-bottom: 21px;
  }
`

const GuestTitle = styled(Title)`
  margin-bottom: 44px;
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    text-align: inherit;
    font-size: 20px;
    line-height: 24px;
    padding-top: 25px;
    margin-bottom: 21px;
  }
`

const Content = styled.div`
  width: 792px;
  height: 516px;
  margin-left: 80px;
  margin-top: 40px;  
  
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    margin: 0;
  }
`

const ByPeriod = styled.div`
  width: 792px;
  height: 30px;
  display: flex;

  button { 
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background: #FFFFFF;
    font-weight: 200;
    font-size: 14px;
    color: #ABABAC;
    border: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;

    button { 
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      font-size: 15px;
    }
  }
  
`

const ByPeriodTitle = styled.div`
  width: 65px; 
  height: 30px; 
  border: white; 
  padding-top: 13px; 
  font-weight: 700; 
  font-size: 14px; 
  margin-right: 20px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 17px;
    padding-top: 0px;
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  width: 240px;
  height: 30px; 
  border: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
  .focused {
    border: 1px solid #FE5A1D;
    color: #FE5A1D;
    font-weight: 700;
  }
  
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    width: 100%;
    height: 48px;
    margin-top: 10px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);

    .focused {
      border: 2px solid #FE5A1D;
    }
  }
`

const ListContents = styled.div`
  width: 792px;
  height: 382px;
  margin-top: ${props=>props.guest === 'Y'? ' ' : '40px'};

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;
    margin-top: ${props=>props.guest === 'Y'? ' ' : '85px'};
  }
`

const ListHeader = styled.div`
  width: 792px;
  height: 35px;
  border: 1px solid #D9D9D9; 
  border-left: none;
  border-right: none;
  padding: 9px 5px 0px 5px;
  display: flex;
  font-weight:700;
  font-size:14px;

  div {
    &:nth-child(1) {      
      // flex: 1;
      width: 90px;
    }
    &:nth-child(2) {
      // flex: 1.6;
      width: 147px;
    }
    &:nth-child(3) {
      // flex: 2;
      width: 180px;
    }
    &:nth-child(4) {
      // flex: 1.78; 
      width: 160px; 
    }
    &:nth-child(5) {
      // flex: 1.4;
      width: 125px;
    }
    &:nth-child(6) {
      // flex: 1;
      width: 90px;
    }
    // &:nth-child(7) {
    //   flex:.1;
    // }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const ListContainer = styled.div`
  width: 800px;
  height: 390px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    // background: rgba(0,0,0,0.1);
    
    background-color: #d3d3d347;
    border-radius: 8px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: initial;
    overflow-y: unset;
  }
`

const Lists = styled.div`
  width: 792px;
  height: 347px;

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: unset;

    // background-color: aqua;
  }
`

const GuestBlank = styled.div`
  width: 100%;
  // height: 20px;

  // margin-bottom: -105px;
`
