import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { productApi } from 'utils/api';
import { handleAlertText } from 'components/common/SweetAlert';
import { Ring } from '@uiball/loaders';

const FailLoading = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [navigateYn, setNavigateYn] = useState('N');

  const redirect = async (data) => {
    await productApi.callbackTossFail(data).then(response => {
      handleAlertText('결제 실패!', `결제에 실패하였습니다.`, 'error').then((result) => {
        if (result.isConfirmed) {
          navigate('/mypage');
        }
      });
    }, error => {
      handleAlertText('서버 오류!', '서버에 에러가 생겼습니다.', 'error');
    });
  }

  useEffect(() => {
    if (searchParams.toString()) {
      setNavigateYn('Y');
    } else {
      if (navigateYn === 'N') {
        handleAlertText('접근 불가!', '정상적인 접근경로가 아닙니다.', 'warning').then(() => {
          navigate('/');
        });
      }      
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('orderId') && searchParams.get('code') && searchParams.get('message')) {
      const formElem = {
        ordId : searchParams.get('orderId'),
        code : searchParams.get('code'),
        message : searchParams.get('message')
      }

      redirect(formElem);
    } 
  }, [searchParams]);
  
  useEffect(() => {
    navigate(".", { replace: true });
  }, [navigate]);
  
  return (
    <>
      <Container>
        <Ring 
          size={40}
          lineWeight={5}
          speed={2} 
          color="black"
        />
      </Container>
    </>
  )
}

export default FailLoading;

const Container = styled.div`
  display: flex;
  position: relative;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 62px);
`