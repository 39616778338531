import React,{useEffect, useState} from "react";
import { useScrollFadeIn } from "hooks/useScrollFadeIn";
import { useInterval } from "hooks/useInterval";
import serviceImg1 from "resources/main/service/w1.png";
import serviceImg2 from "resources/main/service/w2.png";
import serviceImg3 from "resources/main/service/w3.png";
import serviceImgMo1 from "resources/main/service/w1_mo.png";
import serviceImgMo2 from "resources/main/service/w2_mo.png";
import serviceImgMo3 from "resources/main/service/w3_mo.png";
import styled from "styled-components";

const Service = () => {

  const [serviceImgNo,setServiceImgNo] = useState(0)
  const [serviceImgNoMo,setServiceImgNoMo] = useState(0)
  const serviceArr = [serviceImg1,serviceImg2,serviceImg3];
  const serviceArrMo = [serviceImgMo1,serviceImgMo2,serviceImgMo3];
  
  //pc scroll action
  const ServiceWord1 = useScrollFadeIn({threshold:0.3,delay:'.3',opacity:'1'})
  const ServiceWord2 = useScrollFadeIn({threshold:0.3,delay:'.6',opacity:'1'})
  const ServiceWord3 = useScrollFadeIn({threshold:0.3,delay:'.9',opacity:'1'})

  //mobile scroll action
  const ServiceWordMo1 = useScrollFadeIn({threshold:0.3,delay:'.3',opacity:'1'})
  const ServiceWordMo2 = useScrollFadeIn({threshold:0.3,delay:'.6',opacity:'1'})
  const ServiceWordMo3 = useScrollFadeIn({threshold:0.3,delay:'.9',opacity:'1'})

  //for pc mouseover animation
  const onMouseOver = number => {
    setServiceImgNo(number);
  }

  useEffect(function(){
    
  },[])
  useInterval(()=>setServiceImgNoMo((serviceImgNoMo+1)%3),2000)
  return (
    <Container>
      <ServiceWrap image={serviceArr[serviceImgNo]}>
        <ServiceBox>
          <Title>브리피 서비스 소개</Title>
          <SubTitleBox>
            <SubTitle {...ServiceWord1} onMouseOver={()=>onMouseOver(0)} className={`${serviceImgNo===0?'active':''}`}>
              <span>작가 매칭부터 촬영 스케줄까지 <span className="highlight">한 번에 맡기고</span></span>
            </SubTitle>
            <SubTitle {...ServiceWord2} onMouseOver={()=>onMouseOver(1)} className={`${serviceImgNo===1?'active':''}`}>
              <span>촬영 내역부터 수정 요청까지 <span className="highlight">한 번에 관리하고</span></span>
            </SubTitle>
            <SubTitle {...ServiceWord3} onMouseOver={()=>onMouseOver(2)} className={`${serviceImgNo===2?'active':''}`}>
              <span>결과물부터 결산 내역까지 <span className="highlight">한 번에 전달받으세요</span></span>
            </SubTitle>
          </SubTitleBox>
        </ServiceBox>
      </ServiceWrap>
      <MobileServiceWrap image={serviceArrMo[serviceImgNoMo]}>
        <ServiceBox>
          <Title>브리피 서비스 소개</Title>
          <SubTitleBox>
            <SubTitle {...ServiceWordMo1} className={`${serviceImgNoMo===0?'active':''}`}>
              <span>작가 매칭부터 촬영 스케줄까지<br/><span className="highlight">한 번에 맡기고</span></span>
            </SubTitle>
            <SubTitle {...ServiceWordMo2} className={`${serviceImgNoMo===1?'active':''}`}>
              <span>촬영 내역부터 수정 요청까지<br/><span className="highlight">한 번에 관리하고</span></span>
            </SubTitle>
            <SubTitle {...ServiceWordMo3} className={`${serviceImgNoMo===2?'active':''}`}>
              <span>결과물부터 결산 내역까지<br/><span className="highlight">한 번에 전달받으세요</span></span>
            </SubTitle>
          </SubTitleBox>
        </ServiceBox>
        <BulletWrap>
          <Bullet className={`${serviceImgNoMo===0?'active':''}`}></Bullet>
          <Bullet className={`${serviceImgNoMo===1?'active':''}`}></Bullet>
          <Bullet className={`${serviceImgNoMo===2?'active':''}`}></Bullet>
        </BulletWrap>
      </MobileServiceWrap>
    </Container>
  )
}

const Container = styled.section`
  width:${({ theme }) => theme.style.desktopWidth};
  margin:${({ theme }) => theme.style.containerMargin};
  @media ${({ theme }) => theme.device.mobile} {
    width:100%;
    margin:100px auto 0;
  }
`;

const ServiceWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 45%;
  transition: background .2s ease-in-out;
  background: url(${props=>props.image});
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  @media ${({ theme }) => theme.device.mobile} {
    display:none;
  }
`;

const ServiceBox = styled.div`
  position: absolute;
  top: 124px;
  left: 0;
  padding-left: 124px;
  @media ${({ theme }) => theme.device.mobile} {
    top: 13.35vw;
    padding-left: 13.35vw;
  }
`;

const Title = styled.div`
  font-size:45px;
  font-weight: 800;
  margin: 0 0 76px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size:6.5vw;
    font-weight:700;
    margin: 0 0 7vw;
  }
`;

const SubTitleBox = styled.ul`
  list-style-type: decimal;
  margin-left:20px;
  .highlight {
    color: #FE5A1D;
    // color: ${({ theme }) => theme.style.accentColor};
  }
  @media ${({ theme }) => theme.device.mobile} {
    .highlight {
      display: inline-block;
      margin: 5px 0 0;
    }
  }
`;

const SubTitle = styled.li`
  cursor:pointer;
  
  &::marker {
    width:10px;
  }
  &::marker, span{
      opacity:.4;
      font-size:20px;
      font-weight:300;
      transition: opacity .2s ease-in-out;

      &:first-child::before {
        position: absolute;
      }
    }
  &.active{
    &::marker, span{
      opacity:1;
      font-weight:700;
      font-size:26px;
    }
    
    
  }

  & + & {
    padding:33px 0 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    &::marker, span{
      opacity:.4;
      font-size:3.47vw;
      font-weight:300;
      transition: opacity .2s ease-in-out;
    }
    &.active{
      &::marker, span{
        opacity:1;
        font-weight:700;
        font-size:4.27vw;
      }
      
      
    }

    & + & {
      padding:3vw 0 0;
    }
  }
`;

const BulletWrap = styled.div`
  position:relative;
  left:50%;
  bottom:25px;
  width:50px;
  transform:translate3d(-50%,0,0);
`;

const Bullet = styled.div`
  position:relative;
  display: inline-block;
  background-color: white;
  border-radius: 100%;
  width:10px;
  height:10px;
  &::after{
    transition: background-color .2s ease-in-out;
  }
  &.active {
    &::after{
      content:'';
      position:absolute;
      top:50%;
      left:50%;
      transform:translate3d(-50%,-50%,0);
      width:5px;
      height:5px;
      background-color:#6F7070;
      border-radius: 100%;
    }
  }
  & + & {
    margin-left: 10px;
  }
`;

const MobileServiceWrap = styled.div`
  display:none;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
  transition: background .2s ease-in-out;
  background: url(${props=>props.image});
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  @media ${({ theme }) => theme.device.mobile} {
    display:block;
    padding-top: 131%;
  }
`;
export default Service;