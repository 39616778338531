import axios from 'axios'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { isGuestLoggedIn, isUserLoggedIn } from './jwtUtil';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/rest`,
  timeout: 3000,
});

/* Request Interceptor */
axiosInstance.interceptors.request.use(
  config => {
    if (!isUserLoggedIn()) {
      localStorage.removeItem('briphyMarketplaceUser');
      
      // TODO login 화면으로 return
      // return <Navigate to="/login" replace/>;
    } 
    
    if(!isGuestLoggedIn()) {
      localStorage.removeItem('briphyMarketplaceGuest');
    }
    
    if (!isUserLoggedIn() && !isGuestLoggedIn()) {
      localStorage.removeItem('accessToken');

      // TODO login 화면으로 return
      // return <Navigate to="/login" replace/>;
    } 

    // ** Get token from localStorage
    const accessToken = localStorage.getItem('accessToken');
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers['X-AUTH-TOKEN'] = accessToken;
      // config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/* Response Interceptor */
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { config, response } = error
    if (response && response.status === 401) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('briphyMarketplaceUser');
    }

    return Promise.reject(error);
  },
);

const jsonConfig = {
  headers:{
      "Content-Type": "application/json;charset=UTF-8"
  }
}

export const mainApi = {
  getMainProductList: (obj) => axiosInstance.get('/smp/home/product/main/list', obj),
  insertConnectInfo: (obj) => axiosInstance.post('/smp/home/connect/insert', obj),
  getPartnerCommonInfo: () => axiosInstance.get('/smp/home/guest/code'),
  insertConnectPortfolioInfo: (obj) => axiosInstance.post('/smp/home/connect/ptfo/insert', obj),
}

export const memberApi = {
  login: (obj) => axiosInstance.post('/login/smp', obj),
  guestLogin: (obj) => axiosInstance.post('/login/guest', obj),
  findId: (obj) => axiosInstance.post('/login/find/loginId', obj),
  findPW: (obj) => axiosInstance.post('/login/find/pwd', obj),
  modifyPW: (obj) => axiosInstance.put('/login/smp/password/modify', obj),
  checkId: (obj) => axiosInstance.get('/login/smp/checkId', {params:obj}),
  checkEmail: (obj) => axiosInstance.get('/login/smp/checkEmail', {params:obj}),
  join: (obj) => axiosInstance.post('/login/smp/join', obj),
  snsLogin: (obj) => axiosInstance.post('/login/sns', obj),
  snsJoin: (obj) => axiosInstance.post('/login/sns/join', obj),
  getCustUserInfo: (custUserNo) => axiosInstance.get('/smp/user/info/'.concat(custUserNo)),
  modifyUserInfo : (obj) => axiosInstance.put('/smp/user/modify', obj),
  deleteUserInfo : (obj) => axiosInstance.put('/smp/user/withdrawal', obj),
}

export const productApi = {
  getProductList: (priorCtgrId) => axiosInstance.get('/smp/home/product/list?priorCtgrId='.concat(priorCtgrId), jsonConfig),
  getProductInfo: (prodId) => axiosInstance.get('/smp/home/product/detail/'.concat(prodId), jsonConfig),
  getUserSimpleInfo: (custUserNo) => axiosInstance.get('/smp/user/minimum/info/ '.concat(custUserNo), jsonConfig),
  insertOrderInfo: (obj) => axiosInstance.post('/smp/home/order/insert', obj),
  insertGuestOrderInfo: (obj) => axiosInstance.post('/smp/home/guest/order/insert', obj),
  callbackTossSuccess: (obj) => axiosInstance.post('/smp/home/order/payment/success', obj),
  callbackTossFail: (obj) => axiosInstance.post('/smp/home/order/payment/fail', obj),
  guestPurchase : (obj) => axiosInstance.post('/smp/home/guest/insert', obj),
}

export const mypageApi = {
  orderList: (obj) => axiosInstance.get('/smp/home/order/product/list', {params:obj}),
  orderDetailInfo: (ordId) => axiosInstance.get('/smp/home/order/detail/'.concat(ordId)),
  cancelCodeList: (obj) => axiosInstance.get('/smp/home/order/list/cancelCode'),
  cancelOrder: (obj) => axiosInstance.post('/smp/home/order/payment/cancel/update', obj),
  guestOrderList: (obj) => axiosInstance.get('/smp/home/guest/order', {params:obj}),
}

export const portfolioApi = {
  getCategoryList: (obj) => axiosInstance.get('/portfolio/category/list', obj),
  getTagList: (obj) => axiosInstance.get('/portfolio/tag/list', obj),
  getPortfolioList: (obj) => axiosInstance.get('/portfolio/item/list', {params:obj}),
  getStudioPortfolioList: (obj) => axiosInstance.get('/portfolio/studio/item/list', {params:obj}),
  insertContact: (obj) => axiosInstance.post('/portfolio/enquiry', obj),
  insertHitItem: (ptfoSeqno) => axiosInstance.post(`/portfolio/item/hit/${ptfoSeqno}`),
  getMainPortfolioList: (obj) => axiosInstance.get('/portfolio/main/list', {params:obj}),
  getPortfolioPhotoList: (ptfoSeqno) => axiosInstance.get(`/portfolio/item/portfolio/${ptfoSeqno}`),
  getVideoMainPortfolioList: (obj) => axiosInstance.get('/portfolio/video/main/list', {params:obj}),
  getStudioMainPortfolioList: (obj) => axiosInstance.get('/portfolio/studio/video/main/list', {params:obj})
}

export const quotationApi = {
  getCategoryList: () => axiosInstance.get('/smp/home/quotation/init'),
  getQuestionList: (qttCtgrId) => axiosInstance.get(`/smp/home/quotation/list/${qttCtgrId}`),
  getResponseList: (freQttId) => axiosInstance.get(`/smp/home/quotation/result/${freQttId}`),
  insertQuotationInfo: (obj) => axiosInstance.post('/smp/home/quotation/info/insert', obj),
  updateQuotationInfo: (obj) => axiosInstance.put('/smp/home/quotation/info/update', obj),
  insertResponseInfo: (obj) => axiosInstance.post('/smp/home/quotation/response/insert', obj),
  deleteResponseInfo: (obj) => axiosInstance.delete('/smp/home/quotation/response/delete', obj),
}

export const reviewApi = {
  getMainVideoReviewList: () => axiosInstance.get('/smp/home/review/main/video/list')
}